import React, { useState, useEffect } from "react";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import logo2 from "../src/img/logo2.png";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ApiIcon from "@mui/icons-material/Api";
import FunctionsIcon from "@mui/icons-material/Functions";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import LoopIcon from "@mui/icons-material/Loop";
import { APP_PREFIX } from "./config"; // Adjust the path if necessary
import StorageIcon from '@mui/icons-material/Storage';
import './Header.css';
import stampImage from '../src/img/republicstamp.svg';
import stampImage2 from '../src/img/republicstamp_flagdz.png';
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GroupIcon from '@mui/icons-material/Group';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HistoryIcon from '@mui/icons-material/History';

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import CloseIcon from "@mui/icons-material/Close";
import iconbarleft from "../src/img/logo.png";
import EditIcon from "@mui/icons-material/Edit";

import PersonIcon from "@mui/icons-material/Person";
import { Link, useLocation } from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import DescriptionIcon from "@mui/icons-material/Description";
import PrintIcon from "@mui/icons-material/Print";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

import { Button, Grid } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import appico from "../src/img/logo.png";
import downapp from "../src/img/logo.png";

import Footer from "../containers/Footer";
import ApartmentIcon from "@mui/icons-material/Apartment";

import "./amirifont.css";
import pharmacy from "../src/img/logo.png";
import blooddon from "../src/img/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import moresearch from "../src/img/logo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { API_BASE_URL } from "../containers/config"; // Adjust the path if necessary
import flag_dz from "../src/img/flag_dz.png";
import { useMediaQuery } from "@mui/material";
import useAuthentication_logged from "./useAuthentication_logged";
import useAuthentication_cand from "./useAuthentication_cand";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const themeRoboto = createTheme({
  typography: {
    fontFamily: '"Roboto Slab", serif',
    fontSize: 10,
  },
});

const themeChanga = createTheme({
  typography: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 10,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));


export default function Listmenumt(
) {

  const authlogged = useAuthentication_logged(); // Get values from useAuthentication_logged
  const authCandData = useAuthentication_cand(); // Always call the hook at the top level, but we will conditionally use its data
  const [authCand, setAuthCand] = useState(null); // Create a state for authCand

  // Function to get the value from authlogged
  const getValue = (key) => {
    try {
      return authlogged?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  const IDCandidat = getValue("IDCandidat");
  const IDEncadrement = getValue("IDEncadrement");
  const IDetablissement = getValue("IDetablissement");
  const IDDFEP = getValue("IDDFEP");
  const IDNature_etsF = getValue("IDNature_etsF");
  const IDUtilisateur = getValue("IDUtilisateur");
  const IDapis_userslist = getValue("IDapis_userslist");
  const loadingauth = getValue("loadingauth");

  // Function to get the value from authCand if it exists
  const getValuecand = (key) => {
    try {
      return authCand?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  let NumSem = getValuecand("NumSem");
  let loadingauthcand = getValuecand("loadingauth");
  let Singup_active = getValuecand("Singup_active");
  let download_cert_scol_active = getValuecand("download_cert_scol_active");
  let EditingInfo_active = getValuecand("EditingInfo_active");
  let Eval_notes_active = getValuecand("Eval_notes_active");
  let download_cert_inscription_active = getValuecand("download_cert_inscription_active");
  let Singup_active_Dfep = getValuecand("Singup_active_Dfep");
  let download_cert_scol_active_Dfep = getValuecand("download_cert_scol_active_Dfep");
  let EditingInfo_active_Dfep = getValuecand("EditingInfo_active_Dfep");
  let Eval_notes_active_Dfep = getValuecand("Eval_notes_active_Dfep");
  let download_cert_inscription_active_Dfep = getValuecand("download_cert_inscription_active_Dfep");
  let Singup_active_Mfep = getValuecand("Singup_active_Mfep");
  let download_cert_scol_active_Mfep = getValuecand("download_cert_scol_active_Mfep");
  let EditingInfo_active_Mfep = getValuecand("EditingInfo_active_Mfep");
  let Eval_notes_active_Mfep = getValuecand("Eval_notes_active_Mfep");
  let download_cert_inscription_active_Mfep = getValuecand("download_cert_inscription_active_Mfep");
  let crypted_id = getValuecand("crypted_id");

  const navigate = useNavigate();

  useEffect(() => {
    // Check if IDCandidat is valid and authCandData is not loading
    if (IDCandidat !== null && IDCandidat !== undefined && Number(IDCandidat) > 0 && !authCandData["loadingauth"]) {
      // Deep comparison of authCand and authCandData to prevent unnecessary state updates
      if (JSON.stringify(authCand) !== JSON.stringify(authCandData)) {

        setAuthCand(authCandData); // Update authCand state when IDCandidat is valid and authCand has changed
      }
    }
  }, [IDCandidat, authCandData, authCand]); // Trigger useEffect when IDCandidat, authCandData, or authCand changes


  const location = useLocation();

  const currentPath = location.pathname.toLowerCase(); // Normalize the path to lowercase


  const [etabloaded, setetabloaded] = useState(false);

  useEffect(() => {
    // Check if IDetablissement is not null and IDEncadrement is valid
    if (
      IDetablissement !== null && IDetablissement !== undefined &&
      Number(IDetablissement) > 0 &&
      IDEncadrement !== null &&
      IDEncadrement !== undefined &&
      Number(IDEncadrement) > 0 &&
      !authlogged["loadingauth"] && !etabloaded
    ) {

      setetabloaded(true);

      // Condition check
      const shouldNavigateHome =
        IDetablissement === null ||
        IDetablissement === undefined ||
        IDNature_etsF === null ||
        IDNature_etsF === undefined ||
        IDEncadrement === null ||
        IDEncadrement === undefined ||
        ![
          32, 34, 35, 36, 54, 55, 56, 22, 29, 48, 7
        ].includes(Number(IDUtilisateur))


      if (currentPath === '/showallencad' && shouldNavigateHome) {
        navigate('/Logout');
      }

      const shouldNavigateHome2 =
        IDetablissement === null ||
        IDetablissement === undefined ||
        IDNature_etsF === null ||
        IDNature_etsF === undefined ||
        IDEncadrement === null ||
        IDEncadrement === undefined ||
        ![
          32, 35
        ].includes(Number(IDUtilisateur))


      if (
        (currentPath === '/settingdfep' || currentPath === '/settingmfep') &&
        shouldNavigateHome2
      ) {
        navigate('/Logout');
      }

      const shouldNavigateHome3 =
        IDetablissement === null ||
        IDetablissement === undefined ||
        IDNature_etsF === null ||
        IDNature_etsF === undefined ||
        IDEncadrement === null ||
        IDEncadrement === undefined ||
        ![
          25, 34, 36
        ].includes(Number(IDUtilisateur))


      if (
        (currentPath === '/ChangPwStEtab') &&
        shouldNavigateHome3
      ) {
        navigate('/Logout');
      }

      const shouldNavigateHome4 =
        IDetablissement === null ||
        IDetablissement === undefined ||
        IDNature_etsF === null ||
        IDNature_etsF === undefined ||
        IDEncadrement === null ||
        IDEncadrement === undefined ||
        ![
          32, 35, 34, 36
        ].includes(Number(IDUtilisateur))


      if (
        (currentPath === '/ChangePwEtab') &&
        shouldNavigateHome4
      ) {
        navigate('/Logout');
      }



    }
  }, [IDEncadrement, authlogged]); // Trigger useEffect when IDEncadrement or authlogged changes





  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {

    setOpen(false);
  };

  const themeChangaHeader = createTheme({
    typography: {
      fontFamily: '"Tajawal", sans-serif',
      fontSize: 16,
      fontWeight: 900,
    },
  });

  const themeChanga = createTheme({
    typography: {
      fontFamily: '"Tajawal", sans-serif',
      fontSize: 14,
      fontWeight: 900,
    },
  });

  const [lastClicked, setLastClicked] = useState(null);

  // Handler for clicking on ListItem
  const handleClickactive = (item) => {
    setLastClicked(item);
    handleDrawerClose();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openprint = Boolean(anchorEl);

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isMatch_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch_xs = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl_infocand, setAnchorEl_infocand] = React.useState(null);
  const open_infocand = Boolean(anchorEl_infocand);
  const handleClick_infocand = (event) => {
    setAnchorEl_infocand(event.currentTarget);
  };
  const handleClose_infocand = () => {
    setAnchorEl_infocand(null);
  };

  const [anchorElMotakawin, setAnchorElMotakawin] = React.useState(null);
  const openMotakawin = Boolean(anchorElMotakawin);
  const handleClickMotakawin = (event) => {
    setAnchorElMotakawin(event.currentTarget);
  };
  const handleCloseMotakawin = () => {
    setAnchorElMotakawin(null);
  };

  const [anchorElMotakawin_guide, setAnchorElMotakawin_guide] = React.useState(null);
  const openMotakawin_guide = Boolean(anchorElMotakawin_guide);
  const handleClickMotakawin_guide = (event) => {
    setAnchorElMotakawin_guide(event.currentTarget);
  };
  const handleCloseMotakawin_guide = () => {
    setAnchorElMotakawin_guide(null);
  };


  const handleClickSpecialiteinfo = () => {
    // Navigate to the desired route
    navigate("/Specialiteinfo");
    handleCloseMotakawin();
  };

  const handleClicketabinfo = () => {
    // Navigate to the desired route
    navigate("/Etabinfo");
    handleCloseMotakawin();
  };

  const handleClicksettingapi = () => {
    // Navigate to the desired route
    navigate("/Modeformationtableinfo");
    handleCloseMotakawin_guide();
  };
  const handleClicksettingapi_guide = () => {
    // Navigate to the desired route
    navigate("/Modeformationtableinfo");
    handleCloseMotakawin_guide();
  };

  const handleClickHowToSignUp = () => {
    // Navigate to the desired route
    navigate("/HowToSignUp");
    handleCloseMotakawin_guide();
  };


  const handleClickValidationStEtab = () => {
    // Navigate to the desired route
    navigate("/ValidationCandInfo");
    handleCloseMotakawin();
  };

  const handleClickChangPwStEtab = () => {
    // Navigate to the desired route
    navigate("/ChangPwStEtab");
    handleCloseMotakawin();
  };

  const handleClicksettingDfep = () => {
    // Navigate to the desired route
    if (Number(IDNature_etsF) === 5) {
      navigate("/SettingDfep");
    } else if (Number(IDNature_etsF) === 1) {
      navigate("/SettingMfep");
    }

    handleCloseMotakawin();
  };



  const handleClicksettingApis_functions = () => {
    // Navigate to the desired route
    navigate("/Apis_functions");
    handleCloseMotakawin();
  };

  const handleClicksettingMigrate_hfsql = () => {
    // Navigate to the desired route
    navigate("/Migrate_hfsql");
    handleCloseMotakawin();
  };

  const handleClicksession = () => {
    // Navigate to the desired route
    navigate("/SessionsMfep");
    handleCloseMotakawin();
  };

  const handleClicksettingapis_userlist_functions = () => {
    // Navigate to the desired route
    navigate("/apis_userlist_functions");
    handleCloseMotakawin();
  };

  const handleClickChangPwPriv = () => {
    // Navigate to the desired route
    navigate("/ChangPwPriv");
    handleCloseMotakawin();
  };

  const handleClickChangePwEtab = () => {
    // Navigate to the desired route
    navigate("/ChangePwEtab");
    handleCloseMotakawin();
  };

  const [hidepagest, sethidepagest] = useState(false);
  const [hidepageetub, sethidepageetub] = useState(false);

  let toLink_SettingDfep_or_SettingEtab = "";
  let toLink_changpwst = "";
  let toLink_Offers = "";

  if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    Number(IDNature_etsF) === 1
  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeMfep";
    toLink_changpwst = "/ChangPwStMfep";
    toLink_Offers = "/OffersMfep";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    (IDNature_etsF === 40 || IDNature_etsF === "40")
  ) {
    toLink_SettingDfep_or_SettingEtab = "/InnovationList";
    toLink_changpwst = "/ChangPwStEtab";
    toLink_Offers = "";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    (Number(IDNature_etsF) === 5 || IDNature_etsF === "5")

  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeDfep";
    toLink_changpwst = "/ChangPwStDfep";
    toLink_Offers = "/OffersDfep";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== ""

  ) {
    toLink_SettingDfep_or_SettingEtab = "/SettingEtab";
    toLink_changpwst = "/ChangPwStEtab";
    toLink_Offers = "/OffersEtab";
  }

  else if (
    [63, 999].includes(Number(IDUtilisateur))
  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeUtilisateur";
  }



  return (
    <List>
      {

        (IDUtilisateur == null || ![63, 999].includes(Number(IDUtilisateur))) &&
          (IDetablissement == null || IDetablissement == undefined) &&
          (IDCandidat == null || IDCandidat == undefined) &&
          (IDEncadrement == null || IDEncadrement == undefined) &&
          (IDapis_userslist == null || IDapis_userslist == undefined) ? (
          <>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />

            <ListItem disablePadding >
              <ListItemButton component={Link} to="/Login"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >

                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: '#004d40'
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      <PersonIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      بوابة المتكون
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>

          </>
        ) : (
          <></>
        )}

      {
        (IDUtilisateur == null || ![63, 999].includes(Number(IDUtilisateur))) &&
          (IDetablissement == null || IDetablissement == undefined) &&
          (IDCandidat == null || IDCandidat == undefined) &&
          (IDEncadrement == null || IDEncadrement == undefined) &&
          (IDapis_userslist == null || IDapis_userslist == undefined) ? (
          <>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />

            <ListItem disablePadding >


              <ListItemButton
                component={Link}
                to="/loginEtab"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#004d40",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      <ApartmentIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      بوابة المكون
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <></>
        )}

      {/* Candidat List */}

      {IDCandidat !== null && IDCandidat !== undefined && (
        <>
          <Divider
            variant="inset"
            component="li"
            style={{ width: "100%" }}
          />

          <ListItem disablePadding >



            <ListItemButton

              alignItems="flex-start"
              onClick={handleClick_infocand}
            >
              <ListItemText
                primary={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DescriptionIcon
                      style={{
                        marginRight: "5px",
                        marginBottom: "-6px",
                        color: "#004d40",
                      }}
                    />

                    <Typography
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#004d40",
                      }}
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      حسابي
                    </Typography>
                    <ArrowDropDownIcon style={{ color: "#004d40" }} />
                  </div>
                }
                secondary={
                  <React.Fragment>
                    {/* Any secondary content if needed */}
                  </React.Fragment>
                }
              />
            </ListItemButton>
          </ListItem>



          <Divider
            variant="inset"
            component="li"
            style={{ width: "100%" }}
          />
          <ListItem disablePadding >

            <ListItemButton

              alignItems="flex-start"
              onClick={handleClick}
            >
              <ListItemText
                primary={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PrintIcon
                      style={{
                        marginRight: "5px",
                        marginBottom: "-6px",
                        color: "#004d40",
                      }}
                    />
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#004d40",
                      }}
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      مطبوعات
                    </Typography>
                    <ArrowDropDownIcon style={{ color: "#004d40" }} />
                  </div>
                }
                secondary={
                  <React.Fragment>
                    {/* Any secondary content if needed */}
                  </React.Fragment>
                }
              />
            </ListItemButton>
          </ListItem>
        </>

      )}

      {/* Encadrement List */}

      {
        (IDEncadrement !== null && IDEncadrement !== undefined && IDEncadrement !== ""
          && IDetablissement === null || IDetablissement === undefined || IDetablissement === ""
        ) && (

          <>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />

            <ListItem disablePadding >




              <ListItemButton

                component={Link}
                to="/InfoEncadr"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#004d40",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      <HomeIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      الرئيسية
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>



            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />

            <ListItem disablePadding >




              <ListItemButton

                component={Link}
                to="/UploadCvEncadr"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#004d40",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      <UploadFileIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      رفع السيرة الذاتية
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>

        )
      }


      {
        ([63, 999].includes(Number(IDUtilisateur))
        )
        && (

          <>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />

            <ListItem disablePadding >

              <ListItemButton

                component={Link}
                to="/HomeUtilisateur"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#004d40",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      <HomeIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      الرئيسية
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>





          </>

        )
      }


      {IDetablissement !== null &&
        (IDCandidat == null ||
          Number(IDCandidat) <= 0 ||
          IDCandidat == undefined) &&
        IDetablissement !== undefined && (
          <>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />

            <ListItem disablePadding >

              <ListItemButton

                component={Link}
                to={toLink_SettingDfep_or_SettingEtab}
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#004d40",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      <HomeIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      الرئيسية
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>
        )}





      {
        IDUtilisateur !== null &&
        [63].includes(Number(IDUtilisateur)) &&

        (
          <>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />

            <ListItem disablePadding >

              <ListItemButton

                component={Link}
                to="/ShowAllEncadcellcomm"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#004d40",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      <GroupIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      خلايا الإعلام والاتصال
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>


        )}

      {(
        [999].includes(Number(IDUtilisateur)) ||
        IDetablissement !== null &&
        (IDCandidat == null ||
          Number(IDCandidat) <= 0 ||
          IDCandidat == undefined) &&
        IDetablissement !== undefined) && (
          <>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />
            <ListItem disablePadding >

              <ListItemButton
                onClick={handleClickMotakawin}
                alignItems="flex-start"
              >
                <ListItemText
                  primary={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DescriptionIcon
                        style={{
                          marginRight: "5px",
                          marginBottom: "-6px",
                          color: "#004d40",
                        }}
                      />
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: 0,
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          color: "#004d40",
                        }}
                        type="body2"
                        style={{ color: "#004d40" }}
                      >
                        لوحة التحكم
                      </Typography>
                      <ArrowDropDownIcon style={{ color: "#004d40" }} />
                    </div>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>
        )}

      {
        ((IDUtilisateur !== null && [999].includes(Number(IDUtilisateur))) ||
          (IDetablissement !== null &&
            Number(IDNature_etsF) !== 40 &&
            (IDCandidat == null ||
              Number(IDCandidat) <= 0 ||
              IDCandidat == undefined) &&
            IDetablissement !== undefined)) && (
          <>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />
            <ListItem disablePadding >

              <ListItemButton

                component={Link}
                to="/ShowAllAprs"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#004d40",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      <PrintIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      مطبوعات المتكون
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>
        )}


      {

        ((IDUtilisateur !== null && [63,999].includes(Number(IDUtilisateur))) ||
        (((IDetablissement !== null && Number(IDNature_etsF) >= 1) 
        || (IDEncadrement !== null && IDEncadrement !== undefined && IDEncadrement !== "")))) && (

          <>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />

            <ListItem disablePadding >




              <ListItemButton

                component={Link}
                to="/SpecialitePE"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#004d40",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      <DescriptionIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      برنامج التدريس PE
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>

        )
      }




      <>
        <Divider
          variant="inset"
          component="li"
          style={{ width: "100%" }}
        />
        <ListItem disablePadding >

          <ListItemButton

            component={Link}
            to="/Offersinscription"
            alignItems="flex-start"
            onClick={handleDrawerClose}
          >
            <ListItemText
              primary={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EventRepeatIcon
                    style={{
                      marginRight: "5px",
                      marginBottom: "-6px",
                      color: "#004d40",
                    }}
                  />
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: "4px",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      color: "#004d40",
                    }}
                    type="body2"
                    style={{ color: "#004d40" }}
                  >
                    التسجيل الأولي
                  </Typography>
                </div>
              }
              secondary={
                <React.Fragment>
                  {/* Any secondary content if needed */}
                </React.Fragment>
              }
            />
          </ListItemButton>
        </ListItem>
      </>


      <Divider
        variant="inset"
        component="li"
        style={{ width: "100%" }}
      />
      <ListItem disablePadding >

        <ListItemButton
          component={Link}
          to="/Checkpreinscrit"
          alignItems="flex-start"
          onClick={handleDrawerClose}
        >
          <ListItemText
            primary={
              <div style={{ display: "flex", alignItems: "center" }}>
                <PersonSearchIcon
                  style={{
                    marginRight: "5px",
                    marginBottom: "-6px",
                    color: "#004d40",
                  }}
                />
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: "4px",
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#004d40",
                  }}
                  type="body2"
                  style={{ color: "#004d40" }}
                >
                  تحقق من تسجيلك
                </Typography>
              </div>
            }
            secondary={
              <React.Fragment>
                {/* Any secondary content if needed */}
              </React.Fragment>
            }
          />
        </ListItemButton>
      </ListItem>


      <Divider
        variant="inset"
        component="li"
        style={{ width: "100%" }}
      />
      <ListItem disablePadding >

        <ListItemButton
          component={Link}
          to="/checkAuth"
          alignItems="flex-start"
          onClick={handleDrawerClose}
        >
          <ListItemText
            primary={
              <div style={{ display: "flex", alignItems: "center" }}>
                <DocumentScannerIcon
                  style={{
                    marginRight: "5px",
                    marginBottom: "-6px",
                    color: "#004d40",
                  }}
                />
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: "4px",
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#004d40",
                  }}
                  type="body2"
                  style={{ color: "#004d40" }}
                >
                  تحقق من الشهادة
                </Typography>
              </div>
            }
            secondary={
              <React.Fragment>
                {/* Any secondary content if needed */}
              </React.Fragment>
            }
          />
        </ListItemButton>
      </ListItem>


      <Divider
        variant="inset"
        component="li"
        style={{ width: "100%" }}
      />
      <ListItem disablePadding >

        <ListItemButton
          component={Link}

          onClick={handleClickMotakawin_guide}
          alignItems="flex-start"

        >
          <ListItemText
            primary={
              <div style={{ display: "flex", alignItems: "center" }}>
                <LiveHelpIcon
                  style={{
                    marginRight: "5px",
                    marginBottom: "-6px",
                    color: "#004d40",
                  }}
                />
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: "4px",
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#004d40",
                  }}
                  type="body2"
                  style={{ color: "#004d40" }}
                >
                  دليل المتكون
                </Typography>
                <ArrowDropDownIcon />
              </div>
            }
            secondary={
              <React.Fragment>
                {/* Any secondary content if needed */}
              </React.Fragment>
            }
          />
        </ListItemButton>
      </ListItem>

      {

        (IDUtilisateur !== null && [63, 999].includes(Number(IDUtilisateur))) ||

          (IDEncadrement !== null && IDEncadrement !== undefined && IDEncadrement !== "") ||

          (IDetablissement !== null &&
            (IDCandidat == null ||
              Number(IDCandidat) <= 0 ||
              IDCandidat == undefined) &&
            IDetablissement !== undefined) ||
          (IDCandidat !== null && IDCandidat !== undefined) ? (
          <>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />
            <ListItem disablePadding >

              <ListItemButton
                component={Link}
                to="/Logintraces"
                alignItems="flex-start"
                onClick={handleDrawerClose}
              >
                <ListItemText
                  primary={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <HistoryIcon
                        style={{
                          marginRight: "5px",
                          marginBottom: "-6px",
                          color: "#004d40",
                        }}
                      />
                      <Typography
                        theme={themeChanga}
                        sx={{
                          textAlign: "right",
                          mr: "4px",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 700,
                          color: "#004d40",
                        }}
                        type="body2"
                        style={{ color: "#004d40" }}
                      >
                        سجل نشاط الحساب
                      </Typography>
                    </div>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>

        ) : (
          <></>
        )}
      {
        (IDUtilisateur !== null && [63, 999].includes(Number(IDUtilisateur))) ||

          (IDEncadrement !== null && IDEncadrement !== undefined && IDEncadrement !== "") ||

          (IDetablissement !== null &&
            (IDCandidat == null ||
              Number(IDCandidat) <= 0 ||
              IDCandidat == undefined) &&
            IDetablissement !== undefined) ||
          (IDCandidat !== null && IDCandidat !== undefined) ? (
          <>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />
            <ListItem disablePadding >

              <ListItemButton

                component={Link}
                to="/Logout"
                alignItems="flex-start"
                onClick={() => {
                  if (
                    IDapis_userslist !== null &&
                    IDapis_userslist !== undefined
                  ) {

                    // Remove all data with the prefix
                    const keysToRemove = Object.keys(localStorage).filter(
                      (key) => key.startsWith(APP_PREFIX)
                    );
                    keysToRemove.forEach((key) => localStorage.removeItem(key));

                    sethidepagest(false);
                    sethidepageetub(false);

                    setOpen(false);
                  } else {
                    const destroyToken = `${API_BASE_URL}/api/destroyToken`;

                    fetch(destroyToken, {
                      headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${sessionStorage.getItem(
                          "authData_73xJ#E"
                        )}`,
                      },
                    })
                      .then((response) => {
                        if (!response.ok) {
                          sessionStorage.clear();
                          throw new Error("Network response was not ok");
                        }
                        return response.json();
                      })
                      .then((data) => {
                        if (data) {
                          sessionStorage.clear();
                          console.error("Distroyed token: successfully");
                        }
                      })
                      .catch((error) => {
                        sessionStorage.clear();
                        console.error("Error destroyToken:", error);
                      })
                      .finally(() => {
                        sessionStorage.clear();
                        // Remove all data with the prefix
                        const keysToRemove = Object.keys(localStorage).filter(
                          (key) => key.startsWith(APP_PREFIX)
                        );
                        keysToRemove.forEach((key) =>
                          localStorage.removeItem(key)
                        );

                        sethidepagest(false);
                        sethidepageetub(false);

                        setOpen(false);
                      });
                  }
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      theme={themeChanga}
                      sx={{
                        textAlign: "right",
                        mr: 0,
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: "#004d40",
                      }}
                      variant="h6"
                      type="body2"
                      style={{ color: "#004d40" }}
                    >
                      <LogoutIcon
                        style={{ marginRight: "5px", marginBottom: "-6px" }}
                      />{" "}
                      خروج
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {/* Any secondary content if needed */}
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <></>
        )}

      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openprint}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {IDCandidat !== null &&
            IDCandidat !== undefined &&
            download_cert_inscription_active !== null &&
            download_cert_inscription_active !== undefined &&
            Number(download_cert_inscription_active) === 1 &&
            Number(download_cert_inscription_active_Dfep) === 1 &&
            Number(download_cert_inscription_active_Mfep) === 1
            &&
            Number(NumSem) === 1
            ? (
              <MenuItem
                component={Link}
                to={`/ETATCertificatInscriptionPDF/${crypted_id}`}
                target="_blank" // Add this line to open in a new tab
              >
                {" "}
                <PictureAsPdfIcon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  {" "}
                  شهادة تسجيل{" "}
                </Typography>
              </MenuItem>
            ) : Number(NumSem) === 1 ? (
              <MenuItem disabled={true}>
                {" "}
                <PictureAsPdfIcon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  {" "}
                  شهادة تسجيل{" "}
                </Typography>
              </MenuItem>
            ) : null}

          {IDCandidat !== null &&
            IDCandidat !== undefined &&
            download_cert_scol_active !== null &&
            download_cert_scol_active !== undefined &&
            Number(download_cert_scol_active) === 1 &&
            Number(download_cert_scol_active_Dfep) === 1 &&
            Number(download_cert_scol_active_Mfep) === 1 ? (
            <div>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />
              <MenuItem
                component={Link}
                to={`/ETATCertificatScolaritePDF/${crypted_id}`}
                target="_blank" // Add this line to open in a new tab
              >
                {" "}
                <PictureAsPdfIcon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  {" "}
                  شهادة تربص{" "}
                </Typography>
              </MenuItem>
            </div>
          ) : (
            <div>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />
              <MenuItem disabled={true}>
                {" "}
                <PictureAsPdfIcon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  {" "}
                  شهادة تربص{" "}
                </Typography>
              </MenuItem>
            </div>
          )}



        </Menu>
      </div>

      <div>
        <Menu
          id="basic-menu_infocand"
          anchorEl={anchorEl_infocand}
          open={open_infocand}
          onClose={handleClose_infocand}
          MenuListProps={{
            "aria-labelledby": "basic-button_infocand",
          }}
        >
          <MenuItem component={Link} to="/InfoCand">
            {" "}
            <PersonIcon />{" "}
            <Typography
              theme={themeChanga}
              sx={{
                textAlign: "right",
                mr: 0,
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 16,
                fontWeight: 700,
                mr: "5px",
              }}
              type="body2"
            >
              بطاقة معلوماتي
            </Typography>
          </MenuItem>

          {IDCandidat !== null &&
            IDCandidat !== undefined &&
            Eval_notes_active !== null &&
            Eval_notes_active !== undefined &&
            Number(Eval_notes_active) === 1 &&
            Number(Eval_notes_active_Dfep) === 1 &&
            Number(Eval_notes_active_Mfep) === 1 ? (
            <div>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />
              <MenuItem component={Link} to="/NotesApr">
                {" "}
                <StickyNote2Icon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  {" "}
                  نتائج السداسي{" "}
                </Typography>
              </MenuItem>
            </div>
          ) : (
            <div>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />
              <MenuItem disabled={true}>
                {" "}
                <StickyNote2Icon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  {" "}
                  نتائج السداسي{" "}
                </Typography>
              </MenuItem>
            </div>
          )}

          {IDCandidat !== null && IDCandidat !== undefined ? (
            <div>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />
              <MenuItem component={Link} to="/ChangePassword">
                {" "}
                <LockIcon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  {" "}
                  تغيير كلمة السر{" "}
                </Typography>
              </MenuItem>
            </div>
          ) : null}
        </Menu>
      </div>

      <div>
        <Menu
          id="basic-menuMotakawin_guide"
          anchorEl={anchorElMotakawin_guide}
          open={openMotakawin_guide}
          onClose={handleCloseMotakawin_guide}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >

          <div>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />
            <MenuItem onClick={handleClickHowToSignUp}>
              {" "}
              <VideoLibraryIcon />{" "}
              <Typography
                theme={themeChanga}
                sx={{
                  textAlign: "right",
                  mr: 0,
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 700,
                  mr: "5px",
                }}
                type="body2"
              >
                {" "}
                خطوات التسجيل
              </Typography>
            </MenuItem>
          </div>

          <div>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />
            <MenuItem onClick={handleClicksettingapi_guide}>
              {" "}
              <MenuBookIcon />{" "}
              <Typography
                theme={themeChanga}
                sx={{
                  textAlign: "right",
                  mr: 0,
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 700,
                  mr: "5px",
                }}
                type="body2"
              >
                {" "}
                أنماط التكوين
              </Typography>
            </MenuItem>
          </div>



          <div>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />
            <MenuItem onClick={handleClicketabinfo}>
              {" "}
              <ApartmentIcon />{" "}
              <Typography
                theme={themeChanga}
                sx={{
                  textAlign: "right",
                  mr: 0,
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 700,
                  mr: "5px",
                }}
                type="body2"
              >
                {" "}
                مؤسسات التكوين
              </Typography>
            </MenuItem>
          </div>


          <div>
            <Divider
              variant="inset"
              component="li"
              style={{ width: "100%" }}
            />
            <MenuItem onClick={handleClickSpecialiteinfo}>
              {" "}
              <EngineeringIcon />{" "}
              <Typography
                theme={themeChanga}
                sx={{
                  textAlign: "right",
                  mr: 0,
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 700,
                  mr: "5px",
                }}
                type="body2"
              >
                {" "}
                البطاقة الوصفية للتخصصات
              </Typography>
            </MenuItem>
          </div>


        </Menu>
      </div>

      <div>
        <Menu
          id="basic-menuMotakawin"
          anchorEl={anchorElMotakawin}
          open={openMotakawin}
          onClose={handleCloseMotakawin}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {
            [99999].includes(Number(IDUtilisateur))
            && (
              <div>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />
                <MenuItem onClick={handleClicksettingapi}>
                  {" "}
                  <ApiIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    {" "}
                    إدارة حسابات (API){" "}
                  </Typography>
                </MenuItem>
              </div>
            )}

          {[99999].includes(Number(IDUtilisateur)) && (
            <div>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />
              <MenuItem onClick={handleClicksettingApis_functions}>
                {" "}
                <FunctionsIcon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  {" "}
                  إدارة الدوال (API Functions){" "}
                </Typography>
              </MenuItem>
            </div>
          )}

          {[99999].includes(Number(IDUtilisateur)) && (
            <div>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />
              <MenuItem
                onClick={handleClicksettingapis_userlist_functions}
              >
                {" "}
                <FunctionsIcon /> <ApiIcon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  {" "}
                  الدالة و الحساب (API){" "}
                </Typography>
              </MenuItem>
            </div>
          )}

          {[99999].includes(Number(IDUtilisateur)) && (
            <div>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />
              <MenuItem onClick={handleClicksession}>
                {" "}
                <LoopIcon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  {" "}
                  الدورات{" "}
                </Typography>
              </MenuItem>
            </div>
          )}

          {IDetablissement !== null &&
            IDetablissement !== undefined &&
            IDNature_etsF !== null &&
            IDNature_etsF !== undefined &&
            IDEncadrement !== null &&
            IDEncadrement !== undefined &&
            Number(IDNature_etsF) >= 1 &&
            Number(IDNature_etsF) <= 17 &&
            Number(IDNature_etsF) !== 1 &&
            (Number(IDUtilisateur) === 25 ||
              Number(IDUtilisateur) === 34
              || Number(IDUtilisateur) === 36) &&
            Number(IDNature_etsF) !== 5 && (
              <div>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />

                <MenuItem
                  component={Link}
                  to="/PreinscritQr"
                >
                  {" "}
                  <DocumentScannerIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    {" "}
                    تأكيد التسجيل الأولي {" "}
                  </Typography>
                </MenuItem>
              </div>
            )}




          {IDetablissement !== null &&
            IDetablissement !== undefined &&
            IDNature_etsF !== null &&
            IDNature_etsF !== undefined &&
            IDEncadrement !== null &&
            IDEncadrement !== undefined &&
            Number(IDNature_etsF) >= 1 &&
            Number(IDNature_etsF) <= 17 &&
            Number(IDNature_etsF) !== 1 &&
            (Number(IDUtilisateur) === 25 ||
              Number(IDUtilisateur) === 34
              || Number(IDUtilisateur) === 36


            ) &&
            Number(IDNature_etsF) !== 5 && (

              <div>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />

                <MenuItem
                  component={Link}
                  to="/ShowAllPreinscrit"
                >
                  {" "}
                  <PlaylistAddCheckIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    {" "}
                    قائمة المسجلين {" "}
                  </Typography>
                </MenuItem>
              </div>
            )}

          {IDetablissement !== null &&
            Number(IDNature_etsF) !== 40 &&
            (IDCandidat == null ||
              Number(IDCandidat) <= 0 ||
              IDCandidat == undefined) &&
            IDetablissement !== undefined &&
            IDNature_etsF !== null &&
            IDNature_etsF !== undefined && (
              <div>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />
                {/*
component={Link} to={toLink_Offers}
*/}
                <MenuItem
                  sx={{
                    display: 'none'
                  }}
                >
                  {" "}
                  <EventRepeatIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    {" "}
                    عروض التكوين{" "}
                  </Typography>
                </MenuItem>
              </div>
            )}

          {IDetablissement !== null &&
            IDetablissement !== undefined &&
            IDNature_etsF !== null &&
            IDNature_etsF !== undefined &&
            IDEncadrement !== null &&
            IDEncadrement !== undefined &&
            Number(IDNature_etsF) >= 1 &&
            Number(IDNature_etsF) <= 17 &&
            (Number(IDUtilisateur) === 32
              || Number(IDUtilisateur) === 34
              || Number(IDUtilisateur) === 35
              || Number(IDUtilisateur) === 36
              || Number(IDUtilisateur) === 54
              || Number(IDUtilisateur) === 55
              || Number(IDUtilisateur) === 56
              || Number(IDUtilisateur) === 22
              || Number(IDUtilisateur) === 29
              || Number(IDUtilisateur) === 48
              || Number(IDUtilisateur) === 7
            ) &&
            Number(IDNature_etsF) !== 1
            && (
              <div>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />

                <MenuItem component={Link} to="/ShowAllEncad"
                >
                  {" "}
                  <GroupIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    قائمة المستخدمين {" "}
                  </Typography>
                </MenuItem>
              </div>
            )}

          {
            IDUtilisateur !== null &&
            [999].includes(Number(IDUtilisateur)) &&

            (
              <div>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />

                <MenuItem component={Link} to="/showAllEncadmfep"
                >
                  {" "}
                  <GroupIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    قائمة المستخدمين {" "}
                  </Typography>
                </MenuItem>
              </div>



            )}

          {
            IDUtilisateur !== null &&
            [999].includes(Number(IDUtilisateur)) &&

            (
              <div>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />

                <MenuItem component={Link} to="/SettingMfep"
                >
                  {" "}
                  <ApartmentIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    إدارة حسابات المؤسسات {" "}
                  </Typography>
                </MenuItem>
              </div>



            )}



          {IDetablissement !== null &&
            IDetablissement !== undefined &&
            IDNature_etsF !== null &&
            IDNature_etsF !== undefined &&
            IDEncadrement !== null &&
            IDEncadrement !== undefined &&
            Number(IDNature_etsF) >= 1 &&
            Number(IDNature_etsF) <= 17 &&
            (Number(IDUtilisateur) === 25 ||
              Number(IDUtilisateur) === 34
              || Number(IDUtilisateur) === 36


            ) &&
            Number(IDNature_etsF) !== 1
            && (
              <div>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />

                <MenuItem component={Link} to="/ChangPwStEtab"
                >
                  {" "}
                  <LockIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    تغيير كلمة سر المتكون{" "}
                  </Typography>
                </MenuItem>
              </div>
            )}

          {IDetablissement !== null &&
            (IDCandidat == null ||
              Number(IDCandidat) <= 0 ||
              IDCandidat == undefined) &&
            IDetablissement !== undefined &&
            IDEncadrement !== null &&
            IDEncadrement !== undefined &&
            (Number(IDNature_etsF) === 5 ||
              Number(IDNature_etsF) === 1) &&
            (Number(IDUtilisateur) === 32
              || Number(IDUtilisateur) === 35
            ) && (
              <div>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />
                <MenuItem onClick={handleClicksettingDfep}>
                  {" "}
                  <ApartmentIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    {" "}
                    إدارة حسابات المؤسسات{" "}
                  </Typography>
                </MenuItem>
              </div>
            )}

          {IDetablissement !== null &&
            (IDCandidat == null ||
              Number(IDCandidat) <= 0 ||
              IDCandidat == undefined) &&
            IDetablissement !== undefined
            &&
            IDEncadrement !== null &&
            IDEncadrement !== undefined &&
            (Number(IDUtilisateur) === 32
              || Number(IDUtilisateur) === 34
              || Number(IDUtilisateur) === 35
              || Number(IDUtilisateur) === 36) && (
              <div>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ width: "100%" }}
                />
                <MenuItem onClick={handleClickChangePwEtab}
                >
                  {" "}
                  <LockIcon />{" "}
                  <Typography
                    theme={themeChanga}
                    sx={{
                      textAlign: "right",
                      mr: 0,
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 700,
                      mr: "5px",
                    }}
                    type="body2"
                  >
                    {" "}
                    تغيير كلمة سر حسابك{" "}
                  </Typography>
                </MenuItem>
              </div>
            )}

          {[999].includes(Number(IDUtilisateur)) && (
            <div>
              <Divider
                variant="inset"
                component="li"
                style={{ width: "100%" }}
              />
              <MenuItem onClick={handleClicksettingMigrate_hfsql}>
                {" "}
                <StorageIcon />{" "}
                <Typography
                  theme={themeChanga}
                  sx={{
                    textAlign: "right",
                    mr: 0,
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 16,
                    fontWeight: 700,
                    mr: "5px",
                  }}
                  type="body2"
                >
                  {" "}
                  نقل قاعدة بيانات من (HFSQL){" "}
                </Typography>
              </MenuItem>
            </div>
          )}

        </Menu>
      </div>
    </List>

  );
};

