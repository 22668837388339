// Context.js
import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [IDSession_usedata, setIDSession_usedata] = useState(999);

  return (
    <DataContext.Provider value={{ IDSession_usedata, setIDSession_usedata }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
