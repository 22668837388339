import React, { useState, useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import appico from '../src/img/logo.png';
import CameraModal from './CameraModal'; // Import your CameraModal component
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import CameraAltIcon from '@mui/icons-material/CameraAlt';

import logoMfep from '../src/img/logo.png';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import Html5QrcodePlugin from './Html5QrcodePlugin';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';

import ReplayIcon from '@mui/icons-material/Replay';
import FormControl from '@mui/material/FormControl';
import isEmail from 'validator/lib/isEmail';
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Button from '@mui/material/Button';
import NumbersIcon from '@mui/icons-material/Numbers';

import axios from 'axios';
import { isMobile, browserName } from 'react-device-detect';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from 'react-bootstrap/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import '../containers/amirifont.css'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import InfoIcon from '@mui/icons-material/Info';
import Footer from '../containers/Footer';
import Header from '../containers/Header';
import HeaderCheckAuth from '../containers/HeaderCheckAuth';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { makeStyles } from "@material-ui/core/styles";
import dayjs from 'dayjs';
import LinearProgress from '@mui/material/LinearProgress';

import { API_BASE_URL } from '../containers/config'; // Adjust the path if necessary
import ReCAPTCHA from 'react-google-recaptcha';
import useAuthentication_etab from "./useAuthentication_etab";


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://AtibaDz.com/">
                Abdelaziz sebrou
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const BootstrapTooltip = styled((props) => (
    <Tooltip {...props} arrow classes={{ popper: props.className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        ///color: theme.palette.common.black,
        color: 'orange',
        top: '10px',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        ///backgroundColor: theme.palette.common.black,
        backgroundColor: 'orange',
        fontSize: '14px',
        fontWeight: 'bold',
        top: '10px',
    },
}));


const useStyles_CircularProgress = makeStyles((theme) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999, // Ensure it's above everything else
    },
}));


const defaultColor = "#4caf50"; // Default green color
const hoverColor = "#004d40"; // Lighter green when hovered
const focusColor = "#81c784"; // Even lighter green when focused

const themetextfieldnew = createTheme({
    direction: 'rtl',
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: hoverColor,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: focusColor,
                    },
                },
                notchedOutline: {
                    borderColor: defaultColor,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: focusColor,
                    },
                    "&:hover": {
                        color: hoverColor,
                    },
                    color: defaultColor, // Default label color
                },
            },
        },
    },
    fieldset: {
        fontFamily: '"Changa", sans-serif',
        fontSize: 45,
    },
});




const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});




export default function Checkpreinscrit() {
    const {
        IDetablissement,
        IDEts_Form,
        IDDFEP,
        IDNature_etsF,
        IDCandidat,
        NumSem,
        IDapis_userslist,
        loadingauth,
        Singup_active,
        download_cert_scol_active,
        EditingInfo_active,
        Eval_notes_active,
        download_cert_inscription_active,
        Singup_active_Dfep,
        download_cert_scol_active_Dfep,
        EditingInfo_active_Dfep,
        Eval_notes_active_Dfep,
        download_cert_inscription_active_Dfep,
        Singup_active_Mfep,
        download_cert_scol_active_Mfep,
        EditingInfo_active_Mfep,
        Eval_notes_active_Mfep,
        download_cert_inscription_active_Mfep,
      } = useAuthentication_etab();

      
    const [readqr, setreadqr] = useState(false);
    const [valueNINorMatSt, setvalueNINorMatSt] = useState('');

    const NumbersOnlyRegex = /^[0-9\s]+$/;


    const [valueNin, setvalueNin] = useState('');

    const [isCameraOpen, setCameraOpen] = useState(false);

    const handleCameraOpen = () => {
        setCameraOpen(true);
    };

    const handleCameraClose = () => {
        setCameraOpen(false);
    };

    const handleCapture = (capturedValue) => {
        setvalueNin(capturedValue);
    };

    const refNin = useRef(null);

    const handleInput_Nin = (event) => {
        const inputValue = event.target.value;

        if ((NumbersOnlyRegex.test(inputValue) && inputValue.length <= 18) || inputValue === '') {
            setvalueNin(inputValue);
        }
    };

    const onNewScanResult = (decodedText, decodedResult) => {
        //console.log("New scan result:", decodedText);


        if (decodedText !== null && decodedText !== "" && NumbersOnlyRegex.test(decodedText)) {
            setreadqr(true);
            setvalueNINorMatSt(decodedText);

        } else {


            // Attempt to parse JSON first (assuming it might be)
            try {
                const decodedObject = JSON.parse(decodedText);
                const certificationValue = decodedObject["N°certification"]; // Access value

                if (certificationValue !== null && certificationValue !== "" && NumbersOnlyRegex.test(certificationValue)) {
                    setreadqr(true);
                    setvalueNINorMatSt(certificationValue);
                    return; // Exit after successful JSON parse
                }
            } catch (error) {
                // Handle parsing error (not valid JSON)
            }

            // If parsing fails, check for comma-separated values
            if (decodedText.includes(",")) {
                const splittedText = decodedText.split(",");
                const NINorMatSt = splittedText[0];
                if (NumbersOnlyRegex.test(NINorMatSt)) {
                    setreadqr(true);
                    setvalueNINorMatSt(NINorMatSt);
                }
            } else {
                // Handle other non-JSON, non-comma-separated cases (optional)
                // You can set a default value or handle the error differently here
            }


        }


        ////alert(`QR Code Scanned: ${decodedText}`);
        // handle decoded results here
    };



    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    // const history = useHistory();
    const navigate = useNavigate();


    const refNINorMatSt = useRef(null);
    const ismobref = useRef(null);

    const classes_CircularProgress = useStyles_CircularProgress();


    const [isValidemail, setIsValidemail] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [emailverificated, setemailverificated] = React.useState(false);

    const [emailsended, setemailsended] = React.useState(false);
    const [errorsendingemail, seterrorsendingemail] = React.useState(false);
    const refpassword1 = useRef(null);

    const [FailedAuth, setFailedAuth] = useState(false);

    const [OpenDialog, setOpenDialog] = useState(false);
    const [OpenDialog_notfound, setOpenDialog_notfound] = useState(false);


    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleClickOpenDialog_notfound = () => {
        setOpenDialog_notfound(true);
    };


    const handleClickCloseDialog = () => {

        setOpenDialog(false);
    };
    const handleClickCloseDialog_notfound = () => {

        setOpenDialog_notfound(false);
    };


    const [token, setToken] = useState(null);




    const [valuesPassword, setValuesPassword] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleChangePassword = (prop) => (event) => {
        setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValuesPassword({
            ...valuesPassword,
            showPassword: !valuesPassword.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };






    const onemailblur = event => {
        const val = event.target.value;

        if (isValidemail) {
            setDirty(true);
        }



    }



    const hostn = window.location.hostname;
    const full = window.location.protocol + '//' + window.location.host;

    const ismoblinkred = 'intent:' + full + '/LoginDon#Intent;package=com.android.chrome;end';
    const [valuelink, setvaluelink] = useState(ismoblinkred);


    const [recaptchaVerified, setRecaptchaVerified] = useState(true); // Initially assuming reCAPTCHA is verified
    const refReCAPTCHA = useRef(null);


    const handleRecaptchaChange = (value) => {
        // handle reCAPTCHA response
        if (value) {
            // reCAPTCHA verification succeeded
            setRecaptchaVerified(true);
            // Proceed with your logic here
        } else {
            // reCAPTCHA verification failed
            setRecaptchaVerified(true);
            // Handle the failure scenario
        }
    };
    /*
    const handleRecaptchaChange = (e) => {
      setToken(e.target.value); // e.target.value should hold the token
    };*/


    useEffect(() => {
        //console.log(`API_BASE_URL: ${API_BASE_URL}`);

    }, []);


    useEffect(() => {
        /////console.log('isValidemail '+isValidemail+ 'emailverificated ' +emailverificated + 'emailsended '+emailsended )
        setemailsended(false);

    }, [isValidemail]);


    const handleChangeemail = event => {
        const val = event.target.value;


        if (NumbersOnlyRegex.test(val) || val === '') {
            setvalueNINorMatSt(val);
        }


    }

    const sitekey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
    ////////
    ///////6LckCXIpAAAAAO2Y87-XcGXMTbpkBIiNSNyhGWwl

    const [Nom_app, setNom_app] = useState('');
    const [Prenom_app, setPrenom_app] = useState('');
    const [DateNais_app, setDateNais_app] = useState('');
    const [LieuNais_app, setLieuNais_app] = useState('');
    const [listetab, setlistetab] = useState('');

    const Auth = async () => {
        try {
            setLoading(true);

            const data = {
                valueNin: valueNin
            };

            const response = await axios.post(`${API_BASE_URL}/api/checkpreinscrit_moutakawin`, data);


            //console.error('responseazizdata:', response.data);

            if (response.data && response.data.CERTAFICAT) {
                // Check if CERTAFICAT is an empty array
if (Array.isArray(response.data.CERTAFICAT) && response.data.CERTAFICAT.length === 0) {
    setFailedAuth(true);
    handleClickOpenDialog_notfound();
}
else {
                    const etablissementList = response.data.CERTAFICAT.map((item, index) => (
                        `<hr /> 
                        رقم التسجيل: ${item.IDPreinscrit} <br />
                        المؤسسة ${index + 1}: ${item.Etablissement_Nom} <br />
                        ${item.Communn_Nom} <br />
                        ${item.Specialite_Nom} <br />
                        ${item.Mode_formation_Nom} <br /><br />
                        <a href="/Preinscriptionpdf/${item.crypted_id}" style="text-decoration: none;">
                            <button style="
                                background-color: #00695c; 
                                color: #FFFFFF; 
                                font-family: 'Tajawal', sans-serif; 
                                font-size: 12px; 
                                font-weight: 900; 
                                padding: 6px 12px; 
                                border: none; 
                                cursor: pointer;">
                                استمارة التسجيل
                            </button>
                        </a>`
                    )).join('<br /><br />');

                    setlistetab(etablissementList);


                    //alert(etablissementList);


                    setNom_app(response.data.CERTAFICAT[0].Nom);
                    setPrenom_app(response.data.CERTAFICAT[0].Prenom);
                    setDateNais_app(dayjs(response.data.CERTAFICAT[0].DateNais).format('YYYY-MM-DD'));
                    setLieuNais_app(response.data.CERTAFICAT[0].LieuNais);

                    setFailedAuth(false);
                    handleClickOpenDialog();
                }
            }

            else {
                // Handle invalid credentials or other errors
                setFailedAuth(true);
            }
        } catch (error) {
            // Handle API call errors
            console.error('API call failed:', error);
            ////console.error('error:', error.response.data.error);

            // Optionally setFailedAuth(true) or perform other error handling
        } finally {
            setLoading(false);
        }
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        if (valueNin.length == 0 || isNaN(Number(valueNin))) {
            refNin.current.focus();
            setFailedAuth(true);
            return
        }

        Auth(event);


    };


    useEffect(() => {
        if (!loadingauth) {
          if (
            IDetablissement === null ||
            IDDFEP === null ||
            IDNature_etsF === null 
          ) {
             
           
            //alert("not etab");
            // Navigate to the 404 page if any value is missing
            //navigate('/loginEtab');
            return;
          }
          else{
            //alert("etab");
            
          }
        }
      }, [
        IDetablissement,
        IDDFEP,
        IDNature_etsF,
        IDCandidat,
        NumSem,
        IDapis_userslist,
        loadingauth,
        navigate,
    
        Singup_active,
        download_cert_scol_active,
        EditingInfo_active,
        Eval_notes_active,
        download_cert_inscription_active,
        Singup_active_Dfep,
        download_cert_scol_active_Dfep,
        EditingInfo_active_Dfep,
        Eval_notes_active_Dfep,
        download_cert_inscription_active_Dfep,
        Singup_active_Mfep,
        download_cert_scol_active_Mfep,
        EditingInfo_active_Mfep,
        Eval_notes_active_Mfep,
        download_cert_inscription_active_Mfep,
      ]); // Add navigate to dependencies
    
      if (loadingauth) {
        return (
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="success" />
          </Box>
        );
      }
    

    return (

        <>
            <a style={{ display: 'none' }} href={valuelink}

                ref={ismobref}

                target="_blank"></a>




            <div   >

            <Header
        IDetablissement={IDetablissement}
        IDEts_Form={IDEts_Form}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={
          download_cert_inscription_active_Dfep
        }
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={
          download_cert_inscription_active_Mfep
        }
      />

                <div   >

                    <Container sx={{ mt: 1 }} >

                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            style={{ minHeight: '100vh' }}
                        >

                            <div className="container"  >

                                <div className="row text-center">



                                    <Box
                                        sx={{
                                            marginTop: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',

                                        }}
                                    >



                                        <Typography

                                            sx={{
                                                color: "#eff0f4",
                                                ///color: "#ffffff", // Text color
                                                fontFamily: '"Tajawal", sans-serif', // Font family
                                                fontWeight: 900, // Font weight
                                                fontSize: 20, // Font size
                                                backgroundColor: "#004d40", // Green background color
                                                border: "4px solid #e0e4e875", // Border
                                                padding: "10px", // Padding
                                            }}


                                        >
                                            <PersonSearchIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> تحقق من تسجيلك الأولي
                                        </Typography>
                                        <Box component="form" noValidate sx={{ mt: 1 }}>



                                            {/* Email */}
                                            <CacheProvider value={cacheRtl}>
                                                <ThemeProvider theme={themetextfieldnew}>

                                                    <Box sx={{ mt: 2 }}>


                                                        <Grid container >


                                                            <Grid item xs={12}>

                                                                <Box sx={{ minWidth: 120 }}>
                                                                    <FormControl fullWidth variant="outlined"


                                                                    >
                                                                        <BootstrapTooltip

                                                                            title="أدخل رقم التعريف الوطني NIN"

                                                                            followCursor>


                                                                            <Box>
                                                                                <div dir="rtl">

                                                                                    <TextField
                                                                                        fullWidth
                                                                                        name="valueNin"
                                                                                        value={valueNin}
                                                                                        inputRef={refNin}
                                                                                        onInput={handleInput_Nin}
                                                                                        error={

                                                                                            !NumbersOnlyRegex.test(valueNin) ||
                                                                                            !valueNin ||
                                                                                            valueNin.length !== 18


                                                                                        }
                                                                                        helperText={


                                                                                            (!NumbersOnlyRegex.test(valueNin) || !valueNin || valueNin.length !== 18

                                                                                            )
                                                                                                ? 'رقم التعريف الوطني غير صحيح'
                                                                                                : ''
                                                                                        }


                                                                                        InputProps={{
                                                                                            inputProps: { tabIndex: 12 },
                                                                                            startAdornment: (
                                                                                                <InputAdornment position="start">
                                                                                                    <NumbersIcon />
                                                                                                </InputAdornment>
                                                                                            ),
                                                                                            endAdornment: (  // Changed to endAdornment
                                                                                                <InputAdornment position="end">
                                                                                                    <IconButton onClick={handleCameraOpen}>
                                                                                                        <CameraAltIcon />
                                                                                                    </IconButton>
                                                                                                </InputAdornment>
                                                                                            )
                                                                                        }}


                                                                                        sx={{

                                                                                            '& .MuiFormHelperText-root': {
                                                                                                fontFamily: '"Tajawal", sans-serif',
                                                                                                fontSize: '14px',
                                                                                                color: 'red',
                                                                                                fontWeight: 700
                                                                                            },

                                                                                            '& .MuiFormLabel-root': {
                                                                                                fontFamily: '"Tajawal", sans-serif',
                                                                                                fontSize: '16px',
                                                                                                color: '#004d40'
                                                                                            },
                                                                                            '& .MuiInputBase-input': {
                                                                                                fontFamily: '"Tajawal", sans-serif',
                                                                                                fontSize: '18px',
                                                                                                color: '#004d40',
                                                                                                fontWeight: 700
                                                                                            },
                                                                                            '& label.Mui-focused': {
                                                                                                fontSize: '16px',
                                                                                                color: '#004d40',
                                                                                                fontWeight: 700
                                                                                            },
                                                                                            '& .MuiInput-underline:after': {
                                                                                                borderBottomColor: '#004d40',
                                                                                            },
                                                                                            '& .MuiOutlinedInput-root': {
                                                                                                '& fieldset': {
                                                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                                                    fontSize: '16px',
                                                                                                    borderColor: '#004d40',
                                                                                                    borderWidth: "2px",
                                                                                                },
                                                                                                '&:hover fieldset': {
                                                                                                    borderColor: 'green',
                                                                                                },
                                                                                                '&.Mui-focused fieldset': {
                                                                                                    borderColor: 'green',
                                                                                                },
                                                                                            }
                                                                                        }}
                                                                                        id={`outlined-basic-valueNomnin`}

                                                                                        label="رقم التعريف الوطني NIN "
                                                                                        placeholder="رقم التعريف الوطني NIN "

                                                                                        variant="outlined" />                                                </div>
                                                                            </Box>

                                                                        </BootstrapTooltip>

                                                                    </FormControl>


                                                                </Box>
                                                            </Grid>

                                                        </Grid>
                                                    </Box>

                                                </ThemeProvider>

                                            </CacheProvider>


                                            <CameraModal open={isCameraOpen} onClose={handleCameraClose} onCapture={handleCapture} />


                                            {/* Submit Button */}
                                            <Grid item xs={12}>


                                                <CacheProvider value={cacheRtl}>
                                                    <ThemeProvider theme={themetextfieldnew}>

                                                        <Box sx={{ mb: '25px', minWidth: 120, mt: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <BootstrapTooltip

                                                                title="أدخل رقم التعريف الوطني NIN"

                                                                followCursor>


                                                                <Button

                                                                    sx={{
                                                                        backgroundColor: '#4CAF50', // Green color
                                                                        '&:hover': {
                                                                            backgroundColor: '#45a049', // Darker green on hover
                                                                        }
                                                                    }}
                                                                    onClick={handleSubmit}
                                                                    variant="contained"
                                                                    endIcon={<SearchIcon
                                                                        style={{
                                                                            marginRight: '5px', fontSize: 30, transform: 'rotate(0deg)'


                                                                        }} />}
                                                                >
                                                                    <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 22, fontWeight: 900 }}>
                                                                      تحقق الآن
                                                                    </Typography>
                                                                </Button>

                                                            </BootstrapTooltip>
                                                        </Box>
                                                    </ThemeProvider>
                                                </CacheProvider>

                                            </Grid>







                                        </Box>
                                    </Box>


                                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {loading && <CircularProgress />}
                                    </Box>

                                    {loading &&
                                        <div className={classes_CircularProgress.overlay}>

                                            <Dialog open={loading} PaperProps={{ sx: { backgroundColor: 'white' } }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                                                    <CircularProgress sx={{ ml: 1 }} color="primary" />
                                                    <Typography variant="h6" sx={{ color: '#004d40', fontFamily: 'Tajawal, sans-serif', fontWeight: 900, ml: 2 }}>
                                                        جاري البحث عن معلوماتك....
                                                    </Typography>
                                                </Box>
                                            </Dialog>

                                        </div>
                                    }

                                  

{FailedAuth ? <>

<Alert severity="error"
    sx={{
        mt: 2, display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
        , '& .MuiAlert-action': { marginRight: '8px' }
    }} // Adjust padding here
>
    أدخل رقم التعريف الوطني NIN
</Alert>



</> : <></>}


                                </div>

                            </div>



                        </Grid>

                    </Container>
                    <br />
                    <br />
                    <br />
                </div>


                <Footer />

            </div>

            {/* Dialog success save */}

            <Dialog
                PaperProps={{
                    style: {

                        boxShadow: 'none',
                    },
                }}
                //fullScreen={mediumScreen}
                open={OpenDialog}
                onClose={handleClickCloseDialog}
                aria-labelledby="responsive-dialog-title"
            >

                <Card sx={{ maxWidth: 345, }}>
                    <CardHeader
                        
                        action={
                            <IconButton onClick={handleClickCloseDialog} aria-label="close" sx={{ ml: -1, mt: -1 }}>
                                <CloseIcon />
                            </IconButton>
                        }
                        title={
                            <>
                                <Box sx={{ ml: 2, mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                                    <Typography variant="h6" sx={{ color: '#004d40', fontFamily: 'Tajawal, sans-serif', fontWeight: 900 }}>
                                        أنت مسجل تسجيل أولي في الدورة الحالية

                                    </Typography>
                                </Box>
                                <Divider variant="middle" sx={{ mb: 1 }} />

                            </>
                        }
                        subheader={
                            <>
                                <Typography variant="body1" sx={{ display: 'flex', fontSize: 16, fontWeight: 'bold', fontFamily: 'Tajawal, sans-serif' }}>
                                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: 'Tajawal, sans-serif' }}>اللقب و الاسم: </span> &nbsp; {Nom_app} {Prenom_app}
                                </Typography>
                                <Typography variant="body1" sx={{ display: 'flex', fontSize: 16, fontWeight: 'bold', fontFamily: 'Tajawal, sans-serif' }}>
                                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: 'Tajawal, sans-serif' }}>تاريخ و مكان الميلاد: </span> &nbsp; {DateNais_app} {LieuNais_app}
                                </Typography>
                                <Typography variant="body1" sx={{ display: 'flex', fontSize: 14, fontWeight: 'bold', fontFamily: 'Tajawal, sans-serif' }}>
                                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: 'Tajawal, sans-serif' }} dangerouslySetInnerHTML={{ __html: listetab }} />
                                </Typography>


                            </>
                        }
                    />
                </Card>


            </Dialog>








            {/* Dialog not found */}

            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: 'red',
                        boxShadow: 'none',
                    },
                }}
                //fullScreen={mediumScreen}
                open={OpenDialog_notfound}
                onClose={handleClickCloseDialog_notfound}
                aria-labelledby="responsive-dialog-title_notfound"
            >

                <Card sx={{ maxWidth: 345 }}>
                    <CardHeader
                        avatar={
                            <ErrorOutlineIcon sx={{ color: 'white', width: 50, height: 50, ml: 2 }} />
                        }
                        action={
                            <IconButton onClick={handleClickCloseDialog_notfound} aria-label="close" sx={{ ml: -1, mt: -1 }}>
                                <CloseIcon sx={{ color: 'white' }} />
                            </IconButton>
                        }
                        title={
                            <>
                                <Box sx={{ ml: 2, mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                                    <Typography variant="h6" sx={{ color: 'white', fontFamily: 'Tajawal, sans-serif', fontWeight: 900 }}>
                                    لم يتم العثور على استمارة تسجيلك
                                    </Typography>
                                </Box>
                                <Divider variant="middle" sx={{ borderColor: '#ffffff4f', mb: 1 }} />

                            </>
                        }
                        subheader={
                            <>
                                <Typography variant="body1" sx={{ display: 'flex', fontSize: 16, fontWeight: 'bold', fontFamily: 'Tajawal, sans-serif' }}>
                                    <span style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Tajawal, sans-serif' }}>
                                        أدخل رقم التعريف الوطني NIN
                                    </span>
                                </Typography>

                            </>
                        }
                    />
                </Card>


            </Dialog>



        </>

    );
}