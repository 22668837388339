import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import { API_BASE_URL } from './config'; // Adjust the path if necessary

const useAuthentication_encad = () => {
  const navigate = useNavigate();

  // State variables to store different data points
  const [IDEncadrement, setIDEncadrement] = useState(null);
  const [IDFonctionsNature, setIDFonctionsNature] = useState(null);
  const [IDCorp, setIDCorp] = useState(null);
  const [IDIDSituationAdministrat_type, setIDIDSituationAdministrat_type] = useState(null);
  const [IDFonctions, setIDFonctions] = useState(null);
  const [IDGrade, setIDGrade] = useState(null);
  const [IDSituationAdministrat, setIDSituationAdministrat] = useState(null);
  const [IDetablissement, setIDetablissement] = useState(null);
  const [IDSitfamille, setIDSitfamille] = useState(null);
  const [nin, setnin] = useState(null);
  const [IDEts_Form, setIDEts_Form] = useState(null);
  const [IDSituationAdministratPosts, setIDSituationAdministratPosts] = useState(null);
  const [encadata, setencadata] = useState(null);
  const [loadingauth, setloadingauth] = useState(true); // Initialize loading state to true

  // Authentication API endpoint
  const auth_encad = `${API_BASE_URL}/api/auth_encad`;

  useEffect(() => {
   
    let tokenEtab = sessionStorage.getItem("authData_73xJ#E");
    if (!tokenEtab) {
      tokenEtab = localStorage.getItem("authData_73xJ#E");
    }
    if (!tokenEtab) {
      setloadingauth(false); 
     return
    }
    
    // Fetch data from the API
    fetch(auth_encad, {
      headers: {
        Accept: 'application/json',
         Authorization: `Bearer ${tokenEtab}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the data array exists and has at least one item
        if (data && data.data && data.data.length > 0) {
          const firstItem = data.data[0];
          setIDEncadrement(firstItem.IDEncadrement);
          setIDFonctionsNature(firstItem.IDFonctionsNature);
          setIDFonctions(firstItem.IDFonctions);
          setIDCorp(firstItem.IDCorp);
          setIDIDSituationAdministrat_type(firstItem.IDIDSituationAdministrat_type);
          setIDGrade(firstItem.IDGrade);
          setIDSituationAdministrat(firstItem.IDSituationAdministrat);
          setIDetablissement(firstItem.IDetablissement);
          setIDSitfamille(firstItem.IDSitfamille);
          setnin(firstItem.nin);
          setIDEts_Form(firstItem.IDEts_Form);
          setIDSituationAdministratPosts(firstItem.IDSituationAdministratPosts);
          setencadata(firstItem); // Store the first object in the state
        } 
      })
      .catch(error => {
      })
      .finally(() => {
        setloadingauth(false); // Set loading state to false after fetching is done
      });
  }, [navigate, auth_encad]); // Adding navigate and auth_encad to the dependency array

  // Return all the state variables and loading status
  return {
    IDEncadrement,
    IDFonctions,
    IDFonctionsNature,
    IDCorp,
    IDIDSituationAdministrat_type,
    IDGrade,
    IDSituationAdministrat,
    IDetablissement,
    IDSitfamille,
    nin,
    IDEts_Form,
    IDSituationAdministratPosts,
    encadata,
    loadingauth
  };
};

export default useAuthentication_encad;
