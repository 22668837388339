// apiConfig.js

let API_BASE_URL = 'https://tachbik4.mfp.gov.dz'; // Default URL
 const API_BASE_URLhfsql = 'https://tachbikhfsql.mfp.gov.dz';

const APP_PREFIX = 'moutakawinMFEP_';

const testApiUrl = 'https://tachbik4.mfp.gov.dz/api/test';

// Function to check the API response
async function checkApiResponse() {
  try {
    const response = await fetch(testApiUrl);
    if (response.ok) {
      API_BASE_URL = 'https://tachbik4.mfp.gov.dz'; // Set to the test URL if response is OK
    } else {
      API_BASE_URL = 'https://tachbik7.mfp.gov.dz';
      console.error('Test API response not OK:', response.status);
    }
  } catch (error) {
    API_BASE_URL = 'https://tachbik7.mfp.gov.dz';
    console.error('Error fetching test API:', error);
  }
}

// Call the function to check the API response
checkApiResponse();

// Export the variables
export { API_BASE_URL, APP_PREFIX,API_BASE_URLhfsql };
