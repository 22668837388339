import React, { useState, useEffect } from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';

import FacebookIcon from '@mui/icons-material/Facebook';
import Avatar from '@mui/material/Avatar';


import appico from '../src/img/logo.png';
import { Box, Typography, Link, Grid, Container } from '@mui/material';
import { API_BASE_URL } from "../containers/config"; // Adjust the path if necessary

import { useTheme } from '@mui/material/styles';


export default function Footer() {


  const theme = useTheme();
  const [EndTimeupdatedb, setEndTimeupdatedb] = useState("");

  const apiUrl_lastupdatedb = `${API_BASE_URL}/api/lastupdatedb`;

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(apiUrl_lastupdatedb);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        if (!data || !data.data) {
          throw new Error("Unexpected data format received from API");
        }

        // Process fetched data
        const lastupdatedb = data.data;

        // Set selected session if available
        if (lastupdatedb && lastupdatedb.EndTime) {
          setEndTimeupdatedb(lastupdatedb.EndTime);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const loadDataFromLocalForage = async () => {
      try {
        // If data doesn't exist in localforage, fetch it
        await fetchData();
      } catch (error) {
        console.error("Error loading data from localforage:", error);
      }
    };

    // Load data from localforage or fetch from API
    loadDataFromLocalForage();
  }, []);

 

// Function to format date in Arabic with Arabic numerals
const formatArabicDate = (dateString) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, localeMatcher: 'best fit', weekday:"long",era: "long"};
  const easternArabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
  const arabicNumerals = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const formattedDate = new Date(dateString).toLocaleString('ar-EG', options);
  // Replace Eastern Arabic numerals with Arabic numerals
  return formattedDate.replace(/[٠-٩]/g, (match) => arabicNumerals[easternArabicNumerals.indexOf(match)]);
};


  return (

    <>
    
    {/* 


<Container
      sx={{
        mt: '50px',
      }}
      maxWidth="false"
      disableGutters // Disable default margin
    >

      <Grid container  >
        <Grid item sx={{ mr: 4, textAlign: 'right' }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontFamily: '"Tajawal", sans-serif',
              color: "#004d40",
              fontSize: 12,
              fontWeight: 'bold',
            }}
          >
            {EndTimeupdatedb && (
              <>
                آخر تحديث للبيانات: {formatArabicDate(EndTimeupdatedb)}

              </>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Container>

*/}

      <Container
        sx={{
          mt: '50px',
        }}
        maxWidth="false"
        disableGutters // Disable default margin
      >


        <Box
          component="footer"
          sx={{
            mt: 4,
            position: 'fixed', // Fixed position to keep it at the bottom
            bottom: 0, // Aligning it to the bottom
            width: '100%', // Full width
            py: 1,
            borderTop: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: theme.typography.fontFamily,
            textAlign: 'center',
            backgroundColor:'#004d40'
 
          }}
        >
   

          <Grid container spacing={0} justifyContent="center"
          >
            <Grid item sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary">
                <Link
                  href="https://www.mfep.gov.dz/"
                  underline="none" // Remove underline
                  sx={{
                    fontFamily: '"Tajawal", sans-serif',
                    color: "#ffffff",
                    fontSize: 16,
                    fontWeight: 'bold',
                     


                  }}
                >
                  وزارة التكوين والتعليم المهنيين {'© '}
                  {new Date().getFullYear()}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>


    </>
  )















}


