import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { API_BASE_URL } from '../containers/config'; // Adjust the path if necessary
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { APP_PREFIX } from './config'; // Adjust the path if necessary
import useAuthentication_etab from './useAuthentication_etab';


const useStyles_CircularProgress = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure it's above everything else
  },
}));

const ETATCertificatInscriptionPDF = () => {


  const { IDetablissement, IDDFEP, IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  } = useAuthentication_etab();





  const { cryptedId } = useParams();
  const [result, setresult] = useState(null);
  const [result_dfep, setresult_dfep] = useState(null);

  const [result_mfep, setresult_mfep] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null); // State to store PDF URL
  const [Annee_formation_Nom, setAnnee_formation_Nom] = useState(null);

  const [download_cert_inscription_active_state, setdownload_cert_inscription_active_state] = useState(0);
  const [download_cert_inscription_active_dfep_state, setdownload_cert_inscription_active_dfep_state] = useState(0);
  const [download_cert_inscription_active_mfep_state, setdownload_cert_inscription_active_mfep_state] = useState(0);
  const [numsem_state, setnumsem_state] = useState(0);

  const navigate = useNavigate();
  const [loadcrypted, setloadcrypted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/getbycrypted_id_public/${cryptedId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setresult(data.data);
        setresult_dfep(data.data_dfep);
        setresult_mfep(data.data_mfep);


        setdownload_cert_inscription_active_state(data.data.download_cert_inscription_active);
        setdownload_cert_inscription_active_dfep_state(data.data_dfep.download_cert_inscription_active_dfep);
        setdownload_cert_inscription_active_mfep_state(data.data_mfep.download_cert_inscription_active_mfep);
        setnumsem_state(data.data.NumSem);



        //console.log('download_cert_inscription_active:',data.data.download_cert_inscription_active);
        //console.log('download_cert_inscription_active_dfep:',data.data_dfep.download_cert_inscription_active_dfep);
        ///console.log('download_cert_inscription_active_mfep:',data.data_mfep.download_cert_inscription_active_mfep);

        setAnnee_formation_Nom(data.Annee_formation_Nom);

        setloadcrypted(true);
      } catch (error) {
        console.error(error);
        // Handle error, e.g., show an error message to the user
      }
    };
    if (cryptedId) {
      fetchData();
    }



  }, [cryptedId]);



  useEffect(() => {


    if (result && !pdfUrl && !loadingauth) { // Only generate PDF if result is set and PDF URL is not already generated


      if (((Number(result.download_cert_inscription_active) != 0
        || result.download_cert_inscription_active == null)
        || (Number(result_dfep.download_cert_inscription_active_dfep) != 0)
        || (Number(result_mfep.download_cert_inscription_active_mfep) != 0)
      ) &&
        (localStorage.getItem(APP_PREFIX + 'download_cert_inscription_active') != "0"
          || localStorage.getItem(APP_PREFIX + 'download_cert_inscription_active') == null)) {
        navigate('/');
      }
      else {
        localStorage.removeItem(APP_PREFIX + 'download_cert_inscription_active');
      }


      //alert(result.download_cert_inscription_active);

      // Create a new jsPDF instance
      const pdf = new jsPDF();

      // Set document information
      pdf.setProperties({
        title: 'شهادة تسجيل',
        subject: 'شهادة تسجيل',
        author: 'MFEP',
      });
      const fontUrl = `${process.env.PUBLIC_URL}/Amiri-Regular.ttf`;
      const fontUrl_bold = `${process.env.PUBLIC_URL}/Amiri-Bold.ttf`;
      const fontUrl_arabic_ejaza = `${process.env.PUBLIC_URL}/arabic-ejaza.ttf`;
      const fontUrl_majalla = `${process.env.PUBLIC_URL}/majalla.ttf`;

      pdf.addFont(fontUrl, 'Amiri', 'normal');
      // Draw Certificate title
      pdf.addFont(fontUrl_bold, 'Amiri_bold', 'normal');
      pdf.addFont(fontUrl_arabic_ejaza, 'arabic_ejaza', 'normal');
      pdf.addFont(fontUrl_majalla, 'Sakkal Majalla', 'normal');

      pdf.setFont('arabic_ejaza'); // Set font

      // Set the title of the browser tab
      document.title = 'شهادة تسجيل';

      // Set font size and text direction
      pdf.setFontSize(26);
      pdf.text('الجمهورية الجزائرية الديمقراطية الشعبية', pdf.internal.pageSize.getWidth() / 2, 12, { align: 'center' });

      // Move to new line
      pdf.setLineHeightFactor(1.5); // Adjust line height if needed

      // Draw Ministry information
      pdf.text('وزارة التكويـن والتعلـيـم المهنييـن', pdf.internal.pageSize.getWidth() / 2, 22, { align: 'center' });
      pdf.setFont('Sakkal Majalla'); // Set font
      ///pdf.setFont('Amiri'); // Set font
      pdf.setFontSize(16);
      // Draw Wilaya and Direction information
      if (result !== null && result.libelleArabe_Wly_Dfep) {
        pdf.text('ولاية ' + result.libelleArabe_Wly_Dfep, pdf.internal.pageSize.getWidth() - 10, 30, { align: 'right' });
        pdf.text('مديرية التكوين والتعليم المهنيين لولاية ' + result.libelleArabe_Wly_Dfep, pdf.internal.pageSize.getWidth() - 10, 40, { align: 'right' });
      } else {
        pdf.text('ولاية ', pdf.internal.pageSize.getWidth() - 10, 30, { align: 'right' });
        pdf.text('مديرية التكوين والتعليم المهنيين لولاية ', pdf.internal.pageSize.getWidth() - 10, 40, { align: 'right' });
      }

      // Draw Name, Date, and Number
      pdf.text(result.Nom_Et, pdf.internal.pageSize.getWidth() - 10, 50, { align: 'right' });
      pdf.text(' .....:الرقم', pdf.internal.pageSize.getWidth() - 10, 60, { align: 'right' });


      ///pdf.setFont('Amiri_bold'); // Set font
      pdf.setFont('Sakkal Majalla'); // Set font
      pdf.setFontSize(50);
      pdf.text('شهادة تسجيل', pdf.internal.pageSize.getWidth() / 2, 70, { align: 'center' });

      // Add empty line
      pdf.text('', 10, 80);

      // Draw trainee information
      pdf.setFontSize(16);
      pdf.text(': (إن مدير )ة( المؤسسة يشهد أن المتكون )ة', pdf.internal.pageSize.getWidth() - 10, 90, { align: 'right' });

      // Draw trainee details
      pdf.text('اللقب و الاسم : ' + result.Nom_Ca + ' ' + result.Prenom, pdf.internal.pageSize.getWidth() - 10, 100, { align: 'right' });
      pdf.text('تاريخ و مكان الميلاد : ' + result.DateNais + ' ' + result.LieuNais, pdf.internal.pageSize.getWidth() - 10, 110, { align: 'right' });
      pdf.text('العنوان : ' + result.Adres, pdf.internal.pageSize.getWidth() - 10, 120, { align: 'right' });
      pdf.text(result.NumIns + ': مسجل )ة( تحت رقم', pdf.internal.pageSize.getWidth() - 10, 130, { align: 'right' });
      pdf.text('يتابع تكوينا في اختصاص: ' + result.Nom, pdf.internal.pageSize.getWidth() - 10, 140, { align: 'right' });
      pdf.text('نمـط التكوين : ' + result.Mode_formation_Nom, pdf.internal.pageSize.getWidth() - 10, 150, { align: 'right' });

      // Convert result.DateDF and result.DateFF to Date objects
      var dateDF = new Date(result.DateDF);
      var dateFF = new Date(result.DateFF);

      // Get day, month, and year components
      var dayDF = dateDF.getDate();
      var monthDF = dateDF.getMonth() + 1; // Months are zero-based, so add 1
      var yearDF = dateDF.getFullYear();

      var dayFF = dateFF.getDate();
      var monthFF = dateFF.getMonth() + 1; // Months are zero-based, so add 1
      var yearFF = dateFF.getFullYear();

      // Format the date strings as dd-mm-yyyy
      var formattedDateDF = ('0' + dayDF).slice(-2) + '-' + ('0' + monthDF).slice(-2) + '-' + yearDF;
      var formattedDateFF = ('0' + dayFF).slice(-2) + '-' + ('0' + monthFF).slice(-2) + '-' + yearFF;

      // Display the formatted dates
      pdf.text('مدة التكوين من: ' + formattedDateDF + ' إلى ' + formattedDateFF, pdf.internal.pageSize.getWidth() - 10, 160, { align: 'right' });

      // Draw training year
      pdf.text('السنة التكوينيـة: ' + Annee_formation_Nom, pdf.internal.pageSize.getWidth() - 10, 170, { align: 'right' });

      // Draw semester information
      // Convert result.DateD_sem and result.DateF_sem to Date objects
      var dateD_sem = new Date(result.DateD_sem);
      var dateF_sem = new Date(result.DateF_sem);

      // Get day, month, and year components
      var dayD_sem = dateD_sem.getDate();
      var monthD_sem = dateD_sem.getMonth() + 1; // Months are zero-based, so add 1
      var yearD_sem = dateD_sem.getFullYear();

      var dayF_sem = dateF_sem.getDate();
      var monthF_sem = dateF_sem.getMonth() + 1; // Months are zero-based, so add 1
      var yearF_sem = dateF_sem.getFullYear();

      // Format the date strings as dd-mm-yyyy
      var formattedDateD_sem = ('0' + dayD_sem).slice(-2) + '-' + ('0' + monthD_sem).slice(-2) + '-' + yearD_sem;
      var formattedDateF_sem = ('0' + dayF_sem).slice(-2) + '-' + ('0' + monthF_sem).slice(-2) + '-' + yearF_sem;

      // Display the formatted dates
      pdf.text('السداسي رقم: ' + result.NumSem + ' من ' + formattedDateD_sem + ' إلى ' + formattedDateF_sem, pdf.internal.pageSize.getWidth() - 10, 180, { align: 'right' });


      // Add empty lines
      pdf.text('', 10, 190);
      pdf.text('', 10, 200);

      // Draw issuing information
      pdf.setFontSize(12);
      // Get the current date
      var currentDate = new Date();

      // Get day, month, and year components
      var day = currentDate.getDate();
      var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
      var year = currentDate.getFullYear();

      // Format the date as dd-mm-yyyy
      var formattedCurrentDate = ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;

      // Display the formatted current date
      pdf.text('حرر بـ : ' + result.CommunnNom_Ets_Form + ' في : ' + formattedCurrentDate, 60, 190, { align: 'left' });

      pdf.text('مدير )ة( المؤسسة', 80, 200, { align: 'left' });


      // Create QR code as a data URL
      const qrDataURL = document.getElementById('qrCode').toDataURL('image/png');

      // Calculate the X-coordinate for right alignment
      const qrCodeX = pdf.internal.pageSize.getWidth() - 200; // 60 is the total width of the QR code image and its margin

      // Add QR code image to the PDF
      pdf.addImage(qrDataURL, 'PNG', qrCodeX, 230, 50, 50);


      // Add empty lines
      pdf.text('', 10, 230);
      pdf.text('', 10, 240);
      pdf.text('', 10, 250);

      // Draw additional information
      pdf.text('سلمت هذه الوثيقة لاستعمالها فيما يسمح به القانون', pdf.internal.pageSize.getWidth() - 10, 260, { align: 'right' });
      pdf.text(':أنجزت من طرف', pdf.internal.pageSize.getWidth() - 10, 270, { align: 'right' });


      // Generate a blob URL for the PDF content
      const pdfBlob = pdf.output('blob');
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);

      // Set pdfUrl to the blob URL
      setPdfUrl(pdfBlobUrl);

    }
  }, [result, pdfUrl, loadingauth]); // pdfUrl added to dependency array

  const classes_CircularProgress = useStyles_CircularProgress();


  useEffect(() => {
    if (!loadingauth && loadcrypted) {
      if (
        (IDetablissement === null ||
          IDDFEP === null ||
          IDNature_etsF === null) && (

          (download_cert_inscription_active_state && Number(download_cert_inscription_active_state) === 0)
          ||
          (download_cert_inscription_active_dfep_state && Number(download_cert_inscription_active_dfep_state) === 0)
          ||
          (download_cert_inscription_active_mfep_state && Number(download_cert_inscription_active_mfep_state) === 0)
          ||
          Number(NumSem) > 1
          ||
          Number(numsem_state) > 1
        )
      ) {
        console.log('download_cert_inscription_active_state', download_cert_inscription_active_state);
        console.log('download_cert_inscription_active_dfep_state', download_cert_inscription_active_dfep_state);
        console.log('download_cert_inscription_active_mfep_state', download_cert_inscription_active_mfep_state);

        // Navigate to the 404 page if any value is missing
        navigate('/');
        // No return statement here
      }
      // Continue executing the code here, if applicable




    }
  }, [loadcrypted, IDetablissement, IDDFEP, IDNature_etsF,
    loadingauth, download_cert_inscription_active_state, download_cert_inscription_active_dfep_state, download_cert_inscription_active_mfep_state, NumSem, numsem_state
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }



  return (
    <>


      <div style={{ width: '100%', height: '100%' }}>



        {!pdfUrl &&
          <div className={classes_CircularProgress.overlay}>

            <Dialog open={!pdfUrl} PaperProps={{ sx: { backgroundColor: 'white' } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                <CircularProgress sx={{ ml: 1 }} color="primary" />
                <Typography variant="h6" sx={{ color: '#004d40', fontFamily: 'Tajawal, sans-serif', fontWeight: 900, ml: 2 }}>
                  جاري التحميل...
                </Typography>
              </Box>
            </Dialog>

          </div>
        }
        {pdfUrl && (

          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="800px"
            aria-label="Generated PDF"
          >
          </object>


        )}
        {/* Render QR code component */}
        <div style={{ display: 'none' }}>
          <QRCode id="qrCode" value={`${window.location.origin}${window.location.pathname}`} />
        </div>
      </div>
    </>
  );
};

export default ETATCertificatInscriptionPDF;
