import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Divider, Button, LinearProgress, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, styled } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import useAuthentication_encad from './useAuthentication_encad';
import Header from './Header';
import Footer from "./Footer";
import axios from 'axios';
import { API_BASE_URL } from './config'; // Adjust the path if necessary

// Styled components
const Input = styled('input')({
  display: 'none',
});

const Label = styled('label')(({ theme }) => ({
  display: 'inline-block',
  padding: '10px 20px',
  backgroundColor: '#00695c', // Green color
  color: 'white',
  borderRadius: '4px',
  cursor: 'pointer',
  fontFamily: '"Tajawal", sans-serif',
  fontSize: '16px',
  fontWeight: 700,
  textAlign: 'center',
  '&:hover': {
    backgroundColor: '#004d40', // Darker green on hover
  },



}));

function UploadCvEncadr() {
  const { IDEncadrement, encadata, loadingauth } = useAuthentication_encad();
  const navigate = useNavigate();
  // Create the URL to the PDF file
  const pdfUrl = `${process.env.PUBLIC_URL}/DocMfep/cv.docx`;

  const handleOpenPdf = () => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
    }
  };
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(''); // New state for file name
  const [uploading, setUploading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!loadingauth && IDEncadrement === null) {
      navigate('/');
      return;
    }
  }, [IDEncadrement, loadingauth, encadata, navigate]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf' && selectedFile.size <= 2 * 1024 * 1024) {
      setFile(selectedFile);
      setFileName(selectedFile.name); // Update the file name state
    } else {
      setDialogMessage('يرجى اختيار ملف PDF لسيرتك الذاتية بحجم 500 كيلوبايت أو أقل.');
      setSuccess(false);
      setOpenDialog(true);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setDialogMessage('لم يتم اختيار ملف.');
      setSuccess(false);
      setOpenDialog(true);
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('IDEncadrement', IDEncadrement);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/uploadcvencadr`, formData, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      });
      setDialogMessage('تم رفع الملف بنجاح');
      setSuccess(true);
      setOpenDialog(true);
    } catch (error) {
      setDialogMessage('فشل رفع الملف.');
      setSuccess(false);
      setOpenDialog(true);
    } finally {
      setUploading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (!encadata) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }

  return (
    <>
      <Header />
      <Box sx={{ mt: 0, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {!encadata && <CircularProgress />}
      </Box>

      <Grid item sx={{ width: "100%" }}>
        <Divider sx={{ mt: "5px" }}>
          <Grid item>
            <Typography
              style={{
                color: "#004d40",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 25,
                fontWeight: 700,
                marginBottom: "25px",
              }}
              variant="h3"
              gutterBottom
            >
              <UploadFileIcon sx={{ mr: "5px" }} />
              رفع السيرة الذاتية
            </Typography>
          </Grid>
        </Divider>
      </Grid>

      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenPdf}
          sx={{
            backgroundColor: '#00695c', // Green color
            '&:hover': {
              backgroundColor: '#004d40', // Darker green on hover
            },
            padding: '8px 16px',  // Add padding inside the button (top-bottom, left-right)
            gap: '8px',  // Space between Typography and the icon
            mb: 5,
            mt: 2,
            fontFamily: '"Tajawal", sans-serif',
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          نموذج السيرة الذاتية
        </Button>
        <br />
        <Input
          id="file-input"
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}

        />
        <Label htmlFor="file-input">
        اختر ملف PDF للرفع...
        </Label>
        {fileName && <Typography sx={{
          mt: 1, fontFamily: '"Tajawal", sans-serif', fontSize: 20,
        }}>ملف مختار: {fileName}</Typography>}
        {!fileName && <Typography sx={{
          mt: 1, fontFamily: '"Tajawal", sans-serif', fontSize: 20,
        }}> يرجى اختيار ملف PDF لسيرتك الذاتية بحجم 500 كيلوبايت أو أقل.
</Typography>}

        {fileName &&
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={uploading}
            sx={{
              backgroundColor: '#00695c', // Green color
              '&:hover': {
                backgroundColor: '#004d40', // Darker green on hover
              },
              padding: '8px 16px',  // Add padding inside the button (top-bottom, left-right)
              gap: '8px',  // Space between Typography and the icon
              mb: 5,
              mt: 2,
              fontFamily: '"Tajawal", sans-serif',
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            {uploading ? 'جاري الرفع...' : 'رفع الملف'}
          </Button>
        }

        {uploading && (
          <LinearProgress
            sx={{
              mt: 2,
              height: 8, // Adjust height if needed
              borderRadius: 5,
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#004d40', // Green color for the progress bar
              },
              '& .MuiLinearProgress-root': {
                backgroundColor: '#00695c', // Light green background color
              },
            }}
          />
        )}

      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: '"Tajawal", sans-serif',
          }}
        >
          {success ? <CheckCircleIcon sx={{ color: 'green', mr: 1, ml: 1 }} /> : null}
          {success ? 'نجاح' : 'خطأ'}
        </DialogTitle>
        <DialogContent
          sx={{
            fontFamily: '"Tajawal", sans-serif',
          }}
        >
          <DialogContentText sx={{ textAlign: 'center', fontFamily: '"Tajawal", sans-serif' }}>
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            fontFamily: '"Tajawal", sans-serif',
            fontSize: 20,
          }}
        >
          <Button onClick={handleCloseDialog}
            sx={{
              backgroundColor: '#00695c', // Green color
              '&:hover': {
                backgroundColor: '#004d40', // Darker green on hover
              },
              color: '#FFFFFF',
              fontFamily: '"Tajawal", sans-serif',
              fontSize: 16,
              fontWeight: 700,

            }}

          >
            إغلاق
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </>
  );
}

export default UploadCvEncadr;
