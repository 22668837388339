import React, { useState, useEffect, useRef } from "react";

import { Typography, Box } from "@mui/material";

import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Select from "@mui/material/Select";
import { APP_PREFIX } from "./config"; // Adjust the path if necessary
import Autocomplete from "@mui/material/Autocomplete";
import LinearProgress from "@mui/material/LinearProgress";
import useAuthentication_etab from "./useAuthentication_etab";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';

import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,

  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { arSD } from '@mui/x-data-grid/locales';

import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { CacheProvider } from "@emotion/react";
import MenuItem from "@mui/material/MenuItem";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import Card from 'react-bootstrap/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';

import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import './MyToolbarStyles.css'; // Adjust the path as necessary

import XLSX from "sheetjs-style";
import Footer from "./Footer";
import Header from "./Header";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./config"; // Adjust the path if necessary
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import createCache from "@emotion/cache";
import dayjs from 'dayjs';
import "../containers/amirifont.css"; // Import the font CSS file
import Divider from "@mui/material/Divider";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from "@material-ui/core/styles";

const defaultColor = "#4caf50"; // Default green color
const hoverColor = "#004d40"; // Lighter green when hovered
const focusColor = "#81c784"; // Even lighter green when focused

const themetextfieldnew = createTheme({
  palette: {
    primary: {
      main: defaultColor
    },
    secondary: {
      main: defaultColor
    }
  },
  direction: 'rtl',
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: hoverColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: focusColor,
          },
        },
        notchedOutline: {
          borderColor: defaultColor,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: focusColor,
          },
          "&:hover": {
            color: hoverColor,
          },
          color: defaultColor, // Default label color
        },
      },
    },
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  },
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({

  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const StyledBox = styled("div")(({ theme }) => ({
  height: 400,
  width: "100%",
  "& .MuiDataGrid-cell--editable": {
    backgroundColor:
      theme.palette.mode === "dark" ? "#376331" : "rgb(217 243 190)",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1
      })`,
    color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {
  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(
        isValid ? null : "Username must contain only alphabets (a to z)."
      );
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : "Username must contain only Arabic alphabets.");
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}





const apiUrl = `${API_BASE_URL}/api/getPreinscriptionbyetab`;


function CustomToolbar({ setRows, setRowModesModel, columns, handleExportExcel }) {
  return (


    <GridToolbarContainer className="custom-toolbar">
      <GridToolbarColumnsButton className="custom-toolbar" />
      <GridToolbarFilterButton className="custom-toolbar" />
      <GridToolbarDensitySelector className="custom-toolbar" />
      <Button className="custom-toolbar" onClick={handleExportExcel} color="primary">
        تنزيل كملف إكسال
      </Button>
      <GridToolbarExport className="custom-toolbar" />

      <ThemeProvider theme={themetextfieldnew}>
        <GridToolbarQuickFilter
          className="custom-toolbar"

        />
      </ThemeProvider>



    </GridToolbarContainer>
  );
}



let options_Wly = [];
let Wlylists = [];

function ShowAllPreinscrit() {
  const {
    IDetablissement,
    IDEts_Form,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  } = useAuthentication_etab();

  // Inherit the theme from the docs site (dark/light mode)
  const existingTheme = useTheme();

  const theme_datagrid = React.useMemo(
    () =>
      createTheme({}, arSD, existingTheme, {
        direction: 'rtl',
      }),
    [existingTheme],
  );



  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState("");
  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);

  const [filteredRows, setFilteredRows] = useState([]);
  const [gridKey, setGridKey] = useState(0); // Add a key for the DataGrid

  const [uniqueNomEtValues, setUniqueNomEtValues] = useState([]);
  const [uniquesession_nomValues, setUniquesession_nomValues] = useState([]);

  const [Selectedsession_nomValue, setSelectedsession_nomValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "جميع الدورات",
  });

  const [uniquespecialiteValues, setuniquespecialiteValues] = useState([]);

  const [uniqueNomFormationValues, setuniqueNomFormationValues] = useState([]);
  const [uniquesemValues, setuniquesemValues] = useState([]);

  const [selectedNomFormationValue, setselectedNomFormationValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "جميع الأنماط",
  });
  const [selectedsemValue, setselectedsemValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "جميع السداسيات",
  });

  const [selectedNomEtValue, setSelectedNomEtValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "كل المؤسسات",
  });
  const [selectedspecialiteValue, setselectedspecialiteValue] = useState({
    indexvalue: 0,
    value: 999,
    label: "كل التخصصات",
  });

  const [Nom_app, setNom_app] = useState('');
  const [Prenom_app, setPrenom_app] = useState('');
  const [DateNais_app, setDateNais_app] = useState('');
  const [LieuNais_app, setLieuNais_app] = useState('');
  const [listetab, setlistetab] = useState('');

  const [FailedAuth, setFailedAuth] = useState(false);

  const [OpenDialog, setOpenDialog] = useState(false);
  const [OpenDialog_notfound, setOpenDialog_notfound] = useState(false);


  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleClickOpenDialog_notfound = () => {
    setOpenDialog_notfound(true);
  };


  const handleClickCloseDialog = () => {

    setOpenDialog(false);
  };
  const handleClickCloseDialog_notfound = () => {

    setOpenDialog_notfound(false);
  };

  const [IDDFEP_selected, setIDDFEP_selected] = useState(1);

  const [loadingdata_mfep, setloadingdata_mfep] = useState(false);

  const editedValueRef = useRef(editedValue);

  const [selectedOption, setSelectedOption] = useState(null);

  const onwlyChanged = (event, values) => {
    if (values) {
      setSelectedOption(values);
      setIDDFEP_selected(values.value);
    } else {
      return;
    }
  };

  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);

  const navigate = useNavigate();

  const useStyles_listdfep = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 18,
        direction: "rtl",
      },
    },
    inputRoot: {
      color: "#004d40",

      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif',
        fontWeight: 700,
        color: "#004d40",
        // Default left padding is 6px
        paddingLeft: 26,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#004d40",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#00796b",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#004d00",
      },
    },
  }));

  const classes_listdfep = useStyles_listdfep();


  ;

  useEffect(() => {
    if (filteredRows && filteredRows.length > 0) {
      // Increment the key to force a remount of the DataGrid
      setGridKey((prevKey) => {
        ////alert(`Previous key: ${prevKey}`);
        return prevKey + 1;
      });
    }
  }, [filteredRows]);

  useEffect(() => {
    if (IDEts_Form == null || Number(IDEts_Form) <= 0) {
      return; // Or any other handling you might want
    }

    setloadingdata_mfep(true);
    setRows([]); // Clear rows before setting new rows
    setFilteredRows([]);

    const data = {
      IDEts_Form: IDEts_Form,
    };

    // Make the API call using axios
    axios({
      method: 'post', // Assuming it's a POST request
      url: apiUrl,
      data: data, // Send data in the request body
      withCredentials: true, // Send cookies with the request
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`,
      },
    })

      .then((response) => {
       // console.error('responseazizdata:', response.data);
        const apiRows = response.data.data.map((item, index) => ({
          id: item.IDPreinscrit,
          id_rows: index + 1,
          IDPreinscrit: item.IDPreinscrit,
          preinscrit_Nom: item.preinscrit_Nom,
          preinscrit_NomFr: item.preinscrit_NomFr,
          preinscrit_Prenom: item.preinscrit_Prenom,
          preinscrit_PrenomFr: item.preinscrit_PrenomFr,
          DateNais: item.DateNais,
          LieuNais: item.LieuNais,
          Civ: item.Civ,
          dateInscr: item.dateInscr,
          endicape: item.endicape,
          Adres: item.Adres,
          Nin: item.Nin,
          NumActeNais: item.NumActeNais,
          tel1: item.tel1,
          IDOffre: item.IDOffre,
          IDSession: item.IDSession,
          IDSpecialite: item.IDSpecialite,
          IDMode_formation: item.IDMode_formation,
          DateD: item.DateD,
          DateF: item.DateF,
          DateDsession: item.DateDsession,
          IDEts_Form: item.IDEts_Form,
          ValidationDfp: item.ValidationDfp,
          ValidDfp: item.ValidDfp,
          ValideCentral: item.ValideCentral,
          Valide: item.Valide,
          code_etab: item.code_etab,
          Nom_Et: item.Nom_Et,
          Nom_NomFr_session: item.Nom_NomFr_session,
          Nom_NomFr_CodeSpec_NbrSem_Specialite: item.Nom_NomFr_CodeSpec_NbrSem_Specialite,
          code_Nom_etablissement: item.code_Nom_etablissement,
          Nom_NomFr_mode_formation: item.Nom_NomFr_mode_formation,
          Nom_NomFr_qualification_dplm: item.Nom_NomFr_qualification_dplm,
          Abr_AbrFr_qualification_dplm: item.Abr_AbrFr_qualification_dplm,
          Nom_NomFr_niveau_scol: item.Nom_NomFr_niveau_scol,
          AgeMAx: item.AgeMAx,
          AgeMin: item.AgeMin,
          IDCycle_scol: item.IDCycle_scol,
          NumOrd: item.NumOrd,
          Validepreinscrit: item.Validepreinscrit,
          crypted_id: item.crypted_id,
          actions: '',
        }));

        setRows(apiRows);
        setFilteredRows(apiRows);
        setloadingdata_mfep(false);
      })
      .catch((error) => console.error("Error fetching data:", error));

  }, [IDEts_Form]);

  /*
  useEffect(() => {
    if(selectedspecialiteValue==null && uniquespecialiteValues.length>0 ){
      alert("uniquespecialiteValues.length");
    setselectedspecialiteValue({
      indexvalue: 0,
      value: 999,
      label: "كل التخصصات",
    });
    }

    
  }, [uniqueNomEtValues,uniqueNomFormationValues,uniquesemValues,uniquespecialiteValues]);
  */

  useEffect(() => {
    if (
      selectedNomFormationValue &&
      selectedNomEtValue &&
      selectedsemValue &&
      selectedspecialiteValue &&
      Selectedsession_nomValue
    ) {
      // Reset filteredRows
      setFilteredRows([]);

      // Check for default values
      const isNomFormationDefault =
        Number(selectedNomFormationValue.value) === 999;
      const isNomEtDefault = Number(selectedNomEtValue.value) === 999;
      const isSemDefault = Number(selectedsemValue.value) === 999;
      const isSpecDefault = Number(selectedspecialiteValue.value) === 999;
      const isSessionDefault = Number(Selectedsession_nomValue.value) === 999;

      // Filter rows based on selection
      const filteredData = rows.filter((row) => {
        if (
          isNomFormationDefault &&
          isNomEtDefault &&
          isSemDefault &&
          isSpecDefault &&
          isSessionDefault
        ) {
          // No filtering needed, set filteredRows to all rows
          return true;
        } else {
          // Filter rows based on selected values
          return (
            (isNomFormationDefault ||
              Number(row.IDMode_formation) === Number(selectedNomFormationValue.value)) &&
            (isNomEtDefault || row.code_etab === selectedNomEtValue.value) &&
            (isSemDefault || Number(row.NumSem) === Number(selectedsemValue.value)) &&
            (isSpecDefault ||
              Number(row.IDSpecialite) === Number(selectedspecialiteValue.value)) &&
            (isSessionDefault ||
              Number(row.IDSession) === Number(Selectedsession_nomValue.value))
          );
        }
      });

      setFilteredRows(filteredData);
    }
  }, [
    selectedNomEtValue,
    selectedNomFormationValue,
    selectedsemValue,
    selectedspecialiteValue,
    Selectedsession_nomValue,
  ]);

  const handlesessionChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setSelectedsession_nomValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected session Value:", newValue);
    }
  };


  function onchangeValid(row) {
    //alert(row.IDPreinscrit);
    // Toggle the value of Validepreinscrit between 0 and 1
    row.Validepreinscrit = Number(row.Validepreinscrit) === 1 ? 0 : 1;

    // Update the state of the row data
    setRows(prevRows =>
      prevRows.map(prevRow =>
        prevRow.id === row.id ? { ...prevRow, Validepreinscrit: row.Validepreinscrit } : prevRow
      )
    );

    // Handle saving the updated row data
    handleSaveOperation(row.IDPreinscrit, row.Validepreinscrit);
  }

  const handleSaveOperation = async (IDPreinscrit, Validepreinscrit) => {
    // Display an alert with the row ID and the updated value
    try {
      const data = {
        Valideyesno: Validepreinscrit,
        IDPreinscrit: IDPreinscrit,
        IDEts_Form: IDEts_Form
      };

      const response = await axios.post(`${API_BASE_URL}/api/Preinscritvalide_valideyesno`, data,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });


      //console.error('responseazizdata:', response.data);

      if (response.data && response.data.CERTAFICAT) {
        if (response.data.CERTAFICAT === "no") {

        } else {

          setSnackbar({ children: ` تم التعديل على: ${response.data.CERTAFICAT.Nom} ${response.data.CERTAFICAT.Prenom}`, severity: 'success' });
        }

        if (response.data && response.data.preinscritotheretab) {
          console.error('responseazizdata:', response.data.preinscritotheretab);
          // Check if preinscritotheretab is an empty array
          if (Array.isArray(response.data.preinscritotheretab) && response.data.preinscritotheretab.length === 0) {

          }
          else {
            const etablissementList = response.data.preinscritotheretab.map((item, index) => (
              `<hr /> 
                  رقم التسجيل: ${item.IDPreinscrit} <br />
                  المؤسسة ${index + 1}: ${item.Etablissement_Nom} <br />
                  ${item.Communn_Nom} <br />
                  ${item.Specialite_Nom} <br />
                  ${item.Mode_formation_Nom} <br /><br />
                  <a href="/Preinscriptionpdf/${item.crypted_id}" style="text-decoration: none;">
                      <button style="
                          background-color: #00695c; 
                          color: #FFFFFF; 
                          font-family: 'Tajawal', sans-serif; 
                          font-size: 12px; 
                          font-weight: 900; 
                          padding: 6px 12px; 
                          border: none; 
                          cursor: pointer;">
                          استمارة التسجيل
                      </button>
                  </a>`
            )).join('<br /><br />');

            setlistetab(etablissementList);


            //alert(etablissementList);


            setNom_app(response.data.preinscritotheretab[0].Nom);
            setPrenom_app(response.data.preinscritotheretab[0].Prenom);
            setDateNais_app(dayjs(response.data.preinscritotheretab[0].DateNais).format('YYYY-MM-DD'));
            setLieuNais_app(response.data.preinscritotheretab[0].LieuNais);


            handleClickOpenDialog();
          }
        }

      }
      else {

      }
    } catch (error) {
      // Handle API call errors
      console.error('API call failed:', error);
      ////console.error('error:', error.response.data.error);

      // Optionally setFailedAuth(true) or perform other error handling
    } finally {

    }

  }


  const handlespecialiteChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setselectedspecialiteValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected specialite Value:", newValue);
    }
  };

  const handleNomEtChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setSelectedNomEtValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected Nom Et Value:", newValue);
    }
  };

  const handleNomFormationChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setselectedNomFormationValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected Nom formation Value:", newValue);
    }
  };

  const handlesemChange = (event, newValue) => {
    if (newValue) {
      // Handle the change according to newValue
      setselectedsemValue(newValue); // Assuming setSelectedNomEtValue is the function to set the selectedNomEtValue state

      // You can perform further actions based on the selected value if needed
      console.log("Selected semestre Value:", newValue);
    }
  };

  /*
  const handleNomEtChange = event => {
    ///alert(event.target.value)
    
    setSelectedNomEtValue(event.target.value);
  };*/
  const processRowUpdate = async (newRow) => {
    // Check if the row is new
    const isNewRow = rows.find((row) => row.id === newRow.id)?.isNew;

    // Preserve the original isNew value if it exists
    const updatedRow = { ...newRow, isNew: isNewRow };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Display an alert with the updated row data and id
    ///alert(`Row Data After Update for ID ${newRow.id}: ${JSON.stringify(updatedRow)}`);



    return updatedRow;
  };

  const handleRowEditStop = (params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.commit
    ) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);

      if (editedRow) {
        /// alert(`Editing stopped for ID: ${editedRow.id}`);
        // Trigger the save operation for the edited row
      }
    }
  };

  const handlecert_inscription = (id) => () => {
    const rowToid = rows.find((row) => row.id === id);
    if (rowToid) {
      // Extract NomAr value from the row
      const { crypted_id } = rowToid;

      // Retrieve the token from sessionStorage
      const tokenEtab = sessionStorage.getItem("authData_73xJ#E");

      // Store the token in localStorage
      localStorage.setItem("authData_73xJ#E", tokenEtab);

      /// navigate(`/ETATCertificatInscriptionPDF/${crypted_id}`);

      if (tokenEtab) {
        // Extract NomAr value from the row
        const { crypted_id } = rowToid;

        // Open link in a new tab
        window.open(`/ETATCertificatInscriptionPDF/${crypted_id}`, "_blank");
      } else {
        // Handle case where tokenEtab is not available
        console.error("TokenEtab not found in sessionStorage.");
      }
    }
  };

  const handlecert_certscolaire = (id) => () => {
    const rowToid = rows.find((row) => row.id === id);
    if (rowToid) {
      // Extract NomAr value from the row
      const { crypted_id } = rowToid;

      // Retrieve the token from sessionStorage
      const tokenEtab = sessionStorage.getItem("authData_73xJ#E");

      // Store the token in localStorage
      localStorage.setItem("authData_73xJ#E", tokenEtab);

      /// navigate(`/ETATCertificatScolaritePDF/${crypted_id}`);

      if (tokenEtab) {
        // Extract NomAr value from the row
        const { crypted_id } = rowToid;

        // Open link in a new tab
        window.open(`/ETATCertificatScolaritePDF/${crypted_id}`, "_blank");
      } else {
        // Handle case where tokenEtab is not available
        console.error("TokenEtab not found in sessionStorage.");
      }
    }
  };

  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };

  const [idsaved, setidsaved] = useState(0);

  const [confirmationTrigger, setConfirmationTrigger] = useState(false);

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    //setidforsave(id);
    ///setConfirmationTrigger(true); // Set the trigger for confirmation
  };

  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);

  const handleDeleteClick = (id) => () => {
    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { ID } = rowToDelete;
      const { NomAr } = rowToDelete;
      const { PrenomAr } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      setDeletedRowId(ID);
      setDeleted_NomAr(NomAr);
      setDeleted_PrenomAr(PrenomAr);
    }
  };

  const handleSaveConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setSaveConfirmationOpen(false);

    if (confirmed) {
      const idforsavea = idforsave;

      setidforsave(null);
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const SaveConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const [addRecordClicked, setAddRecordClicked] = useState(false);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /*

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  
    // Check if a row is in edit mode
    const editedRowId = Object.keys(newRowModesModel).find(
      (id) => newRowModesModel[id]?.mode === GridRowModes.Edit
    );
  
    // If a row is in edit mode, trigger the save operation
    if (editedRowId) {
      handleSaveOperation(editedRowId);
    }
  };

*/
  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const useStyles = makeStyles({
    cancelBtn: {
      backgroundColor: "#004d40", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const ConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>
          <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider>
                <Grid item sx={{ mt: 2 }}>
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحذف <DeleteIcon sx={{ ml: "0px" }} />
                  </Typography>
                </Grid>
              </Divider>
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                }}
              >
                هل أنت متأكد من أنك تريد حذف{" "}
                <span style={{ color: "black" }}>
                  {deleted_PrenomAr} {deleted_NomAr}؟
                </span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                  backgroundColor: "#004d40", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 16,
                  fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </CacheProvider>
    );
  };

  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map((col) => ({
      name: col.field,
      headerName: col.headerName,
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      rows.map((row) => {
        const newRow = {};
        relevantColumns.forEach((col) => {
          newRow[col.headerName] = row[col.name];
        });
        return newRow;
      })
    );

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: "Calibri",
        sz: 11,
        bold: true,
        color: {
          rgb: "FFFFAA00",
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  const columns = [
    
    {
      field: 'id_rows',
      headerName: 'رقم',
      minWidth: 10,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "actions",
      type: "actions",
      headerName: "استمارة التسجيل",
      align: "center",
      minWidth: 160,
      cellClassName: "actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",

      getActions: (params) => {
        return [
          <Button
            onClick={() => window.open(`/Preinscriptionpdf/${params.row.crypted_id}`, "_blank")}
            sx={{
              backgroundColor: '#00695c', // Green color
              '&:hover': {
                backgroundColor: '#004d40', // Darker green on hover
              }
            }}
            variant="contained"
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 12,
                fontWeight: 900,
              }}
            >
              معاينة
            </Typography>
          </Button>
        ];
      },
    },


    {
      field: 'Validepreinscrit',
      headerName: 'الموافقة',
      minWidth: 55,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              checked={Number(params.row.Validepreinscrit) === 1}
              onChange={() => onchangeValid(params.row)}
            />
          }
          label={
            <span
              style={{
                color: "#004d40",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 16,
                fontWeight: 900,
              }}
            />
          }
        />
      ),
    }
    ,
    {
      field: 'IDPreinscrit',
      headerName: 'رقم التسجيل',
      minWidth: 170,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'preinscrit_Nom',
      headerName: 'اللقب',
      minWidth: 130,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },
    {
      field: 'preinscrit_Prenom',
      headerName: 'الإسم',
      minWidth: 130,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },
    {
      field: 'DateNais',
      headerName: 'تاريخ الميلاد',
      minWidth: 130,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },
    {
      field: 'LieuNais',
      headerName: 'مكان الميلاد',
      minWidth: 130,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },
    {
      field: 'Adres',
      headerName: 'العنوان',
      minWidth: 130,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },
    {
      field: 'Nin',
      headerName: 'NIN',
      minWidth: 250,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },
    {
      field: 'Civ',
      headerName: 'نوع',
      minWidth: 130,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.value === 1 ? 'ذكر' : params.value === 2 ? 'أنثى' : '';
      },
    },

    {
      field: 'endicape',
      headerName: 'ذوي الاحتياجات الخاصة',
      minWidth: 130,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.value === 1 ? 'نعم' : 'لا';
      },
    },

    {
      field: 'dateInscr',
      headerName: 'تاريخ التسجيل',
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    }
    ,
    {
      field: 'IDOffre',
      headerName: 'رمز العرض',
      minWidth: 55,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },


    {
      field: 'Nom_NomFr_CodeSpec_NbrSem_Specialite',
      headerName: 'التخصص',
      minWidth: 550,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },
    {
      field: 'Abr_AbrFr_qualification_dplm',
      headerName: 'الشهادة',
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },
    {
      field: 'Nom_NomFr_niveau_scol',
      headerName: 'المستوى المطلوب',
      minWidth: 300,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',


    },


    {
      field: 'Nom_NomFr_mode_formation',
      headerName: 'نمط التكوين',
      flex: 1,
      minWidth: 400,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'code_Nom_etablissement',
      headerName: 'المؤسسة',
      flex: 1,
      minWidth: 400,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
    },

    {
      field: 'DateD',
      headerName: 'تاريخ البداية',
      minWidth: 140,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'DateF',
      headerName: 'تاريخ النهاية',
      minWidth: 140,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',

    }


  ];

  function generateRandomString(length = 6) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const CopyToClipboardButton = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: "success" });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };

  useEffect(() => {
    if (!loadingauth) {
      if (
        IDetablissement === null ||
        IDDFEP === null ||
        IDNature_etsF === null ||
        Number(IDNature_etsF) === 5
        ||
        Number(IDNature_etsF) === 1
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/loginEtab');
        return;
      }
    }
  }, [
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress color="success" />
      </Box>
    );
  }

  return (
    <>
      <Header
        IDetablissement={IDetablissement}
        IDEts_Form={IDEts_Form}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={
          download_cert_inscription_active_Dfep
        }
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={
          download_cert_inscription_active_Mfep
        }
      />

      <Box
        sx={{
          height: 500,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <div style={{ height: 400, width: "100%" }}>
          <Box
            sx={{
              '& .super-app-theme--header': {
                fontFamily: 'Cairo',
                fontSize: '20px',
                fontWeight: 'bold', // Make the font bold
                backgroundColor: '#004d40', // Light green background color

                borderRight: '1px solid #a5d6a7', // Greenish border for better contrast
                fontWeight: 900,
                color: '#FFFFFF'
              },
            }}

          >
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                {!!snackbar && (
                  <Snackbar
                    open
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={2000}
                    sx={{
                      marginTop: 12,
                    }}
                  >
                    <Alert
                      {...snackbar}
                      onClose={handleCloseSnackbar}
                      sx={{
                        display: "flex",
                        fontFamily: "Amiri",
                        fontSize: "20px",
                        "& .MuiAlert-icon": { marginLeft: "10px" },
                        "& .MuiAlert-action": { marginRight: "8px" },
                      }} // Adjust padding here
                    />
                  </Snackbar>
                )}

                <Grid container>
                  <Grid item sx={{ width: "100%" }}>
                    <Divider sx={{ mt: "20px" }}>
                      <Grid item>
                        <Typography
                          style={{
                            color: "#004d40",
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 25,
                            fontWeight: 700,
                            marginBottom: "25px",
                          }}
                          variant="h3"
                          gutterBottom
                        >
                          <PlaylistAddCheckIcon sx={{ mr: "5px" }} />
                          قائمة المسجلين
                        </Typography>
                      </Grid>
                    </Divider>
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    align="center"
                    justifyContent="center"
                    sx={{ width: "100%", marginBottom: "10px" }}
                  >


                    <Grid container>
                      {uniquesession_nomValues.length > 0 ? (
                        <Grid item xs={2} sx={{ pr: 1 }}>
                          <Autocomplete
                            noOptionsText={"حدد الدورة"}
                            options={uniquesession_nomValues}
                            onChange={handlesessionChange}
                            value={Selectedsession_nomValue}
                            defaultValue={uniquesession_nomValues[0]} // Set the defaultValue to the first option
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="الدورات"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#004d40",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#004d40",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor: indexvalue % 2 === 0 ? '#004d40' : '#FFFFFF',
                                    color: indexvalue % 2 === 0 ? '#FFFFFF' : '#004d40',
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? label : label}
                                </span>
                              );
                            }}
                          />
                        </Grid>
                      ) : null}



                      {uniqueNomFormationValues.length > 0 ? (
                        <Grid item xs={2} sx={{ pr: 1 }}>
                          <Autocomplete
                            noOptionsText={"حدد النمط"}
                            options={uniqueNomFormationValues}
                            onChange={handleNomFormationChange}
                            value={selectedNomFormationValue}
                            defaultValue={uniqueNomFormationValues[0]} // Set the defaultValue to the first option
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="النمط"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#004d40",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#004d40",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor: indexvalue % 2 === 0 ? '#004d40' : '#FFFFFF',
                                    color: indexvalue % 2 === 0 ? '#FFFFFF' : '#004d40',
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? label : label}
                                </span>
                              );
                            }}
                          />
                        </Grid>
                      ) : null}

                      {uniquespecialiteValues.length > 0 ? (
                        <Grid item xs={6} sx={{ pr: 1 }}>
                          <Autocomplete
                            noOptionsText={"حدد التخصص"}
                            options={uniquespecialiteValues}
                            onChange={handlespecialiteChange}
                            value={selectedspecialiteValue}
                            defaultValue={uniquespecialiteValues[0]} // Set the defaultValue to the first option
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="التخصص"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#004d40",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#004d40",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor: indexvalue % 2 === 0 ? '#004d40' : '#FFFFFF',
                                    color: indexvalue % 2 === 0 ? '#FFFFFF' : '#004d40',
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? label : label}
                                </span>
                              );
                            }}
                          />
                        </Grid>
                      ) : null}

                      {uniquesemValues.length > 0 ? (
                        <Grid item xs={2} sx={{ pr: 1 }}>
                          <Autocomplete
                            noOptionsText={"حدد السداسي"}
                            options={uniquesemValues}
                            onChange={handlesemChange}
                            value={selectedsemValue}
                            defaultValue={uniquesemValues[0]} // Set the defaultValue to the first option
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="السداسي"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    color: "#004d40",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    color: "#004d40",
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  },
                                }}
                              />
                            )}
                            renderOption={(props, option) => {
                              const { label, indexvalue } = option;
                              return (
                                <span
                                  {...props}
                                  style={{
                                    backgroundColor: indexvalue % 2 === 0 ? '#004d40' : '#FFFFFF',
                                    color: indexvalue % 2 === 0 ? '#FFFFFF' : '#004d40',
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  {indexvalue === -1 ? label : label}
                                </span>
                              );
                            }}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>

                  {loadingdata_mfep ? (
                    <>
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress color="success" />
                      </Box>
                    </>
                  ) : null}

                  <Grid container>
                    <Grid item sx={{ width: "100%" }}>

                      <CacheProvider value={cacheRtl}>
                        <ThemeProvider theme={theme_datagrid}>
                          <div dir="rtl" style={{ height: '100%', width: '100%', minHeight: 300 }}>
                            <DataGrid



                              rows={filteredRows}
                              columns={columns}


                              editMode="row"
                              rowModesModel={rowModesModel}
                              onRowModesModelChange={handleRowModesModelChange}
                              onRowEditStop={handleRowEditStop}
                              processRowUpdate={processRowUpdate}
                              getRowId={(row) => row.id}
                              getRowClassName={(params) =>
                                parseInt(params.row.id_rows, 10) % 2 === 0 ? 'even-row' : 'odd-row'
                              }

                              slots={{
                                toolbar: CustomToolbar,
                              }}

                              slotProps={{
                                toolbar: { setRows, setRowModesModel, columns, handleExportExcel },
                              }}

                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: 'green', // Green border color
                                overflowX: 'scroll',

                                '& .MuiDataGrid-cell': {
                                  fontFamily: 'Tajawal',
                                  fontSize: '20px',
                                  textAlign: 'center',
                                  backgroundColor: 'rgba(255, 255, 255, 0.65)', // Keep the background color of cells as is
                                  borderRight: '1px solid #ccc', // Keep the right border as is
                                },
                                '& .MuiDataGrid-row:hover': {
                                  backgroundColor: '#004d40', // Green background color when row is hovered
                                },

                                '& .even-row': {
                                  backgroundColor: '#e8f5e9', // Light green background color for even rows
                                },
                                '& .odd-row': {
                                  backgroundColor: '#ffffff', // Keep the background color for odd rows as is
                                },
                              }}


                              initialState={{

                                pagination: {
                                  paginationModel: { pageSize: 25, page: 0 },
                                },

                              }}


                            />

                          </div>
                        </ThemeProvider>
                      </CacheProvider>

                    </Grid>
                  </Grid>


                </Grid>
              </ThemeProvider>
            </CacheProvider>
          </Box>
          <br/>
          <Footer />
        </div>
        <SaveConfirmationDialog
          open={isSaveConfirmationOpen}
          onClose={handleSaveConfirmationResponse}
        />

        {/* Dialog success save */}

        <Dialog
          PaperProps={{
            style: {

              boxShadow: 'none',
            },
          }}
          //fullScreen={mediumScreen}
          open={OpenDialog}
          onClose={handleClickCloseDialog}
          aria-labelledby="responsive-dialog-title"
        >

          <Card sx={{ maxWidth: 345, }}>
            <CardHeader

              action={
                <IconButton onClick={handleClickCloseDialog} aria-label="close" sx={{ ml: -1, mt: -1 }}>
                  <CloseIcon />
                </IconButton>
              }
              title={
                <>
                  <Box sx={{ ml: 2, mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <Typography variant="h6" sx={{ color: '#004d40', fontFamily: 'Tajawal, sans-serif', fontWeight: 900 }}>
                      هذا المتكون موافق عليه في مؤسسة أخرى

                    </Typography>
                  </Box>
                  <Divider variant="middle" sx={{ mb: 1 }} />

                </>
              }
              subheader={
                <>
                  <Typography variant="body1" sx={{ display: 'flex', fontSize: 16, fontWeight: 'bold', fontFamily: 'Tajawal, sans-serif' }}>
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: 'Tajawal, sans-serif' }}>اللقب و الاسم: </span> &nbsp; {Nom_app} {Prenom_app}
                  </Typography>
                  <Typography variant="body1" sx={{ display: 'flex', fontSize: 16, fontWeight: 'bold', fontFamily: 'Tajawal, sans-serif' }}>
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: 'Tajawal, sans-serif' }}>تاريخ و مكان الميلاد: </span> &nbsp; {DateNais_app} {LieuNais_app}
                  </Typography>
                  <Typography variant="body1" sx={{ display: 'flex', fontSize: 14, fontWeight: 'bold', fontFamily: 'Tajawal, sans-serif' }}>
                    <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: 'Tajawal, sans-serif' }} dangerouslySetInnerHTML={{ __html: listetab }} />
                  </Typography>


                </>
              }
            />
          </Card>


        </Dialog>

      </Box>
    </>
  );
}

export default ShowAllPreinscrit;
