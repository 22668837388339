import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from "../containers/config"; // Adjust the path if necessary
import { APP_PREFIX } from "./config"; // Adjust the path if necessary

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {

    const destroyToken = `${API_BASE_URL}/api/destroyToken`;

    fetch(destroyToken, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          sessionStorage.clear();
          throw new Error(
            "Network response was not ok"
          );
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          sessionStorage.clear();
          console.error(
            "Distroyed token: successfully"
          );
        }
      })
      .catch((error) => {
        sessionStorage.clear();
        console.error("Error destroyToken:", error);
      })
      .finally(() => {
        sessionStorage.clear();
        // Remove all data with the prefix
        const keysToRemove = Object.keys(
          localStorage
        ).filter((key) =>
          key.startsWith(APP_PREFIX)
        );
        keysToRemove.forEach((key) =>
          localStorage.removeItem(key)
        );
      });

    // Clear session storage
    sessionStorage.clear();

    // Clear local storage
    localStorage.clear();

    // Add any additional token destruction or API calls if needed here

    // After clearing, navigate to the homepage
    navigate('/');
  }, [navigate]);

  return (
    <div>
      {/* Optionally, you can display a message or a loading spinner */}
     
    </div>
  );
};

export default Logout;
