import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import Tesseract from 'tesseract.js';
import { Modal, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Typography, Box } from '@mui/material';

const CameraModal = ({ open, onClose, onCapture }) => {
    const webcamRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [cameraFacingMode, setCameraFacingMode] = useState('environment'); // Default to back camera

    const captureAndProcess = async () => {
        setLoading(true);
        const imageSrc = webcamRef.current.getScreenshot();
        try {
            const { data: { text } } = await Tesseract.recognize(imageSrc, 'eng');
            const numbers = text.match(/\d{18}/); // Extract exactly 18 consecutive digits
            if (numbers) {
                onCapture(numbers[0]);
                onClose();
            }
        } catch (err) {
            console.error('Error processing image:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            // Start capturing images at regular intervals
            const id = setInterval(captureAndProcess, 3000); // Adjust interval as needed
            setIntervalId(id);

            // Cleanup on unmount or close
            return () => clearInterval(id);
        }
    }, [open]);

    const handleCameraChange = (event) => {
        setCameraFacingMode(event.target.value);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', backgroundColor: '#fff', margin: '10% auto', maxWidth: '400px' }}>
            <FormControl sx={{ mb: 2 }}>
    <InputLabel 
        sx={{
            fontFamily: '"Tajawal", sans-serif',
            fontSize: 18,
            fontWeight: 700,
            color: '#004d40'
        }}
    >
        نوع الكاميرا
    </InputLabel>
    <Select
        value={cameraFacingMode}
        onChange={handleCameraChange}
        label="نوع الكاميرا"
        sx={{
            fontFamily: '"Tajawal", sans-serif',
            fontSize: 18,
            fontWeight: 700,
            color: '#004d40',
            '& .MuiSelect-icon': {
                color: '#004d40',
            }
        }}
    >
        <MenuItem 
            value="user"
            sx={{
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 18,
                fontWeight: 700,
                color: '#004d40'
            }}
        >
            الكاميرا الأمامية
        </MenuItem>
        <MenuItem 
            value="environment"
            sx={{
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 18,
                fontWeight: 700,
                color: '#004d40'
            }}
        >
            الكاميرا الخلفية
        </MenuItem>
    </Select>
</FormControl>

<Box sx={{ textAlign: 'center', mb: 2 }}>
    <Typography 
        sx={{
            fontFamily: '"Tajawal", sans-serif',
            fontSize: 14,
            fontWeight: 700,
            color: '#004d40'
        }}
    >
        ضع بطاقة التعريف الوطني البيومترية في الإطار
    </Typography>
</Box>
                <Webcam
    audio={false}
    ref={webcamRef}
    screenshotFormat="image/jpeg"
    width={320} // العرض
    height={202} // الارتفاع (تقريباً 320 / 1.585)
    videoConstraints={{
        facingMode: cameraFacingMode, // استخدام وضع الكاميرا المحدد
    }}
    style={{
        borderRadius: '8px', // إضافة زوايا دائرية لتشبه بطاقة الائتمان
        objectFit: 'cover', // لملء الإطار والحفاظ على نسبة العرض إلى الارتفاع
    }}
/>


                {loading && <CircularProgress sx={{ mt: 2 }} />}
            </div>
        </Modal>
    );
};

export default CameraModal;
