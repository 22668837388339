import React, { useEffect } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

const EditPdf = () => {
  const modifyPdf = async () => {
    try {
      const url = `${process.env.PUBLIC_URL}/DocMfep/CONTRAT_APPRENTISSAGE.PDF`;
      
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
      
      // Load the existing PDF
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
  
      // Register fontkit to embed custom fonts
      pdfDoc.registerFontkit(fontkit);
  
      // Load and embed the custom font
      const fontUrl_SimplifiedArabic = `${process.env.PUBLIC_URL}/SimplifiedArabic.ttf`;
      const fontBytes = await fetch(fontUrl_SimplifiedArabic).then(res => res.arrayBuffer());
      const Fontseee = await pdfDoc.embedFont(fontBytes);
  
      // Get the first page
      const page = pdfDoc.getPages()[0];
  
      // Draw text on the PDF using the custom font
      page.drawText("valueNomfr", {
        x: 50,
        y: 590,
        size: 14,
        font: Fontseee,
        color: rgb(0, 0, 0),
      });

    
      // Define the text you want to draw
const textnom = "لقب";

// Define the size of the text
const textSize = 14;

// Calculate the width of the text in the desired font and size
const textWidth = Fontseee.widthOfTextAtSize(textnom, textSize);

// Calculate the x position so the text starts at 500 and flows to the left
const xPosition = 547 - textWidth;

page.drawText(textnom, {
  x: xPosition,
  y: 590,
  size: textSize,
  font: Fontseee,
  color: rgb(0, 0, 0),
});

       

 // Draw text on the PDF using the custom font
 page.drawText("valuePrenomfr", {
  x: 215,
  y: 590,
  size: 14,
  font: Fontseee,
  color: rgb(0, 0, 0),
});



      // Define the text you want to draw
      const textprenom = "لقب";

      // Define the size of the text
      const textSizeprenom = 14;
      
      // Calculate the width of the text in the desired font and size
      const textWidthprenom = Fontseee.widthOfTextAtSize(textprenom, textSizeprenom);
      
      // Calculate the x position so the text starts at 500 and flows to the left
      const xPositionprenom = 395 - textWidthprenom;
      
      page.drawText(textprenom, {
        x: xPositionprenom,
        y: 590,
        size: textSizeprenom,
        font: Fontseee,
        color: rgb(0, 0, 0),
      });


      // Draw text on the PDF using the custom font
 page.drawText("1 9 9 1 1 2 1 0", {
  x: 402,
  y: 573,
  size: 14,
  font: Fontseee,
  color: rgb(0, 0, 0),
});


 // Define the text you want to draw
 const textlieuar = "مكان الميلاد";

 // Define the size of the text
 const textSizelieuar  = 14;
 
 // Calculate the width of the text in the desired font and size
 const textWidthlieuar = Fontseee.widthOfTextAtSize(textlieuar, textSizelieuar );
 
 // Calculate the x position so the text starts at 500 and flows to the left
 const xPositionlieuar  = 382 - textWidthlieuar ;
 
 page.drawText(textlieuar , {
   x: xPositionlieuar ,
   y: 573,
   size: textSizelieuar ,
   font: Fontseee,
   color: rgb(0, 0, 0),
 });

  // Draw text on the PDF using the custom font
  page.drawText("X", {
    x: 512,
    y: 557,
    size: 14,
    font: Fontseee,
    color: rgb(0, 0, 0),
  });

  // Draw text on the PDF using the custom font
  page.drawText("X", {
    x: 470,
    y: 557,
    size: 14,
    font: Fontseee,
    color: rgb(0, 0, 0),
  });



 // Define the text you want to draw
 const textaddrar = " أكتب ولا تخف جرب عنوان المتكون هنا";

 // Define the size of the text
 const textSizeaddrar  = 14;
 
 // Calculate the width of the text in the desired font and size
 const textWidthaddrar = Fontseee.widthOfTextAtSize(textaddrar, textSizeaddrar );
 
 // Calculate the x position so the text starts at 500 and flows to the left
 const xPositionaddrar  = 536 - textWidthaddrar ;
 
 page.drawText(textaddrar , {
   x: xPositionaddrar ,
   y: 542,
   size: textSizeaddrar,
   font: Fontseee,
   color: rgb(0, 0, 0),
 });


       // Function to draw text with specific spacing between characters
const drawTextWithSpacing = (page, text, startX, startY, font, size, color, spacing) => {
  let xPosition = startX;
  
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    const charWidth = font.widthOfTextAtSize(char, size);
    
    page.drawText(char, {
      x: xPosition,
      y: startY,
      size: size,
      font: font,
      color: color,
    });
    
    xPosition += charWidth + spacing; // Move x position by character width plus spacing
  }
};

// Define the text and parameters
const texttel = "0699603499";
const startXtel = 450;
const startYtel = 510;
const textSizetel = 14;
const spacingPxtel = 2.3; // Define the space between characters in pixels

// Draw the text with spacing
drawTextWithSpacing(page, texttel, startXtel, startYtel, Fontseee, textSizetel, rgb(0, 0, 0), spacingPxtel);


      
// Draw text on the PDF using the custom font
page.drawText("X", {
  x: 226,
  y: 508,
  size: 14,
  font: Fontseee,
  color: rgb(0, 0, 0),
});


      // Save the modified PDF
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const urlObject = URL.createObjectURL(blob);

      // Open the modified PDF in a new tab
      window.open(urlObject, '_blank');
    } catch (error) {
      console.error('Error modifying PDF:', error);
    }
  };

  useEffect(() => {
    modifyPdf();
  }, []);

  return null; // No need to render anything
};

export default EditPdf;
