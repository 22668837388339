import React, { useState, useEffect, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import appico from "../src/img/logo.png";
import logoMfep from "../src/img/logo.png";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import IconButton from "@mui/material/IconButton";
import { pink } from '@mui/material/colors';
import Radio from '@mui/material/Radio';

import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";

import ReplayIcon from "@mui/icons-material/Replay";
import FormControl from "@mui/material/FormControl";
import isEmail from "validator/lib/isEmail";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Button from "@mui/material/Button";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Cookies from 'js-cookie';
import Cookies_universal from 'universal-cookie';

import axios from "axios";
import { isMobile, browserName } from "react-device-detect";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "react-bootstrap/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CloseIcon from "@mui/icons-material/Close";
import "../containers/amirifont.css";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import InfoIcon from "@mui/icons-material/Info";
import Footer from "./Footer";
import Header from "./Header";
import { API_BASE_URL } from "./config"; // Adjust the path if necessary
import ReCAPTCHA from "react-google-recaptcha";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Switch, { SwitchProps } from '@mui/material/Switch';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Abdelaziz sebrou
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultColor = "#4caf50"; // Default green color
const hoverColor = "#004d40"; // Lighter green when hovered
const focusColor = "#81c784"; // Even lighter green when focused

const themetextfieldnew = createTheme({
  direction: 'rtl',
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: hoverColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: focusColor,
          },
        },
        notchedOutline: {
          borderColor: defaultColor,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: focusColor,
          },
          "&:hover": {
            color: hoverColor,
          },
          color: defaultColor, // Default label color
        },
      },
    },
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  },
});


const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});



const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({

  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const BootstrapTooltip = styled((props) => (
  <Tooltip {...props} arrow classes={{ popper: props.className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    ///color: theme.palette.common.black,
    color: "orange",
    top: "10px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    ///backgroundColor: theme.palette.common.black,
    backgroundColor: "orange",
    fontSize: "14px",
    fontWeight: "bold",
    top: "10px",
  },
}));

export default function LoginEtab() {
  const [selectedValue_radio, setSelectedValue_radio] = React.useState(1);

  const handleChange_radio = (event) => {
    const value = parseInt(event.target.value, 10); // تحويل القيمة إلى رقم

    setSelectedValue_radio(value);
  };

  const controlProps_radio = (item) => ({
    checked: selectedValue_radio === item,
    onChange: handleChange_radio,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': 'item' + item },
  });



  const [loading, setLoading] = useState(false);
  const [isetablis, setIsetablis] = useState(true);

  const handleSwitchChange = (event) => {
    setIsetablis(event.target.checked);
  };


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  // const history = useHistory();
  const navigate = useNavigate();

  const codeetub = useRef(null);
  const codeetub2 = useRef(null);
  const ismobref = useRef(null);

  const [valuecodeetub, setvaluecodeetub] = useState("");
  const [valuecodeetub2, setvaluecodeetub2] = useState("");
  const [isValidemail, setIsValidemail] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [emailverificated, setemailverificated] = React.useState(false);

  const [emailsended, setemailsended] = React.useState(false);
  const [errorsendingemail, seterrorsendingemail] = React.useState(false);
  const refpassword1 = useRef(null);
  const refpassword2 = useRef(null);
  const refpassword3 = useRef(null);

  const [FailedAuth, setFailedAuth] = useState(false);

  const [OpenDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClickCloseDialog = () => {
    if (ismobref.current) {
      ismobref.current.click();
    }
    setOpenDialog(false);
  };

  const [valuesPassword, setValuesPassword] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChangePassword = (prop) => (event) => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
  };
  const NumbersOnlyRegex = /^[0-9]+$/;

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword,
    });
  };

  const [valuesPassword2, setValuesPassword2] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChangePassword2 = (prop) => (event) => {
    setValuesPassword2({ ...valuesPassword2, [prop]: event.target.value });
  };



  const handleClickShowPassword2 = () => {
    setValuesPassword2({
      ...valuesPassword2,
      showPassword: !valuesPassword2.showPassword,
    });
  };


  const [valuesPassword3, setValuesPassword3] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChangePassword3 = (prop) => (event) => {
    setValuesPassword3({ ...valuesPassword3, [prop]: event.target.value });
  };



  const handleClickShowPassword3 = () => {
    setValuesPassword3({
      ...valuesPassword3,
      showPassword: !valuesPassword3.showPassword,
    });
  };




  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword3 = (event) => {
    event.preventDefault();
  };

  const onemailblur = (event) => {
    const val = event.target.value;

    if (isValidemail) {
      setDirty(true);
    }
  };

  const hostn = window.location.hostname;
  const full = window.location.protocol + "//" + window.location.host;

  const ismoblinkred =
    "intent:" + full + "/LoginDon#Intent;package=com.android.chrome;end";
  const [valuelink, setvaluelink] = useState(ismoblinkred);

  useEffect(() => {
    //alert('ismobile: '+browserName);
    if (
      isMobile &&
      (browserName !== "Chrome" ||
        browserName == "Facebook" ||
        browserName == "" ||
        browserName == undefined ||
        browserName == "undefined")
    ) {
      if (ismobref.current) {
        //ismobref.current.click();
        //handleClickOpenDialog();
      }
    }
  }, [valuecodeetub]);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    /////console.log('diry changed='+dirty)
    if (dirty && isValidemail) {
      ///ResendEmailClick();
    }
  }, [dirty]);

  useEffect(() => {
    /////console.log('isValidemail '+isValidemail+ 'emailverificated ' +emailverificated + 'emailsended '+emailsended )
    setemailsended(false);
  }, [isValidemail]);

  const handleChangeemail2 = (event) => {
    const val = event.target.value;

    if (isEmail(val)) {
      setIsValidemail(true);
      /////console.log(val+"   yes email");
    } else {
      setIsValidemail(false);
      /////console.log(val+"   not email");
    }

    setvaluecodeetub2(val);

    /////handleChangeemail(val, isValidemail);
  };

  const handleChangeemail = (event) => {
    const val = event.target.value;
    //alert(val);
    const inputValue = event.target.value;

    if ((NumbersOnlyRegex.test(inputValue) && inputValue.length <= 18) || inputValue === '') {
      setvaluecodeetub(inputValue);
    }

    if (isEmail(val)) {
      setIsValidemail(true);
      /////console.log(val+"   yes email");
    } else {
      setIsValidemail(false);
      /////console.log(val+"   not email");
    }

    // setvaluecodeetub(val);

    /////handleChangeemail(val, isValidemail);
  };

  const [token, setToken] = useState(null);

  const [recaptchaVerified, setRecaptchaVerified] = useState(true); // Initially assuming reCAPTCHA is verified
  const refReCAPTCHA = useRef(null);

  const handleRecaptchaChange = (value) => {
    // handle reCAPTCHA response
    if (value) {
      // reCAPTCHA verification succeeded
      setRecaptchaVerified(true);
      // Proceed with your logic here
    } else {
      // reCAPTCHA verification failed
      setRecaptchaVerified(true);
      // Handle the failure scenario
    }
  };

  /*
  const handleRecaptchaChange = (e) => {
    setToken(e.target.value); // e.target.value should hold the token
  };*/

  const Auth = async () => {
    try {
      setLoading(true);

      const data = {
        nomUser: valuecodeetub,
        password_login: valuesPassword.password,
        isetablis: isetablis,
        nomUseretab: valuecodeetub2,
        password_etab: valuesPassword2.password,
        Motpass_etab: valuesPassword3.password,
        selectedValue_radio: selectedValue_radio
      };

      let idetab_response; // Variable to store candidat.crypted_id

      //while (!idetab_response) {}
      const response = await axios.post(
        `${API_BASE_URL}/api/checkloginEtabEncadr`,
        data
      );

      if (response.status >= 200 && response.status < 300) {
        ///console.error('responseaziz:', response.data.etablissement);

        if (response.data && !response.data.error) {
          // Check if user data exists in the response and no error is present
          setFailedAuth(false);
          const etablissement = response.data.etablissement;
          const privilege_users_details =
            response.data.privilege_users_details;





          if (response.data.etablissement) {
            const token = response.data.token;
            // If 'etablissement' is present in the response
            const etablissementData = response.data.etablissement;
            //// console.error('etablissementData:', response.data.etablissement);

            // Save user data to sessionStorage
            sessionStorage.setItem("authData_73xJ#E", token);
            sessionStorage.setItem("auth_etab", 'auth_etab');


            idetab_response = etablissementData.IDetablissement;

            if (Number(idetab_response) > 0) {
              if (Number(etablissementData.IDNature_etsF) === 1) {
                navigate("/HomeMfep");
              }
              else if (Number(etablissementData.IDNature_etsF) === 5) {
                navigate("/HomeDfep");
              }
              else if (Number(etablissementData.IDNature_etsF) === 40) {
                navigate("/innovationList");
              }
              else if (
                Number(etablissementData.IDNature_etsF) !== 5 &&
                Number(etablissementData.IDNature_etsF) !== 1
              ) {
                navigate("/SettingEtab");
              }
            }

            // Your logic to handle 'etablissementData'
          }
          else if (response.data.encadrement) {
            const token2 = response.data.token;
            const encadrementdata = response.data.encadrement;
            sessionStorage.setItem("authData_73xJ#E", token2);

            navigate("/InfoEncadr");
            //window.open('/InfoEncadr', '_blank'); // Opens in a new tab

          }
          else if (response.data.Utilisateur) {
            const token2 = response.data.token;
            const Utilisateurdata = response.data.Utilisateur;
            sessionStorage.setItem("authData_73xJ#E", token2);

            navigate("/HomeUtilisateur");
            //window.open('/InfoEncadr', '_blank'); // Opens in a new tab

          }


          else {
            // If neither 'etablissement' nor 'privilege_users_details' is present in the response
            console.error(
              "Invalid response data. Neither etablissement nor privilege_users_details found."
            );
            // Handle this case as per your application's requirements
          }


        } else {
          // Handle invalid credentials or other errors
          setFailedAuth(true);
        }
      } else {
        console.error("HTTP error:", response.status);
      }

    } catch (error) {
      setFailedAuth(true);
      // Handle API call errors
      console.error("API call failed:", error);
      // Optionally setFailedAuth(true) or perform other error handling
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    /// ismobref.current.click();

    if (selectedValue_radio === 1) {
      if (!NumbersOnlyRegex.test(valuecodeetub) ||
        valuecodeetub.length !== 18) {
        codeetub.current.focus();
        return;
      }

      if (valuesPassword.password.length == 0) {
        refpassword1.current.focus();
        return;
      }
    }

    if (selectedValue_radio === 2) {
      if (valuecodeetub2.length == 0) {
        codeetub2.current.focus();
        return;
      }

      if (valuesPassword2.password.length == 0) {
        refpassword2.current.focus();
        return;
      }

      if (valuesPassword3.password.length == 0) {
        refpassword3.current.focus();
        return;
      }

    }

    if (selectedValue_radio === 3) {
      if (valuecodeetub2.length == 0) {
        codeetub2.current.focus();
        return;
      }

      if (valuesPassword3.password.length == 0) {
        refpassword3.current.focus();
        return;
      }



    }

    if (!recaptchaVerified) {
      /////return;
    }

    Auth(event);
  };

  const sitekey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
  //////////
  ///////////6LckCXIpAAAAAO2Y87-XcGXMTbpkBIiNSNyhGWwl

  return (
    <>
      <a
        style={{ display: "none" }}
        href={valuelink}
        ref={ismobref}
        target="_blank"
      ></a>

      <Header />
      <div id="root_img">
        <Container component="main" maxWidth="md">
          <Grid
            container
            spacing={0}
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            style={{ minHeight: "100vh" }}
          >
            <div className="container">
              <div className="row text-center">
                <Box
                  sx={{
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Typography
                      sx={{
                        color: "#eff0f4",
                        fontFamily: '"Tajawal", sans-serif',
                        fontWeight: 900,
                        fontSize: 20,
                        backgroundColor: "#D84315",
                        border: "4px solid #e0e4e875",
                        padding: "10px",
                        paddingLeft: "20px",
                        display: 'flex',
                        alignItems: 'center', // Aligns icon and text vertically
                      }}
                    >
                      <ApartmentIcon style={{ marginRight: "5px", marginLeft: "3px", marginBottom: "-6px" }} />

                      {
                        selectedValue_radio === 1
                          ? "بوابة المكون"
                          : selectedValue_radio === 2
                            ? "بوابة المؤسسة"
                            : "بوابة خاصة"
                      }

                    </Typography>

                    <div style={{ marginTop: '8px', direction: 'rtl' }}>
                      {/* 
                      <FormControlLabel
                        labelPlacement="start" // This places the label before the control
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={isetablis}
                            onChange={handleSwitchChange}
                          />
                        }

                      />
                      */}

                      <Radio {...controlProps_radio(1)} color="success" />
                      <Radio {...controlProps_radio(2)} color="success" />
                      <Radio {...controlProps_radio(3)} color="success" />


                    </div>


                  </div>

                  <Box component="form" noValidate sx={{ mt: 1 }}>

                    {selectedValue_radio === 1 || selectedValue_radio === 2 ? (
                      <>
                        {/* nin */}
                        <CacheProvider value={cacheRtl}>
                          <ThemeProvider theme={themetextfieldnew}>
                            <Box sx={{ mt: 2 }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth variant="outlined">
                                      <BootstrapTooltip
                                        title="رقم التعريف الوطني"
                                        followCursor
                                      >
                                        <TextField
                                          sx={{
                                            "& .MuiFormHelperText-root": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "14px",
                                              color: "#004d40",
                                              fontWeight: 700,
                                            },
                                            "& .MuiFormLabel-root": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "18px",
                                              color: "#004d40",
                                              dir: "rtl",
                                            },
                                            "& .MuiInputBase-input": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "22px",
                                              color: "#004d40",
                                              fontWeight: 700,
                                              //text-align: right,
                                            },
                                            "& label.Mui-focused": {
                                              fontSize: "16px",
                                              color: "#004d40",
                                              fontWeight: 700,
                                            },
                                            "& .MuiInput-underline:after": {
                                              borderBottomColor: "#004d40",
                                            },

                                            "& .MuiOutlinedInput-root": {
                                              "& fieldset": {
                                                fontFamily: '"Tajawal", sans-serif',
                                                fontSize: "16px",
                                                borderColor: "#004d40", // Default border color
                                                borderWidth: "2px",
                                              },
                                              "&:hover fieldset": {
                                                borderColor: "#00796b", // Color when hovering
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: "#004d00", // Color when focused
                                              },
                                            },

                                          }}
                                          inputRef={codeetub}
                                          label="رقم التعريف الوطني"
                                          placeholder="رقم التعريف الوطني"
                                          variant="outlined"
                                          size={"small"}
                                          // helperText={props.helperText}
                                          value={valuecodeetub}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}

                                          error={
                                            !NumbersOnlyRegex.test(valuecodeetub) || // Checks if value is not numeric
                                            (valuecodeetub.length !== 0 &&                  // Checks if the value is empty
                                              valuecodeetub.length !== 18)             // Checks if the length is not 18 characters
                                          }
                                          helperText={
                                            !NumbersOnlyRegex.test(valuecodeetub) ||
                                              (valuecodeetub.length !== 0 &&
                                                valuecodeetub.length !== 18)
                                              ? 'رقم التعريف الوطني' : ''
                                          }

                                          onChange={(e) => handleChangeemail(e)}
                                          name="email"
                                        />
                                      </BootstrapTooltip>
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </ThemeProvider>
                        </CacheProvider>


                        {/* Password 01 */}
                        <CacheProvider value={cacheRtl}>
                          <ThemeProvider theme={themetextfieldnew}>
                            <Box sx={{ mt: 2 }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel
                                        sx={{
                                          color: "#004d40",
                                          fontFamily: '"Tajawal", sans-serif',
                                          fontSize: 16,
                                          fontWeight: 700,
                                        }}
                                        htmlFor="outlined-adornment-password"
                                      >
                                        كلمة المرور
                                      </InputLabel>

                                      <OutlinedInput
                                        fullWidth
                                        dir="rtl"
                                        sx={{
                                          //direction:'ltr',

                                          "& .MuiFormHelperText-root": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "14px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                          },
                                          "& .MuiFormLabel-root": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "18px",
                                            color: "#004d40",
                                            dir: "rtl",
                                          },
                                          "& .MuiInputBase-input": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "22px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                            //text-align: right,
                                          },
                                          "& label.Mui-focused": {
                                            fontSize: "16px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                          },
                                          "& .MuiInput-underline:after": {
                                            borderBottomColor: "#004d40",
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "16px",
                                              borderColor: "#004d40",
                                              borderWidth: "2px",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: "#004d00",
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: "#004d00",
                                            },
                                          },
                                        }}
                                        onKeyPress={(ev) => {
                                          ///////console.log(`Pressed keyCode ${ev.key}`);
                                          if (ev.key === "Enter") {
                                            handleSubmit(ev);
                                            ev.target.blur(); ///to hide keyboard
                                            /////console.log("enter")
                                            // Do code here
                                            ///////console.log(JSON.stringify(ev.target.value));
                                            //this.onSearchClickbtn();
                                            ev.preventDefault();
                                          }
                                        }}
                                        inputRef={refpassword1}
                                        id="outlined-adornment-password"
                                        type={
                                          valuesPassword.showPassword
                                            ? "text"
                                            : "password"
                                        }
                                        value={valuesPassword.password}
                                        onChange={handleChangePassword("password")}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              onMouseDown={handleMouseDownPassword}
                                              edge="end"
                                            >
                                              {valuesPassword.showPassword ? (
                                                <VisibilityOff />
                                              ) : (
                                                <Visibility />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        label="كلمة المرور"
                                        name="password"
                                      />
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </ThemeProvider>
                        </CacheProvider>

                      </>
                    ) : null}

                    {selectedValue_radio === 2 ? (
                      <div>
                        {/* Content to display when `isetablis` is true */}

                        {/* nomuser etab */}
                        <CacheProvider value={cacheRtl}>
                          <ThemeProvider theme={themetextfieldnew}>
                            <Box sx={{ mt: 2 }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth variant="outlined">
                                      <BootstrapTooltip
                                        title="إسم المستخدم"
                                        followCursor
                                      >
                                        <TextField
                                          sx={{
                                            "& .MuiFormHelperText-root": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "14px",
                                              color: "#004d40",
                                              fontWeight: 700,
                                            },
                                            "& .MuiFormLabel-root": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "18px",
                                              color: "#004d40",
                                              dir: "rtl",
                                            },
                                            "& .MuiInputBase-input": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "22px",
                                              color: "#004d40",
                                              fontWeight: 700,
                                              //text-align: right,
                                            },
                                            "& label.Mui-focused": {
                                              fontSize: "16px",
                                              color: "#004d40",
                                              fontWeight: 700,
                                            },
                                            "& .MuiInput-underline:after": {
                                              borderBottomColor: "#004d40",
                                            },

                                            "& .MuiOutlinedInput-root": {
                                              "& fieldset": {
                                                fontFamily: '"Tajawal", sans-serif',
                                                fontSize: "16px",
                                                borderColor: "#004d40", // Default border color
                                                borderWidth: "2px",
                                              },
                                              "&:hover fieldset": {
                                                borderColor: "#00796b", // Color when hovering
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: "#004d00", // Color when focused
                                              },
                                            },

                                          }}
                                          inputRef={codeetub2}
                                          label="إسم المستخدم"
                                          placeholder="إسم المستخدم"


                                          variant="outlined"
                                          size={"small"}
                                          // helperText={props.helperText}
                                          value={valuecodeetub2}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          onChange={(e) => handleChangeemail2(e)}
                                          name="email2"
                                        />
                                      </BootstrapTooltip>
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </ThemeProvider>
                        </CacheProvider>

                        {/* Password etab */}
                        <CacheProvider value={cacheRtl}>
                          <ThemeProvider theme={themetextfieldnew}>
                            <Box sx={{ mt: 2 }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel
                                        sx={{
                                          color: "#004d40",
                                          fontFamily: '"Tajawal", sans-serif',
                                          fontSize: 16,
                                          fontWeight: 700,
                                        }}
                                        htmlFor="outlined-adornment-password"
                                      >
                                        كلمة مرور المؤسسة
                                      </InputLabel>

                                      <OutlinedInput
                                        fullWidth
                                        dir="rtl"
                                        sx={{
                                          //direction:'ltr',

                                          "& .MuiFormHelperText-root": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "14px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                          },
                                          "& .MuiFormLabel-root": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "18px",
                                            color: "#004d40",
                                            dir: "rtl",
                                          },
                                          "& .MuiInputBase-input": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "22px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                            //text-align: right,
                                          },
                                          "& label.Mui-focused": {
                                            fontSize: "16px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                          },
                                          "& .MuiInput-underline:after": {
                                            borderBottomColor: "#004d40",
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "16px",
                                              borderColor: "#004d40",
                                              borderWidth: "2px",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: "#004d00",
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: "#004d00",
                                            },
                                          },
                                        }}

                                        inputRef={refpassword2}
                                        id="outlined-adornment-password2"
                                        type={
                                          valuesPassword2.showPassword
                                            ? "text"
                                            : "password"
                                        }
                                        value={valuesPassword2.password}
                                        onChange={handleChangePassword2("password")}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword2}
                                              onMouseDown={handleMouseDownPassword2}
                                              edge="end"
                                            >
                                              {valuesPassword2.showPassword ? (
                                                <VisibilityOff />
                                              ) : (
                                                <Visibility />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        label="كلمة مرور المؤسسة"
                                        name="password2"
                                      />
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </ThemeProvider>
                        </CacheProvider>

                        {/* Motpass utilisateur */}
                        <CacheProvider value={cacheRtl}>
                          <ThemeProvider theme={themetextfieldnew}>
                            <Box sx={{ mt: 2 }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel
                                        sx={{
                                          color: "#004d40",
                                          fontFamily: '"Tajawal", sans-serif',
                                          fontSize: 16,
                                          fontWeight: 700,
                                        }}
                                        htmlFor="outlined-adornment-password"
                                      >
                                        الرمز السري
                                      </InputLabel>

                                      <OutlinedInput
                                        fullWidth
                                        dir="rtl"
                                        sx={{
                                          //direction:'ltr',

                                          "& .MuiFormHelperText-root": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "14px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                          },
                                          "& .MuiFormLabel-root": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "18px",
                                            color: "#004d40",
                                            dir: "rtl",
                                          },
                                          "& .MuiInputBase-input": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "22px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                            //text-align: right,
                                          },
                                          "& label.Mui-focused": {
                                            fontSize: "16px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                          },
                                          "& .MuiInput-underline:after": {
                                            borderBottomColor: "#004d40",
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "16px",
                                              borderColor: "#004d40",
                                              borderWidth: "2px",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: "#004d00",
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: "#004d00",
                                            },
                                          },
                                        }}

                                        inputRef={refpassword3}
                                        id="outlined-adornment-password"
                                        type={
                                          valuesPassword3.showPassword
                                            ? "text"
                                            : "password"
                                        }
                                        value={valuesPassword3.password}
                                        onChange={handleChangePassword3("password")}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword3}
                                              onMouseDown={handleMouseDownPassword3}
                                              edge="end"
                                            >
                                              {valuesPassword3.showPassword ? (
                                                <VisibilityOff />
                                              ) : (
                                                <Visibility />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        label="الرمز السري"
                                        name="password3"
                                      />
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </ThemeProvider>
                        </CacheProvider>


                      </div>
                    ) : (
                      <div>
                        {/* Content to display when `isetablis` is false */}

                      </div>
                    )}

                    {selectedValue_radio === 3 ? (
                      <div>
                        {/* Content to display when `isetablis` is true */}

                        {/* nomuser etab */}
                        <CacheProvider value={cacheRtl}>
                          <ThemeProvider theme={themetextfieldnew}>
                            <Box sx={{ mt: 2 }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth variant="outlined">
                                      <BootstrapTooltip
                                        title="إسم المستخدم"
                                        followCursor
                                      >
                                        <TextField
                                          sx={{
                                            "& .MuiFormHelperText-root": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "14px",
                                              color: "#004d40",
                                              fontWeight: 700,
                                            },
                                            "& .MuiFormLabel-root": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "18px",
                                              color: "#004d40",
                                              dir: "rtl",
                                            },
                                            "& .MuiInputBase-input": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "22px",
                                              color: "#004d40",
                                              fontWeight: 700,
                                              //text-align: right,
                                            },
                                            "& label.Mui-focused": {
                                              fontSize: "16px",
                                              color: "#004d40",
                                              fontWeight: 700,
                                            },
                                            "& .MuiInput-underline:after": {
                                              borderBottomColor: "#004d40",
                                            },

                                            "& .MuiOutlinedInput-root": {
                                              "& fieldset": {
                                                fontFamily: '"Tajawal", sans-serif',
                                                fontSize: "16px",
                                                borderColor: "#004d40", // Default border color
                                                borderWidth: "2px",
                                              },
                                              "&:hover fieldset": {
                                                borderColor: "#00796b", // Color when hovering
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: "#004d00", // Color when focused
                                              },
                                            },

                                          }}
                                          inputRef={codeetub2}
                                          label="إسم المستخدم"
                                          placeholder="إسم المستخدم"


                                          variant="outlined"
                                          size={"small"}
                                          // helperText={props.helperText}
                                          value={valuecodeetub2}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          onChange={(e) => handleChangeemail2(e)}
                                          name="email2"
                                        />
                                      </BootstrapTooltip>
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </ThemeProvider>
                        </CacheProvider>


                        {/* Motpass utilisateur */}
                        <CacheProvider value={cacheRtl}>
                          <ThemeProvider theme={themetextfieldnew}>
                            <Box sx={{ mt: 2 }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel
                                        sx={{
                                          color: "#004d40",
                                          fontFamily: '"Tajawal", sans-serif',
                                          fontSize: 16,
                                          fontWeight: 700,
                                        }}
                                        htmlFor="outlined-adornment-password"
                                      >
                                        الرمز السري
                                      </InputLabel>

                                      <OutlinedInput
                                        fullWidth
                                        dir="rtl"
                                        sx={{
                                          //direction:'ltr',

                                          "& .MuiFormHelperText-root": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "14px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                          },
                                          "& .MuiFormLabel-root": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "18px",
                                            color: "#004d40",
                                            dir: "rtl",
                                          },
                                          "& .MuiInputBase-input": {
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: "22px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                            //text-align: right,
                                          },
                                          "& label.Mui-focused": {
                                            fontSize: "16px",
                                            color: "#004d40",
                                            fontWeight: 700,
                                          },
                                          "& .MuiInput-underline:after": {
                                            borderBottomColor: "#004d40",
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                              fontFamily: '"Tajawal", sans-serif',
                                              fontSize: "16px",
                                              borderColor: "#004d40",
                                              borderWidth: "2px",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: "#004d00",
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: "#004d00",
                                            },
                                          },
                                        }}

                                        inputRef={refpassword3}
                                        id="outlined-adornment-password"
                                        type={
                                          valuesPassword3.showPassword
                                            ? "text"
                                            : "password"
                                        }
                                        value={valuesPassword3.password}
                                        onChange={handleChangePassword3("password")}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword3}
                                              onMouseDown={handleMouseDownPassword3}
                                              edge="end"
                                            >
                                              {valuesPassword3.showPassword ? (
                                                <VisibilityOff />
                                              ) : (
                                                <Visibility />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        label="الرمز السري"
                                        name="password3"
                                      />
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </ThemeProvider>
                        </CacheProvider>


                      </div>
                    ) : (
                      <div>
                        {/* Content to display when `isetablis` is false */}

                      </div>
                    )}






                    {/* 
<ReCAPTCHA style={{marginTop:'10px'}}///6LckCXIpAAAAAO2Y87-XcGXMTbpkBIiNSNyhGWwl
        sitekey="6LckCXIpAAAAAO2Y87-XcGXMTbpkBIiNSNyhGWwl"////////6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
        onChange={handleRecaptchaChange}
        ref={refReCAPTCHA}
      />
*/}

                    <div>
                      <ReCAPTCHA
                        style={{ marginTop: "10px" }}
                        ref={refReCAPTCHA}
                        sitekey="6LcCt3YpAAAAAK33Jnte_3V4gKcLl-kQgeV3qWMP"
                        onChange={handleRecaptchaChange}
                        hl="ar"
                      // Additional optional props (e.g., action, verifyApiArgs)
                      />
                      {/* Submit button or other actions requiring the token */}
                    </div>

                    {/* Submit Button */}
                    {/* Submit Button */}
                    <Grid item xs={12}>
                      <CacheProvider value={cacheRtl}>
                        <ThemeProvider theme={themetextfieldnew}>
                          <Box
                            sx={{
                              minWidth: 120,
                              mt: "15px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button

                              sx={{
                                backgroundColor: '#00695c', // Green color
                                '&:hover': {
                                  backgroundColor: '#004d40', // Darker green on hover
                                }
                              }}
                              onClick={handleSubmit}
                              variant="contained"
                              endIcon={
                                <LockOpenIcon
                                  sx={{ mr: "5px", transform: "rotate(0deg)" }}
                                />
                              }
                            >
                              <Typography
                                sx={{
                                  color: "#FFFFFF",
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 22,
                                  fontWeight: 900,
                                }}
                              >
                                الدخول
                              </Typography>
                            </Button>
                          </Box>
                        </ThemeProvider>
                      </CacheProvider>
                    </Grid>

                    <CacheProvider value={cacheRtl}>
                      <ThemeProvider theme={themetextfieldnew}>
                        <Grid
                          spacing={0}
                          style={{ display: "none" }}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          dir="rtl"
                          container
                          sx={{ mt: 2 }}
                        >
                          <Grid item>
                            <Link
                              href="/ResetPwDon"
                              variant="body2"
                              sx={{
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 12,
                                fontWeight: 900,
                              }}
                            >
                              هل نسيت كلمة السر؟
                            </Link>
                          </Grid>
                          <Grid item sx={{ mt: 1 }}>
                            <Link
                              href="/RegisterDon"
                              variant="body2"
                              sx={{
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 12,
                                fontWeight: 900,
                              }}
                            >
                              {"هل تريد إنشاء حساب جديد؟"}
                            </Link>
                          </Grid>
                        </Grid>
                      </ThemeProvider>
                    </CacheProvider>
                  </Box>
                </Box>

                {!recaptchaVerified && !recaptchaVerified == "" ? (
                  <>
                    <Alert
                      severity="error"
                      sx={{
                        mt: 2,
                        display: "flex",
                        fontFamily: "Amiri",
                        fontSize: "20px",
                        "& .MuiAlert-icon": { marginLeft: "10px" },
                        "& .MuiAlert-action": { marginRight: "8px" },
                      }} // Adjust padding here
                    >
                      لم يتم التحقق من reCAPTCHA بنجاح. يرجى المحاولة مرة أخرى.
                    </Alert>
                  </>
                ) : (
                  <></>
                )}

                {FailedAuth ? (
                  <>
                    <Alert
                      severity="error"
                      sx={{
                        mt: 2,
                        display: "flex",
                        fontFamily: "Amiri",
                        fontSize: "20px",
                        "& .MuiAlert-icon": { marginLeft: "10px" },
                        "& .MuiAlert-action": { marginRight: "8px" },
                      }} // Adjust padding here
                    >
                      يرجى التحقق من صحة المعلومات التي أدخلتها.
                    </Alert>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Grid>
        </Container>
      </div>
      <Footer />

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#004d40",
            boxShadow: "none",
          },
        }}
        //fullScreen={mediumScreen}
        open={OpenDialog}
        onClose={handleClickCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <Card style={{}} sx={{ maxWidth: 345 }}>
          {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
           */}
          <CardHeader
            avatar={
              <InfoIcon
                sx={{ color: "#2196F3", width: 50, height: 50, ml: 2 }}
              ></InfoIcon>
            }
            action={
              <IconButton
                onClick={handleClickCloseDialog}
                aria-label="settings"
                sx={{ ml: -1, mt: -1 }}
              >
                <CloseIcon />
              </IconButton>
            }
            title={
              <>
                <Box
                  sx={{
                    ml: 2,
                    mt: 0,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#004d40",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 14,
                      fontWeight: 900,
                    }}
                  >
                    من الأفضل إستعمال متصفح كروم Chrome
                  </Typography>
                </Box>
              </>
            }
            subheader=""
          />
        </Card>
      </Dialog>
    </>
  );
}
