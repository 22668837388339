import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CacheProvider } from "@emotion/react";
import TextField from '@mui/material/TextField';
import createCache from "@emotion/cache";
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';

import getCsrfToken from '../CsrfToken';
import FormControl from '@mui/material/FormControl';
import isEmail from 'validator/lib/isEmail';
import ReplayIcon from '@mui/icons-material/Replay';
import Input from '@mui/material/Input';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import Footer from './Footer';
import Header from './Header';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';

import Select from '@mui/material/Select';
import CardContent from '@mui/material/CardContent';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';


const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

/*
const IOSSwitch = styled((props: SwitchProps) => (
 <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props}
  // Change the font size here
 />
))(({ theme }) => ({*/

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({

  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));



// Set the CSRF token globally
const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

var md5 = require('md5');
let codeemail = (Math.floor(1000 + Math.random() * 9000));

let sendemailbyemailoneortwo = 'https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';


const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
});



function SettingPriv() {
  const [loading, setLoading] = useState(true);


  const [alertMessageEdit, setAlertMessageEdit] = useState(null);


  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(null);

  const [OpenDialog, setOpenDialog] = useState(false);

  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const [errorget, setErrorget] = useState();
  const navigate = useNavigate();

  const refDateNais = useRef(null);


  const [Civ, setCiv] = React.useState(0);

  const handleChange_Civ = (event) => {
    setCiv(event.target.value);
  };


  const IDetablissement = sessionStorage.getItem("IDetablissement");

  let numrun = 0;

  useEffect(() => {

    if (sessionStorage.getItem("IDetablissement") == null
      || sessionStorage.getItem("IDetablissement") == undefined
      || sessionStorage.getItem("IDetablissement") == ""
      || sessionStorage.getItem("privilege_users_NomAr") == "admfin_all"
      || sessionStorage.getItem("privilege_users_NomAr") == ""
      || !sessionStorage.getItem("privilege_users_NomAr")) {
      navigate('/');
    }


    if (IDetablissement == null || IDetablissement == undefined || !IDetablissement) {
      // Navigate to the "Page Not Found" or error page
      navigate('/');
      return;
    }


    const fetchData = async () => {


      try {
        if (!IDetablissement || Number(numrun) >= 1) {
          navigate('/');
          return;
        }

        const response = await fetch(`${API_BASE_URL}/api/getetab_byid/${IDetablissement}`
          , {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
            }
          });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();

        ///alert(JSON.stringify(data));
        setValuesFromData_getinfo(data.data);
      } catch (error) {
        console.error(error);
        // Handle error, e.g., show an error message to the user
      } finally {
        setLoading(false);
        numrun++;
      }


    };

    if (IDetablissement) {
      fetchData();
    } else {
      navigate('/');
      return;
    }
  }, [IDetablissement, navigate]);



  const [Nom_etab, setNom_etab] = useState();
  const [libelleArabe_etab, setlibelleArabe_etab] = useState();
  const [Singup_active_etab, setSingup_active_etab] = useState();
  const [download_cert_scol_active_etab, setdownload_cert_scol_active_etab] = useState();
  const [EditingInfo_active_etab, setEditingInfo_active_etab] = useState();
  const [Eval_notes_active, setEval_notes_active] = useState();


  const [Eval_notes_active_enable, setEval_notes_active_enable] = useState(false);
  const [EditingInfo_active_etab_enable, setEditingInfo_active_etab_enable] = useState(false);
  const [download_cert_scol_active_etab_enable, setdownload_cert_scol_active_etab_enable] = useState(false);


  const [Eval_notes_active_disabled, setEval_notes_active_disabled] = useState(false);
  const [EditingInfo_active_etab_disabled, setEditingInfo_active_etab_disabled] = useState(false);
  const [download_cert_scol_active_etab_disabled, setdownload_cert_scol_active_etab_disabled] = useState(false);



  function setValuesFromData_getinfo(data) {
    // Check if data object is defined
    if (!data) {
      console.error('Data is undefined');
      return;
    }

    setNom_etab(data.Nom);
    setlibelleArabe_etab(data.libelleArabe);
    setSingup_active_etab(data.Singup_active);
    setdownload_cert_scol_active_etab(data.download_cert_scol_active);
    setEditingInfo_active_etab(data.EditingInfo_active);
    setEval_notes_active(data.Eval_notes_active);

    if (data.Eval_notes_active == '1' || Number(data.Eval_notes_active) == 1 || Number(data.Eval_notes_active) === 1) {
      setEval_notes_active_enable(true);
    } else {
      setEval_notes_active_enable(false);
    }

    if (data.Eval_notes_active == '2' || Number(data.Eval_notes_active) == 2 || Number(data.Eval_notes_active) === 2) {
      setEval_notes_active_disabled(true);
    } else {
      setEval_notes_active_disabled(false);
    }


    if (data.EditingInfo_active == '1' || Number(data.EditingInfo_active) == 1 || Number(data.EditingInfo_active) === 1) {
      setEditingInfo_active_etab_enable(true);
    }
    else {
      setEditingInfo_active_etab_enable(false);
    }

    if (data.EditingInfo_active == '2' || Number(data.EditingInfo_active) == 2 || Number(data.EditingInfo_active) === 2) {
      setEditingInfo_active_etab_disabled(true);
    } else {
      setEditingInfo_active_etab_disabled(false);
    }

    if (data.download_cert_scol_active == '1' || Number(data.download_cert_scol_active) == 1 || Number(data.download_cert_scol_active) === 1) {
      setdownload_cert_scol_active_etab_enable(true);
    } else {
      setdownload_cert_scol_active_etab_enable(false);
    }

    if (data.download_cert_scol_active == '2' || Number(data.download_cert_scol_active) == 2 || Number(data.download_cert_scol_active) === 2) {
      setdownload_cert_scol_active_etab_disabled(true);
    } else {
      setdownload_cert_scol_active_etab_disabled(false);
    }


  }





  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };


  const handleClickCloseDialog = () => {
    setOpenDialog(false);
    //// navigate('/Doc');
  };


  const [emailverificated, setemailverificated] = useState(false);

  const [emailsended, setemailsended] = useState(false);
  const [errorsendingemail, seterrorsendingemail] = useState(false);
  const [codeemailsent, setcodeemailsent] = useState(false);
  const [codeemailforchecking, setcodeemailforchecking] = useState(md5(codeemail));

  const [valueNomMere, setvalueNomMere] = useState('');
  const [valueNomMereFr, setvalueNomMereFr] = useState('');
  const [valuePrenomMereFr, setvaluePrenomMereFr] = useState('');
  const [valuePrenomMere, setvaluePrenomMere] = useState('');


  const [valueNom, setvalueNom] = useState('');
  const [valueLieuNaisFr, setvalueLieuNaisFr] = useState('');
  const [valueLieuNais, setvalueLieuNais] = useState('');

  const [valueAdres, setvalueAdres] = useState('');

  const [valuePrenomPere, setvaluePrenomPere] = useState('');
  const [valuePrenomPereFr, setvaluePrenomPereFr] = useState('');


  const [valueAdresFr, setvalueAdresFr] = useState('');


  const refLieuNais = useRef(null);
  const refLieuNaisFr = useRef(null);

  const refNomMere = useRef(null);
  const refNomMereFr = useRef(null);
  const refPrenomMere = useRef(null);
  const refPrenomMereFr = useRef(null);



  const handleInput_LieuNais = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueLieuNais(inputValue);
    }
  };

  const handleInput_LieuNaisFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueLieuNaisFr(inputValue);
    }
  };
  const refNom = useRef(null);
  const refvalueDateNais = useRef(null);
  const [valuePrenom, setvaluePrenom] = useState('');
  const refPrenom = useRef(null);
  const refPrenomPere = useRef(null);
  const refPrenomPereFr = useRef(null);


  const [valuenbcodeverifie, setvaluenbcodeverifie] = useState('');
  const refnbcodeverifie = useRef(null);

  const ArabicCharactersRegex = /^[\u0600-\u06FF\s]+$/;
  const ArabicCharactersRegex_withdash = /^[\u0600-\u06FF\s-_]+$/;
  const ArabicCharactersRegex_address = /^[\u0600-\u06FF\s-_\/0-9]+$/;

  const NumbersOnlyRegex = /^[0-9]+$/;


  const handleInput_Adres = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex_address.test(inputValue) || inputValue === '') {
      setvalueAdres(inputValue);
    }
  };

  const handleInput_PrenomMere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomMere(inputValue);
    }
  };

  const handleInput_NomMere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueNomMere(inputValue);
    }
  };

  const handleInput_Nom = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueNom(inputValue);
    }
  };



  const [valueDateNais, setvalueDateNais] = useState('');

  // Regular expression to match the format "YYYY/MM/DD"
  const dateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

  // Regular expression to match the format "YYYY/MM/DD" with digits for years, months, and days
  const fullDateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

  const handleInput_DateNais = (event) => {
    const inputValue = event.target.value;
    setvalueDateNais(inputValue);
  };



  const handleInput_PrenomPere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomPere(inputValue);
    }
  };

  const handleInput_Prenom = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenom(inputValue);
    }
  };

  const handleInput_nbcodeverifie = (event) => {
    const inputValue = event.target.value;

    if (NumbersOnlyRegex.test(inputValue) || inputValue === '') {
      setvaluenbcodeverifie(inputValue);
    }
  };

  const [valueNomFr, setvalueNomFr] = useState('');
  const refNomFr = useRef(null);
  const [error, setError] = useState('');

  const [valuePrenomFr, setvaluePrenomFr] = useState('');
  const refPrenomFr = useRef(null);

  const LatinLettersRegex = /^[a-zA-Z\s]+$/;
  const LatinLettersRegex_withdash = /^[a-zA-Z\s-_]+$/;
  const LatinLettersRegex_address = /^[a-zA-Z\s\-_\/0-9]+$/;

  const handleInput_AdresFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex_address.test(inputValue) || inputValue === '') {
      setvalueAdresFr(inputValue);
    }
  };



  const handleInput_PrenomMereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomMereFr(inputValue);
    }
  };

  const handleInput_NomMereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueNomMereFr(inputValue);
    }
  };

  const handleInput_NomFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueNomFr(inputValue);
    }
  };

  const handleInput_PrenomPereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomPereFr(inputValue);
    }
  };

  const handleInput_PrenomFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomFr(inputValue);
    }
  };

  const [singtoggleclicked, setsingtoggleclicked] = useState(false);
  const [edittoggleclicked, setedittoggleclicked] = useState(false);
  const [downtoggleclicked, setdowntoggleclicked] = useState(false);
  const [notestoggleclicked, setnotestoggleclicked] = useState(false);


  useEffect(() => {
    if ((IDetablissement && singtoggleclicked)) {
      ontoggleupdatedata();
      setsingtoggleclicked(false);
    }

    if ((IDetablissement && downtoggleclicked)) {
      ontoggleupdatedata();
      setdowntoggleclicked(false);
    }

    if ((IDetablissement && edittoggleclicked)) {
      ontoggleupdatedata();
      setedittoggleclicked(false);
    }

    if ((IDetablissement && notestoggleclicked)) {
      ontoggleupdatedata();
      setnotestoggleclicked(false);
    }

  }, [download_cert_scol_active_etab_enable, EditingInfo_active_etab_enable, Eval_notes_active_enable]);

  const ontoggleclicked_singup = (event) => {
    setsingtoggleclicked(1);

    if (Number(Singup_active_etab) == 1) {
      setSingup_active_etab(0);
    } else {
      setSingup_active_etab(1);
    }
  };

  const ontoggleclicked_download = (event) => {
    setdowntoggleclicked(true);
    if (download_cert_scol_active_etab_enable) {
      setdownload_cert_scol_active_etab_enable(false);
    } else {
      setdownload_cert_scol_active_etab_enable(true);
    }
  };



  const ontoggleclicked_notes = (event) => {
    setnotestoggleclicked(true);
    if (Eval_notes_active_enable) {
      setEval_notes_active_enable(false);
    } else {
      setEval_notes_active_enable(true);
    }
  };

  const ontoggleclicked_edit = (event) => {
    setedittoggleclicked(true);
    if (EditingInfo_active_etab_enable) {
      setEditingInfo_active_etab_enable(false);
    } else {
      setEditingInfo_active_etab_enable(true);
    }
  };

  const ontoggleupdatedata = async (e) => {


    try {
      const data = {
        Singup_active: Singup_active_etab ? 1 : 0,
        download_cert_scol_active: download_cert_scol_active_etab_enable ? 1 : 0,
        EditingInfo_active: EditingInfo_active_etab_enable ? 1 : 0,
        Eval_notes_active: Eval_notes_active_enable ? 1 : 0,
        IDetablissement: IDetablissement
      };
      // Convert the data object to a JSON string
      const jsonData = JSON.stringify(data);

      // Alert the JSON string
      ///alert(jsonData);



      const responseFromApi = await axios.post(`${API_BASE_URL}/api/updateSettingEtab`, data,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });

      if (responseFromApi.status === 200) {
        // Response is OK, show the updated data
        //console.log("Updated data:", responseFromApi.data.updatedData);
        //console.log("responseFromApi Eval_notes_active:", responseFromApi.data.updatedData.Eval_notes_active);


        setSnackbar({ children: 'تم الحفظ بنجاح!', severity: 'success' });

        // Do something with the updated data, such as updating the UI
      } else {
        // Log error if response status is not OK
        console.error("Error:", responseFromApi.data.error);
      }
    } catch (error) {
      // Log any other errors, such as network errors
      console.error("Error:", error.message);
    }
  };



  const refbtnsubmit = useRef(null);
  const refpassword1 = useRef(null);
  const refpassword2 = useRef(null);
  const refpasswordnow = useRef(null);
  const refAdres = useRef(null);
  const refAdresFr = useRef(null);



  const [valuesPassword, setValuesPassword] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [valuesPasswordRe, setValuesPasswordRe] = React.useState({
    amount: '',
    PasswordRe: '',
    weight: '',
    weightRange: '',
    showPasswordRe: false,
  });

  const [valuespasswordnow, setValuespasswordnow] = React.useState({
    amount: '',
    passwordnow: '',
    weight: '',
    weightRange: '',
    showpasswordnow: false,
  });


  const handleChangePasswordRe = (prop) => (event) => {
    setValuesPasswordRe({ ...valuesPasswordRe, [prop]: event.target.value });
  };

  const handleChangepasswordnow = (prop) => (event) => {
    setValuespasswordnow({ ...valuespasswordnow, [prop]: event.target.value });
  };

  const handleClickShowPasswordRe = () => {
    setValuesPasswordRe({
      ...valuesPasswordRe,
      showPasswordRe: !valuesPasswordRe.showPasswordRe,
    });
  };

  const handleClickShowpasswordnow = () => {
    setValuespasswordnow({
      ...valuespasswordnow,
      showpasswordnow: !valuespasswordnow.showpasswordnow,
    });
  };

  const handleMouseDownPasswordRe = (event) => {
    event.preventDefault();
  };

  const handleMouseDownpasswordnow = (event) => {
    event.preventDefault();
  };


  const handleChangePassword = (prop) => (event) => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  // Store token in state
  const [csrfToken, setCsrfToken] = useState(null);

  // Fetch on app load
  useEffect(() => {
    const getCsrfToken = async () => {
      const response = await axios.get(`${API_BASE_URL}/api/csrf-token`);
      setCsrfToken(response.data.csrf_token);
    };

    getCsrfToken();
  }, []);










  return (
    <>

      <Header />




      <Box sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {loading && <CircularProgress />}
      </Box>

      <Container sx={{ textAlign: 'center', mx: 'auto', width: '80%' }}>



        <Card

        >
          <CardContent>
            <Divider sx={{ mt: '0px' }}>
              <Grid item  >
                <Typography
                  style={{
                    color: "#004d40",
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 18,
                    fontWeight: 700,
                    marginBottom: '25px'
                  }}
                  variant="h3" gutterBottom>
                  إعدادات <SettingsIcon sx={{ ml: '0px' }} />
                </Typography>
              </Grid>

            </Divider>


            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{
                    display: 'flex',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontFamily: '"Tajawal", sans-serif',
                    textAlign: 'right', // Align text to right by default
                  }}
                >



                  <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                    ولاية: &nbsp;
                  </span> {sessionStorage.getItem("wilayas_libelleArabe")} &nbsp;
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    display: 'flex',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    fontFamily: '"Tajawal", sans-serif',
                    direction: 'rtl', // Set direction to right-to-left
                    textAlign: 'right', // Align text to right
                  }}
                >
                  <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                    المؤسسة:
                  </span>&nbsp;
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    display: 'flex',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    fontFamily: '"Tajawal", sans-serif',
                    direction: 'rtl', // Set direction to right-to-left
                    textAlign: 'right', // Align text to right
                  }}
                >
                  <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                    الصلاحيات الممنوحة:
                  </span>&nbsp; {sessionStorage.getItem("privilege_users_NomAr")}
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    display: 'flex',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    fontFamily: '"Tajawal", sans-serif',
                    direction: 'rtl', // Set direction to right-to-left
                    textAlign: 'right', // Align text to right
                  }}
                >
                  <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                    السيد(ة):
                  </span>&nbsp; {sessionStorage.getItem("NomEtPrenom")}
                </Typography>

              </Grid>
            </Grid>


          </CardContent>



        </Card>

        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
            sx={{ marginTop: '50px' }}
          >
            <Alert
              {...snackbar}
              onClose={handleCloseSnackbar}
              sx={{
                display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
                , '& .MuiAlert-action': { marginRight: '8px' }
              }} // Adjust padding here
            />
          </Snackbar>

        )}


      </Container>

    </>
  );
}

export default SettingPriv;
