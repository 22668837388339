import React, { useState, useEffect, useRef } from 'react';

 import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,

  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import {  Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import './MyToolbarStyles.css'; // Adjust the path as necessary
import HistoryIcon from '@mui/icons-material/History';

import { Grid } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Footer from './Footer';
import Header from './Header';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import { arSD } from '@mui/x-data-grid/locales';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import XLSX from 'sheetjs-style';
import useAuthentication_etab from "./useAuthentication_etab";

import { Typography, Box } from '@mui/material';
import Divider from '@mui/material/Divider';

const defaultColor = "#4caf50"; // Default green color
const hoverColor = "#004d40"; // Lighter green when hovered
const focusColor = "#81c784"; // Even lighter green when focused

const themetextfieldnew = createTheme({
  palette: {
    primary: {
      main: defaultColor
    },
    secondary: {
        main: defaultColor
    }
  },
    direction: 'rtl',
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: hoverColor,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: focusColor,
                    },
                },
                notchedOutline: {
                    borderColor: defaultColor,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: focusColor,
                    },
                    "&:hover": {
                        color: hoverColor,
                    },
                    color: defaultColor, // Default label color
                },
            },
        },
    },
    fieldset: {
        fontFamily: '"Changa", sans-serif',
        fontSize: 45,
    },
});
// Create a cache with RTL support
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
});

function CustomToolbar({ columns, handleExportExcel }) {
  return (


    <GridToolbarContainer className="custom-toolbar">
      <GridToolbarColumnsButton className="custom-toolbar" />
      <GridToolbarFilterButton className="custom-toolbar" />
      <GridToolbarDensitySelector className="custom-toolbar" />
      <Button className="custom-toolbar" onClick={handleExportExcel} color="primary">
        تنزيل كملف إكسال
      </Button>
      <GridToolbarExport className="custom-toolbar" />

      <ThemeProvider theme={themetextfieldnew}>
      <GridToolbarQuickFilter
        className="custom-toolbar"
         
      />
      </ThemeProvider>



    </GridToolbarContainer>
  );
}

  


const Logintraces = () => {
  const {
    IDetablissement,
    IDEts_Form,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  } = useAuthentication_etab();

   
  const [open, setOpen] = React.useState(false);
  const [fullDescription, setFullDescription] = React.useState('');
  const [nom, setNom] = React.useState('');

  const handleOpen = (description, nom) => {
    setFullDescription(description);
    setNom(nom);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [rows, setRows] = useState([]);
  const [loadingData, setLoadingData] = useState(true);


  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map(col => ({ name: col.field, headerName: col.headerName }));
  
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
      const newRow = {};
      relevantColumns.forEach(col => {
        newRow[col.headerName] = row[col.name];
      });
      return newRow;
    }));
  
    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };
  
    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    const timestamp = Math.floor(Date.now() / 1000);
  
    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  // Inherit the theme from the docs site (dark/light mode)
  const existingTheme = useTheme();

  const theme_datagrid = React.useMemo(
    () =>
      createTheme({}, arSD, existingTheme, {
        direction: 'rtl',
      }),
    [existingTheme],
  );


  useEffect(() => {
    const logintraceslist = `${API_BASE_URL}/api/loginTracesList`;
  
    fetch(logintraceslist, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data && Array.isArray(data.data)) {
        setRows(data.data);
        //console.error("data.data:", data.data);
      } else {
        console.error("Unexpected data format:", data);
        setRows([]); // Set to empty array to avoid errors in DataGrid
      }
      setLoadingData(false);
    })
    .catch(error => {
      console.error("Error fetching data:", error);
      setLoadingData(false);
    });
  }, []);
  

  const columns = [
   

    {
      field: 'id',
      headerName: 'رقم',
      minWidth: 40,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },
       
    { field: 'created_at', headerName: 'تاريخ تسجيل الدخول', width: 300,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center', 
      
    },  
    {
      field: 'NomNin',
      headerName: 'المستخدم',
      width: 350,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => {
        const { Nom, Nin } = params.row;
        return Nin ? `${Nom} (${Nin})` : Nom;
      },
    }
    
  /*  { field: 'Nom', headerName: 'المستخدم', width: 350,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center', 
      flex: 1,
      
    },
    { field: 'Nin', headerName: 'NIN', width: 200,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center', 
      
    }*/
  
    
  ];

  const handleRowDoubleClick = (params) => {
    console.log('Row double clicked:', params.row);
  };

 
  return (
    <>
       <Header
        IDetablissement={IDetablissement}
        IDEts_Form={IDEts_Form}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={
          download_cert_inscription_active_Dfep
        }
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={
          download_cert_inscription_active_Mfep
        }
      />
    
    <Box
            sx={{
              '& .super-app-theme--header': {
                  fontFamily: 'Cairo',
                  fontSize: '20px',
                  fontWeight: 'bold', // Make the font bold
                  backgroundColor: '#004d40', // Light green background color

                  borderRight: '1px solid #a5d6a7', // Greenish border for better contrast
                  fontWeight: 900,
                  color:'#FFFFFF'
              },
          }}
          

          >

<Grid item sx={{ width: "100%" }}>

                  <Divider sx={{ mt: '15px' }}>
  <Grid item  >
    <Typography
      style={{
        color: "#004d40",
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 25,
        fontWeight: 700,
        
      }}
      variant="h3" gutterBottom>

      <HistoryIcon sx={{ mr: '5px', ml:'5px' }} />
      سجل نشاط الحساب
    </Typography>
  </Grid>

</Divider>
</Grid>
         
    <Grid container>
      <Grid item sx={{ width: "100%" }}>
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme_datagrid}>
            <div   style={{ height: '100%', width: '100%', minHeight: 300 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                loading={loadingData}
                onRowDoubleClick={handleRowDoubleClick}
                editMode="row"
                getRowId={(row) => row.id}
                getRowClassName={(params) =>
                  parseInt(params.row.id, 10) % 2 === 0 ? 'even-row' : 'odd-row'
                }
                slots={{
                  toolbar: CustomToolbar,
                }}
                slotProps={{
                  toolbar: { columns, handleExportExcel },
                }}
                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'green',
                  overflowX: 'scroll',
                  '& .MuiDataGrid-cell': {
                    fontFamily: 'Tajawal',
                    fontSize: '20px',
                   
                    backgroundColor: 'rgba(255, 255, 255, 0.65)',
                    borderRight: '1px solid #ccc',
                  },
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: '#004d40',
                  },
                  '& .even-row': {
                    backgroundColor: '#e8f5e9',
                  },
                  '& .odd-row': {
                    backgroundColor: '#ffffff',
                  },
                }}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 25, page: 0 },
                  },
                }}

                getRowHeight={() => 50} // Example: adjust as needed based on content
              />

<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle  sx={{
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 18,
                fontWeight: 600,
              }}>{nom}</DialogTitle>
        <DialogContent>
          <Typography
            style={{
              whiteSpace: 'pre-line',
              wordBreak: 'break-word',
              fontFamily: 'Cairo, sans-serif',
              fontSize: '20px',
              padding: '8px',
            }}
            variant="body1"
          >
            {fullDescription}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: '#c62828', // Red color
              color: '#FFFFFF', // White text
              '&:hover': {
                backgroundColor: '#b71c1c', // Darker red on hover
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              إغلاق
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      
            </div>
          </ThemeProvider>
        </CacheProvider>
      </Grid>
    </Grid>

    </Box>

    <Footer/>
    </>
  );
};

export default Logintraces;
