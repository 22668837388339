import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import EngineeringIcon from '@mui/icons-material/Engineering';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Container from "@mui/material/Container";
import Footer from "../containers/Footer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ApiIcon from "@mui/icons-material/Api";
import FunctionsIcon from "@mui/icons-material/Functions";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import LoopIcon from "@mui/icons-material/Loop";
import StorageIcon from '@mui/icons-material/Storage';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import './Header.css';
import stampImage from '../src/img/republicstamp.svg';
import stampImage2 from '../src/img/republicstamp_flagdz.png';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import MenuIcon from "@mui/icons-material/Menu";
import ApartmentIcon from '@mui/icons-material/Apartment';
import useAuthentication_encad from './useAuthentication_encad';
import useAuthentication_cand from './useAuthentication_cand';
import useAuthentication_logged from "./useAuthentication_logged";

import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { APP_PREFIX } from "./config"; // Adjust the path if necessary
import Drawer from "@mui/material/Drawer";

import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import DescriptionIcon from "@mui/icons-material/Description";
import PrintIcon from "@mui/icons-material/Print";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

import appico from "../src/img/logo.png";
import logo2 from "../src/img/logo2.png";
import flag_dz from "../src/img/flag_dz.png";

import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import iconbarleft from "../src/img/logo.png";
import pharmacy from "../src/img/logo.png";
import blooddon from "../src/img/logo.png";
import moresearch from "../src/img/logo.png";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Listmenumt from "./Listmenumt";
import Box from "@mui/material/Box";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./Drawer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../containers/config"; // Adjust the path if necessary

import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import "./amirifont.css";
import { styled } from "@mui/material/styles";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const Header = () => {


  const authlogged = useAuthentication_logged(); // Get values from useAuthentication_logged
  const authCandData = useAuthentication_cand(); // Always call the hook at the top level, but we will conditionally use its data
  const [authCand, setAuthCand] = useState(null); // Create a state for authCand

  // Function to get the value from authlogged
  const getValue = (key) => {
    try {
      return authlogged?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  const IDCandidat = getValue("IDCandidat");
  const IDEncadrement = getValue("IDEncadrement");
  const IDetablissement = getValue("IDetablissement");
  const IDDFEP = getValue("IDDFEP");
  const IDNature_etsF = getValue("IDNature_etsF");
  const IDUtilisateur = getValue("IDUtilisateur");
  const IDapis_userslist = getValue("IDapis_userslist");
  const loadingauth = getValue("loadingauth");

  // Function to get the value from authCand if it exists
  const getValuecand = (key) => {
    try {
      return authCand?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  let NumSem = getValuecand("NumSem");
  let loadingauthcand = getValuecand("loadingauth");
  let Singup_active = getValuecand("Singup_active");
  let download_cert_scol_active = getValuecand("download_cert_scol_active");
  let EditingInfo_active = getValuecand("EditingInfo_active");
  let Eval_notes_active = getValuecand("Eval_notes_active");
  let download_cert_inscription_active = getValuecand("download_cert_inscription_active");
  let Singup_active_Dfep = getValuecand("Singup_active_Dfep");
  let download_cert_scol_active_Dfep = getValuecand("download_cert_scol_active_Dfep");
  let EditingInfo_active_Dfep = getValuecand("EditingInfo_active_Dfep");
  let Eval_notes_active_Dfep = getValuecand("Eval_notes_active_Dfep");
  let download_cert_inscription_active_Dfep = getValuecand("download_cert_inscription_active_Dfep");
  let Singup_active_Mfep = getValuecand("Singup_active_Mfep");
  let download_cert_scol_active_Mfep = getValuecand("download_cert_scol_active_Mfep");
  let EditingInfo_active_Mfep = getValuecand("EditingInfo_active_Mfep");
  let Eval_notes_active_Mfep = getValuecand("Eval_notes_active_Mfep");
  let download_cert_inscription_active_Mfep = getValuecand("download_cert_inscription_active_Mfep");
  let crypted_id = getValuecand("crypted_id");

  useEffect(() => {
    // Check if IDCandidat is valid and authCandData is not loading
    if (IDCandidat !== null && IDCandidat !== undefined && Number(IDCandidat) > 0 && !authCandData["loadingauth"]) {
      // Deep comparison of authCand and authCandData to prevent unnecessary state updates
      if (JSON.stringify(authCand) !== JSON.stringify(authCandData)) {

        setAuthCand(authCandData); // Update authCand state when IDCandidat is valid and authCand has changed
      }
    }
  }, [IDCandidat, authCandData, authCand]); // Trigger useEffect when IDCandidat, authCandData, or authCand changes






  const [IDsession_selected, setIDsession_selected] = useState(null);
  const [Nomsession, setNomsession] = useState("");

  const apiUrl_session = `${API_BASE_URL}/api/getsessionEncour`;


  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(apiUrl_session);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        if (!data || !data.data || !Array.isArray(data.data)) {
          throw new Error("Unexpected data format received from API");
        }

        // Store fetched data in localforage
        ////await localforage.setItem('sessionEncour', data.data);

        // Process fetched data
        const getsessionEncour = data.data.filter(
          (Wlylist) => Wlylist.Nom !== null
        );

        // Set selected session if available
        if (getsessionEncour.length > 0) {
          ///console.log("IDsession_selected: ",getsessionEncour[0].IDSession);
          setIDsession_selected(getsessionEncour[0].IDSession);
          setNomsession(getsessionEncour[0].Nom);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const loadDataFromLocalForage = async () => {
      try {
        // If data doesn't exist in localforage, fetch it
        await fetchData();
      } catch (error) {
        console.error("Error loading data from localforage:", error);
      }
    };

    // Load data from localforage or fetch from API
    loadDataFromLocalForage();
  }, []);

  const [value, setValue] = useState(0); //select default tab
  const [value2, setValue2] = useState(0);
  const [hidepagest, sethidepagest] = useState(false);
  const [hidepageetub, sethidepageetub] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {

    setOpen(false);
  };

  useEffect(() => {

    if (
      IDCandidat == null ||
      IDCandidat == undefined ||
      !Number(IDCandidat) > 0
    ) {
      sethidepagest(true);
    } else if (
      IDetablissement == null ||
      IDetablissement == undefined ||
      !Number(IDetablissement) > 0 ||
      !Number(IDNature_etsF) > 0
    ) {
      sethidepageetub(true);
    } else {
      sethidepageetub(false);
      sethidepagest(false);
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openanchorEl = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl_infocand, setAnchorEl_infocand] = React.useState(null);
  const open_infocand = Boolean(anchorEl_infocand);
  const handleClick_infocand = (event) => {
    setAnchorEl_infocand(event.currentTarget);
  };
  const handleClose_infocand = () => {
    setAnchorEl_infocand(null);
  };
  const openprint = Boolean(anchorEl);

  const [anchorElMotakawin, setAnchorElMotakawin] = React.useState(null);
  const openMotakawin = Boolean(anchorElMotakawin);
  const handleClickMotakawin = (event) => {
    setAnchorElMotakawin(event.currentTarget);
  };
  const handleCloseMotakawin = () => {
    setAnchorElMotakawin(null);
  };

  const navigate = useNavigate();
  const handleClickValidationStEtab = () => {
    // Navigate to the desired route
    navigate("/ValidationCandInfo");
    handleCloseMotakawin();
  };
  const handleClickChangPwStEtab = () => {
    // Navigate to the desired route
    navigate("/ChangPwStEtab");
    handleCloseMotakawin();
  };

  const handleClicksettingDfep = () => {
    // Navigate to the desired route
    if (Number(IDNature_etsF) === 5) {
      navigate("/SettingDfep");
    } else if (Number(IDNature_etsF) === 1) {
      navigate("/SettingMfep");
    }

    handleCloseMotakawin();
  };



  const handleClicketabinfo = () => {
    // Navigate to the desired route
    navigate("/Etabinfo");
    handleCloseMotakawin();
  };

  const handleClicksettingapi = () => {
    // Navigate to the desired route
    navigate("/Modeformationtableinfo");
    handleCloseMotakawin();
  };

  const handleClickSpecialiteinfo = () => {
    // Navigate to the desired route
    navigate("/Specialiteinfo");
    handleCloseMotakawin();
  };
  const handleClickHowToSignUp = () => {
    // Navigate to the desired route
    navigate("/HowToSignUp");
    handleCloseMotakawin();
  };

  const handleClicksettingApis_functions = () => {
    // Navigate to the desired route
    navigate("/Apis_functions");
    handleCloseMotakawin();
  };

  const handleClicksettingMigrate_hfsql = () => {
    // Navigate to the desired route
    navigate("/Migrate_hfsql");
    handleCloseMotakawin();
  };

  const handleClicksettingapis_userlist_functions = () => {
    // Navigate to the desired route
    navigate("/apis_userlist_functions");
    handleCloseMotakawin();
  };

  const handleClicksession = () => {
    // Navigate to the desired route
    navigate("/SessionsMfep");
    handleCloseMotakawin();
  };

  const handleClickChangPwPriv = () => {
    // Navigate to the desired route
    navigate("/ChangPwPriv");
    handleCloseMotakawin();
  };

  const handleClickChangePwEtab = () => {
    // Navigate to the desired route
    navigate("/ChangePwEtab");
    handleCloseMotakawin();
  };

  /* const usePathname = () => {
     const location = useLocation();
     return location.pathname;
   }*/

  const openMyWindow = () => {
    //window.open("/Doc", "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")
    ///window.open("/Doc", '_system');
  };

  const location = useLocation();
  //////////////console.log(location.pathname);

  useEffect(() => {

    ////////let path = props.location.pathname;
    let path = location.pathname;

    ///alert('location.pathname'+location.pathname);
    //////////////console.log('location.pathname'+location.pathname);


    if ((path === "/SettingEtab" || path === "/HomeDfep" || path === "/HomeUtilisateur" || (path === "/Login" || path === "/login"
      || path === "/InfoEncadr")
    )) {
      setValue(0);
    }
    else if ((path === "/" || path === "/Offersinscription") ||
      (path.includes("/inscription"))) {

      setValue(1);
    }
    else if (

      (path === "/Checkpreinscrit")
    ) {
      setValue(2);
    }
    else if (
      ((path === "/CheckAuth" || path === "/checkAuth"))
    ) {
      setValue(3);
    }
    else if (
      ((path === "/Modeformationtableinfo"))
    ) {
      setValue(4);
    }
    else {
      setValue(null);
    }


    if ((path === "/Login")) {
      sessionStorage.clear();
      setValue(0);
    } else if (path === "/loginEtab") {
      sessionStorage.clear();

    }


  }, []);

  const handleChangeTabs1 = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  ///////////console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  ///////////console.log(isMatch);
  const isMatch_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch_xs = useMediaQuery(theme.breakpoints.down("xs"));

  const themeAmiri = createTheme({
    typography: {
      fontFamily: '"Amiri", serif',
      fontSize: 12,
    },
  });

  const themeRoboto = createTheme({
    typography: {
      fontFamily: '"Roboto Slab", serif',
      fontSize: 12,
    },
  });

  const themeChanga = createTheme({
    typography: {
      fontFamily: '"Tajawal", sans-serif',
      fontSize: 16,
    },
  });



  let toLink_SettingDfep_or_SettingEtab = "";
  let toLink_changpwst = "";
  let toLink_Offers = "";

  if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    Number(IDNature_etsF) === 1
  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeMfep";
    toLink_changpwst = "/ChangPwStMfep";
    toLink_Offers = "/OffersMfep";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    (IDNature_etsF === 40 || IDNature_etsF === "40")
  ) {
    toLink_SettingDfep_or_SettingEtab = "/InnovationList";
    toLink_changpwst = "/ChangPwStEtab";
    toLink_Offers = "";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    (Number(IDNature_etsF) === 5 || IDNature_etsF === "5")

  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeDfep";
    toLink_changpwst = "/ChangPwStDfep";
    toLink_Offers = "/OffersDfep";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== ""

  ) {
    toLink_SettingDfep_or_SettingEtab = "/SettingEtab";
    toLink_changpwst = "/ChangPwStEtab";
    toLink_Offers = "/OffersEtab";
  }

  else if (
    [63,999].includes(Number(IDUtilisateur))
  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeUtilisateur";
  }


  return (
    ///select first tab

    <>


      {isMatch ? (
        <>
          <Box
            sx={{ mb: 6 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <DrawerComp

            />
          </Box>
        </>
      ) : (
        <>


          <Box
            sx={{
              mb: 25,
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <React.Fragment>
              <div>
                {/* Your content above the header */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >

                  <AppBar>
                    <header className="header" dir="rtl">
                      <div className="heading-container">
                        <img src={stampImage} alt="stamp" className="stamp" />

                        <div>
                          <h1>الجمهورية الجزائرية الديمقراطية الشعبية</h1>
                          <h2>وزارة التكويـن والتعلـيـم المهنييـن</h2>
                        </div>
                        <img src={stampImage2} alt="Stamp" className="stamp" />
                      </div>
                    </header>




                    <Toolbar sx={{ width: "100%", backgroundColor: '#ffffff', color: '#004d40' }}>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        sx={{ ...(open && { display: "none" }), mr: 2, ml: 1 }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: '5px',
                            backgroundColor: '#004d400d',
                            borderRadius: '50%'
                          }}
                        >
                          <MenuIcon sx={{ color: "#004d40", fontSize: 35 }} />
                        </Box>
                      </IconButton>

                      <Box
                        component="img"
                        sx={{
                          height: 80,
                          ml: 1,
                        }}
                        alt="منصة المتكون"
                        src={logo2}
                      />



                      <Tabs
                        sx={{ width: "100%", justifyContent: "flex-end" }}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#004d40",
                          },
                        }}
                        textColor="inherit"
                        value={value}
                        onChange={handleChangeTabs1}
                        variant="standard"
                      >
                        {
                          (IDUtilisateur == null ||
                            IDUtilisateur == undefined) &&
                        (IDetablissement == null ||
                          IDetablissement == undefined) &&
                          (IDEncadrement == null ||
                            IDEncadrement == undefined) &&
                          (IDCandidat == null || IDCandidat == undefined) &&
                          (IDapis_userslist == null ||
                            IDapis_userslist == undefined) ? (
                          <Tab
                            component={Link}
                            to="/Login"
                            key={"4"}
                            icon={<PersonIcon />}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              "&:hover": {
                                backgroundColor: "#004d400d", // Lighter green with transparency for hover
                              },
                              "&.Mui-selected": {
                                backgroundColor: "#004d400d", // Darker green with transparency for active state
                              },
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                            label={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  marginRight: "5px",
                                }}
                              >
                                بوابة المتكون
                              </Typography>
                            }
                          />
                        ) : null}
                        {/*
                        {(IDetablissement == null ||
                          IDetablissement == undefined) &&
                          (IDCandidat == null || IDCandidat == undefined) &&
                          (IDapis_userslist == null ||
                            IDapis_userslist == undefined) ? (
                          <Tab
                            component={Link}
                            to="/loginEtab"
                            key={"5"}
                            icon={<ApartmentIcon />}
                            sx={{
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: 16,
                              fontWeight: 700,
                              display: "flex",
                              alignItems: "center",
                              "&:hover": {
                                // Apply styles on hover
                                backgroundColor: "#004d400d", // Darker background color on hover
                              },
                              "&.Mui-selected": {
                                // Apply styles when tab is active
                                backgroundColor: "#004d400d", // Example background color for active state
                              },
                              mr: "5px",
                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                            label={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  marginRight: "5px",
                                }}
                              >
                                بوابة المؤسسة
                              </Typography>
                            }
                          />
                        ) : null}

                        {IDCandidat !== null && IDCandidat !== undefined && (
                          <Tab
                            key={"2"}
                            ///component={Link} to="/Updateinfost"
                            onClick={handleClick_infocand}
                            sx={{
                              minWidth: "auto",
                              "&:hover": {
                                // Apply styles on hover
                                backgroundColor: "#004d400d", // Darker background color on hover
                              },
                              "&.Mui-selected": {
                                // Apply styles when tab is active
                                backgroundColor: "#004d400d", // Example background color for active state
                              },

                              whiteSpace: "nowrap", // Prevent text wrapping
                            }}
                            icon={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <DescriptionIcon />
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mx: "5px",
                                  }}
                                  type="body2"
                                >
                                  حسابي
                                </Typography>
                                <ArrowDropDownIcon />
                              </div>
                            }
                          />
                        )}
*/}

                        {/*
                          (IDEncadrement !== null && IDEncadrement !== undefined && IDEncadrement !== "") && (
                            <Tab
                              component={Link}
                              to="/InfoEncadr"
                              key={"0"}
                              icon={<HomeIcon />}
                              sx={{
                                minWidth: "auto",
                                "&:hover": {
                                  // Apply styles on hover
                                  backgroundColor: "#004d400d", // Darker background color on hover
                                },
                                "&.Mui-selected": {
                                  // Apply styles when tab is active
                                  backgroundColor: "#004d400d", // Example background color for active state
                                },
                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                              label={
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  الرئيسية
                                </Typography>
                              }
                            />
                          )
                        */}



                        {

                          (
                            (IDUtilisateur !==  null && [63,999].includes(Number(IDUtilisateur))) ||
                            (IDEncadrement !== null &&
                              IDEncadrement !== undefined &&
                              IDEncadrement !== "") ||
                            (IDetablissement !== null &&
                              IDetablissement !== undefined
                              && (IDCandidat == null ||
                                IDCandidat == undefined))) && (
                            <Tab
                              component={Link}
                              to={toLink_SettingDfep_or_SettingEtab}
                              key={"0"}
                              icon={<HomeIcon />}
                              sx={{
                                minWidth: "auto",
                                "&:hover": {
                                  // Apply styles on hover
                                  backgroundColor: "#004d400d", // Darker background color on hover
                                },
                                "&.Mui-selected": {
                                  // Apply styles when tab is active
                                  backgroundColor: "#004d400d", // Example background color for active state
                                },
                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                              label={
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  الرئيسية
                                </Typography>
                              }
                            />
                          )
                        }



                         



                        {IDapis_userslist !== null &&
                          IDapis_userslist !== undefined && (
                            <Tab
                              component={Link}
                              to="/ShowApiList"
                              key={"0"}
                              icon={<HomeIcon />}
                              sx={{
                                minWidth: "auto",
                                "&:hover": {
                                  // Apply styles on hover
                                  backgroundColor: "#004d400d", // Darker background color on hover
                                },
                                "&.Mui-selected": {
                                  // Apply styles when tab is active
                                  backgroundColor: "#004d400d", // Example background color for active state
                                },

                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                              label={
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"

                                >
                                  الرئيسية
                                </Typography>
                              }
                            />
                          )}
                        {/*
                        {IDetablissement !== null &&
                          (IDCandidat == null ||
                            Number(IDCandidat) <= 0 ||
                            IDCandidat == undefined) &&
                          IDetablissement !== undefined && (
                            <Tab
                              onClick={handleClickMotakawin}
                              sx={{
                                "&:hover": {
                                  // Apply styles on hover
                                  backgroundColor: "#004d400d", // Darker background color on hover
                                },
                                "&.Mui-selected": {
                                  // Apply styles when tab is active
                                  backgroundColor: "#004d400d", // Example background color for active state
                                },

                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                              icon={<DescriptionIcon />}
                              label={
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    theme={themeChanga}
                                    sx={{
                                      textAlign: "right",
                                      mr: 0,
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: 16,
                                      fontWeight: 700,
                                      mx: "5px",
                                    }}
                                    type="body2"

                                  >
                                    لوحة التحكم
                                  </Typography>
                                  <ArrowDropDownIcon />
                                </div>
                              }
                            />
                          )}
                          */}
                        {/*

                        {IDetablissement !== null &&
                          Number(IDNature_etsF) !== 40 &&
                          (IDCandidat == null ||
                            Number(IDCandidat) <= 0 ||
                            IDCandidat == undefined) &&
                          IDetablissement !== undefined && (

                            <Tab
 
                             component={Link}
                              to={
                                Number(IDNature_etsF) === 1
                                  ? "/ShowAllAprsMfep"
                                  : Number(IDNature_etsF) === 5
                                    ? "/ShowAllAprsdfep"
                                    : "/ShowAllAprs"
                              }
                           

                              key={"8"}
                              icon={<PrintIcon />}
                              sx={{

                                minWidth: "auto",
                                "&:hover": {
                                  // Apply styles on hover
                                  backgroundColor: "#004d400d", // Darker background color on hover
                                },
                                "&.Mui-selected": {
                                  // Apply styles when tab is active
                                  backgroundColor: "#004d400d", // Example background color for active state
                                },

                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                              label={
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"

                                >
                                  مطبوعات
                                </Typography>
                              }
                            />
                          )}
*/}
                        {/*

                        {IDCandidat !== null && IDCandidat !== undefined && (
                          <Tab
                            onClick={handleClick}
                            icon={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <PrintIcon />
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mx: "5px",
                                  }}
                                  type="body2"

                                >
                                  مطبوعات
                                </Typography>
                                <ArrowDropDownIcon />
                              </div>
                            }
                            style={{ minWidth: "auto" }}
                          />
                        )}
*/}

                        <Tab
                          component={Link}
                          to="/Offersinscription"
                          key={"9"}
                          icon={<EventRepeatIcon />}
                          sx={{
                            display: "flex", //

                            alignItems: "right",
                            "&:hover": {
                              // Apply styles on hover
                              backgroundColor: "#004d400d", // Darker background color on hover
                            },
                            "&.Mui-selected": {
                              // Apply styles when tab is active
                              backgroundColor: "#004d400d", // Example background color for active state
                            },
                            whiteSpace: "nowrap", // Prevent text wrapping
                          }}
                          label={
                            <Typography
                              variant="body2"
                              sx={{
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 16,
                                fontWeight: 700,
                                marginRight: "5px",
                                textAlign: "right",
                              }}
                            >
                              التسجيل الأولي
                            </Typography>
                          }
                        />

                        <Tab
                          component={Link}
                          to="/Checkpreinscrit"
                          key={"19"}
                          icon={<PersonSearchIcon />}
                          sx={{
                            display: "flex", //

                            alignItems: "right",
                            "&:hover": {
                              // Apply styles on hover
                              backgroundColor: "#004d400d", // Darker background color on hover
                            },
                            "&.Mui-selected": {
                              // Apply styles when tab is active
                              backgroundColor: "#004d400d", // Example background color for active state
                            },
                            whiteSpace: "nowrap", // Prevent text wrapping
                          }}
                          label={
                            <Typography
                              variant="body2"
                              sx={{
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 16,
                                fontWeight: 700,
                                marginRight: "5px",
                                textAlign: "right",
                              }}
                            >
                              تحقق من تسجيلك
                            </Typography>
                          }
                        />

                        <Tab
                          component={Link}
                          to="/CheckAuth"
                          key={"7"}
                          icon={<DocumentScannerIcon />}
                          sx={{
                            display: "flex",
                            alignItems: "right",
                            "&:hover": {
                              // Apply styles on hover
                              backgroundColor: "#004d400d", // Darker background color on hover
                            },
                            "&.Mui-selected": {
                              // Apply styles when tab is active
                              backgroundColor: "#004d400d", // Example background color for active state
                            },
                            whiteSpace: "nowrap", // Prevent text wrapping
                          }}
                          label={
                            <Typography
                              variant="body2"
                              sx={{
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 16,
                                fontWeight: 700,
                                marginRight: "5px",
                                textAlign: "right",
                              }}
                            >
                              تحقق من الشهادة
                            </Typography>
                          }
                        />


                        {/* */}
                        <Tab
                          component={Link}

                          onClick={handleClickMotakawin}
                          key={"8"}
                          sx={{
                            "&:hover": {
                              // Apply styles on hover
                              backgroundColor: "#004d400d", // Darker background color on hover
                            },
                            "&.Mui-selected": {
                              // Apply styles when tab is active
                              backgroundColor: "#004d400d", // Example background color for active state
                            },

                            whiteSpace: "nowrap", // Prevent text wrapping
                          }}
                          icon={<LiveHelpIcon />}
                          label={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                theme={themeChanga}
                                sx={{
                                  textAlign: "right",
                                  mr: 0,
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  mx: "5px",
                                }}
                                type="body2"

                              >
                                دليل المتكون
                              </Typography>
                              <ArrowDropDownIcon />
                            </div>
                          }
                        />




                        {(IDetablissement == null ||
                          IDetablissement == undefined) &&
                          (IDCandidat == null || IDCandidat == undefined) &&
                          (IDapis_userslist == null ||
                            IDapis_userslist == undefined) ? (
                          <Tab
                            component={Link}
                            to="/SignUpInnovation"

                            icon={<TipsAndUpdatesIcon />}
                            sx={{
                              display: "flex",
                              alignItems: "right",
                              "&:hover": {
                                // Apply styles on hover
                                backgroundColor: "#004d400d", // Darker background color on hover
                              },
                              "&.Mui-selected": {
                                // Apply styles when tab is active
                                backgroundColor: "#004d400d", // Example background color for active state
                              },
                              whiteSpace: "nowrap", // Prevent text wrapping
                              display: 'none'
                            }}
                            label={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  marginRight: "5px",
                                  textAlign: "right",
                                }}
                              >
                                الصالون الوطني للإبتكار
                              </Typography>
                            }
                          />
                        ) : null}






                        {/* 
 
{(IDetablissement!==null && (IDCandidat==null || Number(IDCandidat)<=0 || IDCandidat==undefined) 
  && IDetablissement !== undefined)
  || (IDCandidat !== null 
  && IDCandidat !== undefined)
  || (IDapis_userslist !== null 
  && IDapis_userslist !== undefined) ?  

  
<Tab 
    component={Link} 
    to="/Login"
    key={"6"}
    
    icon={<LogoutIcon />}
     sx={{
      minWidth:'auto',
      fontFamily: 
      '"Tajawal", sans-serif', 
      fontSize: 16
      ,fontWeight:700,
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      '&:hover': { // Apply styles on hover
        backgroundColor: '#004d400d', // Darker background color on hover

      },
      '&.Mui-selected': { // Apply styles when tab is active
        backgroundColor: '#004d400d', // Example background color for active state

      },
      mr:'5px',
     whiteSpace: 'nowrap', // Prevent text wrapping
     display:'none'
    }}
    

    onClick={() => {
      if (IDapis_userslist !== null && IDapis_userslist !== undefined) {

        sessionStorage.clear();
        // Remove all data with the prefix
        const keysToRemove = Object.keys(localStorage).filter(key => key.startsWith(APP_PREFIX));
        keysToRemove.forEach(key => localStorage.removeItem(key));

      } else {
        const distroytoken = `${API_BASE_URL}/api/distroytoken`;
        fetch(distroytoken, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        })
        .then(response => response.json())
        .then(data => {
          if (data) {
            console.error("Distroyed token: successfully");
          }
        })
        .catch(error => {
          console.error("Error distroytoken:", error);
        })
        .finally(() => {
          sessionStorage.clear();
          // Remove all data with the prefix
          const keysToRemove = Object.keys(localStorage).filter(key => key.startsWith(APP_PREFIX));
          keysToRemove.forEach(key => localStorage.removeItem(key));
        });
      }
    }}

    
    
    label={
      <Typography
        theme={themeChanga}
        sx={{  textAlign: 'right', mr: 0, fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 700, mr: '5px' }}
        type="body2"
        style={{ color: '#ffffff' }}
      >
        خروج
      </Typography>
    }
  />
  
   
: null
            }


*/}
                      </Tabs>

                      <div>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openanchorEl}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {IDCandidat !== null &&
                            IDCandidat !== undefined &&
                            download_cert_inscription_active !== null &&
                            download_cert_inscription_active !== undefined &&
                            Number(download_cert_inscription_active) === 1 &&
                            Number(download_cert_inscription_active_Dfep) === 1 &&
                            Number(download_cert_inscription_active_Mfep) ===
                            1 &&
                            Number(NumSem) === 1
                            ? (
                              <MenuItem
                                component={Link}
                                to={`/ETATCertificatInscriptionPDF/${crypted_id}`}
                                target="_blank" // Add this line to open in a new tab
                              >
                                {" "}
                                <PictureAsPdfIcon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  شهادة تسجيل{" "}
                                </Typography>
                              </MenuItem>
                            ) : Number(NumSem) === 1 ? (
                              <MenuItem disabled={true}>
                                {" "}
                                <PictureAsPdfIcon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  شهادة تسجيل{" "}
                                </Typography>
                              </MenuItem>
                            ) : null}

                          {IDCandidat !== null &&
                            IDCandidat !== undefined &&
                            download_cert_scol_active !== null &&
                            download_cert_scol_active !== undefined &&
                            Number(download_cert_scol_active) === 1 &&
                            Number(download_cert_scol_active_Dfep) === 1 &&
                            Number(download_cert_scol_active_Mfep) === 1 ? (
                            <div>
                              <Divider
                                variant="inset"
                                component="li"
                                style={{ width: "100%" }}
                              />
                              <MenuItem
                                component={Link}
                                to={`/ETATCertificatScolaritePDF/${crypted_id}`}
                                target="_blank" // Add this line to open in a new tab
                              >
                                {" "}
                                <PictureAsPdfIcon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  شهادة تربص{" "}
                                </Typography>
                              </MenuItem>
                            </div>
                          ) : (
                            <div>
                              <Divider
                                variant="inset"
                                component="li"
                                style={{ width: "100%" }}
                              />
                              <MenuItem disabled={true}>
                                {" "}
                                <PictureAsPdfIcon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  شهادة تربص{" "}
                                </Typography>
                              </MenuItem>
                            </div>
                          )}
                        </Menu>
                      </div>

                      <div>
                        <Menu
                          id="basic-menu_infocand"
                          anchorEl={anchorEl_infocand}
                          open={open_infocand}
                          onClose={handleClose_infocand}
                          MenuListProps={{
                            "aria-labelledby": "basic-button_infocand",
                          }}
                        >
                          <MenuItem component={Link} to="/InfoCand">
                            {" "}
                            <PersonIcon />{" "}
                            <Typography
                              theme={themeChanga}
                              sx={{
                                textAlign: "right",
                                mr: 0,
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 16,
                                fontWeight: 700,
                                mr: "5px",
                              }}
                              type="body2"
                            >
                              بطاقة معلوماتي
                            </Typography>
                          </MenuItem>

                          {IDCandidat !== null &&
                            IDCandidat !== undefined &&
                            Eval_notes_active !== null &&
                            Eval_notes_active !== undefined &&
                            Number(Eval_notes_active) === 1 &&
                            Number(Eval_notes_active_Dfep) === 1 &&
                            Number(Eval_notes_active_Mfep) === 1 ? (
                            <div>
                              <Divider
                                variant="inset"
                                component="li"
                                style={{ width: "100%" }}
                              />
                              <MenuItem component={Link} to="/NotesApr">
                                {" "}
                                <StickyNote2Icon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  نتائج السداسي{" "}
                                </Typography>
                              </MenuItem>
                            </div>
                          ) : (
                            <div>
                              <Divider
                                variant="inset"
                                component="li"
                                style={{ width: "100%" }}
                              />
                              <MenuItem disabled={true}>
                                {" "}
                                <StickyNote2Icon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  نتائج السداسي{" "}
                                </Typography>
                              </MenuItem>
                            </div>
                          )}

                          {IDCandidat !== null && IDCandidat !== undefined ? (
                            <div>
                              <Divider
                                variant="inset"
                                component="li"
                                style={{ width: "100%" }}
                              />
                              <MenuItem component={Link} to="/ChangePassword">
                                {" "}
                                <LockIcon />{" "}
                                <Typography
                                  theme={themeChanga}
                                  sx={{
                                    textAlign: "right",
                                    mr: 0,
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,
                                    mr: "5px",
                                  }}
                                  type="body2"
                                >
                                  {" "}
                                  تغيير كلمة السر{" "}
                                </Typography>
                              </MenuItem>
                            </div>
                          ) : null}
                        </Menu>
                      </div>

                      <div>
                        <Menu
                          id="basic-menuMotakawin"
                          anchorEl={anchorElMotakawin}
                          open={openMotakawin}
                          onClose={handleCloseMotakawin}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >

                          <div>
                            <Divider
                              variant="inset"
                              component="li"
                              style={{ width: "100%" }}
                            />
                            <MenuItem onClick={handleClickHowToSignUp}>
                              {" "}
                              <VideoLibraryIcon />{" "}
                              <Typography
                                theme={themeChanga}
                                sx={{
                                  textAlign: "right",
                                  mr: 0,
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  mr: "5px",
                                }}
                                type="body2"
                              >
                                {" "}
                                خطوات التسجيل
                              </Typography>
                            </MenuItem>
                          </div>

                          <div>
                            <Divider
                              variant="inset"
                              component="li"
                              style={{ width: "100%" }}
                            />
                            <MenuItem onClick={handleClicksettingapi}>
                              {" "}
                              <MenuBookIcon />{" "}
                              <Typography
                                theme={themeChanga}
                                sx={{
                                  textAlign: "right",
                                  mr: 0,
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  mr: "5px",
                                }}
                                type="body2"
                              >
                                {" "}
                                أنماط التكوين
                              </Typography>
                            </MenuItem>
                          </div>



                          <div>
                            <Divider
                              variant="inset"
                              component="li"
                              style={{ width: "100%" }}
                            />
                            <MenuItem onClick={handleClicketabinfo}>
                              {" "}
                              <ApartmentIcon />{" "}
                              <Typography
                                theme={themeChanga}
                                sx={{
                                  textAlign: "right",
                                  mr: 0,
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  mr: "5px",
                                }}
                                type="body2"
                              >
                                {" "}
                                مؤسسات التكوين
                              </Typography>
                            </MenuItem>
                          </div>

                          <div>
                            <Divider
                              variant="inset"
                              component="li"
                              style={{ width: "100%" }}
                            />
                            <MenuItem onClick={handleClickSpecialiteinfo}>
                              {" "}
                              <EngineeringIcon />{" "}
                              <Typography
                                theme={themeChanga}
                                sx={{
                                  textAlign: "right",
                                  mr: 0,
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  mr: "5px",
                                }}
                                type="body2"
                              >
                                {" "}
                                البطاقة الوصفية للتخصصات
                              </Typography>
                            </MenuItem>
                          </div>


                        </Menu>
                      </div>

                      {
                        (IDUtilisateur !==  null && [63,999].includes(Number(IDUtilisateur))) || 
                        (IDEncadrement !== null && IDEncadrement !== undefined && IDEncadrement !== "") ||
                          (IDetablissement !== null &&
                            (IDCandidat == null ||
                              Number(IDCandidat) <= 0 ||
                              IDCandidat == undefined) &&
                            IDetablissement !== undefined) ||
                          (IDCandidat !== null && IDCandidat !== undefined) ||
                          (IDapis_userslist !== null &&
                            IDapis_userslist !== undefined) ? (
                          <Box ml="auto" sx={{ ml: 5 }}>
                            {" "}
                            {/* This will push the Button to the end */}
                            <Button
                              variant="contained"
                              color="primary"
                              component={Link}
                              to="/Logout"
                              key={"6"}
                              icon={<LogoutIcon />}
                              sx={{
                                backgroundColor: "#004d40", // Set background color to red
                                border: "2px solid #004d40", // Add white border
                                minWidth: "auto",
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 16,
                                fontWeight: 700,
                                flex: 1,
                                display: "flex",
                                alignItems: "center",
                                "&:hover": {
                                  // Apply styles on hover

                                  backgroundColor: "red", // Set background color to red
                                  border: "2px solid #ffffffd1", // Add white border
                                },
                                "&.Mui-selected": {
                                  // Apply styles when tab is active
                                  // backgroundColor: "#ffffff1a", // Example background color for active state
                                  backgroundColor: '#004d40'
                                },
                                mr: "5px",
                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                              onClick={() => {
                                if (
                                  IDapis_userslist !== null &&
                                  IDapis_userslist !== undefined
                                ) {

                                  // Remove all data with the prefix
                                  const keysToRemove = Object.keys(
                                    localStorage
                                  ).filter((key) => key.startsWith(APP_PREFIX));
                                  keysToRemove.forEach((key) =>
                                    localStorage.removeItem(key)
                                  );
                                } else {
                                  const destroyToken = `${API_BASE_URL}/api/destroyToken`;

                                  fetch(destroyToken, {
                                    headers: {
                                      Accept: 'application/json',
                                      Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
                                    }
                                  })
                                    .then((response) => {
                                      if (!response.ok) {
                                        sessionStorage.clear();
                                        throw new Error(
                                          "Network response was not ok"
                                        );
                                      }
                                      return response.json();
                                    })
                                    .then((data) => {
                                      if (data) {
                                        sessionStorage.clear();
                                        console.error(
                                          "Distroyed token: successfully"
                                        );
                                      }
                                    })
                                    .catch((error) => {
                                      sessionStorage.clear();
                                      console.error("Error destroyToken:", error);
                                    })
                                    .finally(() => {
                                      sessionStorage.clear();
                                      // Remove all data with the prefix
                                      const keysToRemove = Object.keys(
                                        localStorage
                                      ).filter((key) =>
                                        key.startsWith(APP_PREFIX)
                                      );
                                      keysToRemove.forEach((key) =>
                                        localStorage.removeItem(key)
                                      );
                                    });
                                }
                              }}
                            >
                              <Typography
                                theme={themeChanga}
                                sx={{
                                  textAlign: "right",
                                  mr: 0,
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 16,
                                  fontWeight: 700,
                                  mr: "0px",
                                }}
                                type="body2"

                              >
                                <LogoutIcon
                                  style={{
                                    marginLeft: "3px",
                                    marginBottom: "-6px",
                                  }}
                                />{" "}
                                خروج
                              </Typography>
                            </Button>
                          </Box>
                        ) : null}
                    </Toolbar>
                  </AppBar>
                  <br />
                  <br />


                  <Drawer
                    sx={{
                      width: drawerWidth,
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: drawerWidth,
                      },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={open}
                  >
                    <DrawerHeader dir="ltr">
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? <CloseIcon /> : <CloseIcon />}
                      </IconButton>
                    </DrawerHeader>

                    <Listmenumt

                    />

                  </Drawer>

                </Box>
              </div>
            </React.Fragment>
          </Box>

        </>
      )}





    </>
  );
};

export default Header;
