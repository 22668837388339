import React from 'react';
import { Typography, Box } from '@mui/material';
import underconstruction from '../src/img/underconstruction.png';

const UnderConstruction = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                backgroundColor: '#004d40',
            }}
        >
            <Typography
                sx={{
                    color: '#eff0f4',
                    fontFamily: '"Tajawal", sans-serif',
                    fontWeight: 900,
                    fontSize: 20,
                    backgroundColor: '#004d40',
                    border: '4px solid #e0e4e875',
                    padding: '10px',
                    textAlign: 'center',
                }}
            >
                الموقع تحت الصيانة
                <br />
                يرجى المحاولة لاحقًا
            </Typography>
            <Box
                component="img"
                src={underconstruction}
                alt="Under Construction"
                sx={{
                    marginTop: '20px',
                    maxWidth: '100%',
                    height: 'auto',
                }}
            />
        </Box>
    );
};

export default UnderConstruction;
