import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CacheProvider } from "@emotion/react";
import TextField from '@mui/material/TextField';
import createCache from "@emotion/cache";
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import logoMfep from '../src/img/logo.png';
import LinearProgress from '@mui/material/LinearProgress';
import '../containers/amirifont.css'; // Import the font CSS file


import {
  useMediaQuery,
  useTheme,
} from "@mui/material";

import getCsrfToken from '../CsrfToken';
import FormControl from '@mui/material/FormControl';
import isEmail from 'validator/lib/isEmail';
import ReplayIcon from '@mui/icons-material/Replay';
import Input from '@mui/material/Input';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import Footer from './Footer';
import Header from './Header';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import useAuthentication_cand from './useAuthentication_cand';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import Select from '@mui/material/Select';
import CardContent from '@mui/material/CardContent';

// Set the CSRF token globally
const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

var md5 = require('md5');
let codeemail = (Math.floor(1000 + Math.random() * 9000));

let sendemailbyemailoneortwo = 'https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';


const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
});



const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

function InfoCand() {


  const { IDetablissement, IDDFEP, IDNature_etsF,
    IDCandidat,
    crypted_id,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  } = useAuthentication_cand();

  const [EndTimeupdatedb, setEndTimeupdatedb] = useState("");

  const apiUrl_lastupdatedb = `${API_BASE_URL}/api/lastupdatedb/Candidat`;

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(apiUrl_lastupdatedb);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        if (!data || !data.data) {
          throw new Error("Unexpected data format received from API");
        }

        // Process fetched data
        const lastupdatedb = data.data;

        // Set selected session if available
        if (lastupdatedb && lastupdatedb.EndTime) {
          setEndTimeupdatedb(lastupdatedb.EndTime);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const loadDataFromLocalForage = async () => {
      try {
        // If data doesn't exist in localforage, fetch it
        await fetchData();
      } catch (error) {
        console.error("Error loading data from localforage:", error);
      }
    };

    // Load data from localforage or fetch from API
    loadDataFromLocalForage();
  }, []);

  // Function to format date in Arabic with Arabic numerals
  const formatArabicDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, localeMatcher: 'best fit', weekday: "long", era: "long" };
    const easternArabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    const arabicNumerals = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const formattedDate = new Date(dateString).toLocaleString('ar-EG', options);
    // Replace Eastern Arabic numerals with Arabic numerals
    return formattedDate.replace(/[٠-٩]/g, (match) => arabicNumerals[easternArabicNumerals.indexOf(match)]);
  };


  const myDate = dayjs(); // This is your Day.js object
  const formattedDate = myDate.format('YYYY-MM-DD'); // Format it as a string


  const [loading, setLoading] = useState(true);


  const [alertMessageEdit, setAlertMessageEdit] = useState(null);


  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(null);

  const [OpenDialog, setOpenDialog] = useState(false);


  const [errorget, setErrorget] = useState();
  const navigate = useNavigate();

  const refDateNais = useRef(null);


  const [Civ, setCiv] = React.useState(0);

  const handleChange_Civ = (event) => {
    setCiv(event.target.value);
  };





  let numrun = 0;
  useEffect(() => {


    const fetchData = async () => {



      try {
        if (!crypted_id || Number(numrun) >= 1) {

          return;
        }


        const response = await fetch(`${API_BASE_URL}/api/getbycrypted_id/${crypted_id}`
          , {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
            }
          });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        ///alert('userIdtoken: '+data.userIdtoken);
        ///setValuesFromData_getinfo(data.data);
        setValuesFromData_getinfo(data.data, data.data_dfep, data.data_mfep);

      } catch (error) {
        console.error(error);
        // Handle error, e.g., show an error message to the user
      } finally {
        setLoading(false);
        numrun++;
      }


    };

    if (crypted_id) {
      fetchData();
    } else {

      return;
    }
  }, [crypted_id, navigate]);






  const [IdMihnati1, setIdMihnati1] = useState();

  const [Nom_Et_query, setNom_Et_query] = useState();
  const [candata, setcandata] = useState();
  const [Nom_Ca_query, setNom_Ca_query] = useState();
  const [Prenom_query, setPrenom_query] = useState();
  const [DateNais_query, setDateNais_query] = useState('');
  const [NumIns_query, setNumIns_query] = useState();
  const [LieuNais_query, setLieuNais_query] = useState();
  const [Adres_query, setAdres_query] = useState();
  const [Nom_query, setNom_query] = useState();
  const [Mode_formation_Nom_query, setMode_formation_Nom_query] = useState();
  const [DateDF_query, setDateDF_query] = useState('');
  const [DateFF_query, setDateFF_query] = useState('');
  const [NumSem_query, setNumSem_query] = useState();
  const [DateD_sem_query, setDateD_sem_query] = useState('');
  const [DateF_sem_query, setDateF_sem_query] = useState('');
  const [libelleArabe_Wly_Dfep_query, setlibelleArabe_Wly_Dfep_query] = useState();



  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isMatch_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch_xs = useMediaQuery(theme.breakpoints.down("xs"));

  function setValuesFromData_getinfo(data, data_dfep, data_mfep) {
    // Check if data object is defined
    if (!data || !data_dfep || !data_mfep) {
      console.error('Data is undefined');
      return;
    }

    setcandata(data);

    setNom_Et_query(data.Nom_Et || '');
    setNom_Ca_query(data.Nom_Ca || '');
    setPrenom_query(data.Prenom || '');
    setDateNais_query(dayjs(data.DateNais).format('DD-MM-YYYY') || '');
    setLieuNais_query(data.LieuNais || '');
    setAdres_query(data.Adres || '');
    setNom_query(data.Nom || '');
    setMode_formation_Nom_query(data.Mode_formation_Nom || '');
    setDateDF_query(dayjs(data.DateDF).format('DD-MM-YYYY') || '');
    setDateFF_query(dayjs(data.DateFF).format('DD-MM-YYYY') || '');
    setNumSem_query(data.NumSem || '');
    setDateD_sem_query(dayjs(data.DateD_sem).format('DD-MM-YYYY') || '');
    setDateF_sem_query(dayjs(data.DateF_sem).format('DD-MM-YYYY') || '');
    setNumIns_query(data.NumIns || '');
    setlibelleArabe_Wly_Dfep_query(data.libelleArabe_Wly_Dfep || '');
    setCiv(data.Civ);
    setIdMihnati1(data.IdMihnati1);



  }



  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };


  const handleClickCloseDialog = () => {
    setOpenDialog(false);
    //// navigate('/Doc');
  };


  const [emailverificated, setemailverificated] = useState(false);

  const [emailsended, setemailsended] = useState(false);
  const [errorsendingemail, seterrorsendingemail] = useState(false);
  const [codeemailsent, setcodeemailsent] = useState(false);
  const [codeemailforchecking, setcodeemailforchecking] = useState(md5(codeemail));

  const [valueNomMere, setvalueNomMere] = useState('');
  const [valueNomMereFr, setvalueNomMereFr] = useState('');
  const [valuePrenomMereFr, setvaluePrenomMereFr] = useState('');
  const [valuePrenomMere, setvaluePrenomMere] = useState('');


  const [valueNom, setvalueNom] = useState('');
  const [valueLieuNaisFr, setvalueLieuNaisFr] = useState('');
  const [valueLieuNais, setvalueLieuNais] = useState('');

  const [valueAdres, setvalueAdres] = useState('');

  const [valuePrenomPere, setvaluePrenomPere] = useState('');
  const [valuePrenomPereFr, setvaluePrenomPereFr] = useState('');


  const [valueAdresFr, setvalueAdresFr] = useState('');


  const refLieuNais = useRef(null);
  const refLieuNaisFr = useRef(null);

  const refNomMere = useRef(null);
  const refNomMereFr = useRef(null);
  const refPrenomMere = useRef(null);
  const refPrenomMereFr = useRef(null);



  const handleInput_LieuNais = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueLieuNais(inputValue);
    }
  };

  const handleInput_LieuNaisFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueLieuNaisFr(inputValue);
    }
  };
  const refNom = useRef(null);
  const refvalueDateNais = useRef(null);
  const [valuePrenom, setvaluePrenom] = useState('');
  const refPrenom = useRef(null);
  const refPrenomPere = useRef(null);
  const refPrenomPereFr = useRef(null);


  const [valuenbcodeverifie, setvaluenbcodeverifie] = useState('');
  const refnbcodeverifie = useRef(null);

  const ArabicCharactersRegex = /^[\u0600-\u06FF\s]+$/;
  const ArabicCharactersRegex_withdash = /^[\u0600-\u06FF\s-_]+$/;
  const ArabicCharactersRegex_address = /^[\u0600-\u06FF\s-_\/0-9]+$/;

  const NumbersOnlyRegex = /^[0-9]+$/;


  const handleInput_Adres = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex_address.test(inputValue) || inputValue === '') {
      setvalueAdres(inputValue);
    }
  };

  const handleInput_PrenomMere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomMere(inputValue);
    }
  };

  const handleInput_NomMere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueNomMere(inputValue);
    }
  };

  const handleInput_Nom = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueNom(inputValue);
    }
  };



  const [valueDateNais, setvalueDateNais] = useState('');

  // Regular expression to match the format "YYYY/MM/DD"
  const dateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

  // Regular expression to match the format "YYYY/MM/DD" with digits for years, months, and days
  const fullDateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

  const handleInput_DateNais = (event) => {
    const inputValue = event.target.value;
    setvalueDateNais(inputValue);
  };



  const handleInput_PrenomPere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomPere(inputValue);
    }
  };

  const handleInput_Prenom = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenom(inputValue);
    }
  };

  const handleInput_nbcodeverifie = (event) => {
    const inputValue = event.target.value;

    if (NumbersOnlyRegex.test(inputValue) || inputValue === '') {
      setvaluenbcodeverifie(inputValue);
    }
  };

  const [valueNomFr, setvalueNomFr] = useState('');
  const refNomFr = useRef(null);
  const [error, setError] = useState('');

  const [valuePrenomFr, setvaluePrenomFr] = useState('');
  const refPrenomFr = useRef(null);

  const LatinLettersRegex = /^[a-zA-Z\s]+$/;
  const LatinLettersRegex_withdash = /^[a-zA-Z\s-_]+$/;
  const LatinLettersRegex_address = /^[a-zA-Z\s\-_\/0-9]+$/;

  const handleInput_AdresFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex_address.test(inputValue) || inputValue === '') {
      setvalueAdresFr(inputValue);
    }
  };



  const handleInput_PrenomMereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomMereFr(inputValue);
    }
  };

  const handleInput_NomMereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueNomMereFr(inputValue);
    }
  };

  const handleInput_NomFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueNomFr(inputValue);
    }
  };

  const handleInput_PrenomPereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomPereFr(inputValue);
    }
  };

  const handleInput_PrenomFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomFr(inputValue);
    }
  };



  const refbtnsubmit = useRef(null);
  const refpassword1 = useRef(null);
  const refpassword2 = useRef(null);
  const refpasswordnow = useRef(null);
  const refAdres = useRef(null);
  const refAdresFr = useRef(null);



  const [valuesPassword, setValuesPassword] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [valuesPasswordRe, setValuesPasswordRe] = React.useState({
    amount: '',
    PasswordRe: '',
    weight: '',
    weightRange: '',
    showPasswordRe: false,
  });

  const [valuespasswordnow, setValuespasswordnow] = React.useState({
    amount: '',
    passwordnow: '',
    weight: '',
    weightRange: '',
    showpasswordnow: false,
  });


  const handleChangePasswordRe = (prop) => (event) => {
    setValuesPasswordRe({ ...valuesPasswordRe, [prop]: event.target.value });
  };

  const handleChangepasswordnow = (prop) => (event) => {
    setValuespasswordnow({ ...valuespasswordnow, [prop]: event.target.value });
  };

  const handleClickShowPasswordRe = () => {
    setValuesPasswordRe({
      ...valuesPasswordRe,
      showPasswordRe: !valuesPasswordRe.showPasswordRe,
    });
  };

  const handleClickShowpasswordnow = () => {
    setValuespasswordnow({
      ...valuespasswordnow,
      showpasswordnow: !valuespasswordnow.showpasswordnow,
    });
  };

  const handleMouseDownPasswordRe = (event) => {
    event.preventDefault();
  };

  const handleMouseDownpasswordnow = (event) => {
    event.preventDefault();
  };


  const handleChangePassword = (prop) => (event) => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  // Store token in state
  const [csrfToken, setCsrfToken] = useState(null);

  // Fetch on app load
  useEffect(() => {
    const getCsrfToken = async () => {
      const response = await axios.get(`${API_BASE_URL}/api/csrf-token`);
      setCsrfToken(response.data.csrf_token);
    };

    getCsrfToken();
  }, []);



  const onsubmitclicked = async (e) => {




    e.preventDefault();

    // Fetch CSRF token
    try {
      // Refresh token
      const response = await axios.get(`${API_BASE_URL}/api/csrf-token`);
      setCsrfToken(response.data.csrf_token);

      ///axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

      /////console.log(response.data.csrf_token);
      ///alert(valuespasswordnow.passwordnow)


      try {
        const data = {
          Nom: valueNom,
          NomFr: valueNomFr,
          Prenom: valuePrenom,
          PrenomFr: valuePrenomFr,
          Password: valuesPassword.password,
          Password_old: valuespasswordnow.passwordnow,
          IDCandidat: IDCandidat,
          LieuNaisFr: valueLieuNaisFr,
          LieuNais: valueLieuNais,
          PrenomPereFr: valuePrenomPereFr,
          NomMereFr: valueNomMereFr,
          PrenomMereFr: valuePrenomMereFr,
          AdresFr: valueAdresFr,
          PrenomPere: valuePrenomPere,
          NomMere: valueNomMere,
          PrenomMere: valuePrenomMere,
          Adres: valueAdres,
          Civ: Civ,
          DateNais: valueDateNais

        };

        //alert(JSON.stringify(data));
        const responseFromApi = await axios.post(`${API_BASE_URL}/api/insertorupdatest`, data);

        if (responseFromApi.data.savedSuccess) {
          setAlertMessage("");
          handleClickOpenDialog();
          ////navigate('/');
        } else {
          ///alert('Failed to register information. Please try again.');
        }

        /////console.log(responseFromApi);
      } catch (error) {
        console.error('Error making API request:', error);
        if (error.response) {
          /////console.log(error.response);

          // Check if there is a specific error message for password_old
          if (error.response.data && error.response.data.error_password_old) {
            const errorMessage = error.response.data.error_password_old;
            setAlertMessage(errorMessage);
            setAlertSeverity('error');
          }
          else if (error.response && error.response.data && error.response.data.error) {
            const validationErrors = error.response.data.error;
            const errorMessage = Object.keys(validationErrors)
              .map((field, index) => `- ${validationErrors[field][0]}`)
              .join('\n');
            setAlertMessage(errorMessage);
            setAlertSeverity('error');
          }

        }
      }
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };




  useEffect(() => {
    if (!loadingauth) {
      if (
        IDetablissement === null ||
        IDDFEP === null ||
        IDCandidat === null
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/');
        return;
      }
      if(Eval_notes_active_Mfep !==null){
        //alert(Eval_notes_active_Mfep);
      }

    }
  }, [IDetablissement, IDDFEP, IDNature_etsF, IDCandidat, crypted_id, NumSem, IDapis_userslist,
    loadingauth, navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }

  

  const rows = [
    { label: "رقم التسجيل", value: candata?.NumIns ?? '' },

    {
      label: "الجنس",
      value: candata?.Civ === 1 ? "ذكر" : candata?.Civ === 2 ? "أنثى" : ''
    },

    {
      label: "ذوي الاحتياجات الخاصة",
      value: candata?.endicape === 1 ? "نعم" : candata?.endicape === 0 ? "لا" : ''
    },
    { label: "تاريخ الميلاد", value: candata?.DateNais?? '' },
    { label: "المكان", value: candata?.LieuNais ?? '' },
    { label: "العنوان", value: candata?.Adres ?? '' },
    { label: "رقم عقد الميلاد", value: candata?.NumActeNais ?? '' },
    { label: "رقم التعريف الوطني", value: candata?.Nin ?? '' },
    { label: "رقم الضمان الإجتماعي", value: candata?.Nss ?? '' },
    
  ];

  
  const rows2 = [
   
    { label: "المؤسسة", value: candata?.Nom_Et ?? '' },
    { label: "الولاية", value: candata?.libelleArabe_Wly_Dfep ?? '' },
    { label: "التخصص", value: candata?.Nom ?? '' },
    { label: "نمط التكوين", value: candata?.Mode_formation_Nom ?? '' },
    { label: "مدة التكوين من", value: `${candata?.DateDF ?? ''} إلى ${dayjs(candata?.DateFF).format('DD-MM-YYYY') ?? ''}` }, 
    { label: "رقم السداسي", value: candata?.NumSem ?? '' },
    { label: "السداسي من", value: `${candata?.DateD_sem ?? ''} إلى ${dayjs(candata?.DateF_sem).format('DD-MM-YYYY') ?? ''}` }

  ];



  return (
    <>

      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        crypted_id={crypted_id}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={download_cert_inscription_active_Dfep}
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={download_cert_inscription_active_Mfep}
      />

<div id="root_img">

      <Box sx={{ mt: 0, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {loading && <CircularProgress />}
      </Box>


      <Grid item sx={{ width: "100%" }}>
                    <Divider sx={{ mt: "5px" }}>
                      <Grid item>
                        <Typography
                          style={{
                            color: "#004d40",
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 25,
                            fontWeight: 700,
                            marginBottom: "25px",
                          }}
                          variant="h3"
                          gutterBottom
                        >
                          <PersonIcon sx={{ mr: "5px" }} />
                          ملف المتكون
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            fontFamily: '"Tajawal", sans-serif',
                            color: "#004d40",
                            fontSize: 12,
                            fontWeight: 'bold',
                            mt: 1,
                          }}
                        >
                          {EndTimeupdatedb && (
                            <>
                             آخر تحديث: {formatArabicDate(EndTimeupdatedb)}

                            </>
                          )}
                        </Typography>

                      </Grid>
                    </Divider>
                  </Grid>




                  <TableContainer
  component={Paper}
  sx={{
    width: '90%', // Adjusted to be more responsive
    maxWidth: '1200px', // Maximum width for large screens
    margin: '0 auto',  // Centering the container
    overflowX: 'auto', // Enable horizontal scrolling on small screens
  }}
>
  
 
    

   

  <Table>
    <TableBody>
     
    <TableRow key={1}>
        <TableCell
          sx={{
            textAlign: 'right',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#004d40',
            borderRight: '1px solid #a5d6a7',
            color: '#FFFFFF',
            whiteSpace: 'nowrap',
            
          }}
        >
          اللقب
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'right',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
          }}
        >
          {candata?.Nom_Ca ?? ''}
        </TableCell>
      </TableRow>

      <TableRow key={2}>
        <TableCell
          sx={{
            textAlign: 'right',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#004d40',
            borderRight: '1px solid #a5d6a7',
            color: '#FFFFFF',
            whiteSpace: 'nowrap',
             
          }}
        >
          الإسم
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'right',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
          }}
        >
          {candata?.Prenom ?? ''}
        </TableCell>
      </TableRow>
</TableBody>
</Table>

      <Table>
      <TableBody>

      <TableRow key={3}>
      <TableCell
          sx={{
            textAlign: 'left',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
          }}
        >
          {candata?.NomFr ?? ''}
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'left',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#004d40',
            borderLeft: '1px solid #a5d6a7',
            color: '#FFFFFF',
            whiteSpace: 'nowrap',
            
            
          }}
        >
          Nom
        </TableCell>
       
      </TableRow>

      <TableRow key={4}>
      <TableCell
          sx={{
            textAlign: 'left',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
          }}
        >
          {candata?.PrenomFr ?? ''}
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'left',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#004d40',
            borderLeft: '1px solid #a5d6a7',
            color: '#FFFFFF',
            whiteSpace: 'nowrap',
            
          }}
        >
          Prénom
        </TableCell>
      
      </TableRow>
      </TableBody>
      </Table>

      <Table>
      <TableBody>
      {rows.map((row, index) => (
        <TableRow key={index}>
          <TableCell
            sx={{
              textAlign: 'right',
              fontFamily: 'Cairo',
              fontSize: '16px',
              fontWeight: 'bold',
              backgroundColor: '#004d40',
              borderRight: '1px solid #a5d6a7',
              color: '#FFFFFF',
              whiteSpace: 'nowrap', // Prevent text wrapping
            }}
          >
            {row.label}
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'right',
              fontFamily: 'Cairo',
              fontSize: '16px',
              fontWeight: 'bold',
              width: '100%', // Takes the remaining space
            }}
          >
            {row.value}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>

  <Table>
    <TableBody>
     
    <TableRow key={1}>
        <TableCell
          sx={{
            textAlign: 'right',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#004d40',
            borderRight: '1px solid #a5d6a7',
            color: '#FFFFFF',
            whiteSpace: 'nowrap',
            
          }}
        >
          إسم الأب
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'right',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
          }}
        >
          {candata?.PrenomPere ?? ''}
        </TableCell>
      </TableRow>

     
</TableBody>
</Table>

<Table>
      <TableBody>

      <TableRow key={3}>
      <TableCell
          sx={{
            textAlign: 'left',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
          }}
        >
          {candata?.PrenomPereFr ?? ''}
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'left',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#004d40',
            borderLeft: '1px solid #a5d6a7',
            color: '#FFFFFF',
            whiteSpace: 'nowrap',
            
            
          }}
        >
          Prénom Pere
        </TableCell>
       
      </TableRow>

      </TableBody>
      </Table>

     

    <Table>
    <TableBody>
     
    <TableRow key={1}>
        <TableCell
          sx={{
            textAlign: 'right',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#004d40',
            borderRight: '1px solid #a5d6a7',
            color: '#FFFFFF',
            whiteSpace: 'nowrap',
            
          }}
        >
          لقب الأم
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'right',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
          }}
        >
          {candata?.NomMere ?? ''}
        </TableCell>
      </TableRow>

      <TableRow key={2}>
        <TableCell
          sx={{
            textAlign: 'right',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#004d40',
            borderRight: '1px solid #a5d6a7',
            color: '#FFFFFF',
            whiteSpace: 'nowrap',
             
          }}
        >
          إسم الأم
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'right',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
          }}
        >
          {candata?.PrenomMere ?? ''}
        </TableCell>
      </TableRow>
</TableBody>
</Table>

      <Table>
      <TableBody>

      <TableRow key={3}>
      <TableCell
          sx={{
            textAlign: 'left',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
          }}
        >
          {candata?.NomMereFr ?? ''}
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'left',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#004d40',
            borderLeft: '1px solid #a5d6a7',
            color: '#FFFFFF',
            whiteSpace: 'nowrap',
            
            
          }}
        >
          Nom Mere
        </TableCell>
       
      </TableRow>

      <TableRow key={4}>
      <TableCell
          sx={{
            textAlign: 'left',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
          }}
        >
          {candata?.PrenomMereFr ?? ''}
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'left',
            fontFamily: 'Cairo',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#004d40',
            borderLeft: '1px solid #a5d6a7',
            color: '#FFFFFF',
            whiteSpace: 'nowrap',
            
          }}
        >
          Prénom Mere
        </TableCell>
      
      </TableRow>
      </TableBody>
      </Table>

      <Table>
      <TableBody>
      {rows2.map((row, index) => (
        <TableRow key={index}>
          <TableCell
            sx={{
              textAlign: 'right',
              fontFamily: 'Cairo',
              fontSize: '16px',
              fontWeight: 'bold',
              backgroundColor: '#004d40',
              borderRight: '1px solid #a5d6a7',
              color: '#FFFFFF',
              whiteSpace: 'nowrap', // Prevent text wrapping
            }}
          >
            {row.label}
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'right',
              fontFamily: 'Cairo',
              fontSize: '16px',
              fontWeight: 'bold',
              width: '100%', // Takes the remaining space
            }}
          >
            {row.value}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>

</TableContainer>


          

        </div>
<br/>
      <Footer />
    </>

  );
}

export default InfoCand;
