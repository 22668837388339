import React, { useState, useEffect, useRef } from 'react';

import { Typography, Box } from '@mui/material';
import axios from 'axios';
import LoopIcon from '@mui/icons-material/Loop';

import { InputLabel, Select, MenuItem } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
   
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { arSD } from '@mui/x-data-grid/locales';

import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { CacheProvider } from "@emotion/react";

import Checkbox from '@mui/material/Checkbox';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import XLSX from 'sheetjs-style';
import Footer from './Footer';
import Header from './Header';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import createCache from "@emotion/cache";

import '../containers/amirifont.css'; // Import the font CSS file
import Divider from '@mui/material/Divider';
import LockIcon from '@mui/icons-material/Lock';
import { makeStyles } from "@material-ui/core/styles";
import { TheatersSharp } from '@mui/icons-material';

import useAuthentication_etab from './useAuthentication_etab';
import LinearProgress from '@mui/material/LinearProgress';


const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
}, arSD);

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({

  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

async function loginUser(email, MotDePass) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, MotDePass: MotDePass }),
    });

    if (!response.ok) {
      throw new Error('Invalid credentials');
    }

    const data = await response.json();
    const token = data.token;
    return token;
  } catch (error) {
    console.error('Error logging in:', error.message);
    return null;
  }
}


const StyledBox = styled('div')(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));

let rowsdata = [];
let promiseTimeout;
let options_Wly = [];
let options_sess = [];
let options_specialite = [];
let options_bran = [];
function validateName(username) {

  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only alphabets (a to z).');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only Arabic alphabets.');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}


const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}



function CustomToolbar({ setRows, setRowModesModel, handleExportExcel, handleAddRecord }) {
  return (


    <GridToolbarContainer style={{ fontFamily: 'Amiri', fontSize: '20px' }} >
      <Button
        onClick={handleAddRecord}
        variant="contained"
        endIcon={<AddCircleOutlineIcon sx={{ fontSize: 40 }} />}
        style={{ backgroundColor: 'green', width: 'fit-content' }}
      >
        <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 24, fontWeight: 900 }}>
          إضافة
        </Typography>
      </Button>

      <GridToolbarColumnsButton style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <GridToolbarFilterButton style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <GridToolbarDensitySelector style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <Button style={{ fontFamily: 'Amiri', fontSize: '20px' }} onClick={handleExportExcel} color="primary">
        تنزيل كملف إكسال
      </Button>


      <GridToolbarExport style={{ fontFamily: 'Amiri', fontSize: '20px' }} />

      <GridToolbarQuickFilter
        style={{ fontFamily: 'Amiri', fontSize: '20px', marginRight: '25px' }}
        inputProps={{ style: { fontFamily: 'Amiri', fontSize: '20px' } }}
      />



    </GridToolbarContainer>
  );
}




function SessionsMfep() {

  const { IDetablissement, IDDFEP, IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  } = useAuthentication_etab();


  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState('');
  const [loadingdata_mfep, setloadingdata_mfep] = useState(false);

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState('');
  const [valuegenpw_nomUser, setvaluegenpw_nomUser] = useState('');


  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);

  const [privilege_users_data, setprivilege_users_data] = useState([]);
  const [selectedID_privilege_users, setselectedID_privilege_users] = useState('');
  const [selectedOption_session, setSelectedOption_session] = useState(null);
  const [selectedOption_branche, setSelectedOption_branche] = useState(null);
  const [selectedOption_spec, setSelectedOption_spec] = useState(null);

  const [options_session, setoptions_session] = React.useState([]);
  const [options_branche, setoptions_branche] = React.useState([]);

  const editedValueRef = useRef(editedValue);
  const [IDDFEP_selected, setIDDFEP_selected] = useState(1);
  const [IDsession_selected, setIDsession_selected] = useState(0);
  const [IDBranche_selected, setIDBranche_selected] = useState(1);
  const [IDSpecialite_selected, setIDSpecialite_selected] = useState(0);
  // Define options_specrow state to store specialite options for each row
  const [options_specrow, setOptionsSpecRow] = useState({});
  const [valueDateNais, setvalueDateNais] = useState('');
  const handleInput_DateNais = (event) => {
    const inputValue = event.target.value;
    setvalueDateNais(inputValue);
  };


  // Function to update specialite options for a specific row
  const handleSpecialiteOptionsChange = (rowId, options) => {
    setOptionsSpecRow(prevOptions => ({
      ...prevOptions,
      [rowId]: options
    }));
  };


  const useStyles_listdfep = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily:
          '"Tajawal", sans-serif',
        fontSize: 18,
        direction: "rtl"
      }
    },
    inputRoot: {

      color: "#004d40",


      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif',
        fontWeight: 700,
        color: "#004d40",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#004d40"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#00796b"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#004d00"
      }
    }
  }));

  const classes_listdfep = useStyles_listdfep();





  const onwlyChanged_session = (event, values) => {

    if (values) {
      setSelectedOption_session(values);
      setIDsession_selected(values.value);
    } else {
      return;
    }


  }

  const onwlyChanged_branche = (event, values) => {

    if (values) {
      setSelectedOption_branche(values);
      setIDBranche_selected(values.value);
    } else {
      return;
    }


  }


  const onwlyChanged_spec = (event, values) => {

    if (values) {
      setSelectedOption_spec(values);
      setIDSpecialite_selected(values.value);
    } else {
      return;
    }


  }

  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);

  const navigate = useNavigate();




  const apiUrl_session = `${API_BASE_URL}/api/getlistofallsessionMfep`;

  // Fetch data from the API when the component mounts
  useEffect(() => {


    setloadingdata_mfep(true);
     
    fetch(apiUrl_session,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assuming 'data' is an array of objects
        const Wlylists = data.data; // Extracting the relevant data

        /////////////Wilayets///////////////////

        const WlyOnly = Wlylists.filter(Wlylist => Wlylist.Nom !== null);

        // Sorting in descending order (from A to Z)
        /* options_sess = WlyOnly.sort((a, b) => Number(a.IDSession) - Number(b.IDSession))
             .map((Wlylist, i) => ({
                 value: Wlylist.IDSession,
                 label: Wlylist.Nom + ' | ' + Wlylist.NomFr,
                 indexvalue: i
             }));*/


        // Sorting in descending order (from Z to A)
        options_sess = WlyOnly.sort((a, b) => Number(b.IDSession) - Number(a.IDSession))
          .map((Wlylist, i) => ({
            value: Wlylist.IDSession,
            label: Wlylist.Nom + ' | ' + Wlylist.NomFr,
            indexvalue: i
          }));

        ///// options_sess.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

        setoptions_session(options_sess);

        /// console.log('options_Wly[0].value:'+options_sess[0].value);
        setloadingdata_mfep(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });


  }, []);

  useEffect(() => {
    if (options_session && options_session.length > 0) {
      setSelectedOption_session(options_session[0]);
      setIDsession_selected(options_session[0].value);
    }

  }, [options_session]);



  const [options_spec, setoptions_spec] = React.useState([]);
  const [options_specall, setoptions_specall] = React.useState([]);
  const [options_mode_formation, setoptions_mode_formation] = React.useState([]);

  const apiUrl_branche = `${API_BASE_URL}/api/getlistofallbranche`;
  const apiUrl_spec = `${API_BASE_URL}/api/getlistofallspecialite`;

  const apiUrl_mode_formation = `${API_BASE_URL}/api/getlistofallmode_formation`;


  // Fetch data from the API when the component mounts
  useEffect(() => {


    setloadingdata_mfep(true);
    fetch(apiUrl_branche,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assuming 'data' is an array of objects
        const Wlylists = data.data; // Extracting the relevant data

        /////////////Wilayets///////////////////

        const WlyOnly = Wlylists.filter(Wlylist => Wlylist.Nom !== null);

        /*
        IDBranche
        Nom
        NomFr
        Code
        activee
        numod
        
        */

        // Sorting in descending order (from Z to A)
        options_bran = WlyOnly.sort((a, b) => Number(b.IDBranche) - Number(a.IDBranche))
          .map((Wlylist, i) => ({
            value: Wlylist.IDBranche,
            label: Wlylist.Code + ' | ' + Wlylist.Nom + ' | ' + Wlylist.NomFr,
            indexvalue: i
          }));

        ///// options_bran.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

        setoptions_branche(options_bran);

        /// console.log('options_Wly[0].value:'+options_bran[0].value);

      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });

    /////////////////
    
    fetch(apiUrl_spec,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assuming 'data' is an array of objects
        const Wlylists = data.data; // Extracting the relevant data
        //// console.log('specialite: ',data.data);
        /////////////Wilayets///////////////////
        setoptions_specall(Wlylists);

        const WlyOnly = Wlylists.filter(Wlylist => Wlylist.IDSpecialite !== null);

        // Sorting in descending order (from Z to A)
        options_specialite = WlyOnly.sort((a, b) => Number(b.IDSpecialite) - Number(a.IDSpecialite))
          .map((Wlylist, i) => ({

            value: Wlylist.IDSpecialite,
            label: Wlylist.CodeSpec + ' | ' + Wlylist.Nom + ' | ' + Wlylist.NomFr,
            indexvalue: i
          }));

        ///// options_spec.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

        setoptions_spec(options_specialite);

        /// console.log('options_Wly[0].value:'+options_spec[0].value);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });



    /////////////////
    fetch(apiUrl_mode_formation,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assuming 'data' is an array of objects
        const Wlylists = data.data; // Extracting the relevant data
        //// console.log('specialite: ',data.data);
        /////////////Wilayets///////////////////
        setoptions_mode_formation(Wlylists);

        setloadingdata_mfep(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });




  }, []);



  // Fetch data from the API when the component mounts
  useEffect(() => {
    let option_sp = options_specall.filter(listsp => listsp.IDBranche === IDBranche_selected);
    let filteredOptions = option_sp.map((Wlylist, i) => ({
      value: Wlylist.IDSpecialite,
      label: Wlylist.CodeSpec + ' | ' + Wlylist.Nom + ' | ' + Wlylist.NomFr,
      indexvalue: i
    }));
    setoptions_spec(filteredOptions);
  }, [IDBranche_selected, options_specall]);




  const apiUrl = `${API_BASE_URL}/api/getlistofallsessionMfep`;

  // Fetch data from the API when the component mounts
  React.useEffect(() => {

     

    setloadingdata_mfep(true);
    setRows([]); // Clear rows before setting new rows

    fetch(apiUrl,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then((response) => response.json())
      .then((data) => {
        const apiRows = data.data.map((item, index) => ({

          id: item.IDSession,
          id_rows: index + 1,
          IDSession: item.IDSession,
          Nom: item.Nom,
          NomFr: item.NomFr,
          Encour: item.Encour,
          Clouture: item.Clouture,
          DateD: item.DateD,
          DateDInscr: item.DateDInscr,
          DateFInscr: item.DateFInscr,
          JourDeSelection: item.JourDeSelection,
          DateConcour: item.DateConcour,
          DateAffResulta: item.DateAffResulta,
          Code: item.Code,

        }));
        setRows(apiRows);
        /// console.log('apiRows:',apiRows);


        setloadingdata_mfep(false);


      })
      .catch((error) => console.error('Error fetching data:', error));


  }, []);


  const renderEditCellWithFontSize = (params, fontSize) => {
    return (
      <TextField
        value={params.value}
        onChange={(e) => params.api.setValue(params.id, e.target.value)}
        style={{ fontSize: fontSize }} // Apply the specified font size here
      />
    );
  };


  // Define state variable to store selected IDs for each Select component
  const [selectedIDs, setSelectedIDs] = useState({});
  const [selectedBranches, setSelectedBranches] = useState({});

  const handleBrancheChange = (idRows, value) => {

    // Update the rows state
    setRows(prevRows => {
      const updatedRows = prevRows.map(row => {
        if (row.id === idRows) {
          // Check if value is not null or undefined before accessing its value property
          const newValue = value !== null && value !== undefined ? value.value : null;
          return { ...row, IDBranche: newValue };
        }
        return row;
      });
      return updatedRows;
    });




    setSelectedBranches(prevState => ({
      ...prevState,
      [idRows]: value !== null && value !== undefined ? value : null,
    }));
  };

  const [selectedSpecialite, setSelectedSpecialite] = useState({});

  const handlespecialiteChange = (idRows, value) => {

    // Update the rows state
    setRows(prevRows => {
      const updatedRows = prevRows.map(row => {
        if (row.id === idRows) {
          const newValue = value !== null && value !== undefined ? value.value : null;
          return { ...row, IDSpecialite: newValue };
        }
        return row;
      });

      // Alert the updatedRow here, inside the setRows callback
      /// alert(`Row Data After Update: ${JSON.stringify(updatedRows.find(row => row.id === idRows))}`);

      return updatedRows;
    });

    setSelectedSpecialite(prevState => ({
      ...prevState,
      [idRows]: value !== null && value !== undefined ? value : null,
    }));
  };



  const [selectedmformation, setSelectedmformation] = useState({});

  const handlemformationChange = (idRows, value) => {

    // Update the rows state
    setRows(prevRows => {
      const updatedRows = prevRows.map(row => {
        if (row.id === idRows) {
          const newValue = value !== null && value !== undefined ? value.value : null;
          return { ...row, IDMode_formation: newValue };
        }
        return row;
      });
      return updatedRows;
    });



    setSelectedmformation(prevState => ({
      ...prevState,
      [idRows]: value !== null && value !== undefined ? value : null,
    }));
  };

  const handleInput_DateNaisss = (event) => {
    const inputValue = event.target.value;
    alert(inputValue);
  };

  ///DateD
  const [selectedDateD, setSelectedDateD] = useState({});

  const handleDateDChange = (idRows, newValue) => {

    // Update the rows state
    setRows(prevRows => {
      const updatedRows = prevRows.map(row => {
        if (row.id === idRows) {

          return { ...row, DateD: newValue };
        }
        return row;
      });
      return updatedRows;
    });




    setSelectedDateD(prevState => ({
      ...prevState,
      [idRows]: newValue,
    }));
  };

  ///DateDInscr


  const [selectedDateDInscr, setSelectedDateDInscr] = useState({});

  const handleDateDInscrChange = (idRows, newValue) => {
    // Update the rows state
    setRows(prevRows => {
      const upDateDInscrRows = prevRows.map(row => {
        if (row.id === idRows) {

          return { ...row, DateDInscr: newValue };
        }
        return row;
      });
      return upDateDInscrRows;
    });



    setSelectedDateDInscr(prevState => ({
      ...prevState,
      [idRows]: newValue,
    }));
  };



  ///DateFInscr


  const [selectedDateFInscr, setSelectedDateFInscr] = useState({});

  const handleDateFInscrChange = (idRows, newValue) => {
    // Update the rows state
    setRows(prevRows => {
      const upDateFInscrRows = prevRows.map(row => {
        if (row.id === idRows) {

          return { ...row, DateFInscr: newValue };
        }
        return row;
      });
      return upDateFInscrRows;
    });



    setSelectedDateFInscr(prevState => ({
      ...prevState,
      [idRows]: newValue,
    }));
  };


  ///JourDeSelection


  const [selectedJourDeSelection, setSelectedJourDeSelection] = useState({});

  const handleJourDeSelectionChange = (idRows, newValue) => {
    // Update the rows state
    setRows(prevRows => {
      const upJourDeSelectionRows = prevRows.map(row => {
        if (row.id === idRows) {

          return { ...row, JourDeSelection: newValue };
        }
        return row;
      });
      return upJourDeSelectionRows;
    });



    setSelectedJourDeSelection(prevState => ({
      ...prevState,
      [idRows]: newValue,
    }));
  };


  ///DateConcour


  const [selectedDateConcour, setSelectedDateConcour] = useState({});

  const handleDateConcourChange = (idRows, newValue) => {
    // Update the rows state
    setRows(prevRows => {
      const upDateConcourRows = prevRows.map(row => {
        if (row.id === idRows) {

          return { ...row, DateConcour: newValue };
        }
        return row;
      });
      return upDateConcourRows;
    });


    setSelectedDateConcour(prevState => ({
      ...prevState,
      [idRows]: newValue,
    }));
  };

  ///DateAffResulta


  const [selectedDateAffResulta_d, setSelectedDateAffResulta] = useState({});

  const handleDateAffResultaChange = (idRows, newValue) => {
    // Update the rows state
    setRows(prevRows => {
      const upDateAffResultaRows = prevRows.map(row => {
        if (row.id === idRows) {

          return { ...row, DateAffResulta: newValue };
        }
        return row;
      });
      return upDateAffResultaRows;
    });



    setSelectedDateAffResulta(prevState => ({
      ...prevState,
      [idRows]: newValue,
    }));
  };





  // Handler to update selected ID for a specific Select component
  const handleChange_privilege_users = (event, index, id) => {
    const newID = event.target.value;

    // Update selectedIDs state
    setSelectedIDs(prevState => ({
      ...prevState,
      [id]: newID,
    }));



    /* // Update the corresponding row in the rows state with the new ID
   setRows(prevRows => {
     const updatedRows = prevRows.map(row => {
       if (row.id === id) {
         return { ...row, IDprivilege_users: newID };
       }
       return row;
     });
     
     // Alert the updatedRow here, inside the setRows callback
     alert(`Row Data After Update: ${JSON.stringify(updatedRows.find(row => row.id === id))}`);
     
     return updatedRows;
   });*/




  };


  // Render EditCell for privilege_users
  const renderEditCell_privilege_users = (params) => {
    /// //console.log("selectedIDs[params.value]",selectedIDs[params.value]);
    /////console.log("selectedIDs[params.value]",params.id);
    return (
      <div>
        <FormControl fullWidth>
          <Select
            label={`Select NomAr ${params.id}`}
            sx={{
              fontFamily: 'Amiri',
              fontSize: '18px',
              fontWeight: 'bold',
            }}
            value={selectedIDs[params.id] || params.value} // Get value from selectedIDs based on params.value
            onChange={(event) => handleChange_privilege_users(event, params.value, params.id)} // Pass params.value to handleChange_privilege_users
          >
            {privilege_users_data.map(item => (
              <MenuItem
                sx={{
                  fontFamily: 'Amiri',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}

                key={item.ID} value={item.ID}>{item.NomAr}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  // Render Cell for privilege_users
  const renderCell_privilege_users = (params) => {
    const rowData = privilege_users_data.find(item => Number(item.ID) === Number(params.value));
    return (
      <div>
        {rowData ? rowData.NomAr : ''}
      </div>
    );
  };



  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.commit) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);

      if (editedRow) {
        /// alert(`Editing stopped for ID: ${editedRow.id}`);
        // Trigger the save operation for the edited row
        ////handleSaveOperation(editedRow.id);


      }
    }
  };




  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };



  const [idsaved, setidsaved] = useState(0);


  const [confirmationTrigger, setConfirmationTrigger] = useState(false);



  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);



  const handleDeleteClick = (id) => () => {

    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { id } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      setDeletedRowId(id);


    }


  };




  const handleConfirmationResponse = async (confirmed) => {

    // Close the confirmation dialog
    setConfirmationOpen(false);
    if (confirmed) {
      const idToDelete = deletedRowId;
      setDeletedRowId(null); // Reset deletedRowId to avoid multiple deletions

      try {
        // Send the API request to delete the row from the Laravel backend
        const response = await fetch(`${API_BASE_URL}/api/deleteRow_fromsession/${idToDelete}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });

        if (response.ok) {
          // If the API request is successful, update the local state to remove the row
          setRows((prevRows) => prevRows.filter((row) => row.id !== idToDelete));
          ////////console.log('Row deleted successfully from Laravel backend.');
          setSnackbar({ children: '  تم الحذف بنجاح  ', severity: 'success' });

        } else {
          console.error('Failed to delete row from Laravel backend.');
          // Handle the error or display a notification to the user
        }
      } catch (error) {
        console.error('Error during the API request:', error);
        // Handle the error or display a notification to the user
      }
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const handleSaveConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setSaveConfirmationOpen(false);

    if (confirmed) {
      const idforsavea = idforsave;
      handleSaveOperation(idforsavea);
      setidforsave(null);
    } else {
      // User clicked "Cancel," do nothing
    }
  };



  const processRowUpdate = async (newRow) => {



    // Check if the row is new
    const isNewRow = rows.find((row) => row.id === newRow.id)?.isNew;

    // Preserve the original isNew value if it exists
    const updatedRow = { ...newRow, isNew: isNewRow };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Display an alert with the updated row data and id
    ///alert(`Row Data After Update for ID ${newRow.id}: ${JSON.stringify(newRow)}`);

    try {

      // Call handleSaveOperation with the id
      await handleSaveOperation(newRow.id, updatedRow);
    } catch (error) {
      console.error('Error during handleSaveOperation:', error);
    }

    return updatedRow;
  };


  // Function to check if a string is a valid date
  function isValidDate(dateString) {
    // Attempt to parse the date
    const parsedDate = new Date(dateString);

    // Check if the parsed date is a valid date
    return !isNaN(parsedDate.getTime());
  }


  const handleSaveOperation = async (id, updatedRow) => {


    let new_selectedDateD = "0000-00-00";
    if (selectedDateD[id] !== undefined) {
      new_selectedDateD = selectedDateD[id];
    } else if (updatedRow && updatedRow.DateD) {
      new_selectedDateD = updatedRow.DateD;
    }

    let new_selectedDateDInscr = "0000-00-00";
    if (selectedDateDInscr[id] !== undefined) {
      new_selectedDateDInscr = selectedDateDInscr[id];
    } else if (updatedRow && updatedRow.DateDInscr) {
      new_selectedDateDInscr = updatedRow.DateDInscr;
    }


    let new_selectedDateFInscr = "0000-00-00";
    if (selectedDateFInscr[id] !== undefined) {
      new_selectedDateFInscr = selectedDateFInscr[id];
    } else if (updatedRow && updatedRow.DateFInscr) {
      new_selectedDateFInscr = updatedRow.DateFInscr;
    }



    let new_selectedJourDeSelection = "0000-00-00";
    if (selectedJourDeSelection[id] !== undefined) {
      new_selectedJourDeSelection = selectedJourDeSelection[id];
    } else if (updatedRow && updatedRow.JourDeSelection) {
      new_selectedJourDeSelection = updatedRow.JourDeSelection;
    }



    let new_selectedDateConcour = "0000-00-00";
    if (selectedDateConcour[id] !== undefined) {
      new_selectedDateConcour = selectedDateConcour[id];
    } else if (updatedRow && updatedRow.DateConcour) {
      new_selectedDateConcour = updatedRow.DateConcour;
    }



    let new_selectedDateAffResulta = "0000-00-00";
    if (selectedDateAffResulta_d[id] !== undefined) {
      new_selectedDateAffResulta = selectedDateAffResulta_d[id];
    } else if (updatedRow && updatedRow.DateAffResulta) {
      new_selectedDateAffResulta = updatedRow.DateAffResulta;
    }

    const startDate = dayjs(new_selectedDateDInscr);
    const endDate = dayjs(new_selectedDateFInscr);

    // Calculate the difference in days
    const numberOfDays = endDate.diff(startDate, 'day');


    if (isEmpty(new_selectedDateD) || !isValidDate(new_selectedDateD)) {
      setSnackbar({ children: 'حدد تاريخ البداية', severity: 'error' });

      return false;
    }

    /*
      if (isEmpty(new_selectedDateDInscr) || !isValidDate(new_selectedDateDInscr)) {
       setSnackbar({ children: 'حدد تاريخ البداية', severity: 'error' });
  
        return false;
      }
 
      if (isEmpty(new_selectedDateFInscr) || !isValidDate(new_selectedDateFInscr)) {
       setSnackbar({ children: 'حدد تاريخ النهاية', severity: 'error' });
 
       return false;
     }
 
      if (numberOfDays<7) {
       setSnackbar({ children: 'مدة التسجيلات غير صحيحة', severity: 'error' });
  
      return false;
    }
 
 
    if (isEmpty(new_selectedJourDeSelection) || !isValidDate(new_selectedJourDeSelection)) {
     setSnackbar({ children: 'حدد تاريخ الإنتقاء', severity: 'error' });
 
     return false;
   }
 
   
   if (isEmpty(new_selectedDateConcour) || !isValidDate(new_selectedDateConcour)) {
     setSnackbar({ children: 'حدد تاريخ الإمتحان', severity: 'error' });
 
     return false;
   }
 
   if (isEmpty(new_selectedDateAffResulta) || !isValidDate(new_selectedDateAffResulta)) {
     setSnackbar({ children: 'حدد تاريخ التوجيه', severity: 'error' });
 
     return false;
   }
 */



    const editedRow = { ...updatedRow };

    // Check if DateD is a valid date
    if (new_selectedDateD !== "0000-00-00" && !isNaN(Date.parse(new_selectedDateD))) {
      editedRow.DateD = new_selectedDateD;
    }

    // Repeat the same process for other date fields
    // DateDInscr
    if (new_selectedDateDInscr !== "0000-00-00" && !isNaN(Date.parse(new_selectedDateDInscr))) {
      editedRow.DateDInscr = new_selectedDateDInscr;
    }

    // DateFInscr
    if (new_selectedDateFInscr !== "0000-00-00" && !isNaN(Date.parse(new_selectedDateFInscr))) {
      editedRow.DateFInscr = new_selectedDateFInscr;
    }

    // JourDeSelection
    if (new_selectedJourDeSelection !== "0000-00-00" && !isNaN(Date.parse(new_selectedJourDeSelection))) {
      editedRow.JourDeSelection = new_selectedJourDeSelection;
    }

    // DateConcour
    if (new_selectedDateConcour !== "0000-00-00" && !isNaN(Date.parse(new_selectedDateConcour))) {
      editedRow.DateConcour = new_selectedDateConcour;
    }

    // DateAffResulta
    if (new_selectedDateAffResulta !== "0000-00-00" && !isNaN(Date.parse(new_selectedDateAffResulta))) {
      editedRow.DateAffResulta = new_selectedDateAffResulta;
    }




    ///alert(`Row Data After Update: ${JSON.stringify(editedRow)}`);
    ///console.log(`Row Data After Update: ${JSON.stringify(editedRow)}`);

    try {
      let response;


      // If the row is not new, it means it's already in the database, so update it
      response = await fetch(`${API_BASE_URL}/api/updateRowsessionmfep/${id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', // Specify the content type
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        },
        body: JSON.stringify(editedRow),
      });


      if (response.ok) {
        const jsonData = await response.json();
        console.log('Response JSON Data:', jsonData);

        // Check if 'Session_table' exists in the JSON response
        if (jsonData && jsonData.Session_table) {
            const updatedRowData = jsonData.Session_table;
    
            // Update the row in the data grid with the new values
            setRows((prevRows) => {
                return prevRows.map((row) => {
                    // Check if the row id matches the id of the updated row
                    if (row.id === id) {
                        // Update the row with the new values
                        return {
                            ...row,
                            id:updatedRowData.IDSession,
                            IDSession: updatedRowData.IDSession,
                            Nom: updatedRowData.Nom,
                            NomFr: updatedRowData.NomFr,
                            Encour: updatedRowData.Encour,
                            Clouture: updatedRowData.Clouture,
                            DateD: updatedRowData.DateD,
                            DateDInscr: updatedRowData.DateDInscr,
                            DateFInscr: updatedRowData.DateFInscr,
                            JourDeSelection: updatedRowData.JourDeSelection,
                            DateConcour: updatedRowData.DateConcour,
                            DateAffResulta: updatedRowData.DateAffResulta,
                            Code: updatedRowData.Code,
                        };
                    }
                    return row;
                });
            });
    
            // Set the snackbar message
            setSnackbar({ children: '  تم الحفظ  ', severity: 'success' });
    
            // Set the row mode to view after saving
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [updatedRowData.id]: { mode: GridRowModes.View }
            }));
        } else {
            // Handle the case where 'Session_table' doesn't exist in the response
            console.error('Session_table not found in JSON response');
        }
    }
     else {
        console.error('Failed to save record in the database.');

        // Extract validation error messages from the API response
        const errorData = await response.json();
        const validationErrors = errorData?.error;

        // Concatenate validation error messages
        const errorMessage =
          'الأخطاء:' +
          Object.values(validationErrors || {}).join(', ');

        console.error('errorMessage:', errorMessage);

        setSnackbar({ children: validationErrors, severity: 'error' });

      }
    } catch (error) {
      console.error('Error during the API request:', error);

      // Set the snackbar message for the error
      setSnackbar({ children: 'حدث خطأ', severity: 'error' });
    }

  };

  const SaveConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };





  const [addRecordClicked, setAddRecordClicked] = useState(false);

  useEffect(() => {
    if (addRecordClicked) {
      handleAddRecord();
      setAddRecordClicked(false);
    }
  }, [addRecordClicked]);

  const handleAddClick = () => {
    setIsAdding(true);
    setAddRecordClicked(true);
  };



const handleAddRecord = () => {
  const id = uuidv4();

  // Create the new empty row
  const emptyRow = {
    id: id,
    IDSession: '',
    Nom: '',
    NomFr: '',
    Encour: 0,
    Clouture: 1,
    DateD: '',
    DateDInscr: '',
    DateFInscr: '',
    JourDeSelection: '',
    DateConcour: '',
    DateAffResulta: '',
    Code: '',
    actions: '',
    isNew: true,
  };

  // Add the new row to the beginning of the rows array
  setRows((oldRows) => [emptyRow, ...oldRows]);

  // Set the row mode for the new row
  setRowModesModel((oldModel) => ({
    ...oldModel,
    [id]: { mode: GridRowModes.Edit, fieldToFocus: 'Nom' },
  }));
};




  const handleRowModesModelChange = (newRowModesModel) => {

    setRowModesModel(newRowModesModel);
  };



  /*

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  
    // Check if a row is in edit mode
    const editedRowId = Object.keys(newRowModesModel).find(
      (id) => newRowModesModel[id]?.mode === GridRowModes.Edit
    );
  
    // If a row is in edit mode, trigger the save operation
    if (editedRowId) {
      handleSaveOperation(editedRowId);
    }
  };

*/
  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };


  const useStyles = makeStyles({
    cancelBtn: {
      backgroundColor: "#004d40", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const ConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>


          <Dialog
            open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider >
                <Grid item sx={{ mt: 2 }} >
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحذف <DeleteIcon sx={{ ml: '0px' }} />
                  </Typography>
                </Grid>
              </Divider>


            </DialogTitle>



            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                هل أنت متأكد من أنك تريد حذف هذا السطر؟

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#004d40", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      </CacheProvider>
    );
  };


  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map(col => ({ name: col.field, headerName: col.headerName }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
      const newRow = {};
      relevantColumns.forEach(col => {
        newRow[col.headerName] = row[col.name];
      });
      return newRow;
    }));

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  /*
  
  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows, {
      header: columns.map((col) => col.headerName),
    });
  
    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };
  
    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };*/

  const handleSaveClick = (id, row) => () => {

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    //// handleSaveOperation(row.id,row);


  };

  /*
   id: item.IDSession,
          id_rows: index + 1,
          IDSession: item.IDSession,
          Nom: item.Nom,
          NomFr: item.NomFr,
          Encour: item.Encour,
          Clouture: item.Clouture,
          DateD: item.DateD,
          DateDInscr: item.DateDInscr,
          DateFInscr: item.DateFInscr,
          JourDeSelection: item.JourDeSelection,
          DateConcour: item.DateConcour,
          DateAffResulta: item.DateAffResulta,
          Code: item.Code,       */


  const columns = [

    {
      field: 'id_rows',
      headerName: 'ترقيم',
      minWidth: 40,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'IDSession',
      headerName: 'IDSession',
      minWidth: 55,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'Nom',
      headerName: 'Nom',
      flex: 1,
      editable: true,
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

    },

    {
      field: 'NomFr',
      headerName: 'NomFr',
      flex: 1,
      editable: true,
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'right',
      headerAlign: 'center',

    },

    {
      field: 'Code',
      headerName: 'Code',
      flex: 1,
      editable: true,
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'right',
      headerAlign: 'center',

    },

    {
      field: 'Encour',
      headerName: 'Encour',
      minWidth: 140,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',

      renderCell: ({ row }) => {
        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
        if (row && isIntegerNumber(row.IDSession) && !isInEditMode) {
          return (
            <FormControlLabel
              control={<IOSSwitch
                sx={{ m: 1 }}
                checked={Number(row.Encour) === 1 ? true : false}
                onChange={() => onchangeEncour(row)}
              />}
              label={<span style={{
                color: "#004d40",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 16,
                fontWeight: 900,
              }}></span>}
            />
          );

        } else {
          return (
            <FormControlLabel
              control={<IOSSwitch
                sx={{ m: 1 }}
                checked={false}
                disabled={true}
              />}
              label={<span style={{
                color: "#004d40",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 16,
                fontWeight: 900,
              }}></span>}
            />
          );

        }

      }

    },


    {
      field: 'Clouture',
      headerName: 'Clouture',
      minWidth: 140,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',

      renderCell: ({ row }) => {
        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
        if (row && isIntegerNumber(row.IDSession) && !isInEditMode) {
          return (
            <FormControlLabel
              control={<IOSSwitch
                sx={{ m: 1 }}
                checked={Number(row.Clouture) === 1 ? true : false}
                onChange={() => onchangeClouture(row)}
              />}
              label={<span style={{
                color: "#004d40",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 16,
                fontWeight: 900,
              }}></span>}
            />
          );

        } else {
          return (
            <FormControlLabel
              control={<IOSSwitch
                sx={{ m: 1 }}
                checked={false}
                disabled={true}
              />}
              label={<span style={{
                color: "#004d40",
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 16,
                fontWeight: 900,
              }}></span>}
            />
          );

        }

      }

    },


    {
      field: 'DateD',
      headerName: 'DateD',
      editable: true,
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',

      renderEditCell: (params) => {
        return (
          <Grid item xs={12}>
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                <Box>
                  <div dir="ltr">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          value={selectedDateD[params.row.id] !== undefined ? selectedDateD[params.row.id] : dayjs(params.value)}
                          onChange={(newValue) => {
                            const formattedDate = newValue.format("YYYY-MM-DD");
                            handleDateDChange(params.row.id, formattedDate);
                          }}
                          format="YYYY-MM-DD" // Set the desired format here
                          placeholder="YYYY-MM-DD"
                          sx={{
                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#004d40'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#004d40',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#004d40',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: "#00796b",
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: "#00796b",
                              },
                            }
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </Box>
              </ThemeProvider>
            </CacheProvider>
          </Grid>
        );
      },


    },


    {
      field: 'DateDInscr',
      headerName: 'DateDInscr',
      editable: true,
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderEditCell: (params) => {

        return (
          <Grid item xs={12}>
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                <Box>
                  <div dir="ltr">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          value={selectedDateDInscr[params.row.id] !== undefined ? selectedDateDInscr[params.row.id] : dayjs(params.value)}

                          onChange={(newValue) => {
                            const formattedDate = newValue.format("YYYY-MM-DD");
                            handleDateDInscrChange(params.row.id, formattedDate);
                            ///handleDateFChange(params.row.id, newValue);
                          }}
                          format="YYYY-MM-DD" // Set the desired format here
                          placeholder="YYYY-MM-DD"
                          sx={{
                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#004d40'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#004d40',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#004d40',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: "#00796b",
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: "#00796b",
                              },
                            }
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </Box>
              </ThemeProvider>
            </CacheProvider>
          </Grid>
        );
      },

    },


    {
      field: 'DateFInscr',
      headerName: 'DateFInscr',
      editable: true,
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderEditCell: (params) => {

        return (
          <Grid item xs={12}>
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                <Box>
                  <div dir="ltr">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          value={selectedDateFInscr[params.row.id] !== undefined ? selectedDateFInscr[params.row.id] : dayjs(params.value)}

                          onChange={(newValue) => {
                            const formattedDate = newValue.format("YYYY-MM-DD");
                            handleDateFInscrChange(params.row.id, formattedDate);
                            ///handleDateFChange(params.row.id, newValue);
                          }}
                          format="YYYY-MM-DD" // Set the desired format here
                          placeholder="YYYY-MM-DD"
                          sx={{
                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#004d40'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#004d40',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#004d40',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: "#00796b",
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: "#00796b",
                              },
                            }
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </Box>
              </ThemeProvider>
            </CacheProvider>
          </Grid>
        );
      },

    },


    {
      field: 'JourDeSelection',
      headerName: 'JourDeSelection',
      editable: true,
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderEditCell: (params) => {

        return (
          <Grid item xs={12}>
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                <Box>
                  <div dir="ltr">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          value={selectedJourDeSelection[params.row.id] !== undefined ? selectedJourDeSelection[params.row.id] : dayjs(params.value)}

                          onChange={(newValue) => {
                            const formattedDate = newValue.format("YYYY-MM-DD");
                            handleJourDeSelectionChange(params.row.id, formattedDate);
                            ///handleDateFChange(params.row.id, newValue);
                          }}
                          format="YYYY-MM-DD" // Set the desired format here
                          placeholder="YYYY-MM-DD"
                          sx={{
                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#004d40'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#004d40',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#004d40',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: "#00796b",
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: "#00796b",
                              },
                            }
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </Box>
              </ThemeProvider>
            </CacheProvider>
          </Grid>
        );
      },

    },



    {
      field: 'DateConcour',
      headerName: 'DateConcour',
      editable: true,
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderEditCell: (params) => {

        return (
          <Grid item xs={12}>
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                <Box>
                  <div dir="ltr">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          value={selectedDateConcour[params.row.id] !== undefined ? selectedDateConcour[params.row.id] : dayjs(params.value)}

                          onChange={(newValue) => {
                            const formattedDate = newValue.format("YYYY-MM-DD");
                            handleDateConcourChange(params.row.id, formattedDate);
                            ///handleDateFChange(params.row.id, newValue);
                          }}
                          format="YYYY-MM-DD" // Set the desired format here
                          placeholder="YYYY-MM-DD"
                          sx={{
                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#004d40'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#004d40',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#004d40',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: "#00796b",
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: "#00796b",
                              },
                            }
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </Box>
              </ThemeProvider>
            </CacheProvider>
          </Grid>
        );
      },

    },



    {
      field: 'DateAffResulta',
      headerName: 'DateAffResulta',
      editable: true,
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderEditCell: (params) => {

        return (
          <Grid item xs={12}>
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                <Box>
                  <div dir="ltr">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          value={selectedDateAffResulta_d[params.row.id] !== undefined ? selectedDateAffResulta_d[params.row.id] : dayjs(params.value)}

                          onChange={(newValue) => {
                            const formattedDate = newValue.format("YYYY-MM-DD");
                            handleDateAffResultaChange(params.row.id, formattedDate);
                            ///handleDateFChange(params.row.id, newValue);
                          }}
                          format="YYYY-MM-DD" // Set the desired format here
                          placeholder="YYYY-MM-DD"
                          sx={{
                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#004d40'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#004d40',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#004d40',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: "#00796b",
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: "#00796b",
                              },
                            }
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </Box>
              </ThemeProvider>
            </CacheProvider>
          </Grid>
        );
      },

    },


    {
      field: 'actions',
      type: 'actions',
      headerName: 'إجراءات',
      flex: 1,
      align: 'left',
      minWidth: 220,
      cellClassName: 'actions',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      getActions: ({ id, params, row }) => {

        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Button
              onClick={handleSaveClick(id, row)}
              variant="contained"
              endIcon={<SaveIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />}
              style={{ backgroundColor: 'green', width: 'fit-content' }}
            >
              <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
                حفظ
              </Typography>
            </Button>,
            <Button
              onClick={handleCancelClick(id)}
              variant="contained"
              endIcon={<CancelIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />}
              style={{ backgroundColor: 'grey', width: 'fit-content' }}
            >
              <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
                إلغاء
              </Typography>
            </Button>
          ];
        }

        return [
          <Button
            onClick={handleEditClick(id)}
            variant="contained"
            endIcon={<EditIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />}
            style={{ backgroundColor: '#4CAF50', width: 'fit-content' }}
          >
            <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
              تعديل
            </Typography>
          </Button>,
          <Button
            onClick={handleDeleteClick(id)}
            variant="contained"
            endIcon={<DeleteIcon sx={{ mr: '0px', ml: '0px', transform: 'rotate(0deg)' }} />}
            style={{ backgroundColor: 'red', width: 'fit-content' }}
          >
            <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
              حذف
            </Typography>
          </Button>
        ];

      },
    }

  ];


  const styles_cell_editable = {
    cell_editable: {
      border: '2px solid green', // Define the border style
    },
  };

  const CellRenderer = ({ params }) => {
    const { id, value } = params;

    const [isActive, setIsActive] = useState(Number(value) === 1);


    const handleChange = () => {
      setIsActive(!isActive);
    };

    return (

      <div>
        <Checkbox
          checked={isActive}
          onChange={handleChange}
          disabled={!params.row.editable}
          color="success" // Set the color to "success"
        />
        {isActive ? 'قَيْد التشغيل' : 'مُعَطَّل'}
      </div>
    );
  };


  const EditCellRenderer = ({ params }) => {
    const { id, value, api, field } = params;
    const [isActive, setIsActive] = useState(Number(value) === 1);

    const handleChange = () => {
      setIsActive(!isActive);
      api.setEditCellValue({ id, field, value: isActive ? "0" : "1" });
    };

    return (


      <Checkbox
        checked={isActive}
        onChange={handleChange}
        color="success" // Set the color to "success"
      />

    );
  };



  function generateRandomString(length = 8) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function generateRandomString_nomUser(length = 6) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return IDetablissement + '_' + result;
  }




  const CopyToClipboardButton = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };

  const CopyToClipboardButton_nomUser = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    ////setSnackbar({ children:  `  تم نسخ  ${value}  `, severity: 'success' });
    setSnackbar({ children: <span dangerouslySetInnerHTML={{ __html: `تم نسخ <div dir="ltr">${value}</div>` }} />, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };




  // Function to check if a value is empty, null, undefined, or equal to 0
  const isEmpty = (value) => value === "" || value === null || value === undefined || value === 0;

  function onchangeEncour(row) {

    if (Number(row.Encour) === 1) {
      /// alert(0);
      row.Encour = 0; // Set Valid to 0
    } else if (Number(row.Encour) === 0) {
      //// alert(1);
      row.Encour = 1; // Set Valid to 1
    }

    // Update the state of the row data
    setRows(prevRows => {
      // Create a new array by mapping over the previous rows
      return prevRows.map(prevRow => {
        // If the ID of the current row matches the modified row, return the modified row
        if (prevRow.id === row.id) {
          return row;
        }
        // Otherwise, return the unchanged row
        return prevRow;
      });
    });

    handleSaveOperation(row.id, row);

  }


  function onchangeClouture(row) {

    if (Number(row.Clouture) === 1) {
      /// alert(0);
      row.Clouture = 0; // Set Valid to 0
    } else if (Number(row.Clouture) === 0) {
      //// alert(1);
      row.Clouture = 1; // Set Valid to 1
    }

    // Update the state of the row data
    setRows(prevRows => {
      // Create a new array by mapping over the previous rows
      return prevRows.map(prevRow => {
        // If the ID of the current row matches the modified row, return the modified row
        if (prevRow.id === row.id) {
          return row;
        }
        // Otherwise, return the unchanged row
        return prevRow;
      });
    });

    handleSaveOperation(row.id, row);

  }

  function isIntegerNumber(str) {
    return /^\d+$/.test(str);
  }


  useEffect(() => {
    if (!loadingauth) {
      if (
        IDetablissement === null ||
        IDDFEP === null ||
        IDNature_etsF === null ||
        Number(IDNature_etsF) !== 1
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/');
        return;
      }


    }
  }, [IDetablissement, IDDFEP, IDNature_etsF, IDCandidat, NumSem, IDapis_userslist,
    loadingauth, navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }


  return (
    <>

      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={download_cert_inscription_active_Dfep}
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={download_cert_inscription_active_Mfep}
      />






      <Box
        sx={{
          '& .super-app-theme--header': {
            fontFamily: 'Amiri',
            fontSize: '20px',
            fontWeight: 'bold', // Make the font bold
            backgroundColor: '#B9D3EE',
            borderRight: '1px solid #ccc', // Add this line to apply right border to the cell
          }

        }}

      >

        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={themetextfieldnew}>



            {!!snackbar && (
              <Snackbar
                open
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                sx={{
                  marginTop: 12
                }}
              >
                <Alert
                  {...snackbar}
                  onClose={handleCloseSnackbar}
                  sx={{
                    display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
                    , '& .MuiAlert-action': { marginRight: '8px' }
                  }} // Adjust padding here
                />
              </Snackbar>

            )}



            <Grid container   >


              <Grid item sx={{ width: "100%" }}>

                <Divider sx={{ mt: '20px' }}>
                  <Grid item  >
                    <Typography
                      style={{
                        color: "#004d40",
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 25,
                        fontWeight: 700,
                        marginBottom: '25px'
                      }}
                      variant="h3" gutterBottom>
                      <LoopIcon sx={{ mr: '5px' }} />
                      الدورات
                    </Typography>
                  </Grid>

                </Divider>



                <Grid container justifyContent="center">



                </Grid>





              </Grid>




              {loadingdata_mfep ? <>

                <Box sx={{ width: '100%' }}>
                  <LinearProgress color="success" />
                </Box>

              </> : null}

              <DataGrid
                locale="ar-SD"
                rows={rows}
                columns={columns}

                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}

                getRowId={(row) => row.id}
                getRowClassName={(params) =>
                  parseInt(params.row.id_rows, 10) % 2 === 0 ? 'even-row' : 'odd-row'
                }

                slots={{
                  toolbar: CustomToolbar,
                }}

                slotProps={{
                  toolbar: { setRows, setRowModesModel, columns, handleExportExcel, handleAddRecord },
                }}

                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',


                  '& .MuiDataGrid-editInputCell': {
                    fontFamily: 'Amiri',
                    fontSize: '20px',
                    textAlign: 'center',
                    backgroundColor: 'rgba(255,255,255,0.65)',
                    borderRight: '1px solid #ccc', // Add this line to apply right border to the cell


                  },

                  '& .MuiDataGrid-cell': {
                    fontFamily: 'Amiri',
                    fontSize: '20px',
                    textAlign: 'center',
                    backgroundColor: 'rgba(255,255,255,0.65)',
                    borderRight: '1px solid #ccc', // Add this line to apply right border to the cell


                  },
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: '#2b84dd', // Background color when row is hovered
                  },

                  '& .even-row': {
                    backgroundColor: '#2b84dd61', // Background color for even rows
                  },
                  '& .odd-row': {
                    backgroundColor: '#ffffff', // Background color for odd rows
                  },

                  '& .cell-editable': styles_cell_editable.cell_editable, // Apply styles to the header with class 'cell-editable'


                }}

                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 25, page: 0 },
                  },
                }}
              />



            </Grid>



          </ThemeProvider>
        </CacheProvider>

      </Box>


      <Footer />

      <ConfirmationDialog open={isConfirmationOpen} onClose={handleConfirmationResponse} />
      <SaveConfirmationDialog open={isSaveConfirmationOpen} onClose={handleSaveConfirmationResponse} />

    </>
  );
}

export default SessionsMfep;
