import React, { useState, useEffect } from "react";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import logo2 from "../src/img/logo2.png";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ApiIcon from "@mui/icons-material/Api";
import FunctionsIcon from "@mui/icons-material/Functions";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import LoopIcon from "@mui/icons-material/Loop";
import { APP_PREFIX } from "./config"; // Adjust the path if necessary
import StorageIcon from '@mui/icons-material/Storage';
import './Header.css';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import stampImage from '../src/img/republicstamp.svg';
import stampImage2 from '../src/img/republicstamp_flagdz.png';
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import CloseIcon from "@mui/icons-material/Close";
import iconbarleft from "../src/img/logo.png";
import EditIcon from "@mui/icons-material/Edit";

import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import DescriptionIcon from "@mui/icons-material/Description";
import PrintIcon from "@mui/icons-material/Print";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

import { Button, Grid } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import appico from "../src/img/logo.png";
import downapp from "../src/img/logo.png";

import Footer from "../containers/Footer";
import ApartmentIcon from "@mui/icons-material/Apartment";

import "./amirifont.css";
import pharmacy from "../src/img/logo.png";
import blooddon from "../src/img/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import moresearch from "../src/img/logo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { API_BASE_URL } from "../containers/config"; // Adjust the path if necessary
import flag_dz from "../src/img/flag_dz.png";
import { useMediaQuery } from "@mui/material";
import Listmenumt from "./Listmenumt";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const themeRoboto = createTheme({
  typography: {
    fontFamily: '"Roboto Slab", serif',
    fontSize: 10,
  },
});

const themeChanga = createTheme({
  typography: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 10,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerRight({
  IDEncadrement,
  IDFonctions,
  IDFonctionsNature,
  IDsession_selected,
  IDetablissement,
  IDDFEP,
  IDNature_etsF,
  IDCandidat,
  crypted_id,
  NumSem,
  IDapis_userslist,
  loadingauth,
  Singup_active,
  download_cert_scol_active,
  EditingInfo_active,
  Eval_notes_active,
  download_cert_inscription_active,
  Singup_active_Dfep,
  download_cert_scol_active_Dfep,
  EditingInfo_active_Dfep,
  Eval_notes_active_Dfep,
  download_cert_inscription_active_Dfep,
  Singup_active_Mfep,
  download_cert_scol_active_Mfep,
  EditingInfo_active_Mfep,
  Eval_notes_active_Mfep,
  download_cert_inscription_active_Mfep,
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const themeChangaHeader = createTheme({
    typography: {
      fontFamily: '"Tajawal", sans-serif',
      fontSize: 16,
      fontWeight: 900,
    },
  });

  const themeChanga = createTheme({
    typography: {
      fontFamily: '"Tajawal", sans-serif',
      fontSize: 14,
      fontWeight: 900,
    },
  });

  const [lastClicked, setLastClicked] = useState(null);

  // Handler for clicking on ListItem
  const handleClickactive = (item) => {
    setLastClicked(item);
    handleDrawerClose();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openprint = Boolean(anchorEl);

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isMatch_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch_xs = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl_infocand, setAnchorEl_infocand] = React.useState(null);
  const open_infocand = Boolean(anchorEl_infocand);
  const handleClick_infocand = (event) => {
    setAnchorEl_infocand(event.currentTarget);
  };
  const handleClose_infocand = () => {
    setAnchorEl_infocand(null);
  };

  const [anchorElMotakawin, setAnchorElMotakawin] = React.useState(null);
  const openMotakawin = Boolean(anchorElMotakawin);
  const handleClickMotakawin = (event) => {
    setAnchorElMotakawin(event.currentTarget);
  };
  const handleCloseMotakawin = () => {
    setAnchorElMotakawin(null);
  };

  const navigate = useNavigate();
  const handleClickValidationStEtab = () => {
    // Navigate to the desired route
    navigate("/ValidationCandInfo");
    handleCloseMotakawin();
  };

  const handleClickChangPwStEtab = () => {
    // Navigate to the desired route
    navigate("/ChangPwStEtab");
    handleCloseMotakawin();
  };

  const handleClicksettingDfep = () => {
    // Navigate to the desired route
    if (Number(IDNature_etsF) === 5) {
      navigate("/SettingDfep");
    } else if (Number(IDNature_etsF) === 1) {
      navigate("/SettingMfep");
    }

    handleCloseMotakawin();
  };

  const handleClicksettingapi = () => {
    // Navigate to the desired route
    navigate("/Apis_userslist");
    handleCloseMotakawin();
  };

  const handleClicksettingApis_functions = () => {
    // Navigate to the desired route
    navigate("/Apis_functions");
    handleCloseMotakawin();
  };

  const handleClicksettingMigrate_hfsql = () => {
    // Navigate to the desired route
    navigate("/Migrate_hfsql");
    handleCloseMotakawin();
  };

  const handleClicksession = () => {
    // Navigate to the desired route
    navigate("/SessionsMfep");
    handleCloseMotakawin();
  };

  const handleClicksettingapis_userlist_functions = () => {
    // Navigate to the desired route
    navigate("/apis_userlist_functions");
    handleCloseMotakawin();
  };

  const handleClickChangPwPriv = () => {
    // Navigate to the desired route
    navigate("/ChangPwPriv");
    handleCloseMotakawin();
  };

  const handleClickChangePwEtab = () => {
    // Navigate to the desired route
    navigate("/ChangePwEtab");
    handleCloseMotakawin();
  };

  const [hidepagest, sethidepagest] = useState(false);
  const [hidepageetub, sethidepageetub] = useState(false);

  let toLink_SettingDfep_or_SettingEtab = "";
  let toLink_changpwst = "";
  let toLink_Offers = "";

  if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    Number(IDNature_etsF) === 1
  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeMfep";
    toLink_changpwst = "/ChangPwStMfep";
    toLink_Offers = "/OffersMfep";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    (IDNature_etsF === 40 || IDNature_etsF === "40")
  ) {
    toLink_SettingDfep_or_SettingEtab = "/InnovationList";
    toLink_changpwst = "/ChangPwStEtab";
    toLink_Offers = "";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== "" &&
    (IDNature_etsF === 5 || IDNature_etsF === "5")
  ) {
    toLink_SettingDfep_or_SettingEtab = "/HomeDfep";
    toLink_changpwst = "/ChangPwStDfep";
    toLink_Offers = "/OffersDfep";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== ""
  ) {
    toLink_SettingDfep_or_SettingEtab = "/SettingEtab";
    toLink_changpwst = "/ChangPwStEtab";
    toLink_Offers = "/OffersEtab";
  } else if (
    IDetablissement !== null &&
    (IDCandidat == null ||
      Number(IDCandidat) <= 0 ||
      IDCandidat == undefined) &&
    IDetablissement !== undefined &&
    IDetablissement !== ""
  ) {
    toLink_SettingDfep_or_SettingEtab = "/SettingPriv";
    toLink_changpwst = "";
    toLink_Offers = "";
  }

  useEffect(() => {
     
    if (
      IDCandidat == null ||
      IDCandidat == undefined ||
      !Number(IDCandidat) > 0
    ) {
      sethidepagest(true);
    } else if (
      IDetablissement == null ||
      IDetablissement == undefined ||
      !Number(IDetablissement) > 0 ||
      !Number(IDNature_etsF) > 0
    ) {
      sethidepageetub(true);
    } else {
      sethidepageetub(false);
      sethidepagest(false);
    }
  }, []);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <CssBaseline />
      <AppBar open={open} sx={{ width: "100%" }}>

        <header class="header" dir="rtl">
          <div class="heading-container">
            <img src={stampImage} alt="stamp" className="stamp" />

            <div>
              <h1>الجمهورية الجزائرية الديمقراطية الشعبية</h1>
              <h2>وزارة التكويـن والتعلـيـم المهنييـن</h2>
            </div>
            <img src={stampImage2} alt="Stamp" className="stamp" />
          </div>
        </header>

        <Toolbar sx={{ width: "100%", backgroundColor: '#ffffff', color: '#004d40' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: '5px',
                backgroundColor: '#004d400d',
                borderRadius: '50%'
              }}
            >
              <MenuIcon sx={{ color: "#004d40" }} />
            </Box>
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            theme={themeChangaHeader}
            sx={{
              flexGrow: 1,
              fontSize: 18,
              mr: 1,
              mt: 0,
              fontFamily: '"Tajawal", sans-serif',
              fontSize: 20,
              fontWeight: 700,
            }}
            component="div"
          >
            منصة المتكون
          </Typography>

          <a style={{ textDecoration: "none" }} href="https://www.mfep.gov.dz/">
            <img
              alt="منصة المتكون"
              src={logo2}
              style={{ width: 60, height: 60 }} // Adjust width, height, and border-radius as needed
            />
          </a>
        </Toolbar>
      </AppBar>
      <br />
      <br />
      <Main open={open}>
        <DrawerHeader />
      </Main>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader dir="ltr">
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </DrawerHeader>
        <Listmenumt
          IDEncadrement={IDEncadrement}
          IDFonctions={IDFonctions}
          IDFonctionsNature={IDFonctionsNature}
          IDsession_selected={IDsession_selected}
          IDetablissement={IDetablissement}
          IDDFEP={IDDFEP}
          IDNature_etsF={IDNature_etsF}
          IDCandidat={IDCandidat}
          crypted_id={crypted_id}
          NumSem={NumSem}
          IDapis_userslist={IDapis_userslist}
          loadingauth={loadingauth}
          Singup_active={Singup_active}
          download_cert_scol_active={download_cert_scol_active}
          EditingInfo_active={EditingInfo_active}
          Eval_notes_active={Eval_notes_active}
          download_cert_inscription_active={
            download_cert_inscription_active
          }
          Singup_active_Dfep={Singup_active_Dfep}
          download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
          EditingInfo_active_Dfep={EditingInfo_active_Dfep}
          Eval_notes_active_Dfep={Eval_notes_active_Dfep}
          download_cert_inscription_active_Dfep={
            download_cert_inscription_active_Dfep
          }
          Singup_active_Mfep={Singup_active_Mfep}
          download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
          EditingInfo_active_Mfep={EditingInfo_active_Mfep}
          Eval_notes_active_Mfep={Eval_notes_active_Mfep}
          download_cert_inscription_active_Mfep={
            download_cert_inscription_active_Mfep
          }
        />



      </Drawer>
    </Box>
  );
}
