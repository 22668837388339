import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { API_BASE_URL } from '../containers/config'; // Adjust the path if necessary
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { APP_PREFIX } from './config'; // Adjust the path if necessary
import useAuthentication_etab from './useAuthentication_etab';


const useStyles_CircularProgress = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure it's above everything else
  },
}));

const Preinscriptionpdf = () => {


  const { cryptedId } = useParams();
  const [result, setresult] = useState(null);
  const [result_dfep, setresult_dfep] = useState(null);

  const [IDPreinscrit, setIDPreinscrit] = useState(0);
  const [result_mfep, setresult_mfep] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null); // State to store PDF URL
  const [Annee_formation_Nom, setAnnee_formation_Nom] = useState(null);

  const [download_cert_inscription_active_state, setdownload_cert_inscription_active_state] = useState(0);
  const [download_cert_inscription_active_dfep_state, setdownload_cert_inscription_active_dfep_state] = useState(0);
  const [download_cert_inscription_active_mfep_state, setdownload_cert_inscription_active_mfep_state] = useState(0);
  const [numsem_state, setnumsem_state] = useState(0);

  const navigate = useNavigate();
  const [loadcrypted, setloadcrypted] = useState(false);


  const [jsonData, setJsonData] = useState({
    weburl: `${window.location.origin}${window.location.pathname}`,
    IDPreinscrit: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/getPreinscriptionpdf/${cryptedId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setresult(data.data);


        setJsonData(prevState => ({
          ...prevState,
          IDPreinscrit: data.data.IDPreinscrit
        }));


        setAnnee_formation_Nom(data.Annee_formation_Nom);

        setloadcrypted(true);
      } catch (error) {
        console.error(error);
        // Handle error, e.g., show an error message to the user
      }
    };
    if (cryptedId) {
      fetchData();
    }



  }, [cryptedId]);

  // Function to ensure the '+' sign is at the beginning of the phone number
  function formatPhoneNumber(phoneNumber) {
    // Trim any extra whitespace
    phoneNumber = phoneNumber.trim();

    // Check if the phone number contains a '+'
    const plusIndex = phoneNumber.indexOf('+');

    if (plusIndex !== -1) {
      // Remove the '+' from its current position
      phoneNumber = phoneNumber.slice(0, plusIndex) + phoneNumber.slice(plusIndex + 1);

      // Prepend the '+' to the beginning
      phoneNumber = phoneNumber;
    }

    return phoneNumber;
  }

  useEffect(() => {
    if (result && !pdfUrl) {
      setIDPreinscrit(result.IDPreinscrit);

      // Create a new jsPDF instance in landscape mode
      const pdf = new jsPDF('landscape');

      // Set document information
      pdf.setProperties({
        title: 'استمارة التسجيل الأولي',
        subject: 'استمارة التسجيل الأولي',
        author: 'MFEP',
      });

      const fontUrl = `${process.env.PUBLIC_URL}/Amiri-Regular.ttf`;
      const fontUrl_bold = `${process.env.PUBLIC_URL}/Amiri-Bold.ttf`;
      const fontUrl_arabic_ejaza = `${process.env.PUBLIC_URL}/arabic-ejaza.ttf`;
      const fontUrl_majalla = `${process.env.PUBLIC_URL}/majalla.ttf`;

      pdf.addFont(fontUrl, 'Amiri', 'normal');
      pdf.addFont(fontUrl_bold, 'Amiri_bold', 'normal');
      pdf.addFont(fontUrl_arabic_ejaza, 'arabic_ejaza', 'normal');
      pdf.addFont(fontUrl_majalla, 'Sakkal Majalla', 'normal');


      // Set the title of the browser tab
      document.title = 'استمارة التسجيل الأولي';

      // Function to add content to a specific side (left or right)
      const addPageContent = (xOffset) => {
        pdf.setFont('arabic_ejaza'); // Set font

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        // Set font size and text direction
        pdf.setFontSize(20);
        pdf.text('الجمهورية الجزائرية الديمقراطية الشعبية', xOffset + pageWidth / 4, 12, { align: 'center' });

        // Move to new line
        pdf.setLineHeightFactor(1.5); // Adjust line height if needed

        // Draw Ministry information
        pdf.text('وزارة التكويـن والتعلـيـم المهنييـن', xOffset + pageWidth / 4, 22, { align: 'center' });

        pdf.setFont('Sakkal Majalla'); // Set font
        pdf.setFontSize(16);

        // Draw Wilaya and Direction information
        if (result !== null && result.dfep_Nom) {
          pdf.text('مديرية التكوين والتعليم المهنيين لولاية ' + result.dfep_Nom, xOffset + (pageWidth / 4) + 65, 30, { align: 'right' });
        } else {
          pdf.text('مديرية التكوين والتعليم المهنيين لولاية ', xOffset + (pageWidth / 4) + 65, 30, { align: 'right' });
        }


        // Draw Name, Date, and Number
        pdf.text(result.etablissement_Nom, xOffset + (pageWidth / 4) + 65, 37, { align: 'right' });
        pdf.text(' الرقم:  ' + result.IDPreinscrit, xOffset + (pageWidth / 4) + 65, 44, { align: 'right' });

        pdf.setFont('Sakkal Majalla'); // Set font
        pdf.setFontSize(25);
        pdf.text(' استمارة التسجيل الأولي ', xOffset + pageWidth / 4, 51, { align: 'center' });
        pdf.setFontSize(25);
        pdf.text('( ' + result.session_Nom + ' )', xOffset + pageWidth / 4, 60, { align: 'center' });

        // Draw trainee details
        pdf.setFontSize(16);
        let yfromnom = 80;
        pdf.text('اللقب و الاسم : ' + result.Nom + ' ' + result.Prenom, xOffset + (pageWidth / 4) + 65, yfromnom, { align: 'right' });

        // Check if the date string is in the expected 'YYYY-MM-DD' format
        if (result.DateNais && result.DateNais.length === 10 && result.DateNais[4] === '-' && result.DateNais[7] === '-') {
          const dateParts = result.DateNais.split('-');
          const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
          pdf.text('تاريخ و مكان الميلاد : ' + formattedDate + ' ' + result.LieuNais, xOffset + (pageWidth / 4) + 65, yfromnom + 7, { align: 'right' });
        } else {
          pdf.text('تاريخ و مكان الميلاد : ' + result.DateNais + ' ' + result.LieuNais, xOffset + (pageWidth / 4) + 65, yfromnom + 7, { align: 'right' });
        }

        pdf.text('العنوان : ' + result.Adres, xOffset + (pageWidth / 4) + 65, yfromnom + 14, { align: 'right' });
        pdf.text('رقم التعريف الوطني : ' + result.Nin, xOffset + (pageWidth / 4) + 65, yfromnom + 21, { align: 'right' });



        // Assume result.tel1 is the phone number
        let phoneNumber = result.tel1;

        // Format the phone number
        phoneNumber = formatPhoneNumber(phoneNumber);

         // Check if the phone number contains a '+'
    const plusIndex = result.tel1.indexOf('+');

    if (plusIndex !== -1) {
        // Add text to PDF
        pdf.text('+رقم الهاتف: ' + phoneNumber, xOffset + (pageWidth / 4) + 65, yfromnom + 28, { align: 'right' });
      }else{
        pdf.text('رقم الهاتف: ' + result.tel1, xOffset + (pageWidth / 4) + 65, yfromnom + 28, { align: 'right' });

      }


        //pdf.text('رقم الهاتف: ' + result.tel1, xOffset + (pageWidth / 4) + 65, yfromnom+28, { align: 'right' });
        pdf.text(result.IDPreinscrit + ' : مسجل )ة( تحت رقم', xOffset + (pageWidth / 4) + 65, yfromnom + 42, { align: 'right' });
        pdf.text('اختصاص: ' + result.specialite_Nom, xOffset + (pageWidth / 4) + 65, yfromnom + 35, { align: 'right' });
        pdf.text('نمـط التكوين : ' + result.mode_formation_Nom, xOffset + (pageWidth / 4) + 65, yfromnom + 49, { align: 'right' });


        // Add dates
        var dateDF = result.DateD ? new Date(result.DateD) : null;
        var dateFF = result.DateF ? new Date(result.DateF) : null;

        if (dateDF instanceof Date && !isNaN(dateDF) && dateFF instanceof Date && !isNaN(dateFF)) {
          var dayDF = ('0' + dateDF.getDate()).slice(-2);
          var monthDF = ('0' + (dateDF.getMonth() + 1)).slice(-2);
          var yearDF = dateDF.getFullYear();

          var dayFF = ('0' + dateFF.getDate()).slice(-2);
          var monthFF = ('0' + (dateFF.getMonth() + 1)).slice(-2);
          var yearFF = dateFF.getFullYear();

          var formattedDateDF = dayDF + '-' + monthDF + '-' + yearDF;
          var formattedDateFF = dayFF + '-' + monthFF + '-' + yearFF;

          pdf.text('مدة التكوين من: ' + formattedDateDF + ' إلى ' + formattedDateFF, xOffset + (pageWidth / 4) + 65, yfromnom + 56, { align: 'right' });
        }



        // Position for the text
        let x = xOffset + (pageWidth / 4) + 65;
        let y = 150;

        // Write the bold text
        pdf.text(' :ملاحظة', x, y, { align: 'right' });

        // Calculate the text width to draw the underline
        let textWidth = pdf.getTextWidth('ملاحظة:');

        // Draw the underline (starting at the left of the text, ending at the right)
        pdf.line(x - textWidth, y + 1, x, y + 1);  // Adjust `y + 1` for the line's vertical position



        // Write the rest of the text
        pdf.text('هذه الاستمارة لا تعني أنك مسجل بشكل نهائي في هذا العرض', x - 15, y, { align: 'right' });

        // Write the rest of the text
        pdf.text('يرجى التقرب من المؤسسة التي سجلت فيها لمعرفة المزيد', x, y + 7, { align: 'right' });

        pdf.text('', 10, 190);
        pdf.text('', 10, 200);

        // Get the current date
        var currentDate = new Date();
        var day = ('0' + currentDate.getDate()).slice(-2);
        var month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        var year = currentDate.getFullYear();
        var hours = ('0' + currentDate.getHours()).slice(-2);
        var minutes = ('0' + currentDate.getMinutes()).slice(-2);
        var seconds = ('0' + currentDate.getSeconds()).slice(-2);
        var formattedCurrentDate = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;

        pdf.text(formattedCurrentDate, xOffset + 20, 170, { align: 'left' });

        // Generate QR Code
        const jsonString = JSON.stringify(jsonData);
        const qrCodeElement = document.getElementById('qrCode');
        qrCodeElement.setAttribute('value', jsonString);
        const qrDataURL = qrCodeElement.toDataURL('image/png');

        // Add QR code image to the PDF on the left side
        pdf.addImage(qrDataURL, 'PNG', xOffset + 23, 175, 30, 30);

        // Add the current URL at the bottom of the PDF page
        const currentUrl = window.location.href;
        //pdf.text(currentUrl, xOffset + 20, pageHeight - 30, { align: 'left' });
      };

      // Add the content twice: one on the left and one on the right
      addPageContent(0); // Left side
      addPageContent(pdf.internal.pageSize.getWidth() / 2); // Right side

      // Generate a blob URL for the PDF content
      const pdfBlob = pdf.output('blob');
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);

      // Set pdfUrl to the blob URL
      setPdfUrl(pdfBlobUrl);
    }
  }, [result, pdfUrl]);


  /*
  
    useEffect(() => {
  
  
      if (result && !pdfUrl) { // Only generate PDF if result is set and PDF URL is not already generated
  
  
        setIDPreinscrit(result.IDPreinscrit);
   
        // Create a new jsPDF instance
        const pdf = new jsPDF();
   
        // Set document information
        pdf.setProperties({
          title: 'استمارة التسجيل الأولي',
          subject: 'استمارة التسجيل الأولي',
          author: 'MFEP',
        });
  
       
        const fontUrl = `${process.env.PUBLIC_URL}/Amiri-Regular.ttf`;
        const fontUrl_bold = `${process.env.PUBLIC_URL}/Amiri-Bold.ttf`;
        const fontUrl_arabic_ejaza = `${process.env.PUBLIC_URL}/arabic-ejaza.ttf`;
        const fontUrl_majalla = `${process.env.PUBLIC_URL}/majalla.ttf`;
  
        pdf.addFont(fontUrl, 'Amiri', 'normal');
        // Draw Certificate title
        pdf.addFont(fontUrl_bold, 'Amiri_bold', 'normal');
        pdf.addFont(fontUrl_arabic_ejaza, 'arabic_ejaza', 'normal');
        pdf.addFont(fontUrl_majalla, 'Sakkal Majalla', 'normal');
  
        pdf.setFont('arabic_ejaza'); // Set font
  
        // Set the title of the browser tab
        document.title = 'استمارة التسجيل الأولي';
  
        // Function to add page content
    const addPageContent = () => {
  
        // Set font size and text direction
        pdf.setFontSize(26);
        pdf.text('الجمهورية الجزائرية الديمقراطية الشعبية', pdf.internal.pageSize.getWidth() / 2, 12, { align: 'center' });
  
        // Move to new line
        pdf.setLineHeightFactor(1.5); // Adjust line height if needed
  
        // Draw Ministry information
        pdf.text('وزارة التكويـن والتعلـيـم المهنييـن', pdf.internal.pageSize.getWidth() / 2, 22, { align: 'center' });
        pdf.setFont('Sakkal Majalla'); // Set font
        ///pdf.setFont('Amiri'); // Set font
        pdf.setFontSize(16);
        // Draw Wilaya and Direction information
        if (result !== null && result.dfep_Nom) {
          ///pdf.text('ولاية ' + result.dfep_Nom, pdf.internal.pageSize.getWidth() - 10, 30, { align: 'right' });
          pdf.text('مديرية التكوين والتعليم المهنيين لولاية ' + result.dfep_Nom, pdf.internal.pageSize.getWidth() - 10, 40, { align: 'right' });
        } else {
          /// pdf.text('ولاية ', pdf.internal.pageSize.getWidth() - 10, 30, { align: 'right' });
          pdf.text('مديرية التكوين والتعليم المهنيين لولاية ', pdf.internal.pageSize.getWidth() - 10, 40, { align: 'right' });
        }
  
        // Draw Name, Date, and Number
        pdf.text(result.etablissement_Nom, pdf.internal.pageSize.getWidth() - 10, 50, { align: 'right' });
        pdf.text(' الرقم:  ' + result.IDPreinscrit, pdf.internal.pageSize.getWidth() - 10, 60, { align: 'right' });
  
  
        ///pdf.setFont('Amiri_bold'); // Set font
        pdf.setFont('Sakkal Majalla'); // Set font
        pdf.setFontSize(35);
        pdf.text(' استمارة التسجيل الأولي ', pdf.internal.pageSize.getWidth() / 2, 70, { align: 'center' });
        pdf.setFontSize(25);
        pdf.text('( ' + result.session_Nom + ' )', pdf.internal.pageSize.getWidth() / 2, 80, { align: 'center' });
  
        // Add empty line
        ///pdf.text('', 10, 80);
  
        // Draw trainee information
        pdf.setFontSize(16);
  
        // Draw trainee details
        pdf.text('اللقب و الاسم : ' + result.Nom + ' ' + result.Prenom, pdf.internal.pageSize.getWidth() - 10, 100, { align: 'right' });
        ///pdf.text('تاريخ و مكان الميلاد : ' + result.DateNais + ' ' + result.wilayas_libelleArabe + ' ' + result.communn_Nom, pdf.internal.pageSize.getWidth() - 10, 110, { align: 'right' });
        // Assuming result.DateNais is in the format yyyy-mm-dd
        //const dateParts = result.DateNais.split('-');
        //const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        //pdf.text('تاريخ و مكان الميلاد : ' + formattedDate  + ' ' + result.communn_Nom, pdf.internal.pageSize.getWidth() - 10, 110, { align: 'right' });
  
        // Check if the date string is in the expected 'YYYY-MM-DD' format
        if (result.DateNais && result.DateNais.length === 10 && result.DateNais[4] === '-' && result.DateNais[7] === '-') {
          const dateParts = result.DateNais.split('-');
          const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
          pdf.text('تاريخ و مكان الميلاد : ' + formattedDate + ' ' + result.LieuNais, pdf.internal.pageSize.getWidth() - 10, 110, { align: 'right' });
  
          //return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        } else {
          // Return the original string if it's not in the expected format
          pdf.text('تاريخ و مكان الميلاد : ' + result.DateNais + ' ' + result.LieuNais, pdf.internal.pageSize.getWidth() - 10, 110, { align: 'right' });
  
  
        }
  
  
        pdf.text('العنوان : ' + result.Adres, pdf.internal.pageSize.getWidth() - 10, 120, { align: 'right' });
        pdf.text(result.IDPreinscrit + ' : مسجل )ة( تحت رقم', pdf.internal.pageSize.getWidth() - 10, 130, { align: 'right' });
        pdf.text('اختصاص: ' + result.specialite_Nom, pdf.internal.pageSize.getWidth() - 10, 140, { align: 'right' });
        pdf.text('نمـط التكوين : ' + result.mode_formation_Nom, pdf.internal.pageSize.getWidth() - 10, 150, { align: 'right' });
  
        // Convert result.DateD and result.DateF to Date objects if they exist and are valid
        var dateDF = result.DateD ? new Date(result.DateD) : null;
        var dateFF = result.DateF ? new Date(result.DateF) : null;
  
        // Check if both dates are valid
        if (dateDF instanceof Date && !isNaN(dateDF) && dateFF instanceof Date && !isNaN(dateFF)) {
          // Get day, month, and year components
          var dayDF = dateDF.getDate();
          var monthDF = dateDF.getMonth() + 1; // Months are zero-based, so add 1
          var yearDF = dateDF.getFullYear();
  
          var dayFF = dateFF.getDate();
          var monthFF = dateFF.getMonth() + 1; // Months are zero-based, so add 1
          var yearFF = dateFF.getFullYear();
  
          // Format the date strings as dd-mm-yyyy
          var formattedDateDF = ('0' + dayDF).slice(-2) + '-' + ('0' + monthDF).slice(-2) + '-' + yearDF;
          var formattedDateFF = ('0' + dayFF).slice(-2) + '-' + ('0' + monthFF).slice(-2) + '-' + yearFF;
  
          // Display the formatted dates
          pdf.text('مدة التكوين من: ' + formattedDateDF + ' إلى ' + formattedDateFF, pdf.internal.pageSize.getWidth() - 10, 160, { align: 'right' });
        }
  
        // Add empty lines
        pdf.text('', 10, 190);
        pdf.text('', 10, 200);
  
        // Draw issuing information
        pdf.setFontSize(16);
        // Get the current date
        var currentDate = new Date();
  
        // Get day, month, and year components
        var day = currentDate.getDate();
        var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
        var year = currentDate.getFullYear();
  
        // Get hours, minutes, and seconds components
        var hours = currentDate.getHours();
        var minutes = currentDate.getMinutes();
        var seconds = currentDate.getSeconds();
  
        // Format the date as dd-mm-yyyy hh:mm:ss
        var formattedCurrentDate = ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year +
          ' ' + ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
  
        // Display the formatted current date
        pdf.text(' في : ' + formattedCurrentDate, 20, 190, { align: 'left' });
        ////pdf.text('مدير )ة( المؤسسة', 80, 200, { align: 'left' });
  
  
  
  
        const jsonString = JSON.stringify(jsonData);
  
        // Assuming you have a QR code library
        const qrCodeElement = document.getElementById('qrCode');
        qrCodeElement.setAttribute('value', jsonString);
  
        // Assuming the library has a method to get the QR code as a data URL
        const qrDataURL = qrCodeElement.toDataURL('image/png');
  
  
        // Calculate the X-coordinate for right alignment
        const qrCodeX = pdf.internal.pageSize.getWidth() - 200; // 60 is the total width of the QR code image and its margin
  
        // Add QR code image to the PDF
        ///pdf.addImage(qrDataURL, 'PNG', qrCodeX, 200, 50, 50);
        pdf.addImage(qrDataURL, 'PNG', 20, 200, 50, 50);
  
        // Get the full current URL
        const currentUrl = window.location.href;
  
        // Add the current URL at the bottom of the PDF page
        pdf.text(currentUrl, 20, pdf.internal.pageSize.height - 30, { align: 'left' });
  
        };
  
    // Add the content multiple times
    for (let i = 0; i < 2; i++) { // Change the number to however many times you want to repeat the page
      if (i > 0) pdf.addPage(); // Add a new page if it's not the first iteration
      addPageContent();
    }
       
        // Generate a blob URL for the PDF content
        const pdfBlob = pdf.output('blob');
        const pdfBlobUrl = URL.createObjectURL(pdfBlob);
  
        // Set pdfUrl to the blob URL
        setPdfUrl(pdfBlobUrl);
  
        
  
      }
    }, [result, pdfUrl]); // pdfUrl added to dependency array
  */
  const classes_CircularProgress = useStyles_CircularProgress();



  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh', // Adjust to '100vh' to take full viewport height
  };

  const objectStyle = {
    flex: 1, // Make the object element fill available space
    width: '100%',
    height: '100%',
  };

  return (
    <>


      <div style={{ width: '100%', height: '100vh' }}>
        {!pdfUrl && (
          <div className={classes_CircularProgress.overlay}>
            <Dialog open={!pdfUrl} PaperProps={{ sx: { backgroundColor: 'white' } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                <CircularProgress sx={{ ml: 1 }} color="primary" />
                <Typography variant="h6" sx={{ color: '#004d40', fontFamily: 'Tajawal, sans-serif', fontWeight: 900, ml: 2 }}>
                  جاري التحميل...
                </Typography>
              </Box>
            </Dialog>
          </div>
        )}
        {pdfUrl && (
          <div style={containerStyle}>
            <object
              data={pdfUrl}
              type="application/pdf"
              style={objectStyle}
              aria-label="Generated PDF"
            />
          </div>
        )}
        {/* Render QR code component */}
        <div style={{ display: 'none' }}>
          <QRCode id="qrCode" value={`${JSON.stringify(jsonData)}`} />
          {/* 
        <QRCode id="qrCode" value={`${window.location.origin}${window.location.pathname}`} />
        */}
        </div>
      </div>
    </>
  );
};

export default Preinscriptionpdf;
