import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import { API_BASE_URL } from './config'; // Adjust the path if necessary

const useAuthentication_cand = () => {
  const navigate = useNavigate();

  const [IDetablissement, setIDetablissement] = useState(null);
  const [IDDFEP, setIDDFEP] = useState(null);
  const [IDCandidat, setIDCandidat] = useState(null);
  const [loadingauth, setloadingauth] = useState(true); // Initialize loading state to true


  const [Singup_active, setSingup_active] = useState(null);
  const [download_cert_scol_active, setDownloadCertScolActive] = useState(null);
  const [EditingInfo_active, setEditingInfoActive] = useState(null);
  const [Eval_notes_active, setEvalNotesActive] = useState(null);
  const [download_cert_inscription_active, setDownloadCertInscriptionActive] = useState(null);

  const [Singup_active_Dfep, setSingupActiveDfep] = useState(null);
  const [download_cert_scol_active_Dfep, setDownloadCertScolActiveDfep] = useState(null);
  const [EditingInfo_active_Dfep, setEditingInfoActiveDfep] = useState(null);
  const [Eval_notes_active_Dfep, setEvalNotesActiveDfep] = useState(null);
  const [download_cert_inscription_active_Dfep, setDownloadCertInscriptionActiveDfep] = useState(null);

  const [Singup_active_Mfep, setSingupActiveMfep] = useState(null);
  const [download_cert_scol_active_Mfep, setDownloadCertScolActiveMfep] = useState(null);
  const [EditingInfo_active_Mfep, setEditingInfoActiveMfep] = useState(null);
  const [Eval_notes_active_Mfep, setEvalNotesActiveMfep] = useState(null);
  const [download_cert_inscription_active_Mfep, setDownloadCertInscriptionActiveMfep] = useState(null);

  const [IDNature_etsF, setIDNature_etsF] = useState(null);
  const [IDapis_userslist, setIDapis_userslist] = useState(null);
  const [NumSem, setNumSem] = useState(null);
  const [crypted_id, setcrypted_id] = useState(null);



  const auth_cand = `${API_BASE_URL}/api/auth_cand`;

  useEffect(() => {

    let tokenEtab = sessionStorage.getItem("authData_73xJ#E");
    if (!tokenEtab) {
      tokenEtab = localStorage.getItem("authData_73xJ#E");
    }
    if (!tokenEtab) {
      setloadingauth(false); 
      return
    }


    fetch(auth_cand, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenEtab}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.IDetablissement && data.IDDFEP && data.IDCandidat) {
          if (Number(data.IDetablissement) > 0 &&
            Number(data.IDDFEP) > 0 &&
            Number(data.IDCandidat) > 0
          ) {
            setcrypted_id(data.crypted_id);
            setIDetablissement(data.IDetablissement);
            setIDDFEP(data.IDDFEP);
            setIDCandidat(data.IDCandidat);
            setNumSem(data.NumSem);

            setSingup_active(data.Singup_active);
            setDownloadCertScolActive(data.download_cert_scol_active);
            setEditingInfoActive(data.EditingInfo_active);
            setEvalNotesActive(data.Eval_notes_active);
            setDownloadCertInscriptionActive(data.download_cert_inscription_active);

            setSingupActiveDfep(data.Singup_active_Dfep);
            setDownloadCertScolActiveDfep(data.download_cert_scol_active_Dfep);
            setEditingInfoActiveDfep(data.EditingInfo_active_Dfep);
            setEvalNotesActiveDfep(data.Eval_notes_active_Dfep);
            setDownloadCertInscriptionActiveDfep(data.download_cert_inscription_active_Dfep);

            setSingupActiveMfep(data.Singup_active_Mfep);
            setDownloadCertScolActiveMfep(data.download_cert_scol_active_Mfep);
            setEditingInfoActiveMfep(data.EditingInfo_active_Mfep);
            setEvalNotesActiveMfep(data.Eval_notes_active_Mfep);
            setDownloadCertInscriptionActiveMfep(data.download_cert_inscription_active_Mfep);


          }
        }
      })
      .catch(error => {
      })
      .finally(() => {
        setloadingauth(false); // Set loading state to false after fetching is done
      });
  }, [navigate]); // Adding navigate to dependencies


  // Return all the constants
  return {
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    crypted_id,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  };


};

export default useAuthentication_cand;
