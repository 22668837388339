import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CacheProvider } from "@emotion/react";
import TextField from '@mui/material/TextField';
import createCache from "@emotion/cache";
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';

import getCsrfToken from '../CsrfToken';
import FormControl from '@mui/material/FormControl';
import isEmail from 'validator/lib/isEmail';
import ReplayIcon from '@mui/icons-material/Replay';
import Input from '@mui/material/Input';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import Footer from './Footer';
import Header from './Header';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';

import Select from '@mui/material/Select';
import CardContent from '@mui/material/CardContent';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';
import useAuthentication_logged from "./useAuthentication_logged";
import useAuthentication_etab from "./useAuthentication_etab";

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

/*
const IOSSwitch = styled((props: SwitchProps) => (
 <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props}
  // Change the font size here
 />
))(({ theme }) => ({*/

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({

  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));



// Set the CSRF token globally
const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

var md5 = require('md5');
let codeemail = (Math.floor(1000 + Math.random() * 9000));

let sendemailbyemailoneortwo = 'https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';


const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
});



function SettingEtab() {

  const authlogged = useAuthentication_logged(); // Get values from useAuthentication_logged
  const authCandData = useAuthentication_etab(); // Always call the hook at the top level, but we will conditionally use its data
  const [authCand, setAuthCand] = useState(null); // Create a state for authCand

  // Function to get the value from authlogged
  const getValue = (key) => {
    try {
      return authlogged?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  const IDCandidat = getValue("IDCandidat");
  const IDEncadrement = getValue("IDEncadrement");
  const IDetablissement = getValue("IDetablissement");
  const IDDFEP = getValue("IDDFEP");
  const IDNature_etsF = getValue("IDNature_etsF");
  const IDUtilisateur = getValue("IDUtilisateur");
  const UtilisateurNom = getValue("UtilisateurNom");
  const LoginTracesNom = getValue("LoginTracesNom");
  const IDapis_userslist = getValue("IDapis_userslist");
  const loadingauth = getValue("loadingauth");

  // Function to get the value from authCand if it exists
  const getValuecand = (key) => {
    try {
      return authCand?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  let NumSem = getValuecand("NumSem");
  let loadingauthcand = getValuecand("loadingauth");
  let Singup_active = getValuecand("Singup_active");
  let download_cert_scol_active = getValuecand("download_cert_scol_active");
  let EditingInfo_active = getValuecand("EditingInfo_active");
  let Eval_notes_active = getValuecand("Eval_notes_active");
  let download_cert_inscription_active = getValuecand("download_cert_inscription_active");
  let Singup_active_Dfep = getValuecand("Singup_active_Dfep");
  let download_cert_scol_active_Dfep = getValuecand("download_cert_scol_active_Dfep");
  let EditingInfo_active_Dfep = getValuecand("EditingInfo_active_Dfep");
  let Eval_notes_active_Dfep = getValuecand("Eval_notes_active_Dfep");
  let download_cert_inscription_active_Dfep = getValuecand("download_cert_inscription_active_Dfep");
  let Singup_active_Mfep = getValuecand("Singup_active_Mfep");
  let download_cert_scol_active_Mfep = getValuecand("download_cert_scol_active_Mfep");
  let EditingInfo_active_Mfep = getValuecand("EditingInfo_active_Mfep");
  let Eval_notes_active_Mfep = getValuecand("Eval_notes_active_Mfep");
  let download_cert_inscription_active_Mfep = getValuecand("download_cert_inscription_active_Mfep");
  let crypted_id = getValuecand("crypted_id");

  useEffect(() => {
    // Check if IDCandidat is valid and authCandData is not loading
    if (IDetablissement !== null && IDetablissement !== undefined && Number(IDetablissement) > 0 && !authCandData["loadingauth"]) {
      // Deep comparison of authCand and authCandData to prevent unnecessary state updates
      if (JSON.stringify(authCand) !== JSON.stringify(authCandData)) {
        
        setAuthCand(authCandData); // Update authCand state when IDCandidat is valid and authCand has changed
      }
    }
  }, [IDetablissement, authCandData, authCand]); // Trigger useEffect when IDCandidat, authCandData, or authCand changes
  

 

  const [download_cert_inscription_active_state, setdownload_cert_inscription_active_state] = useState('');
  const [download_cert_scol_active_state, setdownload_cert_scol_active_state] = useState('');
  const [Eval_notes_active_state, setEval_notes_active_state] = useState('');
  const [Singup_active_state, setSingup_active_state] = useState('');
  const [EditingInfo_active_state, setEditingInfo_active_state] = useState('');




  const [loading, setLoading] = useState(true);





  const [alertMessageEdit, setAlertMessageEdit] = useState(null);


  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(null);

  const [OpenDialog, setOpenDialog] = useState(false);

  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const [errorget, setErrorget] = useState();
  const navigate = useNavigate();

  const refDateNais = useRef(null);


  const [Civ, setCiv] = React.useState(0);

  const handleChange_Civ = (event) => {
    setCiv(event.target.value);
  };



  const [run_getetab_byid, setrun_getetab_byid] = useState(false);
  const [allowfetch, setallowfetch] = useState(true);

  useEffect(() => {






    const fetchData = async () => {


      try {


        if (!allowfetch || run_getetab_byid) {

          return;
        }

        const response = await fetch(`${API_BASE_URL}/api/getetab_byid/${IDetablissement}`
          , {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
            }
          });



        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();

        ///alert(JSON.stringify(data));
        setValuesFromData_getinfo(data.data, data.data_dfep, data.data_mfep);
      } catch (error) {
        console.error(error);
        // Handle error, e.g., show an error message to the user
      } finally {
        setLoading(false);
        setrun_getetab_byid(true);
        setallowfetch(false);
      }


    };

    if (allowfetch && !run_getetab_byid && Number(IDetablissement) > 0) {
      fetchData();
    }
  }, [allowfetch, run_getetab_byid, IDetablissement]);



  const [Nom_etab, setNom_etab] = useState();
  const [libelleArabe_etab, setlibelleArabe_etab] = useState();



  function setValuesFromData_getinfo(data, data_dfep, data_mfep) {
    // Check if data object is defined
    if (!data || !data_dfep || !data_mfep) {
      console.error('Data is undefined');
      return;
    }


    setNom_etab(data.Nom);
    setlibelleArabe_etab(data.libelleArabe);

  }






  const [downtoggleclicked, setdowntoggleclicked] = useState(false);
  const [notestoggleclicked, setnotestoggleclicked] = useState(false);
  const [down_inscriptiontoggleclicked, setdown_inscriptiontoggleclicked] = useState(false);



  useEffect(() => {
    if ((down_inscriptiontoggleclicked && IDetablissement &&
      (Number(download_cert_inscription_active_state) === 0
        || Number(download_cert_inscription_active_state) === 1)
    )) {
      ontoggleupdatedata();
    }

    if ((downtoggleclicked && IDetablissement &&
      (Number(download_cert_scol_active_state) === 0
        || Number(download_cert_scol_active_state) === 1)
    )) {
      ontoggleupdatedata();
    }

    if ((notestoggleclicked && IDetablissement &&
      (Number(Eval_notes_active_state) === 0
        || Number(Eval_notes_active_state) === 1)
    )) {
      ontoggleupdatedata();
    }


  }, [download_cert_inscription_active_state, down_inscriptiontoggleclicked,
    download_cert_scol_active_state, downtoggleclicked,
    notestoggleclicked, Eval_notes_active_state]);



  const ontoggleclicked_download = (event) => {

    setdowntoggleclicked(true);
    if (Number(download_cert_scol_active_state) === 0) {
      setdownload_cert_scol_active_state(1);
    } else {
      setdownload_cert_scol_active_state(0);
    }

  };

  const ontoggleclicked_download_inscription = (event) => {

    setdown_inscriptiontoggleclicked(true);
    if (Number(download_cert_inscription_active_state) === 0) {
      setdownload_cert_inscription_active_state(1);
    } else {
      setdownload_cert_inscription_active_state(0);
    }

  };


  const ontoggleclicked_notes = (event) => {

    setnotestoggleclicked(true);
    if (Number(Eval_notes_active_state) === 0) {
      setEval_notes_active_state(1);
    } else {
      setEval_notes_active_state(0);
    }

  };



  const ontoggleupdatedata = async (e) => {


    try {
      const data = {
        Singup_active: Singup_active,
        download_cert_scol_active: download_cert_scol_active_state,
        download_cert_inscription_active: download_cert_inscription_active_state,
        EditingInfo_active: EditingInfo_active_state,
        Eval_notes_active: Eval_notes_active_state,
        IDetablissement: IDetablissement
      };
      ////alert(download_cert_inscription_active_state);



      const responseFromApi = await axios.post(`${API_BASE_URL}/api/updateSettingEtab`, data,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });

      if (responseFromApi.status === 200) {
        // Response is OK, show the updated data
        //console.log("Updated data:", responseFromApi.data.updatedData);
        //console.log("responseFromApi Eval_notes_active:", responseFromApi.data.updatedData.Eval_notes_active);


        setSnackbar({ children: 'تم الحفظ بنجاح!', severity: 'success' });
        setdown_inscriptiontoggleclicked(false);
        setdowntoggleclicked(false);
        // Do something with the updated data, such as updating the UI
      } else {
        // Log error if response status is not OK
        console.error("Error:", responseFromApi.data.error);
      }
    } catch (error) {
      // Log any other errors, such as network errors
      console.error("Error:", error.message);
    }
  };



  useEffect(() => {
    if (!loadingauth) {
      if (
        IDetablissement === null ||
        IDDFEP === null ||
        IDNature_etsF === null ||
        Number(IDNature_etsF) === 5 ||
        Number(IDNature_etsF) === 1
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/');
        return;
      }


      setdownload_cert_inscription_active_state(download_cert_inscription_active);
      setdownload_cert_scol_active_state(download_cert_scol_active);
      setEval_notes_active_state(Eval_notes_active);
      setSingup_active_state(Singup_active);
      setEditingInfo_active_state(EditingInfo_active);
    }
  }, [IDetablissement, IDDFEP, IDNature_etsF, IDCandidat, NumSem, IDapis_userslist,
    loadingauth, navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }






  return (
    <>

      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={download_cert_inscription_active_Dfep}
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={download_cert_inscription_active_Mfep}
      />





      <Box sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {loading && <CircularProgress />}
      </Box>

      <Container sx={{ textAlign: 'center', mx: 'auto', width: '80%' }}>



        <Card

        >
          <CardContent>
            <Divider sx={{ mt: '0px' }}>
              <Grid item  >
                <Typography
                  style={{
                    color: "#004d40",
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 18,
                    fontWeight: 700,
                    marginBottom: '25px'
                  }}
                  variant="h3" gutterBottom>
                  إعدادات <SettingsIcon sx={{ ml: '0px' }} />
                </Typography>
              </Grid>

            </Divider>


            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{
                    display: 'flex',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontFamily: '"Tajawal", sans-serif',
                    textAlign: 'right', // Align text to right by default
                  }}
                >
                  <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                    ولاية: &nbsp;
                  </span> {libelleArabe_etab} &nbsp;
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    display: 'flex',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    fontFamily: '"Tajawal", sans-serif',
                    direction: 'rtl', // Set direction to right-to-left
                    textAlign: 'right', // Align text to right
                  }}
                >
                  <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                    المؤسسة:
                  </span>&nbsp;{Nom_etab}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    display: 'flex',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    fontFamily: '"Tajawal", sans-serif',
                    direction: 'rtl', // Set direction to right-to-left
                    textAlign: 'right', // Align text to right
                  }}
                >
                  <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                  {UtilisateurNom}
                  </span> 
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    display: 'flex',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    fontFamily: '"Tajawal", sans-serif',
                    direction: 'rtl', // Set direction to right-to-left
                    textAlign: 'right', // Align text to right
                  }}
                >
                  <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                    مرحبا 
                  </span>&nbsp;{LoginTracesNom}
                </Typography>
              </Grid>
            </Grid>

            {
              Number(IDUtilisateur) === 34 ||
             
              Number(IDUtilisateur) === 36 ? (
               <>
               
            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>

                {/*        <p>
      <FormControlLabel  
        control={<IOSSwitch sx={{ m: 1 }} checked={Singup_active_etab} />}
        label={<span style={{  color: "#004d40",
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 22,
        fontWeight: 900, }}> فتح التسجيلات </span>}
         onClick={ontoggleclicked_singup}
      />
     checked={EditingInfo_active_etab === 1}
</p>
*/}
                {/* 
<p>
      <FormControlLabel
        control={<IOSSwitch 
          disabled={EditingInfo_active_etab_disabled}
 
           sx={{ m: 1 }} checked={EditingInfo_active_etab_enable} />}
        label={<span style={{  color: "#004d40",
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 16,
        fontWeight: 900, }}> 
        {EditingInfo_active_etab_disabled? <>
         السماح بتعديل المعلومات (مغلوق من المديرية)
        </>
        :<>
        السماح بتعديل المعلومات 
        </>}
        
        </span>}
        onClick={EditingInfo_active_etab_disabled ? () => {} : ontoggleclicked_edit} 
       />
</p>
*/}

                <p>
                  <FormControlLabel
                    control={<IOSSwitch
                      disabled={(Number(download_cert_inscription_active_Mfep) === 0) || (Number(download_cert_inscription_active_Dfep) === 0)}
                      sx={{ m: 1 }}
                      checked={Number(download_cert_inscription_active_state) === 1}
                    />}
                    label={<span style={{
                      color: "#004d40",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 900,
                    }}>
                      {(Number(download_cert_inscription_active_Mfep) === 0) ? <>
                        السماح بتحميل شهادة التسجيل (مغلوق من الوزارة)
                      </> :
                        (Number(download_cert_inscription_active_Dfep) === 0) ? <>
                          السماح بتحميل شهادة التسجيل (مغلوق من المديرية)
                        </> :
                          <>السماح بتحميل شهادة التسجيل</>
                      }
                    </span>}

                    onClick={(Number(download_cert_inscription_active_Mfep) === 0) || (Number(download_cert_inscription_active_Dfep) === 0) ? () => { } : ontoggleclicked_download_inscription}


                  />


                </p>

                <p>

                  <FormControlLabel
                    control={<IOSSwitch
                      disabled={(Number(download_cert_scol_active_Mfep) === 0) || (Number(download_cert_scol_active_Dfep) === 0)}
                      sx={{ m: 1 }}
                      checked={Number(download_cert_scol_active_state) === 1}

                    />}
                    label={<span style={{
                      color: "#004d40",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 900,
                    }}>
                      {(Number(download_cert_scol_active_Mfep) === 0) ? <>
                        السماح بتحميل شهادة التربص (مغلوق من الوزارة)
                      </> :
                        (Number(download_cert_scol_active_Dfep) === 0) ? <>
                          السماح بتحميل شهادة التربص (مغلوق من المديرية)
                        </> :
                          <>
                            السماح بتحميل شهادة التربص
                          </>
                      }

                    </span>}
                    onClick={(Number(download_cert_scol_active_Mfep) === 0) || (Number(download_cert_scol_active_Dfep) === 0) ? () => { } : ontoggleclicked_download}

                  />
                </p>

                <p>


                  <FormControlLabel
                    control={<IOSSwitch
                      disabled={(Number(Eval_notes_active_Mfep) === 0) || (Number(Eval_notes_active_Dfep) === 0)}
                      sx={{ m: 1 }}

                      checked={Number(Eval_notes_active_state) === 1}

                    />}
                    label={<span style={{
                      color: "#004d40",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 16,
                      fontWeight: 900,
                    }}>
                      {(Number(Eval_notes_active_Mfep) === 0) ? <>
                        السماح بمعاينة تقييم نتائج السداسي الحالي (مغلوق من الوزارة)
                      </> :
                        (Number(Eval_notes_active_Dfep) === 0) ? <>
                          السماح بمعاينة تقييم نتائج السداسي الحالي (مغلوق من المديرية)
                        </> :
                          <>
                            السماح بمعاينة تقييم نتائج السداسي الحالي
                          </>
                      }

                    </span>}
                    onClick={(Number(Eval_notes_active_Mfep) === 0) || (Number(Eval_notes_active_Dfep) === 0) ? () => { } : ontoggleclicked_notes}


                  />
                </p>


              </ThemeProvider>

            </CacheProvider>

            </>
            ) : null}

          </CardContent>





        </Card>

        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
            sx={{ marginTop: '50px' }}
          >
            <Alert
              {...snackbar}
              onClose={handleCloseSnackbar}
              sx={{
                display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
                , '& .MuiAlert-action': { marginRight: '8px' }
              }} // Adjust padding here
            />
          </Snackbar>

        )}


      </Container>

    </>
  );
}

export default SettingEtab;
