import * as React from 'react';

import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Button, Grid } from '@mui/material';

export default function HomePageMfp() {
    return (

        <Grid container spacing={2}
            sx={{
                mt: 2,
                mb: 2,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            



        </Grid>

    );
}