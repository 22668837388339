import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import { API_BASE_URL } from './config'; // Adjust the path if necessary

const useAuthentication_logged = () => {
  const navigate = useNavigate();

  // State variables to store different data points
  const [IDEncadrement, setIDEncadrement] = useState(null);
  const [IDCandidat, setIDCandidat] = useState(null);
  const [IDetablissement, setIDetablissement] = useState(null);
  const [IDEts_Form, setIDEts_Form] = useState(null);

  
  const [IDDFEP, setIDDFEP] = useState(null);
  const [IDNature_etsF, setIDNature_etsF] = useState(null);
  const [IDUtilisateur, setIDUtilisateur] = useState(null);
  const [UtilisateurNom, setUtilisateurNom] = useState(null);
  const [LoginTracesNom, setLoginTracesNom] = useState(null);
  
  const [IDapis_userslist, setIDapis_userslist] = useState(null);
  const [loadingauth, setLoadingAuth] = useState(true); // Initialize loading state to true
   
  // Authentication API endpoint
  const authlogged = `${API_BASE_URL}/api/authlogged`;

  useEffect(() => {
    let tokenEtab = sessionStorage.getItem("authData_73xJ#E") || localStorage.getItem("authData_73xJ#E");
    
    if (!tokenEtab) {
      setLoadingAuth(false); 
      return;
    }
    
    // Fetch data from the API
    fetch(authlogged, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenEtab}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setIDEncadrement(data.IDEncadrement);
          setIDCandidat(data.IDCandidat);
          setIDDFEP(data.IDDFEP);
          setIDNature_etsF(data.IDNature_etsF);
          setIDetablissement(data.IDetablissement);
          setIDEts_Form(data.IDEts_Form);

          
          setIDUtilisateur(data.IDUtilisateur);
          setIDapis_userslist(data.IDapis_userslist);
          setUtilisateurNom(data.UtilisateurNom);
          setLoginTracesNom(data.LoginTracesNom);
          
        }
      })
      .catch((error) => {
        console.error('Error fetching auth data:', error);
      })
      .finally(() => {
        setLoadingAuth(false); // Set loading state to false after fetching is done
      });
  }, [authlogged]);

  // Return all the state variables and loading status
  return {
    IDCandidat,
    IDEncadrement,
    IDetablissement,
    IDEts_Form,
    IDDFEP,
    IDNature_etsF,
    IDUtilisateur,
    UtilisateurNom,
    LoginTracesNom,
    IDapis_userslist,
    loadingauth,
  };
};

export default useAuthentication_logged;
