import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import { API_BASE_URL } from './config'; // Adjust the path if necessary

const useAuthentication_apisuser = () => {
  const navigate = useNavigate();

  const auth_apisuser = `${API_BASE_URL}/api/auth_apisuser`;
  const [IDetablissement, setIDetablissement] = useState(null);
  const [IDDFEP, setIDDFEP] = useState(null);
  const [IDCandidat, setIDCandidat] = useState(null);
  const [loadingauth, setloadingauth] = useState(true); // Initialize loading state to true


  const [Singup_active, setSingup_active] = useState(null);
  const [download_cert_scol_active, setDownloadCertScolActive] = useState(null);
  const [EditingInfo_active, setEditingInfoActive] = useState(null);
  const [Eval_notes_active, setEvalNotesActive] = useState(null);
  const [download_cert_inscription_active, setDownloadCertInscriptionActive] = useState(null);

  const [Singup_active_Dfep, setSingupActiveDfep] = useState(null);
  const [download_cert_scol_active_Dfep, setDownloadCertScolActiveDfep] = useState(null);
  const [EditingInfo_active_Dfep, setEditingInfoActiveDfep] = useState(null);
  const [Eval_notes_active_Dfep, setEvalNotesActiveDfep] = useState(null);
  const [download_cert_inscription_active_Dfep, setDownloadCertInscriptionActiveDfep] = useState(null);

  const [Singup_active_Mfep, setSingupActiveMfep] = useState(null);
  const [download_cert_scol_active_Mfep, setDownloadCertScolActiveMfep] = useState(null);
  const [EditingInfo_active_Mfep, setEditingInfoActiveMfep] = useState(null);
  const [Eval_notes_active_Mfep, setEvalNotesActiveMfep] = useState(null);
  const [download_cert_inscription_active_Mfep, setDownloadCertInscriptionActiveMfep] = useState(null);

  const [IDNature_etsF, setIDNature_etsF] = useState(null);
  const [IDapis_userslist, setIDapis_userslist] = useState(null);
  const [NumSem, setNumSem] = useState(null);
  const [Nomapis_userslist, setNomapis_userslist] = useState(null);



  useEffect(() => {
    fetch(auth_apisuser, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.IDapis_userslist) {
          if (Number(data.IDapis_userslist) > 0

          ) {
            console.log('data.IDapis_userslist', data.IDapis_userslist);
            setIDapis_userslist(data.IDapis_userslist);
            setNomapis_userslist(data.Nom);
          } else {
            navigate('/');
          }
        } else {
          navigate('/'); // Navigate to 404 if any of the values are missing
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        navigate('/');
      })
      .finally(() => {
        setloadingauth(false); // Set loading state to false after fetching is done
      });
  }, [navigate]); // Adding navigate to dependencies


  // Return all the constants
  return {
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    Nomapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  };


};

export default useAuthentication_apisuser;
