import React, { useState, useEffect, useRef } from "react";
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from "react-router-dom";
import CardContent from '@mui/material/CardContent';
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import { v4 as uuidv4 } from 'uuid'; // Importing UUID library
import PercentIcon from '@mui/icons-material/Percent';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import EditNoteIcon from '@mui/icons-material/EditNote';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { DateValidationError } from '@mui/x-date-pickers/models';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
} from "react-router-dom";
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography, Box } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReCAPTCHA from 'react-google-recaptcha';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from "@material-ui/core/styles";
import {
    useMediaQuery,
    useTheme,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import TitleIcon from '@mui/icons-material/Title';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import isEmail from 'validator/lib/isEmail';

const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
    direction: 'rtl',
    overrides: {
        MuiOutlinedInput: {
            root: {
                "&:hover $notchedOutline": {
                    borderColor: hoverColor
                },
                "&$focused $notchedOutline": {
                    borderColor: focusColor
                }
            },
            notchedOutline: {
                borderColor: defaultColor
            }
        }
    },
    fieldset: {
        fontFamily: '"Changa", sans-serif',
        fontSize: 45,
    }
});

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const themeChanga = createTheme({
    typography: {
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 16,
    },
});

const BootstrapTooltip = styled((props) => (
    <Tooltip {...props} arrow classes={{ popper: props.className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        ///color: theme.palette.common.black,
        color: 'orange',

    },

    [`& .${tooltipClasses.tooltip}`]: {
        ///backgroundColor: theme.palette.common.black,
        backgroundColor: 'orange',
        fontSize: '18px',
        fontWeight: 'bold',
        top: '-20px'
    },
}));

export default function SignUpInnovation() {

    const [isChecked, setIsChecked] = useState(false);
    const [open_info, setopen_info] = useState(false);


    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };


    const [loadingdata_mfep, setloadingdata_mfep] = useState(false);
    const [loadingupload, setloadingupload] = useState(false);
    const [fileUrl, setFileUrl] = useState('');

    const [Nom_NomFr_session, setNom_NomFr_session] = useState('');
    const [Nom_session, setNom_session] = useState('');
    const [Nom_specialite, setNom_specialite] = useState('');

    const [Nom_etablissement, setNom_etablissement] = useState('');
    const [Nom_mode_formation, setNom_mode_formation] = useState('');

    const [valueNom, setvalueNom] = useState('');
    const [valueNom2, setvalueNom2] = useState('');
    const [valueNom3, setvalueNom3] = useState('');
    const [valueNin, setvalueNin] = useState('');
    const [valueNin2, setvalueNin2] = useState('');
    const [valueNin3, setvalueNin3] = useState('');

    const refNin = useRef(null);
    const refNin2 = useRef(null);
    const refNin3 = useRef(null);

    const [valueNomFr, setvalueNomFr] = useState('');
    const refNomFr = useRef(null);
    const refNom = useRef(null);
    const refNom2 = useRef(null);
    const refNom3 = useRef(null);

    const refvalueDateNais = useRef(null);
    const refvalueDateNais2 = useRef(null);
    const refvalueDateNais3 = useRef(null);

    const [valuePrenom, setvaluePrenom] = useState('');
    const refPrenom = useRef(null);
    const refPrenomPere = useRef(null);
    const refPrenomPereFr = useRef(null);

    const [valueNomMere, setvalueNomMere] = useState('');
    const [valueNomMereFr, setvalueNomMereFr] = useState('');
    const [valuePrenomMereFr, setvaluePrenomMereFr] = useState('');
    const [valuePrenomMere, setvaluePrenomMere] = useState('');


    const [valueLieuNaisFr, setvalueLieuNaisFr] = useState('');
    const [valueLieuNais, setvalueLieuNais] = useState('');

    const [valueAdres, setvalueAdres] = useState('');

    const [valuePrenomPere, setvaluePrenomPere] = useState('');
    const [valuePrenomPereFr, setvaluePrenomPereFr] = useState('');


    const [valueAdresFr, setvalueAdresFr] = useState('');
    const [valueDateNais, setvalueDateNais] = useState('');
    const [valueDateNais2, setvalueDateNais2] = useState('');
    const [valueDateNais3, setvalueDateNais3] = useState('');


    const [msgerror, setmsgerror] = useState('');
    const [fieldnameerror, setfieldnameerror] = useState('');

    const refLieuNais = useRef(null);
    const refLieuNaisFr = useRef(null);

    const refNomMere = useRef(null);
    const refNomMereFr = useRef(null);
    const refPrenomMere = useRef(null);
    const refPrenomMereFr = useRef(null);

    const [valuePrenomFr, setvaluePrenomFr] = useState('');
    const refPrenomFr = useRef(null);

    const refDateNais = useRef(null);
    const refDateNais2 = useRef(null);
    const refDateNais3 = useRef(null);

    const [loading, setLoading] = useState(true);



    const [alertMessageEdit, setAlertMessageEdit] = useState(null);

    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState(null);

    const [open_recaptcha, setOpen_recaptcha] = React.useState(false);
    const [open_check, setopen_check] = React.useState(false);

    const [open_valueDateNais, setopen_valueDateNais] = React.useState(false);

    const handleClose_check = () => {
        if (!open_info) {
            setopen_check(false);
        }
        //

    };

    const handleClose_recaptcha = () => {

        setOpen_recaptcha(false);

    };

    const handleOpen_recaptcha = () => {
        setOpen_recaptcha(true);
    };


    const [OpenDialog, setOpenDialog] = useState(false);


    const [errorget, setErrorget] = useState();

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };


    const handleClickCloseDialog = () => {
        setOpenDialog(false);

        navigate(`/Pre_innovationpdf/${crypted_id}`);
    };


    const [Civ, setCiv] = React.useState(1);
    const [spaceproject, setspaceproject] = React.useState(1);
    const [natureproject, setnatureproject] = React.useState(1);

    const [crypted_id, setcrypted_id] = React.useState(null);
    const [IDinnovation, setIDinnovation] = React.useState(null);

    


    const handleChange_Civ = (event) => {
        setCiv(event.target.value);
    };

    const handleChange_spaceproject = (event) => {
        setspaceproject(event.target.value);
    };

    const handleChange_natureproject = (event) => {
        setnatureproject(event.target.value);
    };

    const [sifa, setsifa] = React.useState(1);
    const [sifa2, setsifa2] = React.useState(1);
    const [sifa3, setsifa3] = React.useState(1);

    const handleChange_sifa = (event) => {
        setsifa(event.target.value);
    };

    const handleChange_sifa2 = (event) => {
        setsifa2(event.target.value);
    };

    const handleChange_sifa3 = (event) => {
        setsifa3(event.target.value);
    };


    const handlewlyChange = (event, values) => {

        if (values) {
            setSelectedwlys(values);
            setIDwly_selected(values.value);
        } else {
            return;
        }


    }

    const handlebrancheChange = (event, values) => {

        if (values) {
            setSelectedbranche(values);
            setIdBranche_selected(values.value);
        } else {
            return;
        }


    }



    const handlebldChange = (event, values) => {
        ///alert(values.value);
        setSelectedcommuns(values);
        setIDcommun_selected(values.value);

    };





    const [recaptchaVerified, setRecaptchaVerified] = useState(false); // Initially assuming reCAPTCHA is verified
    const refReCAPTCHA = useRef(null);
    const refcheckbox = useRef(null);



    const handleRecaptchaChange = (value) => {
        // handle reCAPTCHA response
        if (value) {
            // reCAPTCHA verification succeeded
            setRecaptchaVerified(true);
            // Proceed with your logic here
        } else {
            // reCAPTCHA verification failed
            setRecaptchaVerified(false);
            // Handle the failure scenario
        }
    };
    /*
    const handleRecaptchaChange = (e) => {
      setToken(e.target.value); // e.target.value should hold the token
    };*/



    const ArabicCharactersRegex = /^[\u0600-\u06FF\s]+$/;
    const ArabicCharactersRegex_withdash = /^[\u0600-\u06FF\s-_]+$/;
    const ArabicCharactersRegex_address = /^[\u0600-\u06FF\s-_\/0-9]+$/;
    const LatinLettersRegex = /^[a-zA-Z\s]+$/;
    ///const LatinLettersRegex_email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    ///const LatinLettersRegex_email = /^[a-zA-Z\s]+$/;
    const LatinLettersRegex_email = /^[a-zA-Z0-9\s@._]+$/;


    const LatinLettersRegex_withdash = /^[a-zA-Z\s-_]+$/;
    const LatinLettersRegex_address = /^[a-zA-Z\s\-_\/0-9]+$/;


    const NumbersOnlyRegex = /^[0-9]+$/;
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const refemail = useRef(null);
    const refemail2 = useRef(null);
    const refemail3 = useRef(null);
    const refurl_video = useRef(null);
    const refcodeemail = useRef(null);
    const [valueemail, setValueemail] = useState('');
    const [valueemail2, setValueemail2] = useState('');
    const [valueemail3, setValueemail3] = useState('');

    const [valueurl_video, setvalueurl_video] = useState('');
    const [options_branche, setoptions_branche] = useState([]);
    const [isValidemail, setIsValidemail] = useState(false);
    const [isValidemail2, setIsValidemail2] = useState(false);
    const [isValidurl_video, setisValidurl_video] = useState(false);

    const refTEL1 = useRef(null);
    const refTEL12 = useRef(null);
    const refTEL13 = useRef(null);

    const [valueTEL1, setvalueTEL1] = useState('');
    const [valueTEL12, setvalueTEL12] = useState('');
    const [valueTEL13, setvalueTEL13] = useState('');

    const refTEL2 = useRef(null);
    const refTEL22 = useRef(null);
    const refTEL23 = useRef(null);

    const [valueTEL2, setvalueTEL2] = useState('');
    const [valueTEL22, setvalueTEL22] = useState('');
    const [valueTEL23, setvalueTEL23] = useState('');

    useEffect(() => {


        if (isEmail(valueemail)) {
            setIsValidemail(true);
            //console.log(valueemail+"   yes email");            
        } else {
            setIsValidemail(false);
            //console.log(valueemail+"   not email");                         
        }


    }, [valueemail]);

    useEffect(() => {
        const isValidUrl = (url) => {
            // Regular expression for URL validation
            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

            // Test the URL against the regex
            return urlRegex.test(url);
        };

        if (valueurl_video === '' || !isValidUrl(valueurl_video)) {
            // If the value is empty or not a valid URL, consider it invalid
            setisValidurl_video(false);
        } else {
            // Otherwise, consider it valid
            setisValidurl_video(true);
        }
    }, [valueurl_video]);


    const handleChangeemail = event => {
        const val = event.target.value;

        setValueemail(val);


    }

    const handleChangeemail2 = event => {
        const val = event.target.value;

        setValueemail2(val);


    }

    const handleChangeemail3 = event => {
        const val = event.target.value;

        setValueemail3(val);


    }

    const handleChangeurl_video = event => {
        const val = event.target.value;

        setvalueurl_video(val);


    }






    const handleInput_TEL1 = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue === '' ? '' : (inputValue.startsWith('0') ? inputValue : '0' + inputValue);

        if (((NumbersOnlyRegex.test(inputValue) || inputValue === '') && inputValue.length <= 10) || inputValue === '') {
            setvalueTEL1(inputValue);
        }
    };

    const handleInput_TEL12 = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue === '' ? '' : (inputValue.startsWith('0') ? inputValue : '0' + inputValue);

        if (((NumbersOnlyRegex.test(inputValue) || inputValue === '') && inputValue.length <= 10) || inputValue === '') {
            setvalueTEL12(inputValue);
        }
    };

    const handleInput_TEL13 = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue === '' ? '' : (inputValue.startsWith('0') ? inputValue : '0' + inputValue);

        if (((NumbersOnlyRegex.test(inputValue) || inputValue === '') && inputValue.length <= 10) || inputValue === '') {
            setvalueTEL13(inputValue);
        }
    };


    const handleInput_TEL2 = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue === '' ? '' : (inputValue.startsWith('0') ? inputValue : '0' + inputValue);

        if (((NumbersOnlyRegex.test(inputValue) || inputValue === '') && inputValue.length <= 10) || inputValue === '') {
            setvalueTEL2(inputValue);
        }
    };

    const handleInput_TEL22 = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue === '' ? '' : (inputValue.startsWith('0') ? inputValue : '0' + inputValue);

        if (((NumbersOnlyRegex.test(inputValue) || inputValue === '') && inputValue.length <= 10) || inputValue === '') {
            setvalueTEL22(inputValue);
        }
    };

    const handleInput_TEL23 = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue === '' ? '' : (inputValue.startsWith('0') ? inputValue : '0' + inputValue);

        if (((NumbersOnlyRegex.test(inputValue) || inputValue === '') && inputValue.length <= 10) || inputValue === '') {
            setvalueTEL23(inputValue);
        }
    };




    const refbtnsubmit = useRef(null);
    const refpassword1 = useRef(null);
    const refpassword2 = useRef(null);
    const refpasswordnow = useRef(null);
    const refAdres = useRef(null);
    const refAdresFr = useRef(null);



    const [valuesPassword, setValuesPassword] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const [valuesPasswordRe, setValuesPasswordRe] = React.useState({
        amount: '',
        PasswordRe: '',
        weight: '',
        weightRange: '',
        showPasswordRe: false,
    });

    const [valuespasswordnow, setValuespasswordnow] = React.useState({
        amount: '',
        passwordnow: '',
        weight: '',
        weightRange: '',
        showpasswordnow: false,
    });


    const handleChangePasswordRe = (prop) => (event) => {
        setValuesPasswordRe({ ...valuesPasswordRe, [prop]: event.target.value });
    };

    const handleChangepasswordnow = (prop) => (event) => {
        setValuespasswordnow({ ...valuespasswordnow, [prop]: event.target.value });
    };

    const handleClickShowPasswordRe = () => {
        setValuesPasswordRe({
            ...valuesPasswordRe,
            showPasswordRe: !valuesPasswordRe.showPasswordRe,
        });
    };

    const handleClickShowpasswordnow = () => {
        setValuespasswordnow({
            ...valuespasswordnow,
            showpasswordnow: !valuespasswordnow.showpasswordnow,
        });
    };

    const handleMouseDownPasswordRe = (event) => {
        event.preventDefault();
    };

    const handleMouseDownpasswordnow = (event) => {
        event.preventDefault();
    };


    const handleChangePassword = (prop) => (event) => {
        setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValuesPassword({
            ...valuesPassword,
            showPassword: !valuesPassword.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    const navigate = useNavigate();




    // Regular expression to match the format "YYYY/MM/DD"
    const dateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

    // Regular expression to match the format "YYYY/MM/DD" with digits for years, months, and days
    const fullDateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

    const handleInput_DateNais = (event) => {
        ///alert('inputValue');
        const inputValue = event.target.value;
        setvalueDateNais(inputValue);
    };


    const handleInput_LieuNais = (event) => {
        const inputValue = event.target.value;

        setvalueLieuNais(inputValue);

    };

    const handleInput_LieuNaisFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex_withdash.test(inputValue) || inputValue === '') {
            setvalueLieuNaisFr(inputValue);
        }
    };

    const handleInput_Nin = (event) => {
        const inputValue = event.target.value;

        if ((NumbersOnlyRegex.test(inputValue) && inputValue.length <= 18) || inputValue === '') {
            setvalueNin(inputValue);
        }
    };

    const handleInput_Nin2 = (event) => {
        const inputValue = event.target.value;

        if ((NumbersOnlyRegex.test(inputValue) && inputValue.length <= 18) || inputValue === '') {
            setvalueNin2(inputValue);
        }
    };

    const handleInput_Nin3 = (event) => {
        const inputValue = event.target.value;

        if ((NumbersOnlyRegex.test(inputValue) && inputValue.length <= 18) || inputValue === '') {
            setvalueNin3(inputValue);
        }
    };



    const refprojectitle = useRef(null);
    const refwly = useRef(null);

    const [valueprojectitle, setvalueprojectitle] = useState('');


    const handleInput_projectitle = (event) => {
        const inputValue = event.target.value;
        setvalueprojectitle(inputValue);

    };


    const refprojectitle_average = useRef(null);

    const Refselectedbranche = useRef(null);
    const [valueprojectitle_average, setvalueprojectitle_average] = useState('');


    const handleInput_projectitle_average = (event) => {
        let inputValue = event.target.value;

        // Remove any non-numeric characters from the input value
        inputValue = inputValue.replace(/\D/g, '');

        // Ensure the value is within the range of 0 to 100
        if (inputValue === '') {
            setvalueprojectitle_average('');
        } else {
            const numericValue = parseInt(inputValue, 10);
            const clampedValue = Math.min(Math.max(numericValue, 0), 100);
            setvalueprojectitle_average(clampedValue.toString());
        }
    };





    const refetabname = useRef(null);
    const [valueetabname, setvalueetabname] = useState('');


    const handleInput_etabname = (event) => {
        const inputValue = event.target.value;
        setvalueetabname(inputValue);

    };


    const handleInput_Nom = (event) => {
        const inputValue = event.target.value;

        setvalueNom(inputValue);

    };
    const handleInput_Nom2 = (event) => {
        const inputValue = event.target.value;

        setvalueNom2(inputValue);

    };

    const handleInput_Nom3 = (event) => {
        const inputValue = event.target.value;

        setvalueNom3(inputValue);

    };


    const handleInput_NomFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvalueNomFr(inputValue);
        }
    };


    const handleInput_Adres = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex_address.test(inputValue) || inputValue === '') {
            setvalueAdres(inputValue);
        }
    };

    const handleInput_PrenomMere = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomMere(inputValue);
        }
    };

    const handleInput_NomMere = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvalueNomMere(inputValue);
        }
    };

    const handleInput_Prenom = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenom(inputValue);
        }
    };

    const handleInput_PrenomPere = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomPere(inputValue);
        }
    };


    const handleInput_PrenomFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomFr(inputValue);
        }
    };



    const handleInput_AdresFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex_address.test(inputValue) || inputValue === '') {
            setvalueAdresFr(inputValue);
        }
    };



    const handleInput_PrenomMereFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomMereFr(inputValue);
        }
    };

    const handleInput_NomMereFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvalueNomMereFr(inputValue);
        }
    };


    const handleInput_PrenomPereFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomPereFr(inputValue);
        }
    };





    const onsubmitclicked = async (e) => {
        e.preventDefault();

        try {
            const data = {
                selectedwlys: IDwly_selected,
                valueprojectitle: valueprojectitle,
                selectedbranche: IdBranche_selected,
                valueprojectitle_average: valueprojectitle_average,
                value_description: value_description,
                valueurl_video: valueurl_video,
                valueNom: valueNom,
                valueNin: valueNin,
                valueemail: valueemail,
                valueTEL1: valueTEL1,
                valueTEL2: valueTEL2,
                valueNom2: valueNom2,
                valueNin2: valueNin2,
                valueemail2: valueemail2,
                valueTEL12: valueTEL12,
                valueTEL22: valueTEL22,
                valueNom3: valueNom3,
                valueNin3: valueNin3,
                valueemail3: valueemail3,
                valueTEL13: valueTEL13,
                valueTEL23: valueTEL23,
                sifa: sifa === 1 ? "متربص" : sifa === 2 ? "متمهن" : sifa === 3 ? "أستاذ" : "متخرج",
                sifa2: sifa2 === 1 ? "متربص" : sifa2 === 2 ? "متمهن" : sifa2 === 3 ? "أستاذ" : "متخرج",
                sifa3: sifa3 === 1 ? "متربص" : sifa3 === 2 ? "متمهن" : sifa3 === 3 ? "أستاذ" : "متخرج",
                spaceproject:   spaceproject+' متر مربع ',  
                natureproject: natureproject === 1 ? "محاكاة" : natureproject === 2 ? "نمذجة" : "مسوق",
                valueDateNais: dayjs(valueDateNais).format('YYYY-MM-DD'),
                valueDateNais2: dayjs(valueDateNais2).format('YYYY-MM-DD'),
                valueDateNais3: dayjs(valueDateNais3).format('YYYY-MM-DD'),

 
            };

            const responseFromApi = await axios.post(`${API_BASE_URL}/api/insertnewinnovation`, data);

            // Log the entire response data object to the console
            console.log('Response data:', responseFromApi.data);

            // Check if response has data
            if (responseFromApi.data && responseFromApi.data.crypted_id) {
                ///alert(responseFromApi.data.crypted_id);
                setcrypted_id(responseFromApi.data.crypted_id);
                setIDinnovation(responseFromApi.data.IDinnovation);
                // Handle successful response
                setOpenDialog(true);
            } else {
                // Handle empty or missing crypted_id in the response
                console.error('Missing crypted_id in response data');
            }


        } catch (error) {
            // Check if error has response
            if (error.response) {
                // Check if error response has data
                if (error.response.data && error.response.data.errors) {
                    // Extract error messages from the response
                    const errors = error.response.data.errors;

                    // Construct error message string
                    let errorMessage = '';
                    for (const field in errors) {
                        errorMessage += `${errors[field][0]}\n`;
                    }

                    // Display error message
                    //// alert(errorMessage);
                } else {
                    // Handle error without error message
                    ////alert('An error occurred');
                }
            } else {
                // Handle network error or other types of errors
                //alert('An error occurred');
            }
        }
    };



    const [selectedwlys, setSelectedwlys] = useState(null);
    const [selectedbranche, setSelectedbranche] = useState(null);

    const [IDwly_selected, setIDwly_selected] = useState(1);
    const [IdBranche_selected, setIdBranche_selected] = useState(null);

    const [IDcommun_selected, setIDcommun_selected] = useState(0);


    const [options_wly, setoptions_wly] = useState([]);
    let options_bran = [];

    const apiUrl_wly = `${API_BASE_URL}/api/all_wilayas`;
    const apiUrl_branche = `${API_BASE_URL}/api/getlistofallbranche`;

    // Fetch data from the API when the component mounts
    useEffect(() => {

        setloadingdata_mfep(true);

        fetch(apiUrl_wly)
            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of objects
                const Wlylists = data.data; // Extracting the relevant data
                ////console.log('Wlylists: '+Wlylists);
                /////////////Wilayets///////////////////

                const WlyOnly = Wlylists.filter(Wlylist => Wlylist.libelleArabe !== null);

                /*
                IDwilayas
                id
                code
                clibelleArabe
                clibelleLatin
                libelleArabe
                libelleLatin

                */

                // Sorting in descending order (from Z to A)
                options_bran = WlyOnly.sort((a, b) => Number(a.IDwilayas) - Number(b.IDwilayas))
                    .map((Wlylist, i) => ({
                        value: Wlylist.IDwilayas,
                        label: Wlylist.code + ' | ' + Wlylist.libelleArabe + ' | ' + Wlylist.libelleLatin,
                        indexvalue: i
                    }));

                setoptions_wly(options_bran);


            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });



        fetch(apiUrl_branche)

            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of objects
                const Wlylists = data.data; // Extracting the relevant data

                /////////////Wilayets///////////////////

                const WlyOnly = Wlylists.filter(Wlylist => Wlylist.Nom !== null);

                /*
                IDBranche
                Nom
                NomFr
                Code
                activee
                numod
                
                */

                // Sorting in descending order (from Z to A)
                options_bran = WlyOnly.sort((a, b) => Number(b.IDBranche) - Number(a.IDBranche))
                    .map((Wlylist, i) => ({
                        value: Wlylist.IDBranche,
                        label: Wlylist.Code + ' | ' + Wlylist.Nom + ' | ' + Wlylist.NomFr,
                        indexvalue: i
                    }));

                ///// options_bran.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

                setoptions_branche(options_bran);

                /// console.log('options_Wly[0].value:'+options_bran[0].value);
                setloadingdata_mfep(false);

            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });


    }, []);

    /////

    /*
    useEffect(() => {
        if (options_wly && options_wly.length > 0) {
            setSelectedwlys(options_wly[0]);
            setIDwly_selected(options_wly[0].value);
        }

    }, [options_wly]);
    */


    const [selectedcommuns, setSelectedcommuns] = useState(null);
    const [options_commun, setoptions_commun] = useState([]);
    let options_commu = [];


    useEffect(() => {
        if (options_commun && options_commun.length > 0) {
            setSelectedcommuns(options_commun[0]);
            setIDcommun_selected(options_commun[0].value);
            /// alert(options_commun[0].value);
        }

    }, [options_commun]);




    // Fetch data from the API when the component mounts
    useEffect(() => {
        if (!IDwly_selected || isNaN(IDwly_selected) || IDwly_selected <= 0) {
            return;
        }

        const apiUrl_commun = `${API_BASE_URL}/api/all_Communn/${IDwly_selected}`;

        setloadingdata_mfep(true);

        fetch(apiUrl_commun)
            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of objects
                const communlists = data.data; // Extracting the relevant data
                ///console.log('communlists: ' + communlists);

                const communOnly = communlists.filter(communlist => communlist.Nom !== null);

                /*
               IDCommunn
                IDWilayaa
                Nom
                NomFr
                Code
                CodeNouv
                NomEtat
                CODE_GEO


                */

                // Sorting in descending order (from Z to A)
                options_commu = communOnly.sort((a, b) => Number(a.IDCommunn) - Number(b.IDCommunn))
                    .map((communlist, i) => {
                        let label;
                        if (communlist.NomFr && communlist.NomFr.trim() !== '') {
                            label = `${communlist.Nom} | ${communlist.NomFr}`;
                        } else {
                            label = communlist.Nom;
                        }
                        return {
                            value: communlist.IDCommunn,
                            label: label,
                            indexvalue: i
                        };
                    });



                setoptions_commun(options_commu);

            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });




    }, [IDwly_selected]);



    const useStyles_listdfep = makeStyles((theme) => ({
        direction: 'rtl',
        root: {
            "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                // Default transform is "translate(14px, 20px) scale(1)""
                // This lines up the label with the initial cursor position in the input
                // after changing its padding-left.
                transform: "translate(34px, 20px) scale(1);",
                fontFamily:
                    '"Tajawal", sans-serif',
                fontSize: 18,
                direction: "rtl",

            }
        },
        inputRoot: {

            color: "#004d40",


            // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                fontFamily: '"Tajawal", sans-serif',
                fontWeight: 700,
                color: "#004d40",
                // Default left padding is 6px
                paddingLeft: 26
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "2px",
                borderColor: "#004d40"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderWidth: "2px",
                borderColor: "#00796b"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "2px",
                borderColor: "green"
            }
        }
    }));

    const classes_listdfep = useStyles_listdfep();




    const useStyles = makeStyles({
        cancelBtn: {
            backgroundColor: "#004d40", // أخضر
            color: "#fff",
            "&:hover": {
                backgroundColor: "#388e3c",
            },
        },
        yesBtn: {
            backgroundColor: "#f44336", // أحمر
            color: "#fff",
            "&:hover": {
                backgroundColor: "#d32f2f",
            },
        },
    });

    const classes = useStyles();


    const [file, setFile] = useState(null);
    const [filename_uploaded, setfilename_uploaded] = useState(null);


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        if (selectedFile) {
            ///setFileUrl(URL.createObjectURL(selectedFile));
            setFileUrl(selectedFile.name);
            setfilename_uploaded(null);
            ///setFileUrl(filePath);


        }
    };



    const ref_description = useRef(null);
    const [value_description, setvalue_description] = useState('');


    const handleChange_description = (event) => {

        // Limit input to 1000 characters
        const inputValue = event.target.value.slice(0, 1000);
        setvalue_description(inputValue);

    };


    const handleFileClick = (e) => {
        setfilename_uploaded(null);
    };

    const handleUpload = () => {
        if (file) {
            // Check file size
            const maxSizeInBytes = 10 * 1024 * 1024; // 10MB in bytes
            if (file.size > maxSizeInBytes) {
                alert('File size exceeds 10MB. Please select a smaller file.');
                return; // Stop further processing
            }
            setloadingupload(true);
            const formData = new FormData();
            const fileId = uuidv4(); // Generate UUID

            formData.append('file', file, `${fileId}.${file.name.split('.').pop()}`); // Rename the file with the ID

            fetch(`${API_BASE_URL}/api/uploadSignUpInnovation`, {
                method: 'POST',
                body: formData,
            })
                .then(response => {
                    if (response.ok) {
                        // File uploaded successfully
                        return response.json(); // Parse JSON response
                    } else {
                        setloadingupload(false);
                        // Error occurred
                        throw new Error('Failed to upload file');
                    }
                })
                .then(data => {
                    // Check if the response contains an error message
                    if (data.error) {
                        alert('Failed to upload file: ' + data.error);
                    } else {
                        // Access the filename from the response data
                        const { filename, message } = data;
                        setfilename_uploaded(filename);
                        ///alert(`File uploaded successfully: ${filename}`); // Display filename in alert
                        ///alert('تمّ تحميل الملف بنجاح.');
                    }
                    setloadingupload(false);
                })
                .catch(error => {
                    // Handle error
                    ///console.error('Error:', error.message);
                    alert('Failed to upload file: ' + error.message);
                    setloadingupload(false);
                });
        } else {
            alert('Please select a file.');
        }
    };


    // Calculate age
    const currentDate = new Date();
    const birthDate = new Date(valueDateNais);
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    return (
        <>
            {loadingdata_mfep ? <>

                <Box sx={{ width: '100%' }}>
                    <LinearProgress color="success" />
                </Box>

            </> : null}

            <div>

               {/*  <Header /> */}
                <form>
                    <Container sx={{ textAlign: 'center', mx: 'auto', width: '100%' }}>
                        <Grid  >


                            <Box
                                sx={{
                                    marginTop: 4,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',

                                }}
                            >

                                <Typography
                                    sx={{
                                        color: "#0d5193", // Text color
                                        fontFamily: '"Tajawal", sans-serif', // Font family
                                        fontWeight: 900, // Font weight
                                        fontSize: 20, // Font size
                                        backgroundColor: "#d6f6e045", // Background color
                                        border: "2px solid #11da4d36", // Border
                                        padding: "10px", // Padding
                                        paddingLeft: "20px",
                                    }}
                                >

                                    <DescriptionIcon style={{ marginRight: '5px', marginBottom: '-6px' }} />
                                    إستمارة التسجيل للصالون الوطني للإبداع والإبتكار طبعة 2024.


                                </Typography>


                            </Box>


                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }}>


                                <Grid item xs={isMatch ? 12 : 4} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <div dir="rtl">
                                                <Autocomplete

                                                    noOptionsText={'حدث الصفحة مرة أخرى'}
                                                    classes={classes_listdfep}
                                                    id="combo-box-demowly"
                                                    value={selectedwlys}
                                                    options={options_wly}
                                                    defaultValue={options_wly[0]}
                                                    onChange={handlewlyChange}

                                                    renderOption={(props, option) => {
                                                        const { label, indexvalue } = option;
                                                        return (
                                                            <span
                                                                {...props}
                                                                style={{
                                                                    backgroundColor: indexvalue % 2 === 0 ? '#004d40' : '#FFFFFF',
                                                                    color: '#004d40',
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: 18,
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                {indexvalue === -1 ? '- ' + label : label}
                                                            </span>
                                                        );
                                                    }}

                                                    renderInput={(params) => (
                                                        <div dir="rtl">
                                                            <Box display="flex" justifyContent="center" dir="rtl">

                                                                <TextField
                                                                    {...params}
                                                                    classes={classes_listdfep}
                                                                    placeholder="الولاية"
                                                                    dir="rtl" // Added here for consistent RTL alignment
                                                                    inputRef={refwly}
                                                                    error={!selectedwlys}
                                                                    helperText={
                                                                        !selectedwlys
                                                                            ? 'إختر ولاية'
                                                                            : ''
                                                                    }

                                                                    tabIndex={1}

                                                                    fullWidth

                                                                    sx={{

                                                                        '& .MuiFormHelperText-root': {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: '14px',
                                                                            color: '#004d40',
                                                                            fontWeight: 700
                                                                        },

                                                                        '& .MuiFormLabel-root': {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: '16px',
                                                                            color: '#004d40'
                                                                        },
                                                                        '& .MuiInputBase-input': {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: '18px',
                                                                            color: '#004d40',
                                                                            fontWeight: 700
                                                                        },
                                                                        '& label.Mui-focused': {
                                                                            fontSize: '16px',
                                                                            color: '#004d40',
                                                                            fontWeight: 700
                                                                        },
                                                                        '& .MuiInput-underline:after': {
                                                                            borderBottomColor: '#004d40',
                                                                        },
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                fontFamily: '"Tajawal", sans-serif',
                                                                                fontSize: '16px',
                                                                                borderColor: '#004d40',
                                                                                borderWidth: "2px",
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: 'green',
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: 'green',
                                                                            },
                                                                        }
                                                                    }}
                                                                />

                                                            </Box>
                                                        </div>
                                                    )}


                                                />
                                            </div>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>


                                {/*  Project title  - عنوان المشروع */}

                                <Grid item xs={isMatch ? 12 : 8} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueprojectitle"
                                                        value={valueprojectitle}
                                                        error={!valueprojectitle}
                                                        helperText={
                                                            !valueprojectitle
                                                                ? 'أكتب عنوان المشروع'
                                                                : ''
                                                        }

                                                        inputRef={refprojectitle}
                                                        onInput={handleInput_projectitle}

                                                        InputProps={{
                                                            inputProps: { tabIndex: 2 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <TitleIcon />

                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueprojectitle" label="عنوان المشروع" placeholder="عنوان المشروع" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>


                            </Grid>



                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }} >


                                <Grid item xs={isMatch ? 12 : 6} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <Autocomplete
                                                        noOptionsText={'حدث الصفحة مرة أخرى'}
                                                        classes={classes_listdfep}
                                                        id="combo-box-demobranche"
                                                        value={selectedbranche}

                                                        options={options_branche}
                                                        onChange={handlebrancheChange}
                                                        renderOption={(props, option) => {
                                                            const { label, indexvalue } = option;
                                                            return (
                                                                <span
                                                                    {...props}
                                                                    style={{
                                                                        backgroundColor: indexvalue % 2 === 0 ? '#004d40' : '#FFFFFF',
                                                                        color: '#004d40',
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: 18,
                                                                        fontWeight: 700,
                                                                    }}
                                                                >
                                                                    {indexvalue === -1 ? '- ' + label : label}
                                                                </span>
                                                            );
                                                        }}

                                                        renderInput={(params) => (

                                                            <TextField
                                                                {...params}
                                                                placeholder="المجال أو شعبة المشروع"
                                                                error={!selectedbranche}
                                                                helperText={
                                                                    !selectedbranche
                                                                        ? 'إختر مجال أو شعبة المشروع'
                                                                        : ''
                                                                }
                                                                tabIndex={3}
                                                                dir="rtl" // Add dir="rtl" here
                                                                inputRef={Refselectedbranche}
                                                                fullWidth
                                                                sx={{

                                                                    '& .MuiFormHelperText-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '14px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },

                                                                    '& .MuiFormLabel-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        color: '#004d40'
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '18px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& label.Mui-focused': {
                                                                        fontSize: '16px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#004d40',
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: '16px',
                                                                            borderColor: '#004d40',
                                                                            borderWidth: "2px",
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                    }
                                                                }}
                                                            />


                                                        )}



                                                    />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>



                                {/*  Project achievement rate  -     معدل / نسبة إنجاز المشروع */}

                                <Grid item xs={isMatch ? 12 : 6} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueprojectitle_average"
                                                        value={valueprojectitle_average}
                                                        error={!valueprojectitle_average}
                                                        helperText={
                                                            !valueprojectitle_average
                                                                ? 'أدخل معدل / نسبة إنجاز المشروع '
                                                                : ''
                                                        }
                                                        inputRef={refprojectitle_average}
                                                        onInput={handleInput_projectitle_average}

                                                        InputProps={{
                                                            inputProps: { tabIndex: 4 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PercentIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueprojectitle" label=" نسبة إنجاز المشروع " placeholder=" نسبة إنجاز المشروع " variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>




                            </Grid>



                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        value={value_description}
                                                        error={!value_description}
                                                        helperText={
                                                            !value_description
                                                                ? ' أكتب نبذة عن المشروع (حد أقصى 1000 حرف)'
                                                                : ''
                                                        }
                                                        inputRef={ref_description}
                                                        onChange={handleChange_description}
                                                        aria-label="textarea with character limit"
                                                        multiline
                                                        rows={5}
                                                        placeholder=" نبذة عن المشروع (حد أقصى 1000 حرف)"
                                                        style={{ width: '100%' }}

                                                        InputProps={{
                                                            inputProps: { tabIndex: 5 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EditNoteIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuedescription" label="نبذة عن المشروع"
                                                        variant="outlined" />

                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>

                                </Grid>
                            </Grid>



                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }} >


                                {/* مساحة عرض المشروع */}
                                <Grid item xs={isMatch ? 12 : 6} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth>
                                                        <InputLabel sx={{
                                                            fontFamily: '"Tajawal", sans-serif',
                                                            fontSize: '16px',
                                                            color: '#004d40'
                                                        }} id="spaceproject-simple-select-label">مساحة عرض المشروع</InputLabel>
                                                        <Select
                                                            labelId="spaceproject-simple-select-label"
                                                            id="spaceproject-simple-select"
                                                            value={spaceproject}
                                                            label="spaceproject"

                                                            onChange={handleChange_spaceproject}


                                                            sx={{

                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'green',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'green',
                                                                    borderWidth: "2px",
                                                                },

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },

                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    color: '#004d40'
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: 'green',
                                                                    },

                                                                },

                                                                '& .MuiList-root.MuiMenu-list': { // Combine both classes to target the correct element
                                                                    '& .MuiMenuItem-root:nth-of-type(odd)': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: 18,
                                                                        fontWeight: 700,
                                                                        backgroundColor: 'lightgray', // Background color for odd items
                                                                    },
                                                                    '& .MuiMenuItem-root:nth-of-type(even)': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: 18,
                                                                        fontWeight: 700,
                                                                        backgroundColor: 'lightblue', // Background color for even items
                                                                    },
                                                                },
                                                            }}
                                                        >


                                                            {[1, 2, 3, 4].map((value, index) => (
                                                                <MenuItem key={value} value={value} style={{ fontSize: 18, fontWeight: 700, color: '#004d40', backgroundColor: index % 2 === 0 ? '#004d40' : '#FFFFFF' }}>
                                                                    {value} متر مربع
                                                                </MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>

                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>

                                </Grid>



                                {/* طبيعة المشروع */}
                                <Grid item xs={isMatch ? 12 : 6} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth>
                                                        <InputLabel sx={{
                                                            fontFamily: '"Tajawal", sans-serif',
                                                            fontSize: '16px',
                                                            color: '#004d40'
                                                        }} id="natureproject-simple-select-label">طبيعة المشروع</InputLabel>
                                                        <Select
                                                            labelId="natureproject-simple-select-label"
                                                            id="natureproject-simple-select"
                                                            value={natureproject}
                                                            label="natureproject"

                                                            onChange={handleChange_natureproject}




                                                            sx={{

                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'green',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'green',
                                                                    borderWidth: "2px",
                                                                },

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },

                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    color: '#004d40'
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                }
                                                            }}
                                                        >
                                                            {[1, 2, 3].map((value, index) => (
                                                                <MenuItem key={value} value={value} style={{ fontSize: 18, fontWeight: 700, color: '#004d40', backgroundColor: index % 2 === 0 ? '#004d40' : '#FFFFFF' }}>
                                                                    {value === 1 ? "محاكاة" : value === 2 ? "نمذجة" : "مسوق"}
                                                                </MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>

                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>

                                </Grid>


                            </Grid>


                            {/*  رابط فيديو المشروع */}
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">

                                                    <Typography
                                                        sx={{
                                                            color: "#0d5193", // Text color
                                                            fontFamily: '"Tajawal", sans-serif', // Font family
                                                            fontWeight: 900, // Font weight
                                                            fontSize: 14, // Font size
                                                            backgroundColor: "#d6f6e045", // Background color
                                                            direction: 'rtl',
                                                            textAlign: 'left',
                                                            mb: 1
                                                        }}
                                                    >
قم بتصوير فيديو قصير يشرح مشروعك وارفعه على أي موقع إلكتروني يسمح برفع الفيديوهات، مثل يوتيوب أو قوقل درايف، وما إلى ذلك. ثم انسخ رابط الفيديو والصقه في حقل 'رابط فيديو المشروع' أدناه. تأكد من اختبار الرابط قبل لصقه للتأكد من إمكانية مشاهدته من قبل اللجنة
</Typography>

                                                    <FormControl fullWidth variant="outlined"


                                                    >

                                                        <TextField
                                                            sx={{
                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',

                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '22px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700,

                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",

                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                }
                                                            }}
                                                            error={isValidurl_video === false}
                                                            label="رابط فيديو المشروع"
                                                            placeholder="رابط فيديو المشروع"
                                                            helperText={isValidurl_video === false ? "تأكد من أن الرابط يبدأ بـ 'ftp'، 'http'، أو 'https' ليكون صالحًا" : null}
                                                            variant="outlined"
                                                            size="small"
                                                            name="valueurl_video"
                                                            value={valueurl_video}
                                                            inputRef={refurl_video}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => handleChangeurl_video(e)}
                                                            InputProps={{
                                                                inputProps: { tabIndex: 6 },
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <YouTubeIcon />
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />


                                                    </FormControl>


                                                </div>
                                            </Box>
                                        </ThemeProvider>

                                    </CacheProvider>



                                </Grid>

                            </Grid>



                            <Box
                                sx={{
                                    marginTop: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',

                                }}
                            >

                                <Typography
                                    sx={{
                                        color: "#0d5193", // Text color
                                        fontFamily: '"Tajawal", sans-serif', // Font family
                                        fontWeight: 900, // Font weight
                                        fontSize: 20, // Font size
                                        backgroundColor: "#d6f6e045", // Background color
                                        border: "2px solid #11da4d36", // Border
                                        padding: "10px", // Padding
                                        paddingLeft: "20px",
                                    }}
                                >

                                    <PersonIcon style={{ marginRight: '5px', marginBottom: '-6px' }} />
                                    معلومات العضو الأول (إجباري)


                                </Typography>


                            </Box>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }} >


                                {/* إسم و لقب العضو الأول بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueNom"
                                                        value={valueNom}
                                                        error={!valueNom}
                                                        helperText={
                                                            !valueNom
                                                                ? 'الإسم و اللقب (إجباري)'
                                                                : ''
                                                        }
                                                        inputRef={refNom}
                                                        onInput={handleInput_Nom}


                                                        InputProps={{
                                                            inputProps: { tabIndex: 7 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNom`}

                                                        label="الإسم و اللقب"
                                                        placeholder="الإسم و اللقب"

                                                        variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                                {/*  تاريخ الميلاد */}

                                <Grid item xs={isMatch ? 12 : 3} sx={{ mt: '-7px' }} >

                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">

                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker']}>
                                                            <DatePicker
                                                                ref={refvalueDateNais}
                                                                name="valueDateNais"
                                                                value={valueDateNais}
                                                                slotProps={{
                                                                    textField: {
                                                                        error: !valueDateNais || age < 15,

                                                                        helperText: !valueDateNais || age < 15 ? 'يجب أن يكون على الأقل 15 عامًا' : '',
                                                                    },
                                                                }}

                                                                inputRef={refDateNais}

                                                                onChange={(newValue) => setvalueDateNais(newValue)}
                                                                format="YYYY/MM/DD" // Set the desired format here
                                                                placeholder="YYYY/MM/DD"

                                                                label=" تاريخ ميلاد "



                                                                sx={{

                                                                    '& .MuiFormHelperText-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '14px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },

                                                                    '& .MuiFormLabel-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        color: '#004d40'
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '18px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& label.Mui-focused': {
                                                                        fontSize: '16px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#004d40',
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: '16px',
                                                                            borderColor: '#004d40',
                                                                            borderWidth: "2px",
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                    }
                                                                }}

                                                            />


                                                        </DemoContainer>
                                                    </LocalizationProvider>




                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>



                                {/* الصفة*/}
                                <Grid item xs={isMatch ? 12 : 3} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth>
                                                        <InputLabel sx={{
                                                            fontFamily: '"Tajawal", sans-serif',
                                                            fontSize: '16px',
                                                            color: '#004d40'
                                                        }} id="sifa-simple-select-label">الصفة</InputLabel>
                                                        <Select
                                                            labelId="sifa-simple-select-label"
                                                            id="sifa-simple-select"
                                                            value={sifa}
                                                            label="sifa"

                                                            onChange={handleChange_sifa}




                                                            sx={{

                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'green',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'green',
                                                                    borderWidth: "2px",
                                                                },

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },

                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    color: '#004d40'
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                }
                                                            }}
                                                        >
                                                            {["متربص", "متمهن", "أستاذ", "متخرج"].map((value, index) => (
                                                                <MenuItem key={value} value={index + 1} style={{ fontSize: 18, fontWeight: 700, color: '#004d40', backgroundColor: index % 2 === 0 ? '#004d40' : '#FFFFFF' }}>
                                                                    {value}
                                                                </MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>

                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>

                                </Grid>


                            </Grid>





                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }}>

                                {/* رقم التعريف الوطني NIN */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">

                                                    <TextField

                                                        fullWidth
                                                        name="valueNin"
                                                        value={valueNin}
                                                        inputRef={refNin}
                                                        onInput={handleInput_Nin}
                                                        error={!NumbersOnlyRegex.test(valueNin) || !valueNin || valueNin.length !== 18}

                                                        helperText={
                                                            !NumbersOnlyRegex.test(valueNin) || !valueNin || valueNin.length !== 18
                                                                ? 'رقم التعريف الوطني NIN يجب ان يحتوي على 18 رقم'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 8 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <NumbersIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomnin`}

                                                        label="رقم التعريف الوطني NIN "
                                                        placeholder="رقم التعريف الوطني NIN "

                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>



                                {/* البريد الإلكتروني */}
                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth variant="outlined"


                                                    >

                                                        <TextField

                                                            sx={{

                                                                //direction:'ltr',

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700

                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    dir: 'rtl',

                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '22px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700,
                                                                    //text-align: right,



                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",

                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                    '&.Mui-focused fieldset': {

                                                                        borderColor: 'green',
                                                                    },
                                                                }
                                                            }}

                                                            inputRef={refemail}
                                                            error={isValidemail === false}
                                                            label="البريد الإلكتروني"
                                                            placeholder="البريد الإلكتروني"


                                                            helperText={isValidemail === false ? "أدخل عنوان بريد إلكتروني صالح" : <>


                                                            </>}

                                                            variant="outlined"
                                                            size={'small'}
                                                            name="valueemail"
                                                            value={valueemail}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => handleChangeemail(e)}



                                                            InputProps={{
                                                                inputProps: { tabIndex: 9 },
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <EmailIcon />
                                                                    </InputAdornment>
                                                                )


                                                            }}


                                                        />

                                                    </FormControl>


                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                            </Grid>





                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }}>

                                {/* رقم الهاتف 01 */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueTEL1"
                                                        value={valueTEL1}
                                                        inputRef={refTEL1}
                                                        onInput={handleInput_TEL1}
                                                        error={!NumbersOnlyRegex.test(valueTEL1)}

                                                        helperText={
                                                            (!NumbersOnlyRegex.test(valueTEL1) || valueTEL1.length !== 10)
                                                                ? 'أدخل رقم هاتف صالح'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 10 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhonelinkRingIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomtel1`}

                                                        label="رقم الهاتف 01"
                                                        placeholder="رقم الهاتف 01"
                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                                {/* رقم الهاتف 02 */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueTEL2"
                                                        value={valueTEL2}
                                                        inputRef={refTEL2}
                                                        onInput={handleInput_TEL2}

                                                        helperText={
                                                            (!valueTEL2 || (NumbersOnlyRegex.test(valueTEL2) && valueTEL2.length == 10))
                                                                ? ''
                                                                : 'أدخل رقم هاتف صالح'
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 11 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhonelinkRingIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomtel2`}

                                                        label="رقم الهاتف 02 (إختياري)"
                                                        placeholder="رقم الهاتف 02 (إختياري)"

                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>

                            </Grid>


                            <Box
                                sx={{
                                    marginTop: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',

                                }}
                            >

                                <Typography
                                    sx={{
                                        color: "#0d5193", // Text color
                                        fontFamily: '"Tajawal", sans-serif', // Font family
                                        fontWeight: 900, // Font weight
                                        fontSize: 20, // Font size
                                        backgroundColor: "#d6f6e045", // Background color
                                        border: "2px solid #11da4d36", // Border
                                        padding: "10px", // Padding
                                        paddingLeft: "20px",
                                    }}
                                >

                                    <PersonIcon style={{ marginRight: '5px', marginBottom: '-6px' }} />
                                    معلومات العضو الثاني (اختياري) [إذا وُجِد]


                                </Typography>


                            </Box>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }} >


                                {/* إسم و لقب العضو الثاني بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueNom2"
                                                        value={valueNom2}
                                                        inputRef={refNom2}
                                                        onInput={handleInput_Nom2}


                                                        InputProps={{
                                                            inputProps: { tabIndex: 12 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNom2`}

                                                        label="الإسم و اللقب"
                                                        placeholder="الإسم و اللقب"

                                                        variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                                {/*  تاريخ الميلاد */}

                                <Grid item xs={isMatch ? 12 : 3} sx={{ mt: '-7px' }} >

                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">

                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker']}>
                                                            <DatePicker
                                                                ref={refvalueDateNais2}
                                                                name="valueDateNais2"
                                                                value={valueDateNais2}

                                                                inputRef={refDateNais2}

                                                                onChange={(newValue) => setvalueDateNais2(newValue)}
                                                                format="YYYY/MM/DD" // Set the desired format here
                                                                placeholder="YYYY/MM/DD"

                                                                label=" تاريخ ميلاد "

                                                                sx={{
                                                                    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: '#004d40', // Change border color to #2c17db for error state
                                                                    },

                                                                    '& .MuiFormHelperText-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '14px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },

                                                                    '& .MuiFormLabel-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        color: '#004d40'
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '18px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& label.Mui-focused': {
                                                                        fontSize: '16px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#004d40',
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {

                                                                        '& fieldset': {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: '16px',
                                                                            borderColor: '#004d40',
                                                                            borderWidth: "2px",
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                    }
                                                                }}

                                                            />


                                                        </DemoContainer>
                                                    </LocalizationProvider>




                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>



                                {/* الصفة*/}
                                <Grid item xs={isMatch ? 12 : 3} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth>
                                                        <InputLabel sx={{
                                                            fontFamily: '"Tajawal", sans-serif',
                                                            fontSize: '16px',
                                                            color: '#004d40'
                                                        }} id="sifa2-simple-select-label">الصفة</InputLabel>
                                                        <Select
                                                            labelId="sifa2-simple-select-label"
                                                            id="sifa2-simple-select"
                                                            value={sifa2}
                                                            label="sifa2"

                                                            onChange={handleChange_sifa2}




                                                            sx={{

                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'green',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'green',
                                                                    borderWidth: "2px",
                                                                },

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },

                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    color: '#004d40'
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                }
                                                            }}
                                                        >
                                                            {["متربص", "متمهن", "أستاذ", "متخرج"].map((value, index) => (
                                                                <MenuItem key={value} value={index + 1} style={{ fontSize: 18, fontWeight: 700, color: '#004d40', backgroundColor: index % 2 === 0 ? '#004d40' : '#FFFFFF' }}>
                                                                    {value}
                                                                </MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>

                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>

                                </Grid>


                            </Grid>





                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }}>

                                {/* رقم التعريف الوطني NIN */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">

                                                    <TextField

                                                        fullWidth
                                                        name="valueNin2"
                                                        value={valueNin2}
                                                        inputRef={refNin2}
                                                        onInput={handleInput_Nin2}
                                                        helperText={
                                                            (NumbersOnlyRegex.test(valueNin2) && valueNin2.length !== 18)
                                                                ? 'رقم التعريف الوطني NIN يجب ان يحتوي على 18 رقم'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 13 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <NumbersIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomnin2`}

                                                        label="رقم التعريف الوطني NIN "
                                                        placeholder="رقم التعريف الوطني NIN "

                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>



                                {/* البريد الإلكتروني */}
                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth variant="outlined"


                                                    >

                                                        <TextField

                                                            sx={{

                                                                //direction:'ltr',

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700

                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    dir: 'rtl',

                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '22px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700,
                                                                    //text-align: right,



                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",

                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                    '&.Mui-focused fieldset': {

                                                                        borderColor: 'green',
                                                                    },
                                                                }
                                                            }}

                                                            inputRef={refemail2}
                                                            label="البريد الإلكتروني"
                                                            placeholder="البريد الإلكتروني"




                                                            variant="outlined"
                                                            size={'small'}
                                                            name="valueemail2"
                                                            value={valueemail2}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => handleChangeemail2(e)}



                                                            InputProps={{
                                                                inputProps: { tabIndex: 14 },
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <EmailIcon />
                                                                    </InputAdornment>
                                                                )


                                                            }}


                                                        />

                                                    </FormControl>


                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                            </Grid>





                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }}>

                                {/* رقم الهاتف 01 */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueTEL12"
                                                        value={valueTEL12}
                                                        inputRef={refTEL12}
                                                        onInput={handleInput_TEL12}
                                                        helperText={
                                                            (!valueTEL12 || (NumbersOnlyRegex.test(valueTEL12) && valueTEL12.length == 10))
                                                                ? ''
                                                                : 'أدخل رقم هاتف صالح'
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 15 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhonelinkRingIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomtel21`}

                                                        label="رقم الهاتف 01"
                                                        placeholder="رقم الهاتف 01"
                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                                {/* رقم الهاتف 02 */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueTEL22"
                                                        value={valueTEL22}
                                                        inputRef={refTEL22}
                                                        onInput={handleInput_TEL22}
                                                        helperText={
                                                            (!valueTEL22 || (NumbersOnlyRegex.test(valueTEL22) && valueTEL22.length == 10))
                                                                ? ''
                                                                : 'أدخل رقم هاتف صالح'
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 16 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhonelinkRingIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomtel22`}

                                                        label="رقم الهاتف 02 (إختياري)"
                                                        placeholder="رقم الهاتف 02 (إختياري)"

                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>

                            </Grid>





                            <Box
                                sx={{
                                    marginTop: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',

                                }}
                            >

                                <Typography
                                    sx={{
                                        color: "#0d5193", // Text color
                                        fontFamily: '"Tajawal", sans-serif', // Font family
                                        fontWeight: 900, // Font weight
                                        fontSize: 20, // Font size
                                        backgroundColor: "#d6f6e045", // Background color
                                        border: "2px solid #11da4d36", // Border
                                        padding: "10px", // Padding
                                        paddingLeft: "20px",
                                    }}
                                >

                                    <PersonIcon style={{ marginRight: '5px', marginBottom: '-6px' }} />
                                    معلومات العضو الثالث (اختياري) [إذا وُجِد]


                                </Typography>


                            </Box>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }} >


                                {/* إسم و لقب العضو الثالث بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueNom3"
                                                        value={valueNom3}
                                                        inputRef={refNom3}
                                                        onInput={handleInput_Nom3}


                                                        InputProps={{
                                                            inputProps: { tabIndex: 17 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNom3`}

                                                        label="الإسم و اللقب"
                                                        placeholder="الإسم و اللقب"

                                                        variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                                {/*  تاريخ الميلاد */}

                                <Grid item xs={isMatch ? 12 : 3} sx={{ mt: '-7px' }} >

                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">

                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker']}>
                                                            <DatePicker
                                                                ref={refvalueDateNais3}
                                                                name="valueDateNais3"
                                                                value={valueDateNais3}

                                                                inputRef={refDateNais3}

                                                                onChange={(newValue) => setvalueDateNais3(newValue)}
                                                                format="YYYY/MM/DD" // Set the desired format here
                                                                placeholder="YYYY/MM/DD"

                                                                label=" تاريخ ميلاد "

                                                                sx={{
                                                                    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: '#004d40', // Change border color to #2c17db for error state
                                                                    },

                                                                    '& .MuiFormHelperText-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '14px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },

                                                                    '& .MuiFormLabel-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        color: '#004d40'
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '18px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& label.Mui-focused': {
                                                                        fontSize: '16px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#004d40',
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {

                                                                        '& fieldset': {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: '16px',
                                                                            borderColor: '#004d40',
                                                                            borderWidth: "2px",
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: 'green',
                                                                        },
                                                                    }
                                                                }}

                                                            />


                                                        </DemoContainer>
                                                    </LocalizationProvider>




                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>



                                {/* الصفة*/}
                                <Grid item xs={isMatch ? 12 : 3} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth>
                                                        <InputLabel sx={{
                                                            fontFamily: '"Tajawal", sans-serif',
                                                            fontSize: '16px',
                                                            color: '#004d40'
                                                        }} id="sifa3-simple-select-label">الصفة</InputLabel>
                                                        <Select
                                                            labelId="sifa3-simple-select-label"
                                                            id="sifa3-simple-select"
                                                            value={sifa3}
                                                            label="sifa3"

                                                            onChange={handleChange_sifa3}




                                                            sx={{

                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'green',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'green',
                                                                    borderWidth: "2px",
                                                                },

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },

                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    color: '#004d40'
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                }
                                                            }}
                                                        >
                                                            {["متربص", "متمهن", "أستاذ", "متخرج"].map((value, index) => (
                                                                <MenuItem key={value} value={index + 1} style={{ fontSize: 18, fontWeight: 700, color: '#004d40', backgroundColor: index % 2 === 0 ? '#004d40' : '#FFFFFF' }}>
                                                                    {value}
                                                                </MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>

                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>

                                </Grid>


                            </Grid>





                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }}>

                                {/* رقم التعريف الوطني NIN */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">

                                                    <TextField

                                                        fullWidth
                                                        name="valueNin3"
                                                        value={valueNin3}
                                                        inputRef={refNin3}
                                                        onInput={handleInput_Nin3}


                                                        InputProps={{
                                                            inputProps: { tabIndex: 18 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <NumbersIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomnin3`}

                                                        label="رقم التعريف الوطني NIN "
                                                        placeholder="رقم التعريف الوطني NIN "

                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>



                                {/* البريد الإلكتروني */}
                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth variant="outlined"


                                                    >

                                                        <TextField

                                                            sx={{

                                                                //direction:'ltr',

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700

                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    dir: 'rtl',

                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '22px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700,
                                                                    //text-align: right,



                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",

                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: 'green',
                                                                    },
                                                                    '&.Mui-focused fieldset': {

                                                                        borderColor: 'green',
                                                                    },
                                                                }
                                                            }}

                                                            inputRef={refemail3}
                                                            label="البريد الإلكتروني"
                                                            placeholder="البريد الإلكتروني"




                                                            variant="outlined"
                                                            size={'small'}
                                                            name="valueemail3"
                                                            value={valueemail3}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => handleChangeemail3(e)}



                                                            InputProps={{
                                                                inputProps: { tabIndex: 19 },
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <EmailIcon />
                                                                    </InputAdornment>
                                                                )


                                                            }}


                                                        />

                                                    </FormControl>


                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                            </Grid>





                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }}>

                                {/* رقم الهاتف 01 */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueTEL13"
                                                        value={valueTEL13}
                                                        inputRef={refTEL13}
                                                        onInput={handleInput_TEL13}
                                                        helperText={
                                                            (!valueTEL13 || (NumbersOnlyRegex.test(valueTEL13) && valueTEL13.length == 10))
                                                                ? ''
                                                                : 'أدخل رقم هاتف صالح'
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 20 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhonelinkRingIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomtel31`}

                                                        label="رقم الهاتف 01"
                                                        placeholder="رقم الهاتف 01"
                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                                {/* رقم الهاتف 02 */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueTEL23"
                                                        value={valueTEL23}
                                                        inputRef={refTEL23}
                                                        onInput={handleInput_TEL23}
                                                        helperText={
                                                            (!valueTEL23 || (NumbersOnlyRegex.test(valueTEL23) && valueTEL23.length == 10))
                                                                ? ''
                                                                : 'أدخل رقم هاتف صالح'
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 21 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhonelinkRingIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'green',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomtel32`}

                                                        label="رقم الهاتف 02 (إختياري)"
                                                        placeholder="رقم الهاتف 02 (إختياري)"

                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>

                            </Grid>






                            <Grid item xs={12} sx={{ marginTop: '10px' }}>
                                <Paper style={{ maxHeight: 400, overflow: 'auto', padding: 20, mt: 2, mb: 2 }}>
                                    <div className="terms-section" style={{ textAlign: "right" }} direction="rtl">
                                        <Typography variant="body1" dir="rtl"
                                            sx={{
                                                fontFamily: '"Tajawal", sans-serif', // Font family
                                                fontSize: 14, // Font size
                                                direction: 'rtl',
                                                textAlign: 'right'
                                            }}
                                        >

                                            <h2>شروط تخزين ومشاركة البيانات</h2>
                                            <p>سيتم تخزين بياناتك الشخصية بأمان:</p>
                                            <ul>
                                                <li>نستخدم قاعدة بيانات آمنة لحماية بياناتك من الوصول غير المصرح به أو الاستخدام أو الكشف أو التغيير أو التلف.</li>
                                            </ul>
                                            <h2>مشاركة البيانات مع جهات خارجية محددة</h2>
                                            <p>قد نضطر إلى مشاركة بياناتك مع جهات خارجية موثوقة في بعض الحالات، مثل:</p>
                                            <ul>
                                                <li>مؤسسات التكوين المهني.</li>
                                                <li>الجهات الحكومية المختصة عند الطلب لأغراض قانونية أو إدارية.</li>
                                                <li>مقدمي الخدمات الذين نتعاقد معهم لتقديم خدمات الدعم والمتابعة، مع ضمان حماية بياناتك.</li>
                                            </ul>
                                            <h2>موافقتك ضرورية</h2>
                                            <p>باستخدامك لهذه المنصة، فإنك توافق على شروط الاتفاق هذه.</p>
                                            <p>إذا كنت لا توافق على هذه الشروط، فلا تستخدم هذه المنصة.</p>

                                        </Typography>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                    inputRef={refcheckbox}
                                                    color="primary"
                                                    inputProps={{
                                                        tabIndex: 22,
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography variant="body2" dir="rtl" sx={{ // Use Typography component
                                                    fontFamily: '"Tajawal", sans-serif',
                                                    fontSize: 14,
                                                    color: 'green', // Change label color to blue
                                                    fontWeight: 'bold', // Make the label text bolder
                                                }}>
                                                    أوافق على شروط وأحكام الاستخدام
                                                </Typography>
                                            }
                                            sx={{ // Remove styling for the FormControlLabel itself (optional)
                                                direction: 'rtl',
                                                textAlign: 'right'
                                            }}
                                        />


                                    </div>
                                </Paper>


                            </Grid>
                            <ClickAwayListener onClickAway={handleClose_recaptcha}>
                                <div>
                                    <BootstrapTooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        open={open_recaptcha} onClose={handleClose_recaptcha} onOpen={handleOpen_recaptcha}


                                        title="اثبت انك لست روبوت"

                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                    >
                                        <Grid item xs={12} container justifyContent="center">


                                            <ReCAPTCHA style={{ marginTop: '10px' }}
                                                ref={refReCAPTCHA}
                                                sitekey="6LcCt3YpAAAAAK33Jnte_3V4gKcLl-kQgeV3qWMP"
                                                onChange={handleRecaptchaChange}
                                                hl="ar"
                                            // Additional optional props (e.g., action, verifyApiArgs)
                                            />

                                        </Grid>

                                    </BootstrapTooltip>






                                    {/* Submit Button */}
                                    <Grid item xs={12}  >
                                        <CacheProvider value={cacheRtl}>
                                            <ThemeProvider theme={themetextfieldnew}>
                                                <Box sx={{ minWidth: 120, mt: '35px' }}>
                                                    <div>

                                                        <Button

                                                            onClick={(e) => {



                                                                e.preventDefault();

                                                                /*
                                                                setvalueNom('صبرو');
                                                                setvalueNomFr('sebrou');
                                                                setvaluePrenom('عبد العزيز');
                                                                setvaluePrenomFr('abdelaziz');

                                                                setvalueAdres('حي بن سمارة');
                                                                setvalueAdresFr('ben smara rue');
                                                                setvaluePrenomPere('بلقاسم');
                                                                setvaluePrenomPereFr('belkacem');
                                                                setvalueNomMere('الشحمة');
                                                                setvalueNomMereFr('chehma');
                                                                setvaluePrenomMere('فطيمة');
                                                                setvaluePrenomMereFr('fatima');
                                                                setIsChecked(true);
                                                                setRecaptchaVerified(true);

                                                                // Now you can set the Password property using setValuesPassword
                                                                setValuesPassword(prevState => ({
                                                                    ...prevState,
                                                                    password: 'azizSEBROU1991' // Changed from 'Password' to 'password'
                                                                }));


                                                                setValuesPasswordRe(prevState => ({
                                                                    ...prevState,
                                                                    PasswordRe: 'azizSEBROU1991'
                                                                }));

                                                                */







                                                                if (!options_wly || !selectedwlys) {
                                                                    refwly.current.focus();
                                                                    return
                                                                }

                                                                if (valueprojectitle === '') {
                                                                    refprojectitle.current.focus();
                                                                    return
                                                                }
                                                                if (!options_branche || !selectedbranche) {
                                                                    Refselectedbranche.current.focus();
                                                                    return
                                                                }

                                                                if (!valueprojectitle_average) {
                                                                    refprojectitle_average.current.focus();
                                                                    return
                                                                }

                                                                if (value_description === '') {
                                                                    ref_description.current.focus();

                                                                    return
                                                                }


                                                                if (!isValidurl_video || valueurl_video === '') {
                                                                    refurl_video.current.focus();

                                                                    return
                                                                }



                                                                if (valueNom === '') {
                                                                    refNom.current.focus();
                                                                    return
                                                                }

                                                                // Calculate age
                                                                const currentDate = new Date();
                                                                const birthDate = new Date(valueDateNais);
                                                                const age = currentDate.getFullYear() - birthDate.getFullYear();

                                                                // Check if age is less than 15
                                                                if (!valueDateNais || age < 15) {
                                                                    setmsgerror("يجب أن يكون على الأقل 15 عامًا.");
                                                                    setfieldnameerror("تاريخ الميلاد: ")
                                                                    setopen_info(true);

                                                                    refDateNais.current.focus();
                                                                    return false;
                                                                }



                                                                if (!NumbersOnlyRegex.test(valueNin) || !valueNin || valueNin.length !== 18) {
                                                                    refNin.current.focus();
                                                                    return
                                                                }


                                                                if (!isEmail(valueemail) || valueemail === '' || !valueemail) {
                                                                    refemail.current.focus();
                                                                    return;
                                                                }

                                                                if (!NumbersOnlyRegex.test(valueTEL1) || valueTEL1.length !== 10) {
                                                                    refTEL1.current.focus();

                                                                    return
                                                                }




                                                                if (!isChecked) {
                                                                    setmsgerror("لم تقم بالموافقة على شروط و أحكام الإستخدام");
                                                                    setfieldnameerror("");

                                                                    setopen_info(true);
                                                                    setopen_check(true);
                                                                    refcheckbox.current.focus();
                                                                    return
                                                                }


                                                                if (!recaptchaVerified) {
                                                                    setmsgerror("قم بالنقر على 'أنا لست روبوت' في ريكابتشا");
                                                                    setfieldnameerror("ريكابتشا: ")
                                                                    setopen_info(true);

                                                                    setOpen_recaptcha(true);
                                                                    return
                                                                }




                                                                onsubmitclicked(e);


                                                            }}



                                                            variant="contained"
                                                            endIcon={<SaveIcon sx={{
                                                                mr: '5px', ml: '5px',
                                                                transform: 'rotate(0deg)', // Adjust the angle as needed
                                                            }} />}>
                                                            <Typography sx={{
                                                                color: "#FFFFFF", fontFamily:
                                                                    '"Tajawal", sans-serif',
                                                                fontSize: 22
                                                                , fontWeight: 900
                                                            }}>
                                                                سجل الآن

                                                            </Typography>

                                                        </Button>

                                                    </div>

                                                </Box>
                                            </ThemeProvider>
                                        </CacheProvider>

                                    </Grid>

                                </div>
                            </ClickAwayListener>





                        </Grid>
                    </Container>
                    <br/>
                    <br/>
                    <br/>

                   {/*  <Footer /> */}

                </form>

            </div >





            {/* Dialog success save */}

            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: '#004d40',
                        boxShadow: 'none',
                    },
                }}
                //fullScreen={mediumScreen}
                open={OpenDialog}
                onClose={handleClickCloseDialog}
                aria-labelledby="responsive-dialog-title"
            >

                <Card style={{}} sx={{ maxWidth: 345 }}>

                    {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
                    <CardHeader
                        avatar={

                            <CheckCircleIcon sx={{ color: '#4BB543', width: 50, height: 50, ml: 2 }}></CheckCircleIcon>

                        }
                        action={


                            <IconButton onClick={handleClickCloseDialog} aria-label="settings" sx={{ ml: -1, mt: -1 }}>

                                <CloseIcon />

                            </IconButton>
                        }
                        title={
                            <>
                                <Box sx={{ ml: 2, mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>

                                    <Typography sx={{ color: '#004d40', fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }} >
                                        تم التسجيل بنجاح
                                      
                                        </Typography>

                                </Box>

                            </>

                        }
                        subheader={
                            <>
                                <Box sx={{  mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>

                                    <Typography sx={{ color: '#004d40', fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }} >
                                        
                                        رقم التسجيل: {IDinnovation}
                                        
                                        </Typography>

                                </Box>

                            </>

                        }
                    />
                    <CardContent>
                        <Divider sx={{ mt: '0px' }}></Divider>
                        <Grid item  >
                            <Button
                                component={Link}
                                to={`/Pre_innovationpdf/${crypted_id}`}
                                target="_blank"
                                variant="contained" // Add this line to make it a contained button
                                sx={{
                                    display: 'block', // Display the button as a block element
                                    textAlign: 'right',
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,

                                }}
                            >

                                <Typography
                                    theme={themeChanga}
                                    sx={{ textAlign: "right", mr: 0 }}
                                    type="body2"
                                >

                                    تحميل استمارة التسجيل <PictureAsPdfIcon />
                                </Typography>
                            </Button>
                        </Grid>
                    </CardContent>


                </Card>

            </Dialog>










            <CacheProvider value={cacheRtl}>
                <ThemeProvider theme={themetextfieldnew}>


                    <Dialog
                        open={open_info} onClose={() => setopen_info(false)}>
                        <DialogTitle>
                            <Divider >
                                <Grid item sx={{ mt: 2 }} >
                                    <Typography
                                        style={{
                                            color: "#023020",
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: 20,
                                            fontWeight: 700,
                                        }}
                                    >
                                        أكمل المعلومات <InfoIcon sx={{ ml: '0px' }} />
                                    </Typography>
                                </Grid>
                            </Divider>


                        </DialogTitle>



                        <DialogContent>
                            <DialogContentText
                                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                                <span style={{ color: 'black' }}>{fieldnameerror}</span> {msgerror}

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                onClick={() => setopen_info(false)}
                                className={classes.cancelBtn}
                                color="primary"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                                    backgroundColor: "#004d40", // أخضر
                                    color: "#fff",
                                    "&:hover": {
                                        backgroundColor: "#388e3c",
                                    },
                                }}
                            >
                                موافق
                            </Button>

                        </DialogActions>
                    </Dialog>

                </ThemeProvider>
            </CacheProvider>



        </>
    )
}