import React, { useState, useEffect, useRef } from 'react';

import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,

  GridToolbarQuickFilter,
  useGridApiRef,
  gridExpandedRowCountSelector,
  gridVisibleColumnDefinitionsSelector,
  gridExpandedSortedRowIdsSelector
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import FirstPageIcon from '@mui/icons-material/LastPage';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import './MyToolbarStyles.css'; // Adjust the path as necessary
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Grid } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Footer from './Footer';
import Header from './Header';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import { arSD } from '@mui/x-data-grid/locales';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import XLSX from 'sheetjs-style';
import useAuthentication_etab from "./useAuthentication_etab";
import FacebookIcon from '@mui/icons-material/Facebook';
import { Typography, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const defaultColor = "#4caf50"; // Default green color
const hoverColor = "#004d40"; // Lighter green when hovered
const focusColor = "#81c784"; // Even lighter green when focused

const themetextfieldnew = createTheme({
  palette: {
    primary: {
      main: defaultColor
    },
    secondary: {
      main: defaultColor
    }
  },
  direction: 'rtl',
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: hoverColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: focusColor,
          },
        },
        notchedOutline: {
          borderColor: defaultColor,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: focusColor,
          },
          "&:hover": {
            color: hoverColor,
          },
          color: defaultColor, // Default label color
        },
      },
    },
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  },
});
// Create a cache with RTL support
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
});
function ArrowNavigation({ handleClick }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px', // Adjust this value to control the space between the icons
      }}
    >
       <IconButton aria-label="home" onClick={handleClick('home')} color="secondary">
        <FirstPageIcon />
      </IconButton>

      <IconButton onClick={handleClick('left')} aria-label="left" color="primary">
        <ArrowForwardIcon />
      </IconButton>

      <IconButton onClick={handleClick('right')} aria-label="right" color="primary">
        <ArrowBackIcon />
      </IconButton>
    </Box>
  );
}



function CustomToolbar({ columns, handleExportExcel, handleClick }) {
  return (


    <GridToolbarContainer className="custom-toolbar">
       <ArrowNavigation handleClick={handleClick} />

      <GridToolbarColumnsButton className="custom-toolbar" />
      <GridToolbarFilterButton className="custom-toolbar" />
      <GridToolbarDensitySelector className="custom-toolbar" />
      <Button className="custom-toolbar" onClick={handleExportExcel} color="primary">
        تنزيل كملف إكسال
      </Button>
      <GridToolbarExport className="custom-toolbar" />

      <ThemeProvider theme={themetextfieldnew}>
        <GridToolbarQuickFilter
          className="custom-toolbar"

        />
      </ThemeProvider>

     


    </GridToolbarContainer>
  );
}




const Etabinfo = () => {
  const {
    IDetablissement,
    IDEts_Form,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  } = useAuthentication_etab();

  const [EndTimeupdatedb, setEndTimeupdatedb] = useState("");

  const apiUrl_lastupdatedb = `${API_BASE_URL}/api/lastupdatedb/Etablissement`;

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(apiUrl_lastupdatedb);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        if (!data || !data.data) {
          throw new Error("Unexpected data format received from API");
        }

        // Process fetched data
        const lastupdatedb = data.data;

        // Set selected session if available
        if (lastupdatedb && lastupdatedb.EndTime) {
          setEndTimeupdatedb(lastupdatedb.EndTime);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const loadDataFromLocalForage = async () => {
      try {
        // If data doesn't exist in localforage, fetch it
        await fetchData();
      } catch (error) {
        console.error("Error loading data from localforage:", error);
      }
    };

    // Load data from localforage or fetch from API
    loadDataFromLocalForage();
  }, []);

  // Function to format date in Arabic with Arabic numerals
  const formatArabicDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, localeMatcher: 'best fit', weekday: "long", era: "long" };
    const easternArabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    const arabicNumerals = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const formattedDate = new Date(dateString).toLocaleString('ar-EG', options);
    // Replace Eastern Arabic numerals with Arabic numerals
    return formattedDate.replace(/[٠-٩]/g, (match) => arabicNumerals[easternArabicNumerals.indexOf(match)]);
  };

  const [open, setOpen] = React.useState(false);
  const [fullDescription, setFullDescription] = React.useState('');
  const [nom, setNom] = React.useState('');

  const handleOpen = (description, nom) => {
    setFullDescription(description);
    setNom(nom);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [rows, setRows] = useState([]);
  const [loadingData, setLoadingData] = useState(false);



  const useStyles_listdfep = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily:
          '"Tajawal", sans-serif',
        fontSize: 18,
        direction: "rtl"
      }
    },
    inputRoot: {

      color: "#004d40",


      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif',
        fontWeight: 700,
        color: "#004d40",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#004d40"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#00796b"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#004d00"
      }
    }
  }));

  const classes_listdfep = useStyles_listdfep();

  const [IDDFEP_selected, setIDDFEP_selected] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options_Wlylist, setoptions_Wlylist] = React.useState([]);



  useEffect(() => {

    const dfepData = [
      { IDDFEP: 1, Nom: 'أدرار', NomFr: 'Adrar' },
      { IDDFEP: 2, Nom: 'شلف', NomFr: 'Chlef' },
      { IDDFEP: 3, Nom: 'الأغواط', NomFr: 'Laghouat' },
      { IDDFEP: 4, Nom: 'أم البواقي', NomFr: 'Oum El Bouaghi' },
      { IDDFEP: 5, Nom: 'با تنة', NomFr: 'Batna' },
      { IDDFEP: 6, Nom: 'بجا ية', NomFr: 'Bejaia' },
      { IDDFEP: 7, Nom: 'بسكرة', NomFr: 'Biskra' },
      { IDDFEP: 8, Nom: 'بشا ر', NomFr: 'Bechar' },
      { IDDFEP: 9, Nom: 'البليدة', NomFr: 'Blida' },
      { IDDFEP: 10, Nom: 'البو ير ة', NomFr: 'Bouira' },
      { IDDFEP: 11, Nom: 'تمنراست', NomFr: 'Tamanrasset' },
      { IDDFEP: 12, Nom: 'تبسة', NomFr: 'Tebessa' },
      { IDDFEP: 13, Nom: 'تلمسان', NomFr: 'Tlemcen' },
      { IDDFEP: 14, Nom: 'تيارت', NomFr: 'Tiaret' },
      { IDDFEP: 15, Nom: 'تيزي وزو', NomFr: 'Tizi Ouzou' },
      { IDDFEP: 16, Nom: 'الجزا ئر', NomFr: 'Alger' },
      { IDDFEP: 17, Nom: 'الجلفة', NomFr: 'Djelfa' },
      { IDDFEP: 18, Nom: 'جيجل', NomFr: 'Jijel' },
      { IDDFEP: 19, Nom: 'سطيف', NomFr: 'Setif' },
      { IDDFEP: 20, Nom: 'سعيدة', NomFr: 'Saida' },
      { IDDFEP: 21, Nom: 'سكيكدة', NomFr: 'Skikda' },
      { IDDFEP: 22, Nom: 'سيدي بلعباس', NomFr: 'Sidi Bel Abbes' },
      { IDDFEP: 23, Nom: 'عنابة', NomFr: 'Annaba' },
      { IDDFEP: 24, Nom: 'قالمة', NomFr: 'Guelma' },
      { IDDFEP: 25, Nom: 'قسنطينة', NomFr: 'Constantine' },
      { IDDFEP: 26, Nom: 'المدية', NomFr: 'Medea' },
      { IDDFEP: 27, Nom: 'مستغانم', NomFr: 'Mostaganem' },
      { IDDFEP: 28, Nom: 'مسيلة', NomFr: 'M\'Sila' },
      { IDDFEP: 29, Nom: 'معسكر', NomFr: 'Mascara' },
      { IDDFEP: 30, Nom: 'ورقلة', NomFr: 'Ouargla' },
      { IDDFEP: 31, Nom: 'وهران', NomFr: 'Oran' },
      { IDDFEP: 32, Nom: 'البيض', NomFr: 'El Bayadh' },
      { IDDFEP: 33, Nom: 'إليزي', NomFr: 'Illizi' },
      { IDDFEP: 34, Nom: 'برج بوعريريج', NomFr: 'Bordj Bou Arréridj' },
      { IDDFEP: 35, Nom: 'بومرداس', NomFr: 'Boumerdes' },
      { IDDFEP: 36, Nom: 'الطارف', NomFr: 'El Taref' },
      { IDDFEP: 37, Nom: 'تيندوف', NomFr: 'Tindouf' },
      { IDDFEP: 38, Nom: 'تيسمسيلت', NomFr: 'Tissemsilt' },
      { IDDFEP: 39, Nom: 'الوادي', NomFr: 'El Oued' },
      { IDDFEP: 40, Nom: 'خنشلة', NomFr: 'Khenchela' },
      { IDDFEP: 41, Nom: 'سوق أهراس', NomFr: 'Souk Ahras' },
      { IDDFEP: 42, Nom: 'تيبازة', NomFr: 'Tipaza' },
      { IDDFEP: 43, Nom: 'ميلة', NomFr: 'Mila' },
      { IDDFEP: 44, Nom: 'عين الدفلى', NomFr: 'Ain Defla' },
      { IDDFEP: 45, Nom: 'النعامة', NomFr: 'Naâma' },
      { IDDFEP: 46, Nom: 'عين تيموشنت', NomFr: 'Ain Temouchent' },
      { IDDFEP: 47, Nom: 'غرداية', NomFr: 'Ghardaia' },
      { IDDFEP: 48, Nom: 'غليزان', NomFr: 'Relizane' },
      { IDDFEP: 49, Nom: 'تميمون', NomFr: 'Timimoun' },
      { IDDFEP: 50, Nom: 'برج باجي مختار', NomFr: 'Bordj Badji Mokhtar' },
      { IDDFEP: 51, Nom: 'أولاد جلال', NomFr: 'Ouled  Djellal' },
      { IDDFEP: 52, Nom: 'بني عباس', NomFr: 'Béni Abbès' },
      { IDDFEP: 53, Nom: 'عين صالح', NomFr: 'In Salah' },
      { IDDFEP: 54, Nom: 'عين قزام', NomFr: 'In Guezzam' },
      { IDDFEP: 55, Nom: 'توقرت', NomFr: 'Touggourt' },
      { IDDFEP: 56, Nom: 'جانت', NomFr: 'Djanet' },
      { IDDFEP: 57, Nom: 'المغير', NomFr: 'El M’Ghaier' },
      { IDDFEP: 58, Nom: 'المنيعة', NomFr: 'El Meniaa' }

    ];

    const options_Wly = dfepData.map((Wlylist, i) => ({
      value: Wlylist.IDDFEP,
      label: Wlylist.IDDFEP + '- ' + Wlylist.Nom + '- ' + Wlylist.NomFr,
      indexvalue: i
    }));


    setoptions_Wlylist(options_Wly);
  }, []);


  const onwlyChanged = (event, values) => {

    if (values) {
      setSelectedOption(values);
      setIDDFEP_selected(values.value);

    } else {
      return;
    }


  }

  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map(col => ({ name: col.field, headerName: col.headerName }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
      const newRow = {};
      relevantColumns.forEach(col => {
        newRow[col.headerName] = row[col.name];
      });
      return newRow;
    }));

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  // Inherit the theme from the docs site (dark/light mode)
  const existingTheme = useTheme();

  const theme_datagrid = React.useMemo(
    () =>
      createTheme({}, arSD, existingTheme, {
        direction: 'rtl',
      }),
    [existingTheme],
  );


  useEffect(() => {
    if (!IDDFEP_selected || IDDFEP_selected === null || Number(IDDFEP_selected) <= 0) {
      return;
    }
    const apiUrl_alletab = `${API_BASE_URL}/api/getlistofalletab_byiddfep_pub/` + IDDFEP_selected;

    fetch(apiUrl_alletab, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setRows(data.data);
        } else {
          console.error("Unexpected data format:", data);
          setRows([]); // Set to empty array to avoid errors in DataGrid
        }
        setLoadingData(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setLoadingData(false);
      });
  }, [IDDFEP_selected]);


  const columns = [
    {
      field: 'Code',
      headerName: 'الرمز',
      minWidth: 40,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Nom', headerName: 'التسمية', width: 600,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

    },

    {
      field: 'Nature_etsFNom', headerName: 'طبيعة المؤسسة', width: 400,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

    },

    {
      field: 'Adres', headerName: 'العنوان', width: 600,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

    },

    {
      field: 'Latitude',
      headerName: 'الخريطة',
      width: 200,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const { Latitude, Longitude } = params.row;
        const url = `https://www.google.com/maps/search/?api=1&query=${Latitude},${Longitude}`;

        return (

          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(url, "_blank")}
            sx={{
              backgroundColor: '#00695c', // Green color
              borderRadius: '8px', // Rounded corners
              padding: '8px 16px', // Padding inside the button
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Shadow for elevation
              '&:hover': {
                backgroundColor: '#004d40', // Darker green on hover
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)', // Deeper shadow on hover
              },
              marginRight: '10px', // Adjust as necessary
            }}
            aria-label="Open map location in Google Maps"
          >
            <LocationOnIcon
              sx={{
                fontSize: 20, // Size of the icon
                marginRight: '8px', // Space between icon and text
              }}
            />
            <Typography
              sx={{
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              الخريطة
            </Typography>
          </Button>





        );
      }
    },

    {
      field: 'CommunnNom', headerName: 'البلدية', width: 200,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

    },

    ,
    {
      field: 'Tel', headerName: 'الهاتف', width: 200,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

    },
    {
      field: 'Fax', headerName: 'الفاكس', width: 200,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

    },
    {
      field: 'Email', headerName: 'البريد الإلكتروني', width: 400,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',

    },




    {
      field: 'Fbbtn',
      headerName: 'فيس بوك',
      width: 150,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const { Fb } = params.row;

        // Check if Fb is empty or null
        if (!Fb) {
          return null; // Do not render anything if Fb is empty
        }

        // Ensure URL starts with https://
        const url = Fb.startsWith('https://') ? Fb : `https://${Fb}`;

        return (
          <Button
            variant="contained"
            onClick={() => window.open(url, "_blank")}
            sx={{
              backgroundColor: '#1877F2', // Facebook blue color
              borderRadius: '8px', // Rounded corners
              padding: '8px 16px', // Padding inside the button
              color: '#fff', // White text color
              '&:hover': {
                backgroundColor: '#155DAA', // Darker blue on hover
              },
            }}
            aria-label="Open Facebook profile"
            startIcon={<FacebookIcon sx={{ fontSize: 20 }} />} // Facebook icon
          >
            <Typography
              sx={{
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              فيس بوك
            </Typography>
          </Button>
        );
      }
    }
    ,
    /*
        {
          field: 'Fb', headerName: 'رابط فيس بوك', width: 400,
          headerClassName: 'super-app-theme--header',
          align: 'center',
          headerAlign: 'center',
    
        },
    */
    {
      field: 'surface', headerName: 'المساحة م2', width: 150,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

    },
    ,
    {
      field: 'CapaciteT', headerName: 'السعة ن', width: 150,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

    },

    {
      field: 'CapaciteR', headerName: 'السعة ح', width: 150,
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'center',

    },

    {
      field: 'internatexploit',
      headerName: 'الداخلية',
      width: 120,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const { internat, ExploitInternat } = params.row;
        return (
          Number(internat) === 1 && Number(ExploitInternat) === 1
            ? "نعم"
            : "لا"
        );
      },
    },





  ];

  const handleRowDoubleClick = (params) => {
    console.log('Row double clicked:', params.row);
  };



  const apiRef = useGridApiRef();

  const [coordinates, setCoordinates] = React.useState({
    rowIndex: 0,
    colIndex: 0,
  });



  React.useEffect(() => {
    const { rowIndex, colIndex } = coordinates;
    apiRef.current.scrollToIndexes(coordinates);
    const id = gridExpandedSortedRowIdsSelector(apiRef)[rowIndex];
    const column = gridVisibleColumnDefinitionsSelector(apiRef)[colIndex];
    apiRef.current.setCellFocus(id, column.field);
  }, [apiRef, coordinates]);

  const handleClick = (position) => () => {
    const maxRowIndex = gridExpandedRowCountSelector(apiRef) - 1;
    const maxColIndex = gridVisibleColumnDefinitionsSelector(apiRef).length - 1;

    setCoordinates((coords) => {
      switch (position) {
        case 'top':
          return { ...coords, rowIndex: Math.max(0, coords.rowIndex - 1) };
        case 'bottom':
          return { ...coords, rowIndex: Math.min(maxRowIndex, coords.rowIndex + 1) };
        case 'left':
          return { ...coords, colIndex: Math.max(0, coords.colIndex - 1) };
        case 'right':
          return { ...coords, colIndex: Math.min(maxColIndex, coords.colIndex + 1) };
        default:
          return { ...coords, rowIndex: 0, colIndex: 0 };
      }
    });
  };

  const handleCellClick = (params) => {
    const rowIndex = gridExpandedSortedRowIdsSelector(apiRef).findIndex(
      (id) => id === params.id,
    );
    const colIndex = gridVisibleColumnDefinitionsSelector(apiRef).findIndex(
      (column) => column.field === params.field,
    );
    setCoordinates({ rowIndex, colIndex });
  };


  return (
    <>
      <Header

      />

      <Box
        sx={{
          '& .super-app-theme--header': {
            fontFamily: 'Cairo',
            fontSize: '20px',
            fontWeight: 'bold', // Make the font bold
            backgroundColor: '#004d40', // Light green background color

            borderRight: '1px solid #a5d6a7', // Greenish border for better contrast
            fontWeight: 900,
            color: '#FFFFFF'
          },
        }}


      >


        <Grid item sx={{ width: '100%' }}>
          <Divider sx={{ mt: '15px' }}>
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  color: '#004d40',
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: { xs: '16px', sm: '25px' }, // Responsive font size
                  fontWeight: 700,

                }}
              >
                <ApartmentIcon sx={{ mr: '5px', ml: '5px' }} />
                دليل مؤسسات التكوين
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif',
                  color: '#004d40',
                  fontSize: { xs: '10px', sm: '12px' }, // Responsive font size
                  fontWeight: 'bold',
                  mt: 1, // Margin top
                  display: 'flex',
                  flexWrap: 'wrap', // Allow wrapping if needed
                }}
              >
                {EndTimeupdatedb && (
                  <>
                    آخر جلب للمعلومات من منصة تسيير: {formatArabicDate(EndTimeupdatedb)}
                  </>
                )}
              </Typography>
            </Grid>
          </Divider>
        </Grid>

        <Grid container>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mx: 2 }}>
            <Grid container direction="column" >
              {/* Autocomplement Wilayat  */}

              <CacheProvider value={cacheRtl}>


                <Autocomplete
                  noOptionsText={'حدث الصفحة مرة أخرى'}
                  classes={classes_listdfep}
                  id="combo-box-demowly"
                  onChange={onwlyChanged}
                  value={selectedOption} // Use the value prop to set the selected option
                  options={options_Wlylist}

                  defaultValue={options_Wlylist[0]} // Set the defaultValue to the first option
                  sx={{ mt: 1, mb: 1 }}
                  renderOption={(props, option) => {
                    const { label, indexvalue } = option;
                    return (
                      <span
                        {...props}
                        style={{
                          backgroundColor: indexvalue % 2 === 0 ? '#f2f9f2' : '#FFFFFF', // ألوان خلفية خضراء فاتحة ومنسجمة
                          color: '#004D40', // لون النص داكن ليتناسب مع الخلفية الفاتحة
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 18,
                          fontWeight: 700,
                        }}


                      >
                        {indexvalue === -1 ? '- ' + label : label}
                      </span>
                    );
                  }}

                  renderInput={(params) => (

                    <TextField
                      {...params}

                      placeholder="إختر ولاية"
                      error={!selectedOption}
                      helperText={
                        !selectedOption
                          ? 'إختر ولاية'
                          : ''
                      }
                      tabIndex={1}
                      dir="rtl" // Add dir="rtl" here

                      sx={{
                        mt: 1,
                        mb: 1,
                        '& .MuiFormHelperText-root': {
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: '14px',
                          color: '#004d40',
                          fontWeight: 700
                        },

                        '& .MuiFormLabel-root': {
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: '16px',
                          color: '#004d40'
                        },
                        '& .MuiInputBase-input': {
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: '18px',
                          color: '#004d40',
                          fontWeight: 700
                        },
                        '& label.Mui-focused': {
                          fontSize: '16px',
                          color: '#004d40',
                          fontWeight: 700
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#004d40',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: '16px',
                            borderColor: '#004d40',
                            borderWidth: "2px",
                          },
                          '&:hover fieldset': {
                            borderColor: 'green',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'green',
                          },
                        }
                      }}

                      fullWidth
                      InputLabelProps={{
                        style: {
                          color: "#004d40",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 18,
                          fontWeight: 700,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          color: "#004d40",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 18,
                          fontWeight: 700,
                        },
                      }}

                    />

                    /*
                        <Box display="flex" justifyContent="center">
                          <TextField fullWidth {...params} label="الولايات" />
                        </Box>*/
                  )}


                />




              </CacheProvider>
            </Grid>
          </Grid>

          <Grid item sx={{ width: "100%" }}>

            

            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={theme_datagrid}>
                <div style={{ height: '100%', width: '100%', minHeight: 300 }}>
                  <DataGrid
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    loading={loadingData}
                    onRowDoubleClick={handleRowDoubleClick}
                    editMode="row"
                    getRowId={(row) => row.IDetablissement}
                    getRowClassName={(params) =>
                      parseInt(params.row.IDetablissement, 10) % 2 === 0 ? 'even-row' : 'odd-row'
                    }
                    slots={{
                      toolbar: CustomToolbar,
                    }}
                    slotProps={{
                      toolbar: { columns, handleExportExcel,handleClick },
                    }}
                    sx={{

                      boxShadow: 2,
                      border: 2,
                      borderColor: 'green',
                      overflowX: 'scroll',
                      '& .MuiDataGrid-cell': {
                        fontFamily: 'Tajawal',
                        fontSize: '20px',

                        backgroundColor: 'rgba(255, 255, 255, 0.65)',
                        borderRight: '1px solid #ccc',
                      },
                      
                      '& .MuiDataGrid-cell:focus': {
                        border: '2px solid green',
                      },
                      '& .MuiDataGrid-row:hover': {
                        backgroundColor: '#004d40',
                      },
                      '& .even-row': {
                        backgroundColor: '#e8f5e9',
                      },
                      '& .odd-row': {
                        backgroundColor: '#ffffff',
                      },
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                      },
                    }}

                    getRowHeight={() => 50} // Example: adjust as needed based on content
                  />

                  <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                    <DialogTitle sx={{
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 18,
                      fontWeight: 600,
                    }}>{nom}</DialogTitle>
                    <DialogContent>
                      <Typography
                        style={{
                          whiteSpace: 'pre-line',
                          wordBreak: 'break-word',
                          fontFamily: 'Cairo, sans-serif',
                          fontSize: '20px',
                          padding: '8px',
                        }}
                        variant="body1"
                      >
                        {fullDescription}
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        sx={{
                          backgroundColor: '#c62828', // Red color
                          color: '#FFFFFF', // White text
                          '&:hover': {
                            backgroundColor: '#b71c1c', // Darker red on hover
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          إغلاق
                        </Typography>
                      </Button>
                    </DialogActions>
                  </Dialog>


                </div>
              </ThemeProvider>
            </CacheProvider>
          </Grid>
        </Grid>

      </Box>

      <Footer />
    </>
  );
};

export default Etabinfo;
