import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import localforage from 'localforage';
import { API_BASE_URL } from "./config"; // Adjust the path if necessary
import 'jspdf-autotable';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { APP_PREFIX } from "./config"; // Adjust the path if necessary
import { useNavigate } from "react-router-dom";

const useStyles_CircularProgress = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure it's above everything else
  },
}));

const Releve2notes = () => {
  const [pdfUrl, setPdfUrl] = useState(null); // State to store PDF URL
  const [IDsection_semestre_Module_par, setIDsection_semestre_Module_par] = useState(99446);
  const [IDSemestre_formation_par, setIDSemestre_formation_par] = useState(31);
  const [IDSection_Semestre_par, setIDSection_Semestre_par] = useState(193480);
  const [result, setResult] = useState(null); // State to store fetched data
  const [result_dfep, setresult_dfep] = useState(null);

  const [result_mfep, setresult_mfep] = useState(null);
  const [totalPages, setTotalPages] = useState(0); // State to store total pages
  const { crypt_IDApprenant_Section_semstre } = useParams();
  /*
   "IDsection_semestre_Module": 99446,
   "IDSemestre_formation": 31,
   "IDSection_Semestre": 193480
   */
   const navigate = useNavigate();

  const formatNumber = (value) => {
    // Return an empty string if the number is 0
    if (value === 0) return '';
    // Convert the value to a number
    const number = parseFloat(value);


    // Format the number to two decimal places
    return number.toFixed(2);
  };



  // Fetch data from local storage on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve multiple items from localforage
        const [IDsection_semestre_Module, IDSemestre_formation, IDSection_Semestre] = await Promise.all([
          localforage.getItem("IDsection_semestre_Module"),
          localforage.getItem("IDSemestre_formation"),
          localforage.getItem("IDSection_Semestre")
        ]);

        // Check if each item is available and set them in state
        if (IDsection_semestre_Module) {
          setIDsection_semestre_Module_par(parseInt(IDsection_semestre_Module, 10));
          /// await localforage.removeItem("IDsection_semestre_Module");
        }
        if (IDSemestre_formation) {
          setIDSemestre_formation_par(parseInt(IDSemestre_formation, 10));
          ///await localforage.removeItem("IDSemestre_formation");
        }
        if (IDSection_Semestre) {
          setIDSection_Semestre_par(parseInt(IDSection_Semestre, 10));
          /// await localforage.removeItem("IDSection_Semestre");
        }
      } catch (err) {
        console.error("Error fetching data from localforage:", err);
      }
    };

    fetchData();
  }, []);

  // API URL for fetching data
  const apiUrl = `${API_BASE_URL}/api/REQ_Apprenant_Section_semstre_module`;

  useEffect(() => {
    if (crypt_IDApprenant_Section_semstre == null) {
      return;
    }

    fetch(`${apiUrl}/${crypt_IDApprenant_Section_semstre}`, {
      method: "Get",
      credentials: "include", // Send cookies with the request
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`,
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);

        // Repeat the data five times
        // const repeatedData = Array(4).fill(data.data).flat();

        // Set the repeated data to the result state


        setResult(data.data);
        setresult_dfep(data.data_dfep);
        setresult_mfep(data.data_mfep);
      })
      .catch((error) => console.error("Error fetching data:", error));

  }, [crypt_IDApprenant_Section_semstre]); // Add dependencies


  // Generate PDF once data is fetched and pdfUrl is not set
  useEffect(() => {
    if (result && !pdfUrl) {
      //console.log('Success result:', result);
        //alert(localStorage.getItem(APP_PREFIX + 'Eval_notes_active'));
       
      if (((Number(result.Eval_notes_active) != 0
        || result.Eval_notes_active == null)
        || (Number(result_dfep.Eval_notes_active_dfep) != 0)
        || (Number(result_mfep.Eval_notes_active_mfep) != 0)
      ) &&
        (localStorage.getItem(APP_PREFIX + 'Eval_notes_active') != "0"
          || localStorage.getItem(APP_PREFIX + 'Eval_notes_active') == null)) {
        //navigate('/');
      }
      else {
        localStorage.removeItem(APP_PREFIX + 'Eval_notes_active');
      }


      // Create a new jsPDF instance
      const pdf = new jsPDF();

      // Set document information
      pdf.setProperties({
        title: 'كشف النقاط',
        subject: 'كشف النقاط',
        author: 'MFEP',
      });

      const fontUrl = `${process.env.PUBLIC_URL}/Amiri-Regular.ttf`;
      const fontUrl_bold = `${process.env.PUBLIC_URL}/Amiri-Bold.ttf`;
      const fontUrl_arabic_ejaza = `${process.env.PUBLIC_URL}/arabic-ejaza.ttf`;
      const fontUrl_majalla = `${process.env.PUBLIC_URL}/majalla.ttf`;
      const fontUrl_SimplifiedArabic = `${process.env.PUBLIC_URL}/SimplifiedArabic.ttf`;
      const fontUrl_calibri_0 = `${process.env.PUBLIC_URL}/calibri_0.ttf`;
      const fontUrl_calibrib_0 = `${process.env.PUBLIC_URL}/calibrib_0.ttf`;

      pdf.addFont(fontUrl, 'Amiri', 'normal');
      // Draw Certificate title
      pdf.addFont(fontUrl_bold, 'Amiri_bold', 'normal');
      pdf.addFont(fontUrl_arabic_ejaza, 'arabic_ejaza', 'normal');
      pdf.addFont(fontUrl_majalla, 'Sakkal Majalla', 'normal');
      pdf.addFont(fontUrl_SimplifiedArabic, 'SimplifiedArabic', 'normal');
      pdf.addFont(fontUrl_calibri_0, 'calibri', 'normal');
      pdf.addFont(fontUrl_calibrib_0, 'calibri_bold', 'normal');


      // Define function to draw page numbers
      const addPageNumbers = () => {
        const totalPagesString = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPagesString; i++) {
          pdf.setPage(i);
          pdf.setFont('Amiri');
          pdf.setFontSize(10);
          ///pdf.text(`Page ${i} of ${totalPagesString}`, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 10);
          pdf.text(`صفحة ${i} من ${totalPagesString}`, pdf.internal.pageSize.width / 2, pdf.internal.pageSize.height - 10, { align: 'center' });

        }
      };

      pdf.setFont('calibri_bold'); // Set font

      // Set the title of the browser tab
      document.title = 'كشف النقاط';

      // Set font size and text direction
      pdf.setFontSize(14);
      pdf.text('الجمهورية الجزائرية الديمقراطية الشعبية', pdf.internal.pageSize.getWidth() / 2, 12, { align: 'center' });

      // Move to new line
      pdf.setLineHeightFactor(1.5); // Adjust line height if needed

      // Draw Ministry information
      pdf.text('وزارة التكويـن والتعلـيـم المهنييـن', pdf.internal.pageSize.getWidth() / 2, 22, { align: 'center' });
      pdf.setFont('calibri_bold'); // Set font
      ///pdf.setFont('Amiri'); // Set font
      pdf.setFontSize(12);
      // Draw Wilaya and Direction information
      if (result !== null && result[0].WilayaNom) {
        //pdf.text('ولاية ' + result.WilayaNom, pdf.internal.pageSize.getWidth() - 10, 30, { align: 'right' });
        pdf.text('مديرية التكوين والتعليم المهنيين لولاية ' + result[0].WilayaNom, pdf.internal.pageSize.getWidth() - 10, 30, { align: 'right' });
      } else {
        //pdf.text('ولاية ', pdf.internal.pageSize.getWidth() - 10, 30, { align: 'right' });
        pdf.text('مديرية التكوين والتعليم المهنيين لولاية ', pdf.internal.pageSize.getWidth() - 10, 30, { align: 'right' });
      }

      // Draw Name, Date, and Number
      pdf.text(result[0].Nom_etablissement, pdf.internal.pageSize.getWidth() - 10, 37, { align: 'right' });
      pdf.text(' .....:الرقم', pdf.internal.pageSize.getWidth() - 10, 43, { align: 'right' });



      pdf.setFont('Amiri_bold');
      pdf.setFontSize(20);
      pdf.setLineHeightFactor(1.5); // Adjust line height if needed
      pdf.text('كشف النقاط', pdf.internal.pageSize.getWidth() / 2, 53, { align: 'center' });

      pdf.setFontSize(12);
      pdf.setFont('calibri_bold');
      pdf.text('اللقب و الاسم :   ' + result[0].Nom + ' ' + result[0].Prenom + '    ' + ' الإختصاص: ' + result[0].Nom_Se, pdf.internal.pageSize.getWidth() - 10, 63, { align: 'right' });

      // Function to add text with right-to-left alignment
      function addTextRTL(pdf, text, x, y) {
        const textWidth = pdf.getTextWidth(text);
        pdf.text(text, x - textWidth, y);
      }

      // Function to add text with left-to-right alignment
      function addTextLTR(pdf, text, x, y) {
        pdf.text(text, x, y);
      }

      // Define the base position
      const baseX = pdf.internal.pageSize.getWidth() - 10;
      const baseY = 73;

      // Define the text parts
      const registrationLabel = ' :رقم التسجيل';
      const registrationValue = `${result[0].NumIns}`;
      const levelText = `المستوى: ${result[0].Niveau_FpNom}`;
      const qualificationText = `الشهادة: ${result[0].qualification_dplmNom}`;

      // Calculate widths for text parts
      const registrationLabelWidth = pdf.getTextWidth(registrationLabel);
      const registrationValueWidth = pdf.getTextWidth(registrationValue);
      const levelTextWidth = pdf.getTextWidth(levelText);
      const qualificationTextWidth = pdf.getTextWidth(qualificationText);

      // Calculate total width for label and value combined
      const totalRegistrationWidth = registrationLabelWidth + registrationValueWidth;

      // Add registration label (RTL) and value (LTR)
      addTextRTL(pdf, registrationLabel, baseX, baseY); // RTL
      addTextLTR(pdf, registrationValue, baseX - registrationValueWidth + registrationLabelWidth - 45, baseY); // LTR

      // Add the level text and qualification text
      addTextRTL(pdf, levelText, baseX - totalRegistrationWidth - 10, baseY);
      addTextRTL(pdf, qualificationText, baseX - totalRegistrationWidth - levelTextWidth - 20, baseY);

      // Convert result.DateDF and result.DateFF to Date objects
      var dateDF = new Date(result[0].DateDF);
      var dateFF = new Date(result[0].DateFF);

      // Get day, month, and year components
      var dayDF = dateDF.getDate();
      var monthDF = dateDF.getMonth() + 1; // Months are zero-based, so add 1
      var yearDF = dateDF.getFullYear();

      var dayFF = dateFF.getDate();
      var monthFF = dateFF.getMonth() + 1; // Months are zero-based, so add 1
      var yearFF = dateFF.getFullYear();

      // Format the date strings as dd-mm-yyyy
      var formattedDateDF = ('0' + dayDF).slice(-2) + '-' + ('0' + monthDF).slice(-2) + '-' + yearDF;
      var formattedDateFF = ('0' + dayFF).slice(-2) + '-' + ('0' + monthFF).slice(-2) + '-' + yearFF;

      pdf.text(' فترة التكوين من:   ' + formattedDateDF + '   إلى   ' + formattedDateFF, pdf.internal.pageSize.getWidth() - 10, 83, { align: 'right' });

      // Convert result.DateDF and result.DateFF to Date objects
      var dateDF2 = new Date(result[0].DateD);
      var dateFF2 = new Date(result[0].DateF);

      // Get day, month, and year components
      var dayDF2 = dateDF2.getDate();
      var monthDF2 = dateDF2.getMonth() + 1; // Months are zero-based, so add 1
      var yearDF2 = dateDF2.getFullYear();

      var dayFF2 = dateFF2.getDate();
      var monthFF2 = dateFF2.getMonth() + 1; // Months are zero-based, so add 1
      var yearFF2 = dateFF2.getFullYear();

      // Format the date strings as dd-mm-yyyy
      var formattedDateDF2 = ('0' + dayDF2).slice(-2) + '-' + ('0' + monthDF2).slice(-2) + '-' + yearDF2;
      var formattedDateFF2 = ('0' + dayFF2).slice(-2) + '-' + ('0' + monthFF2).slice(-2) + '-' + yearFF2;


      pdf.text('السداسي:   ' + result[0].NumSem + '   من:   ' + formattedDateDF2 + '   إلى   ' + formattedDateFF2, pdf.internal.pageSize.getWidth() - 10, 93, { align: 'right' });


      /*
            pdf.text(
              `رقم التسجيل: '${result[0].NumIns}' المستوى: ${result[0].Niveau_FpNom} الشهادة: ${result[0].qualification_dplmNom}`, 
              pdf.internal.pageSize.getWidth() - 10, 
              73, 
              { align: 'right' }
            );
            
             
      /*
            pdf.setFont('Amiri');
            ////pdf.setFont('arabic_ejaza'); // Set font
      
            // Set the title of the browser tab
            document.title = 'النقاط حسب المادة';
      
            // Set font size and text direction
            pdf.setFontSize(16);
            ///pdf.text('الجمهورية الجزائرية الديمقراطية الشعبية', pdf.internal.pageSize.getWidth() / 2, 12, { align: 'center' });
            pdf.setFontSize(14);
            // Draw Ministry information
            pdf.text('وزارة التكويـن والتعلـيـم المهنييـن', pdf.internal.pageSize.getWidth() / 2, 12, { align: 'center' });
      
            // Move to new line
            pdf.setLineHeightFactor(1.5); // Adjust line height if needed
            pdf.text(result[0].Nom_etablissement, pdf.internal.pageSize.getWidth() / 2, 20, { align: 'center' });
      
            pdf.setFont('Sakkal Majalla'); // Set font
      
            pdf.setFont('Amiri_bold');
            pdf.setFontSize(20);
            pdf.setLineHeightFactor(1.5); // Adjust line height if needed
            //pdf.text('وثيقة نقل النقاط', pdf.internal.pageSize.getWidth() / 2, 30, { align: 'center' });
      
            // Assuming pdf is your jspdf object
            let text = 'وثيقة نقل النقاط';
            let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            let x = (pdf.internal.pageSize.getWidth() - textWidth) / 2;
            let y = 30;
      
            // Draw the text
            pdf.text(text, x, y);
      
            // Calculate the position for the underline
            let underlineY = y + 2; // Adjust this value based on your font size
      
            // Draw the underline
            pdf.setLineWidth(0.5); // Set line width as needed
            pdf.line(x, underlineY, x + textWidth, underlineY);
      
      
      
            ///pdf.setFont('Sakkal Majalla'); // Set font
      
            // Assuming pdf is your jspdf object
      
            // Set font for 'الفرع:'
            pdf.setFont('Amiri_bold');
            pdf.setFontSize(16);
            pdf.text(' :الفرع', pdf.internal.pageSize.getWidth() - 10, 40, { align: 'right' });
      
            // Set font for result[0].Nom_section + ' ' + result[0].Groupe+ ' ' + result[0].Nom_mode_formation
            pdf.setFont('Amiri');
            pdf.text(' ( ' + result[0].Nom_section + ' ' + result[0].Groupe + ' ) ' + result[0].Nom_mode_formation, pdf.internal.pageSize.getWidth() - 23, 40, { align: 'right' });
      
            // Set font for 'المادة:'
            pdf.setFont('Amiri_bold');
            pdf.setFontSize(16);
            pdf.text(' :المادة', pdf.internal.pageSize.getWidth() - 10, 50, { align: 'right' });
      
            // Set font for result[0].Nom_section + ' ' + result[0].Groupe+ ' ' + result[0].Nom_mode_formation
            pdf.setFont('Amiri');
            pdf.text(result[0].NomMdl, pdf.internal.pageSize.getWidth() - 23, 50, { align: 'right' });
      
            // Set font for 'الأستاذ:'
            pdf.setFont('Amiri_bold');
            pdf.setFontSize(14);
            pdf.text('(الأستاذ)ة', pdf.internal.pageSize.getWidth() - 10, 60, { align: 'right' });
      
            // Set font for result[0].Nom_section + ' ' + result[0].Groupe+ ' ' + result[0].Nom_mode_formation
            pdf.setFont('Amiri');
            pdf.text(result[0].Nom_encadrement + ' ' + result[0].Prenom_encadrement + ' ' + '/' + result[0].Nom_grade + ' : ', pdf.internal.pageSize.getWidth() - 28, 60, { align: 'right' });
      
      
            // Set font for 'المعامل / النقطة الإقصائية:'
            pdf.setFont('Amiri_bold');
            pdf.setFontSize(12);
      
            pdf.text('المعامل / النقطة الإقصائية', 24, 60, { align: 'left' });
      
            // Draw underline
            let textWidth2 = pdf.getStringUnitWidth('المعامل / النقطة الإقصائية') * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            pdf.setLineWidth(0.5); // Adjust line width as needed
            pdf.line(24, 62, 24 + textWidth2, 62); // Adjust y-coordinate (62) based on font size and position
      
      
      
            // Set font for result[0].Nom_section + ' ' + result[0].Groupe+ ' ' + result[0].Nom_mode_formation
            pdf.setFont('Amiri');
            pdf.setFontSize(14);
            pdf.text(result[0].coef + ' / ' + result[0].Ne + ' : ', 10, 60, { align: 'left' });
      */
      // Initialize finalY to store the Y position where the autoTable ends
      let finalY = 0;
      // Calculate total sum of 'coef'
      let totalCoef = result.reduce((sum, row) => sum + row.coef, 0);

      // AutoTable configuration
      pdf.autoTable({
        head: [['الملاحظة', '', '', 'المعدل النهائي', '', 'معدل المادة قبل', 'الامتحان الشامل\n ../40', '2مراقبة\n ../20', '1مراقبة\n ../20', 'المادة', 'الرقم']],
        body: result.map((row, index) => [
          '',          // الملاحظة
          formatNumber(row.Ne),     // المعدل بعد
          (row.coef),      // الإستدراك
          formatNumber(row.MoyApr),       // 40/ش
          formatNumber(row.NoteR),       // 40/ش
          formatNumber(row.MoyAvr),      // م20/2
          formatNumber(row.NoteCs),      // م20/1
          formatNumber(row.NoteC2),     // تاريخ الميلاد
          formatNumber(row.NoteC1),

          row.NomMdl,      // اللقب
          index + 1       // الرقم
        ]),
        /// body: result.map(row => Object.values(row)),
        startY: 100, // Y position from where the table should start
        theme: 'grid', // 'striped', 'grid', 'plain', 'css' (default is 'striped')
        margin: { top: 10, left: 5 }, // Margins (in units declared at inception of PDF document

        headStyles: {
          font: 'calibri_bold',
          fontSize: 8,
          fontStyle: 'bold',
          halign: 'center',
          valign: 'middle',
          fillColor: [240, 240, 240], // Light gray background
          textColor: [0, 0, 0]// Black text color,

        },

        bodyStyles: {
          font: 'calibri_bold',
          fontSize: 10,
          cellPadding: 2,
          halign: 'right', // Align body cells to the right
          textColor: [0, 0, 0] // Black text color
        },

        columnStyles: {
          0: { cellWidth: 20 }, // الملاحظة column width
          1: { cellWidth: 15, halign: 'center', valign: 'middle' }, // المعدل بعد column width
          2: { cellWidth: 10, halign: 'center', valign: 'middle' }, // الإستدراك column width
          3: { cellWidth: 15, halign: 'center', valign: 'middle' }, // المعدل قبل column width
          4: { cellWidth: 15, halign: 'center', valign: 'middle' }, // 40/ش column width
          5: { cellWidth: 15, halign: 'center', valign: 'middle' }, // م20/2 column width
          6: { cellWidth: 15, halign: 'center', valign: 'middle' }, // م20/1 column width
          7: { cellWidth: 15, halign: 'center', valign: 'middle' }, // تاريخ الميلاد column width
          8: { cellWidth: 15, halign: 'center', valign: 'middle' }, // الإسم column width
          9: { cellWidth: 55 }, // اللقب column width
          10: { cellWidth: 10, halign: 'center', valign: 'middle' }  // الرقم column width
        },
        didDrawCell: (data) => {
          if (data.section === 'head') { // Only for header cells
            const { x, y, width, height } = data.cell;
            const borderWidth = 0.2; // Width of the border

            // Draw border
            pdf.setDrawColor(200, 200, 200); // Set border color to black
            pdf.setLineWidth(borderWidth); // Set border width
            pdf.rect(x, y, width, height); // Draw rectangle
          }


          if (data.section === 'head' && data.column.index === 1) { // Check if it's the 'Name' header
            const { x, y, width, height } = data.cell;
            const text = 'الاقصائية';
            const textWidth = pdf.getTextWidth(text);

            // Compute position to center the rotated text
            const centerX = 3 + x + width / 2;
            const centerY = 5 + y + height / 2;

            pdf.text(text, centerX, centerY, {
              angle: 70,
              align: 'center', // Align text to center horizontally
              baseline: 'middle' // Align text to center vertically

            });
          }

          if (data.section === 'head' && data.column.index === 2) { // Check if it's the 'Name' header
            const { x, y, width, height } = data.cell;
            const text = 'المعامل';
            const textWidth = pdf.getTextWidth(text);

            // Compute position to center the rotated text
            const centerX = 3 + x + width / 2;
            const centerY = 5 + y + height / 2;

            pdf.text(text, centerX, centerY, {
              angle: 70,
              align: 'center', // Align text to center horizontally
              baseline: 'middle' // Align text to center vertically

            });
          }
          if (data.section === 'head' && data.column.index === 4) { // Check if it's the 'Name' header
            const { x, y, width, height } = data.cell;
            const text = 'الاستدراك';
            const textWidth = pdf.getTextWidth(text);

            // Compute position to center the rotated text
            const centerX = 3 + x + width / 2;
            const centerY = 5 + y + height / 2;

            pdf.text(text, centerX, centerY, {
              angle: 70,
              align: 'center', // Align text to center horizontally
              baseline: 'middle' // Align text to center vertically

            });
          }
        },
        didDrawPage: (data) => {
          finalY = data.cursor.y; // Capture the final Y position after the table is drawn
          if (pdf.internal.pageSize.height - finalY >= 100) {

            addPageNumbers(data); // Call the function to add page numbers
          }

        }
        ,
        styles: {
          cellWidth: 'wrap', // This ensures that content does not overflow
          halign: 'right' // Right align text in all cells by default
        }
      });


      // Ensures the next content always appears after the table, even on a new page
      if (pdf.internal.pageSize.height - finalY < 100) {

        pdf.addPage(); // Add a new page if there isn't enough space
        finalY = 10; // Reset finalY for the new page
        addPageNumbers();
      }


      // Set font for 'مج قبل:'
      pdf.setFont('calibri_bold');
      pdf.setFontSize(12);

      // Render the right-to-left text first
      pdf.text(' :معدل دورة عادية', pdf.internal.pageSize.getWidth() - 10, finalY + 10, { align: 'right' });
      // Render the formatted number within the square brackets right after the text
      pdf.text('[ ' + formatNumber(result[0].MoyAvr_Ap) + ' ]', pdf.internal.pageSize.getWidth() - 43, finalY + 10, { align: 'right' });
      pdf.text(' :المجموع', pdf.internal.pageSize.getWidth() - 62, finalY + 10, { align: 'right' });
      pdf.text('[ ' + formatNumber(result[0].TotalMoyAvr) + ' ]', pdf.internal.pageSize.getWidth() - 81, finalY + 10, { align: 'right' });

      pdf.text(' :المعاملات', pdf.internal.pageSize.getWidth() - 103, finalY + 10, { align: 'right' });
      pdf.text('[ ' + (totalCoef) + ' ]', pdf.internal.pageSize.getWidth() - 123, finalY + 10, { align: 'right' });

      // Render the right-to-left text first
      pdf.text(' :معدل بعد الإستدراك', pdf.internal.pageSize.getWidth() - 10, finalY + 20, { align: 'right' });
      // Render the formatted number within the square brackets right after the text
      pdf.text('[ ' + formatNumber(result[0].MoyAvr_Ap) + ' ]', pdf.internal.pageSize.getWidth() - 49, finalY + 20, { align: 'right' });

      pdf.text(' :المجموع', pdf.internal.pageSize.getWidth() - 68, finalY + 20, { align: 'right' });
      pdf.text('[ ' + formatNumber(result[0].TotalMoyAvr) + ' ]', pdf.internal.pageSize.getWidth() - 87, finalY + 20, { align: 'right' });

      // Render the right-to-left text first
      pdf.text('  [         ] :عدد الغيابات: ]         [  منها مبررة', pdf.internal.pageSize.getWidth() - 10, finalY + 30, { align: 'right' });

      pdf.text(' :قرار اللجنة', pdf.internal.pageSize.getWidth() - 10, finalY + 40, { align: 'right' });
      pdf.text('[ ' + (result[0].Decision_evalsNom) + ' ]', pdf.internal.pageSize.getWidth() - 32, finalY + 40, { align: 'right' });


      // Draw issuing information
      pdf.setFontSize(12);
      // Get the current date
      var currentDate = new Date();

      // Get day, month, and year components
      var day = currentDate.getDate();
      var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
      var year = currentDate.getFullYear();

      // Format the date as dd-mm-yyyy
      var formattedCurrentDate = ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;

      // Display the formatted current date
      pdf.text('حرر بـ : ' + result[0].CommunnNom + ' في : ' + formattedCurrentDate, 60, finalY + 60, { align: 'left' });

      pdf.text('مدير )ة( المؤسسة', 80, finalY + 70, { align: 'left' });


      // Create QR code as a data URL
      const qrDataURL = document.getElementById('qrCode').toDataURL('image/png');

      // Calculate the X-coordinate for right alignment
      const qrCodeX = pdf.internal.pageSize.getWidth() - 200; // 60 is the total width of the QR code image and its margin

      // Add QR code image to the PDF
      pdf.addImage(qrDataURL, 'PNG', qrCodeX, finalY + 80, 30, 30);


      // Set font for result[0].Nom_section + ' ' + result[0].Groupe+ ' ' + result[0].Nom_mode_formation
      // pdf.setFont('calibri');
      //pdf.text(formatNumber(result[0].TotalMoyAvr), pdf.internal.pageSize.getWidth() - 35, finalY + 10, { align: 'right' });

      // Customize PDF content based on fetched data
      // Adjust this part based on how your data is structured
      ///pdf.text(`IDsection_semestre_Module: ${IDsection_semestre_Module_par}`, 20, 20);
      //pdf.text(`IDSemestre_formation: ${IDSemestre_formation_par}`, 20, 40);
      ///pdf.text(`IDSection_Semestre: ${IDSection_Semestre_par}`, 20, 60);

      // Generate a blob URL for the PDF content
      const pdfBlob = pdf.output('blob');
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);

      // Set pdfUrl to the blob URL
      setPdfUrl(pdfBlobUrl);

      // Get total number of pages
      const totalPages = pdf.internal.getNumberOfPages();
      setTotalPages(totalPages);

    }
  }, [result, pdfUrl]);

  const classes_CircularProgress = useStyles_CircularProgress();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {!pdfUrl && (
        <div className={classes_CircularProgress.overlay}>
          <Dialog open={!pdfUrl} PaperProps={{ sx: { backgroundColor: 'white' } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
              <CircularProgress sx={{ ml: 1 }} color="primary" />
              <Typography variant="h6" sx={{ color: '#004d40', fontFamily: 'Tajawal, sans-serif', fontWeight: 900, ml: 2 }}>
                جاري التحميل...
              </Typography>
            </Box>
          </Dialog>
        </div>
      )}

      {pdfUrl && (
        <div>

          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="800px"
            aria-label="Generated PDF"
          />
        </div>
      )}

      {/* Render QR code component */}
      <div style={{ display: 'none' }}>
        <QRCode id="qrCode" value={`${window.location.origin}${window.location.pathname}`} />
      </div>

    </div>
  );
};

export default Releve2notes;
