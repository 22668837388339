import React, { useState, useEffect, useRef } from "react";

import { Typography, Box } from "@mui/material";
import axios from "axios";

import { InputLabel, Select, MenuItem } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ApiIcon from "@mui/icons-material/Api";
import FunctionsIcon from "@mui/icons-material/Functions";
import useAuthentication_etab from "./useAuthentication_etab";
import LinearProgress from "@mui/material/LinearProgress";
import StorageIcon from "@mui/icons-material/Storage";
import Autocomplete from "@mui/material/Autocomplete";
import AlertTitle from "@mui/material/AlertTitle";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { API_BASE_URLhfsql } from './config'; // Adjust the path if necessary

import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,

  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { arSD } from '@mui/x-data-grid/locales';

import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { CacheProvider } from "@emotion/react";

import Checkbox from "@mui/material/Checkbox";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import XLSX from "sheetjs-style";
import Footer from "./Footer";
import Header from "./Header";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import createCache from "@emotion/cache";

import "../containers/amirifont.css"; // Import the font CSS file
import Divider from "@mui/material/Divider";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import useAuthentication_logged from './useAuthentication_logged';


const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme(
  {
    direction: "rtl",
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: hoverColor,
          },
          "&$focused $notchedOutline": {
            borderColor: focusColor,
          },
        },
        notchedOutline: {
          borderColor: defaultColor,
        },
      },
    },
    fieldset: {
      fontFamily: '"Changa", sans-serif',
      fontSize: 45,
    },
  },
  arSD
);

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

async function loginUser(email, MotDePass) {
  try {
    const response = await fetch(`${API_BASE_URLhfsql}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, MotDePass: MotDePass }),
    });

    if (!response.ok) {
      throw new Error("Invalid credentials");
    }

    const data = await response.json();
    const token = data.token;
    return token;
  } catch (error) {
    console.error("Error logging in:", error.message);
    return null;
  }
}

const StyledBox = styled("div")(({ theme }) => ({
  height: 400,
  width: "100%",
  "& .MuiDataGrid-cell--editable": {
    backgroundColor:
      theme.palette.mode === "dark" ? "#376331" : "rgb(217 243 190)",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1
      })`,
    color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {
  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(
        isValid ? null : "Username must contain only alphabets (a to z)."
      );
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : "Username must contain only Arabic alphabets.");
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

const apiUrl = `${API_BASE_URLhfsql}/api/getapis_functions`;

function Migrate_hfsql() {

  const authlogged = useAuthentication_logged(); // Get values from useAuthentication_logged
  const authCandData = useAuthentication_etab(); // Always call the hook at the top level, but we will conditionally use its data
  const [authCand, setAuthCand] = useState(null); // Create a state for authCand

  // Function to get the value from authlogged
  const getValue = (key) => {
    try {
      return authlogged?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  const IDCandidat = getValue("IDCandidat");
  const IDEncadrement = getValue("IDEncadrement");
  const IDetablissement = getValue("IDetablissement");
  const IDDFEP = getValue("IDDFEP");
  const IDNature_etsF = getValue("IDNature_etsF");
  const IDUtilisateur = getValue("IDUtilisateur");
  const UtilisateurNom = getValue("UtilisateurNom");
  const LoginTracesNom = getValue("LoginTracesNom");
  const IDapis_userslist = getValue("IDapis_userslist");
  const loadingauth = getValue("loadingauth");

  // Function to get the value from authCand if it exists
  const getValuecand = (key) => {
    try {
      return authCand?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  let NumSem = getValuecand("NumSem");
  let loadingauthcand = getValuecand("loadingauth");
  let Singup_active = getValuecand("Singup_active");
  let download_cert_scol_active = getValuecand("download_cert_scol_active");
  let EditingInfo_active = getValuecand("EditingInfo_active");
  let Eval_notes_active = getValuecand("Eval_notes_active");
  let download_cert_inscription_active = getValuecand("download_cert_inscription_active");
  let Singup_active_Dfep = getValuecand("Singup_active_Dfep");
  let download_cert_scol_active_Dfep = getValuecand("download_cert_scol_active_Dfep");
  let EditingInfo_active_Dfep = getValuecand("EditingInfo_active_Dfep");
  let Eval_notes_active_Dfep = getValuecand("Eval_notes_active_Dfep");
  let download_cert_inscription_active_Dfep = getValuecand("download_cert_inscription_active_Dfep");
  let Singup_active_Mfep = getValuecand("Singup_active_Mfep");
  let download_cert_scol_active_Mfep = getValuecand("download_cert_scol_active_Mfep");
  let EditingInfo_active_Mfep = getValuecand("EditingInfo_active_Mfep");
  let Eval_notes_active_Mfep = getValuecand("Eval_notes_active_Mfep");
  let download_cert_inscription_active_Mfep = getValuecand("download_cert_inscription_active_Mfep");
  let crypted_id = getValuecand("crypted_id");

  useEffect(() => {
    // Check if IDCandidat is valid and authCandData is not loading
    if (IDetablissement !== null && IDetablissement !== undefined && Number(IDetablissement) > 0 && !authCandData["loadingauth"]) {
      // Deep comparison of authCand and authCandData to prevent unnecessary state updates
      if (JSON.stringify(authCand) !== JSON.stringify(authCandData)) {

        setAuthCand(authCandData); // Update authCand state when IDCandidat is valid and authCand has changed
      }
    }
  }, [IDetablissement, authCandData, authCand]); // Trigger useEffect when IDCandidat, authCandData, or authCand changes




  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isConfirmationOpen_token, setConfirmationOpen_token] = useState(false);
  const [confirmed_token, setconfirmed_token] = useState(false);

  const [responsevalue, setresponsevalue] = useState(null);
  const [responsevalue_gettable, setresponsevalue_gettable] = useState(null);

  const [selectedtable, setselectedtable] = useState(false);

  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState("");
  const [valuegenpw_nomUser, setvaluegenpw_nomUser] = useState("");

  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);

  const [privilege_users_data, setprivilege_users_data] = useState([]);
  const [selectedID_privilege_users, setselectedID_privilege_users] =
    useState("");

  const useStyles_listdfep = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 18,
        direction: "rtl",
      },
    },
    inputRoot: {
      color: "#004d40",

      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif',
        fontWeight: 700,
        color: "#004d40",
        // Default left padding is 6px
        paddingLeft: 26,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple",
      },
    },
  }));

  const classes_listdfep = useStyles_listdfep();

  const editedValueRef = useRef(editedValue);

  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);

  const navigate = useNavigate();

  const [selectidtable, setselectidtable] = useState([]);

  const [selecttable, setselecttable] = useState(null);


  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const btnclicked = () => {
    setselectedtable(true);
    setresponsevalue(null);
    //alert(value);
    axios
      .get(`${API_BASE_URLhfsql}/api/connectToHfSql_anytable/${value}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then((response) => {
        // Handle the response here if needed
        console.log(response.data); // Example: Log the response data
        ///setresponsevalue(response.data);
        setresponsevalue(prevValue => prevValue ? prevValue + response.data : response.data);



      })
      .catch((error) => {
        // Handle errors here
        // console.error("Error calling Laravel route:", error);
        //setresponsevalue(error);
        // Handle errors here
        console.error("Error calling Laravel route:", error);

        // Check if the error has a response and if it's a JSON error
        if (error.response && error.response.data) {
          setresponsevalue(error.response.data);
        } else {
          setresponsevalue({ error: true, message: "An unknown error occurred." });
        }

      })
      .finally(() => {

        setselectedtable(false);


      });
  };
  const btnclicked2 = (event, values) => {
    if (selectidtable) {

      /*{ label: "All Tables", indexvalue: 900, value: "All Tables" },
         { label: "Small Tables Only", indexvalue: 901, value: "Small Tables Only" },
         { label: "Big Tables Only", indexvalue: 902, value: "Big Tables Only" }
     */
      //connectToHfSql_anytable
      let endpoint = "";

      if (Array.isArray(selectidtable) && selectidtable.length === 1) {

        if (Number(selectidtable[0].indexvalue) === 900) {
          const labels = options_tables.map(option => option.label);
          ////endpoint = `connectToHfSql_anytable_multiple/${JSON.stringify(labels)}`;
          endpoint = `connectToHfSql_anytable_multiple/AllTable`;
        }


        else if (Number(selectidtable[0].indexvalue) === 901) {


          const excludedTables = [
            "Candidat",
            "apprenant_section_semstre",
            "apprenant",
            "offre",
            "section_semestre",
            "section",
            "encadrement",
            "section_semestre_module",
            "apprenant_fin",
            "encadrement_diplome",
            "competance",
            "employeur",
            "etablissement_grade",
            "stageperfectionnemnt",
            "locaux",
            "logement"
            /// "etablissement"
          ]; // List of tables to exclude


          const labels = options_tables
            .filter(option => !excludedTables.includes(option.label)) // Filter out excluded tables
            .map(option => option.label);

          ////endpoint = `connectToHfSql_anytable_multiple/${JSON.stringify(labels)}`;
          endpoint = `connectToHfSql_anytable_multiple/SmallTable`;




        }


        else if (Number(selectidtable[0].indexvalue) === 902) {

          const Tableslist = [
            "Candidat",
            "apprenant_section_semstre",
            "apprenant",
            "offre",
            "section_semestre",
            "section",
            "encadrement",
            "section_semestre_module",
            "apprenant_fin",
            "encadrement_diplome",
            "competance",
            "employeur",
            "etablissement_grade",
            "stageperfectionnemnt",
            "locaux",
            "logement"
          ]; // List of tables to exclude


          ///endpoint = `connectToHfSql_anytable_multiple/${JSON.stringify(Tableslist)}`;
          endpoint = `connectToHfSql_anytable_multiple/BigTable`;

        }
        else {
          // Single selection
          endpoint = `connectToHfSql_anytable_multiple/${selectidtable[0].label}`;
        }




      } else if (Array.isArray(selectidtable) && selectidtable.length > 1) {
        // Multiple selections
        const labels = selectidtable.map(option => option.label);
        endpoint = `connectToHfSql_anytable_multiple/${JSON.stringify(labels)}`;

      } else {
        // Handle case when selectidtable is null or undefined
        console.error("No selected tables");
        return;
      }


      setselectedtable(true);
      setresponsevalue_gettable(null);


      axios
        .get(`${API_BASE_URLhfsql}/api/connectToHfSql_anytable_multiple/${endpoint}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        })
        .then((response) => {
          // Handle the response here if needed
          console.log(response.data); // Example: Log the response data
          ///setresponsevalue(response.data);
          setresponsevalue(prevValue => prevValue ? prevValue + response.data : response.data);



        })
        .catch((error) => {
          // Handle errors here
          console.error("Error calling Laravel route:", error);
          setresponsevalue(error);


        })
        .finally(() => {

          setselectedtable(false);


        });
    }
  };



  const onchange_table = (event, values) => {


    if (values) {

      ////alert(values[0].value);

      if (Array.isArray(values)) {
        // Multiple selections
        setselectidtable(values);
        // Handle multiple selections as needed
      } else {
        // Single selection
        if (values) {
          setselectidtable([values]);
          setselecttable(values.value);
          // Handle single selection as needed
        } else {
          // Handle case when values is null (e.g., when clearing the selection)
          setselectidtable([]);
          setselecttable('');
        }
      }


      setselectedtable(true);
      setresponsevalue_gettable(null);
      setresponsevalue(null);
      axios
        .get(`${API_BASE_URLhfsql}/api/getMigrate_hfsql/` + values[0].value, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        })
        .then((response) => {
          // Handle the response here if needed
          console.log(response.data); // Example: Log the response data
          setresponsevalue_gettable(response.data);
          setselectedtable(false);
        })
        .catch((error) => {
          setselectedtable(false);
          setresponsevalue_gettable(error);
          // Handle errors here
          console.error("Error calling Laravel route:", error);
        });



    } else {
      return;
    }
  };

  /*
  const options_tables = [
    { label: "action", indexvalue: 1, value: "action" },
    { label: "actiontype", indexvalue: 3, value: "actiontype" },
    { label: "activite", indexvalue: 4, value: "activite" },
    { label: "activiteencadreur", indexvalue: 5, value: "activiteencadreur" },
    { label: "activiteencadreurtype", indexvalue: 6, value: "activiteencadreurtype" },
    { label: "annee", indexvalue: 12, value: "annee" },
    { label: "Annee_Formation", indexvalue: 13, value: "Annee_Formation" },
    { label: "apc", indexvalue: 14, value: "apc" },
    { label: "apc1", indexvalue: 15, value: "apc1" },
    { label: "apprenant", indexvalue: 16, value: "apprenant" },
    { label: "apprenant_absence", indexvalue: 17, value: "apprenant_absence" },
    { label: "apprenant_fin", indexvalue: 18, value: "apprenant_fin" },
    { label: "apprenant_regime", indexvalue: 19, value: "apprenant_regime" },
    { label: "apprenant_section_semstre", indexvalue: 20, value: "apprenant_section_semstre" },
    { label: "apprenant_section_semstre_module", indexvalue: 21, value: "apprenant_section_semstre_module" },
    { label: "association_clube", indexvalue: 22, value: "association_clube" },
    { label: "avenat", indexvalue: 24, value: "avenat" },
    { label: "axe_principale", indexvalue: 25, value: "axe_principale" },
    { label: "axe_tache", indexvalue: 26, value: "axe_tache" },
    { label: "beneficiaire", indexvalue: 27, value: "beneficiaire" },
    { label: "branche", indexvalue: 28, value: "branche" },
    { label: "branches", indexvalue: 29, value: "branches" },
    { label: "budget", indexvalue: 30, value: "budget" },
    { label: "bureau", indexvalue: 31, value: "bureau" },
    { label: "bureauetude", indexvalue: 32, value: "bureauetude" },
    { label: "bureauetudenature", indexvalue: 33, value: "bureauetudenature" },
    { label: "cal_tache", indexvalue: 34, value: "cal_tache" },
    { label: "cal_tachedetail", indexvalue: 35, value: "cal_tachedetail" },
    { label: "Candidat", indexvalue: 36, value: "Candidat" },
    { label: "candidat_memo", indexvalue: 37, value: "candidat_memo" },
    { label: "categorie", indexvalue: 38, value: "categorie" },
    { label: "certificat_insc", indexvalue: 39, value: "certificat_insc" },
    { label: "certificat_scol", indexvalue: 40, value: "certificat_scol" },
    { label: "citoyen", indexvalue: 41, value: "citoyen" },
    { label: "citoyenec", indexvalue: 42, value: "citoyenec" },
    { label: "citoyenetr", indexvalue: 43, value: "citoyenetr" },
    { label: "communes", indexvalue: 44, value: "communes" },
    { label: "communn", indexvalue: 45, value: "communn" },
    { label: "competance", indexvalue: 46, value: "competance" },
    { label: "competences_activites_professionnelles", indexvalue: 47, value: "competences_activites_professionnelles" },
    { label: "competences_savoirstheoriques", indexvalue: 48, value: "competences_savoirstheoriques" },
    { label: "compinge", indexvalue: 49, value: "compinge" },
    { label: "compingedfep", indexvalue: 50, value: "compingedfep" },
    { label: "compingeperiode", indexvalue: 51, value: "compingeperiode" },
    { label: "compte", indexvalue: 52, value: "compte" },
    { label: "concours_examenprofessionnel", indexvalue: 53, value: "concours_examenprofessionnel" },
    { label: "convention", indexvalue: 54, value: "convention" },
    { label: "convocation", indexvalue: 55, value: "convocation" },
    { label: "corp", indexvalue: 56, value: "corp" },
    { label: "corps", indexvalue: 57, value: "corps" },
    { label: "cycle_scol", indexvalue: 58, value: "cycle_scol" },
    { label: "decision", indexvalue: 59, value: "decision" },
    { label: "decision_eval_mdl", indexvalue: 60, value: "decision_eval_mdl" },
    { label: "decision_eval_mdlavr", indexvalue: 61, value: "decision_eval_mdlavr" },
    { label: "decision_eval_semestravr", indexvalue: 62, value: "decision_eval_semestravr" },
    { label: "decision_evalf", indexvalue: 63, value: "decision_evalf" },
    { label: "decision_evals", indexvalue: 64, value: "decision_evals" },
    { label: "decision_insc", indexvalue: 65, value: "decision_insc" },
    { label: "decision_orie", indexvalue: 66, value: "decision_orie" },
    { label: "decret", indexvalue: 67, value: "decret" },
    { label: "decret_etat", indexvalue: 68, value: "decret_etat" },
    { label: "decretmfep", indexvalue: 69, value: "decretmfep" },
    { label: "decrettype", indexvalue: 70, value: "decrettype" },
    { label: "dercrte_memo", indexvalue: 71, value: "dercrte_memo" },
    { label: "deroulemnt_cntr", indexvalue: 72, value: "deroulemnt_cntr" },
    { label: "dfep", indexvalue: 73, value: "dfep" },
    { label: "diplome", indexvalue: 74, value: "diplome" },
    { label: "direction", indexvalue: 75, value: "direction" },
    { label: "direction_cal_tache", indexvalue: 76, value: "direction_cal_tache" },
    { label: "direction_cal_tachedetail", indexvalue: 77, value: "direction_cal_tachedetail" },
    { label: "dossier", indexvalue: 78, value: "dossier" },
    { label: "email", indexvalue: 79, value: "email" },
    { label: "emailreponce", indexvalue: 80, value: "emailreponce" },
    { label: "emploitemp", indexvalue: 81, value: "emploitemp" },
    { label: "employeur", indexvalue: 82, value: "employeur" },
    { label: "employeur_branche", indexvalue: 83, value: "employeur_branche" },
    { label: "employeur_nature", indexvalue: 84, value: "employeur_nature" },
    { label: "employeurtype", indexvalue: 85, value: "employeurtype" },
    { label: "encadrement", indexvalue: 86, value: "encadrement" },
    { label: "encadrement_absence", indexvalue: 87, value: "encadrement_absence" },
    { label: "encadrement_compinge", indexvalue: 88, value: "encadrement_compinge" },
    { label: "encadrement_diplome", indexvalue: 89, value: "encadrement_diplome" },
    { label: "encadrement_fautes_professionnelles", indexvalue: 90, value: "encadrement_fautes_professionnelles" },
    { label: "encadrement_fonctions", indexvalue: 91, value: "encadrement_fonctions" },
    { label: "equipement_etat", indexvalue: 92, value: "equipement_etat" },
    { label: "equipement_mouv", indexvalue: 93, value: "equipement_mouv" },
    { label: "equipement_obs", indexvalue: 94, value: "equipement_obs" },
    { label: "equipement_transfert", indexvalue: 95, value: "equipement_transfert" },
    { label: "equipementexploi", indexvalue: 96, value: "equipementexploi" },
    { label: "etablisement_detail", indexvalue: 97, value: "etablisement_detail" },
    { label: "etablissement", indexvalue: 98, value: "etablissement" },
    { label: "etablissement_association", indexvalue: 99, value: "etablissement_association" },
    { label: "etablissement_creattransf", indexvalue: 100, value: "etablissement_creattransf" },
    { label: "etablissement_enservice", indexvalue: 101, value: "etablissement_enservice" },
    { label: "etablissement_etat", indexvalue: 102, value: "etablissement_etat" },
    { label: "etablissement_felicitations_sanction", indexvalue: 103, value: "etablissement_felicitations_sanction" },
    { label: "etablissement_fonctions", indexvalue: 104, value: "etablissement_fonctions" },
    { label: "etablissement_grade", indexvalue: 105, value: "etablissement_grade" },
    { label: "etablissement_session", indexvalue: 106, value: "etablissement_session" },
    { label: "etatmatrice", indexvalue: 107, value: "etatmatrice" },
    { label: "ets_form", indexvalue: 108, value: "ets_form" },
    { label: "ets_scolaire", indexvalue: 109, value: "ets_scolaire" },
    { label: "etsform_branche", indexvalue: 110, value: "etsform_branche" },
    { label: "fautes_professionnelles", indexvalue: 111, value: "fautes_professionnelles" },
    { label: "fautes_professionnelles_degre", indexvalue: 112, value: "fautes_professionnelles_degre" },
    { label: "felicitations_sanctiontype", indexvalue: 113, value: "felicitations_sanctiontype" },
    { label: "filiere", indexvalue: 114, value: "filiere" },
    { label: "fonctionnaire", indexvalue: 115, value: "fonctionnaire" },
    { label: "fonctions", indexvalue: 116, value: "fonctions" },
    { label: "fonctionsnature", indexvalue: 117, value: "fonctionsnature" },
    { label: "fournisseur", indexvalue: 118, value: "fournisseur" },
    { label: "grade", indexvalue: 119, value: "grade" },
    { label: "idactiviteobs", indexvalue: 120, value: "idactiviteobs" },
    { label: "idequipement_etattype", indexvalue: 121, value: "idequipement_etattype" },
    { label: "idsituationadministrat_type", indexvalue: 122, value: "idsituationadministrat_type" },
    { label: "jour", indexvalue: 123, value: "jour" },
    { label: "localtype", indexvalue: 124, value: "localtype" },
    { label: "locaux", indexvalue: 125, value: "locaux" },
    { label: "locaux_etatactual", indexvalue: 126, value: "locaux_etatactual" },
    { label: "logement", indexvalue: 127, value: "logement" },
    { label: "logement_causeoccup", indexvalue: 128, value: "logement_causeoccup" },
    { label: "logement_causeoccup_logement_nature", indexvalue: 129, value: "logement_causeoccup_logement_nature" },
    { label: "logement_nature", indexvalue: 130, value: "logement_nature" },
    { label: "logementnaturejur", indexvalue: 131, value: "logementnaturejur" },
    { label: "logementoccupe", indexvalue: 132, value: "logementoccupe" },
    { label: "logementtype", indexvalue: 133, value: "logementtype" },
    { label: "maitre_apprenti", indexvalue: 134, value: "maitre_apprenti" },
    { label: "membrepv", indexvalue: 135, value: "membrepv" },
    { label: "mention", indexvalue: 136, value: "mention" },
    { label: "ministere", indexvalue: 137, value: "ministere" },
    { label: "miseajour", indexvalue: 138, value: "miseajour" },
    { label: "mode", indexvalue: 139, value: "mode" },
    { label: "mode_formation", indexvalue: 140, value: "mode_formation" },
    { label: "mode_gestion", indexvalue: 141, value: "mode_gestion" },
    { label: "mode_promotion", indexvalue: 142, value: "mode_promotion" },
    { label: "mode_recrutement", indexvalue: 143, value: "mode_recrutement" },
    { label: "module", indexvalue: 144, value: "module" },
    { label: "module_modeenseigne", indexvalue: 146, value: "module_modeenseigne" },
    { label: "mois", indexvalue: 147, value: "mois" },
    { label: "nationalitee", indexvalue: 148, value: "nationalitee" },
    { label: "nationalites", indexvalue: 149, value: "nationalites" },
    { label: "nature_etsf", indexvalue: 150, value: "nature_etsf" },
    { label: "nature_etss", indexvalue: 151, value: "nature_etss" },
    { label: "naturedirection", indexvalue: 152, value: "naturedirection" },
    { label: "niveau_fp", indexvalue: 153, value: "niveau_fp" },
    { label: "niveau_scol", indexvalue: 154, value: "niveau_scol" },
    { label: "niveau_scol_enca", indexvalue: 155, value: "niveau_scol_enca" },
    { label: "niveau_scolaires", indexvalue: 156, value: "niveau_scolaires" },
    { label: "niveauqualifications", indexvalue: 157, value: "niveauqualifications" },
    { label: "nom_equipement", indexvalue: 158, value: "nom_equipement" },
    { label: "nomenclature_mode", indexvalue: 159, value: "nomenclature_mode" },
    { label: "nomenclature_ver", indexvalue: 160, value: "nomenclature_ver" },
    { label: "nomenclaturecorp", indexvalue: 161, value: "nomenclaturecorp" },
    { label: "observationaprenants", indexvalue: 162, value: "observationaprenants" },
    { label: "offert", indexvalue: 163, value: "offert" },
    { label: "offre", indexvalue: 164, value: "offre" },
    { label: "offres", indexvalue: 165, value: "offres" },
    { label: "operation", indexvalue: 166, value: "operation" },
    { label: "operationdecision", indexvalue: 167, value: "operationdecision" },
    { label: "operationdecision_operations", indexvalue: 168, value: "operationdecision_operations" },
    { label: "operationetat", indexvalue: 169, value: "operationetat" },
    { label: "operationnature", indexvalue: 170, value: "operationnature" },
    { label: "operationnature_operations", indexvalue: 171, value: "operationnature_operations" },
    { label: "operationoffre", indexvalue: 172, value: "operationoffre" },
    { label: "operations", indexvalue: 173, value: "operations" },
    { label: "operations_etablissement", indexvalue: 174, value: "operations_etablissement" },
    { label: "operationtype", indexvalue: 175, value: "operationtype" },
    { label: "order", indexvalue: 176, value: "order" },
    { label: "ordernature", indexvalue: 177, value: "ordernature" },
    { label: "participant", indexvalue: 178, value: "participant" },
    { label: "participantnature", indexvalue: 179, value: "participantnature" },
    { label: "pays", indexvalue: 180, value: "pays" },
    { label: "portefeuille", indexvalue: 181, value: "portefeuille" },
    { label: "poste_budgetaire", indexvalue: 182, value: "poste_budgetaire" },
    { label: "privelege", indexvalue: 183, value: "privelege" },
    { label: "privelege_utilisateur", indexvalue: 184, value: "privelege_utilisateur" },
    { label: "procedure_disciplinaire", indexvalue: 185, value: "procedure_disciplinaire" },
    { label: "profil", indexvalue: 186, value: "profil" },
    { label: "programme", indexvalue: 187, value: "programme" },
    { label: "propriete_location", indexvalue: 188, value: "propriete_location" },
    { label: "pvsemstriel", indexvalue: 189, value: "pvsemstriel" },
    { label: "qualification_dplm", indexvalue: 190, value: "qualification_dplm" },
    { label: "recommandations", indexvalue: 191, value: "recommandations" },
    { label: "recommandationsgenerale", indexvalue: 192, value: "recommandationsgenerale" },
    { label: "region", indexvalue: 193, value: "region" },
    { label: "secteur", indexvalue: 194, value: "secteur" },
    { label: "secteurs", indexvalue: 195, value: "secteurs" },
    { label: "section", indexvalue: 196, value: "section" },
    { label: "section_pv", indexvalue: 197, value: "section_pv" },
    { label: "section_semestre", indexvalue: 198, value: "section_semestre" },
    { label: "section_semestre_module", indexvalue: 199, value: "section_semestre_module" },
    { label: "semestre", indexvalue: 200, value: "semestre" },
    { label: "semestre_formation", indexvalue: 201, value: "semestre_formation" },
    { label: "service", indexvalue: 202, value: "service" },
    { label: "session", indexvalue: 203, value: "session" },
    { label: "session_dfep", indexvalue: 204, value: "session_dfep" },
    { label: "session_mode_formation", indexvalue: 205, value: "session_mode_formation" },
    { label: "sessions", indexvalue: 206, value: "sessions" },
    { label: "siege_annex", indexvalue: 207, value: "siege_annex" },
    { label: "sitfamille", indexvalue: 208, value: "sitfamille" },
    { label: "situation", indexvalue: 209, value: "situation" },
    { label: "situationadministrat", indexvalue: 210, value: "situationadministrat" },
    { label: "sous_programme", indexvalue: 211, value: "sous_programme" },
    { label: "sousaction", indexvalue: 212, value: "sousaction" },
    { label: "souscategorie", indexvalue: 213, value: "souscategorie" },
    { label: "specialite", indexvalue: 214, value: "specialite" },
    { label: "specialite_module", indexvalue: 215, value: "specialite_module" },
    { label: "specialite_programme", indexvalue: 216, value: "specialite_programme" },
    { label: "specialites", indexvalue: 217, value: "specialites" },
    { label: "stageperfectionnemnt", indexvalue: 218, value: "stageperfectionnemnt" },
    { label: "statusets", indexvalue: 219, value: "statusets" },
    { label: "suivi", indexvalue: 220, value: "suivi" },
    { label: "syndicat", indexvalue: 221, value: "syndicat" },
    { label: "tache", indexvalue: 222, value: "tache" },
    { label: "tbudget", indexvalue: 223, value: "tbudget" },
    { label: "titre", indexvalue: 224, value: "titre" },
    { label: "trimestre", indexvalue: 225, value: "trimestre" },
    { label: "type_interv", indexvalue: 226, value: "type_interv" },
    { label: "typemodule", indexvalue: 227, value: "typemodule" },
    { label: "unitemodulaire", indexvalue: 228, value: "unitemodulaire" },
    { label: "utilisateur", indexvalue: 229, value: "utilisateur" },
    { label: "utilisateur_mode_formation", indexvalue: 230, value: "utilisateur_mode_formation" },
    { label: "validationdossier", indexvalue: 231, value: "validationdossier" },
    { label: "vehicule", indexvalue: 232, value: "vehicule" },
    { label: "vehiculeenergie", indexvalue: 233, value: "vehiculeenergie" },
    { label: "vehiculeetat", indexvalue: 234, value: "vehiculeetat" },
    { label: "vehiculegenre", indexvalue: 235, value: "vehiculegenre" },
    { label: "vehiculesmarque", indexvalue: 236, value: "vehiculesmarque" },
    { label: "vehiculestype", indexvalue: 237, value: "vehiculestype" },
    { label: "wilaya", indexvalue: 238, value: "wilaya" },
    { label: "wilayas", indexvalue: 239, value: "wilayas" },
    { label: "zone", indexvalue: 240, value: "zone" },
    { label: "All Tables", indexvalue: 900, value: "All Tables" },
    { label: "Small Tables Only", indexvalue: 901, value: "Small Tables Only" },
    { label: "Big Tables Only", indexvalue: 902, value: "Big Tables Only" }
  ];
  */
  const options_nametables = [
    { label: "Candidat", indexvalue: 1, value: "Candidat" },
    { label: "Apprenant_Section_semstre", indexvalue: 2, value: "Apprenant_Section_semstre" },
    { label: "Apprenant", indexvalue: 3, value: "Apprenant" },
    { label: "Apprenant_Section_semstre_module", indexvalue: 4, value: "Apprenant_Section_semstre_module" },
    { label: "section_semestre_Module", indexvalue: 5, value: "section_semestre_Module" },
    { label: "Section", indexvalue: 6, value: "Section" },
    { label: "Offre", indexvalue: 7, value: "Offre" },
    { label: "Section_Semestre", indexvalue: 8, value: "Section_Semestre" },
    { label: "Apprenant_Fin", indexvalue: 9, value: "Apprenant_Fin" },
    { label: "Encadrement", indexvalue: 10, value: "Encadrement" },
    { label: "Encadrement_Grade", indexvalue: 11, value: "Encadrement_Grade" },
    { label: "equipement_composant", indexvalue: 12, value: "equipement_composant" },
    { label: "Encadrement_SituationAdministrat", indexvalue: 13, value: "Encadrement_SituationAdministrat" },
    { label: "Locaux", indexvalue: 14, value: "Locaux" },
    { label: "Equipement", indexvalue: 15, value: "Equipement" },
    { label: "Logement", indexvalue: 16, value: "Logement" },
    { label: "Etablissement", indexvalue: 17, value: "Etablissement" },
    { label: "Etablisement_Detail", indexvalue: 18, value: "Etablisement_Detail" },
    { label: "Etablissement_Fonctions", indexvalue: 19, value: "Etablissement_Fonctions" },
    { label: "Ets_Form", indexvalue: 20, value: "Ets_Form" },
    { label: "Vehicule", indexvalue: 21, value: "Vehicule" },
    { label: "Specialite", indexvalue: 22, value: "Specialite" },
    { label: "Communn", indexvalue: 23, value: "Communn" },
    { label: "apc", indexvalue: 24, value: "apc" },
    { label: "Employeur", indexvalue: 25, value: "Employeur" },
    { label: "Offre_Specialite", indexvalue: 26, value: "Offre_Specialite" },
    { label: "epas", indexvalue: 27, value: "epas" },
    { label: "Utilisateur", indexvalue: 28, value: "Utilisateur" },
    { label: "offre_specialite_prop", indexvalue: 29, value: "offre_specialite_prop" },
    { label: "specialites", indexvalue: 30, value: "specialites" },
    { label: "Cal_Tache", indexvalue: 31, value: "Cal_Tache" },
    { label: "EmploiTemp", indexvalue: 32, value: "EmploiTemp" },
    { label: "Grade", indexvalue: 33, value: "Grade" },
    { label: "MiseAjour", indexvalue: 34, value: "MiseAjour" },
    { label: "Privelege_Utilisateur", indexvalue: 35, value: "Privelege_Utilisateur" },
    { label: "Semestre_Formation", indexvalue: 36, value: "Semestre_Formation" },
    { label: "Privelege", indexvalue: 37, value: "Privelege" },
    { label: "session", indexvalue: 38, value: "session" },
    { label: "Encadrement_Fonctions", indexvalue: 39, value: "Encadrement_Fonctions" },
    { label: "Mode_formation", indexvalue: 40, value: "Mode_formation" },
    { label: "Action", indexvalue: 41, value: "Action" },
    { label: "Activite", indexvalue: 42, value: "Activite" },
    { label: "ActiviteProgramme", indexvalue: 43, value: "ActiviteProgramme" },
    { label: "DFEP", indexvalue: 44, value: "DFEP" },
    { label: "semestre", indexvalue: 45, value: "semestre" },
    { label: "Annee_Formation", indexvalue: 46, value: "Annee_Formation" },
    { label: "Etablissement_Felicitations_Sanction", indexvalue: 47, value: "Etablissement_Felicitations_Sanction" },
    { label: "Fonctions", indexvalue: 48, value: "Fonctions" },
    { label: "Nature_etsF", indexvalue: 49, value: "Nature_etsF" },
    { label: "Niveau_Scol", indexvalue: 50, value: "Niveau_Scol" },
    { label: "SousCategorie", indexvalue: 51, value: "SousCategorie" },
    { label: "direction", indexvalue: 52, value: "direction" },
    { label: "mode", indexvalue: 53, value: "mode" },
    { label: "nationalites", indexvalue: 54, value: "nationalites" },
    { label: "niveau_scolaires", indexvalue: 55, value: "niveau_scolaires" },
    { label: "niveauqualifications", indexvalue: 56, value: "niveauqualifications" },
    { label: "pays", indexvalue: 57, value: "pays" },
    { label: "sessions", indexvalue: 58, value: "sessions" },
    { label: "Categorie", indexvalue: 59, value: "Categorie" },
    { label: "Encadrement_SitFamile", indexvalue: 60, value: "Encadrement_SitFamile" },
    { label: "Niveau_Scol_Enca", indexvalue: 61, value: "Niveau_Scol_Enca" },
    { label: "Nomenclature_ver", indexvalue: 62, value: "Nomenclature_ver" },
    { label: "Service", indexvalue: 63, value: "Service" },
    { label: "Utilisateur_Mode_formation", indexvalue: 64, value: "Utilisateur_Mode_formation" },
    { label: "candidat_update", indexvalue: 65, value: "candidat_update" },
    { label: "wilayas", indexvalue: 66, value: "wilayas" },
    { label: "ActiviteEncadreur", indexvalue: 67, value: "ActiviteEncadreur" },
    { label: "ActivitePresse", indexvalue: 68, value: "ActivitePresse" },
    { label: "Branche", indexvalue: 69, value: "Branche" },
    { label: "CompingePeriode", indexvalue: 70, value: "CompingePeriode" },
    { label: "Corp", indexvalue: 71, value: "Corp" },
    { label: "Employeur_Nature", indexvalue: 72, value: "Employeur_Nature" },
    { label: "Etablissement_Etat", indexvalue: 73, value: "Etablissement_Etat" },
    { label: "Fautes_Professionnelles", indexvalue: 74, value: "Fautes_Professionnelles" },
    { label: "Jour", indexvalue: 75, value: "Jour" },
    { label: "Mode_gestion", indexvalue: 76, value: "Mode_gestion" },
    { label: "Portefeuille", indexvalue: 77, value: "Portefeuille" },
    { label: "Programme", indexvalue: 78, value: "Programme" },
    { label: "SituationAdministrat", indexvalue: 79, value: "SituationAdministrat" },
    { label: "SousAction", indexvalue: 80, value: "SousAction" },
    { label: "Sous_Programme", indexvalue: 81, value: "Sous_Programme" },
    { label: "Titre", indexvalue: 82, value: "Titre" },
    { label: "Wilaya", indexvalue: 83, value: "Wilaya" },
    { label: "annee", indexvalue: 84, value: "annee" }
  ];
   
  const options_tables = [
    { label: "annee", indexvalue: 1, value: "annee" },
    { label: "Annee_Formation", indexvalue: 2, value: "Annee_Formation" },
    { label: "apc", indexvalue: 3, value: "apc" },
    { label: "apc1", indexvalue: 4, value: "apc1" },
    { label: "apprenant", indexvalue: 5, value: "apprenant" },
    { label: "apprenant_absence", indexvalue: 6, value: "apprenant_absence" },
    { label: "apprenant_fin", indexvalue: 7, value: "apprenant_fin" },
    { label: "apprenant_regime", indexvalue: 8, value: "apprenant_regime" },
    { label: "apprenant_section_semstre", indexvalue: 9, value: "apprenant_section_semstre" },
    { label: "apprenant_section_semstre_module", indexvalue: 10, value: "apprenant_section_semstre_module" },
    { label: "branche", indexvalue: 11, value: "branche" },
    { label: "branches", indexvalue: 12, value: "branches" },
    { label: "bureau", indexvalue: 13, value: "bureau" },
    { label: "cal_tache", indexvalue: 14, value: "cal_tache" },
    { label: "cal_tachedetail", indexvalue: 15, value: "cal_tachedetail" },
    { label: "Candidat", indexvalue: 16, value: "Candidat" },
    { label: "cartecarburant", indexvalue: 17, value: "cartecarburant" },
    { label: "categorie", indexvalue: 18, value: "categorie" },
    { label: "certificat_insc", indexvalue: 19, value: "certificat_insc" },
    { label: "certificat_scol", indexvalue: 20, value: "certificat_scol" },
    { label: "citoyen", indexvalue: 21, value: "citoyen" },
    { label: "citoyenec", indexvalue: 22, value: "citoyenec" },
    { label: "citoyenetr", indexvalue: 23, value: "citoyenetr" },
    { label: "communes", indexvalue: 24, value: "communes" },
    { label: "communn", indexvalue: 25, value: "communn" },
    { label: "competance", indexvalue: 26, value: "competance" },
    { label: "competences_activites_professionnelles", indexvalue: 27, value: "competences_activites_professionnelles" },
    { label: "competences_savoirstheoriques", indexvalue: 28, value: "competences_savoirstheoriques" },
    { label: "compinge", indexvalue: 29, value: "compinge" },
    { label: "compingedfep", indexvalue: 30, value: "compingedfep" },
    { label: "compingeperiode", indexvalue: 31, value: "compingeperiode" },
    { label: "compte", indexvalue: 32, value: "compte" },
    { label: "concours_examenprofessionnel", indexvalue: 33, value: "concours_examenprofessionnel" },
    { label: "convention", indexvalue: 34, value: "convention" },
    { label: "conventionarticle", indexvalue: 35, value: "conventionarticle" },
    { label: "conventionetat", indexvalue: 36, value: "conventionetat" },
    { label: "conventiontype", indexvalue: 37, value: "conventiontype" },
    { label: "convocation", indexvalue: 38, value: "convocation" },
    { label: "corp", indexvalue: 39, value: "corp" },
    { label: "corps", indexvalue: 40, value: "corps" },
    { label: "cycle_scol", indexvalue: 41, value: "cycle_scol" },
    { label: "decision", indexvalue: 42, value: "decision" },
    { label: "decision_eval_mdl", indexvalue: 43, value: "decision_eval_mdl" },
    { label: "decision_eval_mdlavr", indexvalue: 44, value: "decision_eval_mdlavr" },
    { label: "decision_eval_semestravr", indexvalue: 45, value: "decision_eval_semestravr" },
    { label: "decision_evalf", indexvalue: 46, value: "decision_evalf" },
    { label: "decision_evals", indexvalue: 47, value: "decision_evals" },
    { label: "decision_insc", indexvalue: 48, value: "decision_insc" },
    { label: "decision_orie", indexvalue: 49, value: "decision_orie" },
    { label: "decret", indexvalue: 50, value: "decret" },
    { label: "decret_etat", indexvalue: 51, value: "decret_etat" },
    { label: "decretmfep", indexvalue: 52, value: "decretmfep" },
    { label: "decrettype", indexvalue: 53, value: "decrettype" },
    { label: "deroulemnt_cntr", indexvalue: 54, value: "deroulemnt_cntr" },
    { label: "DFEP", indexvalue: 55, value: "DFEP" },
    { label: "diplome", indexvalue: 56, value: "diplome" },
    { label: "direction", indexvalue: 57, value: "direction" },
    { label: "direction_cal_tache", indexvalue: 58, value: "direction_cal_tache" },
    { label: "direction_cal_tachedetail", indexvalue: 59, value: "direction_cal_tachedetail" },
    { label: "dossier", indexvalue: 60, value: "dossier" },
    { label: "emploitemp", indexvalue: 61, value: "emploitemp" },
    { label: "employeur", indexvalue: 62, value: "employeur" },
    { label: "employeur_branche", indexvalue: 63, value: "employeur_branche" },
    { label: "employeur_nature", indexvalue: 64, value: "employeur_nature" },
    { label: "employeurtype", indexvalue: 65, value: "employeurtype" },
    { label: "encadrement", indexvalue: 66, value: "encadrement" },
    { label: "encadrement_absence", indexvalue: 67, value: "encadrement_absence" },
    { label: "encadrement_compinge", indexvalue: 68, value: "encadrement_compinge" },
    { label: "encadrement_cyclescol", indexvalue: 69, value: "encadrement_cyclescol" },
    { label: "encadrement_diplome", indexvalue: 70, value: "encadrement_diplome" },
    { label: "encadrement_enfant", indexvalue: 71, value: "encadrement_enfant" },
    { label: "encadrement_fautes_professionnelles", indexvalue: 72, value: "encadrement_fautes_professionnelles" },
    { label: "encadrement_fonctions", indexvalue: 73, value: "encadrement_fonctions" },
    { label: "encadrement_grade", indexvalue: 74, value: "encadrement_grade" },
    { label: "encadrement_mouvement", indexvalue: 75, value: "encadrement_mouvement" },
    { label: "encadrement_procdisciplinaire", indexvalue: 76, value: "encadrement_procdisciplinaire" },
    { label: "encadrement_proposilieu", indexvalue: 77, value: "encadrement_proposilieu" },
    { label: "encadrement_proposition", indexvalue: 78, value: "encadrement_proposition" },
    { label: "encadrement_sitfamile", indexvalue: 79, value: "encadrement_sitfamile" },
    { label: "encadrement_situationadministrat", indexvalue: 80, value: "encadrement_situationadministrat" },
    { label: "encadrement_syndicat", indexvalue: 81, value: "encadrement_syndicat" },
    { label: "engagement", indexvalue: 82, value: "engagement" },
    { label: "epas", indexvalue: 83, value: "epas" },
    { label: "equipement", indexvalue: 84, value: "equipement" },
    { label: "equipement_composant", indexvalue: 85, value: "equipement_composant" },
    { label: "equipement_composant_etat", indexvalue: 86, value: "equipement_composant_etat" },
    { label: "equipement_etablissement", indexvalue: 87, value: "equipement_etablissement" },
    { label: "equipement_etat", indexvalue: 88, value: "equipement_etat" },
    { label: "equipement_mouv", indexvalue: 89, value: "equipement_mouv" },
    { label: "equipement_obs", indexvalue: 90, value: "equipement_obs" },
    { label: "equipement_transfert", indexvalue: 91, value: "equipement_transfert" },
    { label: "equipementexploi", indexvalue: 92, value: "equipementexploi" },
    { label: "etablisement_detail", indexvalue: 93, value: "etablisement_detail" },
    { label: "etablissement", indexvalue: 94, value: "etablissement" },
    { label: "etablissement_association", indexvalue: 95, value: "etablissement_association" },
    { label: "etablissement_creattransf", indexvalue: 96, value: "etablissement_creattransf" },
    { label: "etablissement_enservice", indexvalue: 97, value: "etablissement_enservice" },
    { label: "etablissement_etat", indexvalue: 98, value: "etablissement_etat" },
    { label: "etablissement_felicitations_sanction", indexvalue: 99, value: "etablissement_felicitations_sanction" },
    { label: "etablissement_fonctions", indexvalue: 100, value: "etablissement_fonctions" },
    { label: "etablissement_grade", indexvalue: 101, value: "etablissement_grade" },
    { label: "etablissement_session", indexvalue: 102, value: "etablissement_session" },
    { label: "ets_form", indexvalue: 103, value: "ets_form" },
    { label: "ets_scolaire", indexvalue: 104, value: "ets_scolaire" },
    { label: "etsform_branche", indexvalue: 105, value: "etsform_branche" },
    { label: "fautes_professionnelles", indexvalue: 106, value: "fautes_professionnelles" },
    { label: "fautes_professionnelles_degre", indexvalue: 107, value: "fautes_professionnelles_degre" },
    { label: "filiere", indexvalue: 108, value: "filiere" },
    { label: "fonctionnaire", indexvalue: 109, value: "fonctionnaire" },
    { label: "fonctions", indexvalue: 110, value: "fonctions" },
    { label: "fonctionsnature", indexvalue: 111, value: "fonctionsnature" },
    { label: "fournisseur", indexvalue: 112, value: "fournisseur" },
    { label: "grade", indexvalue: 113, value: "grade" },
    { label: "idactiviteobs", indexvalue: 114, value: "idactiviteobs" },
    { label: "idequipement_etattype", indexvalue: 115, value: "idequipement_etattype" },
    { label: "idsituationadministrat_type", indexvalue: 116, value: "idsituationadministrat_type" },
    { label: "jour", indexvalue: 117, value: "jour" },
    { label: "localtype", indexvalue: 118, value: "localtype" },
    { label: "locaux", indexvalue: 119, value: "locaux" },
    { label: "locaux_etatactual", indexvalue: 120, value: "locaux_etatactual" },
    { label: "logement", indexvalue: 121, value: "logement" },
    { label: "logement_causeoccup", indexvalue: 122, value: "logement_causeoccup" },
    { label: "logement_causeoccup_logement_nature", indexvalue: 123, value: "logement_causeoccup_logement_nature" },
    { label: "logement_nature", indexvalue: 124, value: "logement_nature" },
    { label: "logementnaturejur", indexvalue: 125, value: "logementnaturejur" },
    { label: "logementoccupe", indexvalue: 126, value: "logementoccupe" },
    { label: "logementtype", indexvalue: 127, value: "logementtype" },
    { label: "maitre_apprenti", indexvalue: 128, value: "maitre_apprenti" },
    { label: "membrepv", indexvalue: 129, value: "membrepv" },
    { label: "mention", indexvalue: 130, value: "mention" },
    { label: "ministere", indexvalue: 131, value: "ministere" },
    { label: "mode", indexvalue: 132, value: "mode" },
    { label: "mode_formation", indexvalue: 133, value: "mode_formation" },
    { label: "mode_gestion", indexvalue: 134, value: "mode_gestion" },
    { label: "mode_promotion", indexvalue: 135, value: "mode_promotion" },
    { label: "mode_recrutement", indexvalue: 136, value: "mode_recrutement" },
    { label: "module", indexvalue: 137, value: "module" },
    { label: "module_competences", indexvalue: 138, value: "module_competences" },
    { label: "module_modeenseigne", indexvalue: 139, value: "module_modeenseigne" },
    { label: "mois", indexvalue: 140, value: "mois" },
    { label: "nationalitee", indexvalue: 141, value: "nationalitee" },
    { label: "nationalites", indexvalue: 142, value: "nationalites" },
    { label: "nature_etsf", indexvalue: 143, value: "nature_etsf" },
    { label: "nature_etss", indexvalue: 144, value: "nature_etss" },
    { label: "naturedirection", indexvalue: 145, value: "naturedirection" },
    { label: "niveau_fp", indexvalue: 146, value: "niveau_fp" },
    { label: "niveau_scol", indexvalue: 147, value: "niveau_scol" },
    { label: "niveau_scol_enca", indexvalue: 148, value: "niveau_scol_enca" },
    { label: "niveau_scolaires", indexvalue: 149, value: "niveau_scolaires" },
    { label: "niveauqualifications", indexvalue: 150, value: "niveauqualifications" },
    { label: "nom_equipement", indexvalue: 151, value: "nom_equipement" },
    { label: "nomenclature_mode", indexvalue: 152, value: "nomenclature_mode" },
    { label: "nomenclature_ver", indexvalue: 153, value: "nomenclature_ver" },
    { label: "nomenclaturecorp", indexvalue: 154, value: "nomenclaturecorp" },
    { label: "observationaprenants", indexvalue: 155, value: "observationaprenants" },
    { label: "offert", indexvalue: 156, value: "offert" },
    { label: "offre", indexvalue: 157, value: "offre" },
    { label: "offre_specialite", indexvalue: 158, value: "offre_specialite" },
    { label: "offres", indexvalue: 159, value: "offres" },
    { label: "operation", indexvalue: 160, value: "operation" },
    { label: "operationdecision", indexvalue: 161, value: "operationdecision" },
    { label: "operationdecision_operations", indexvalue: 162, value: "operationdecision_operations" },
    { label: "operationetat", indexvalue: 163, value: "operationetat" },
    { label: "operationnature", indexvalue: 164, value: "operationnature" },
    { label: "operationnature_operations", indexvalue: 165, value: "operationnature_operations" },
    { label: "operationoffre", indexvalue: 166, value: "operationoffre" },
    { label: "operations", indexvalue: 167, value: "operations" },
    { label: "operations_etablissement", indexvalue: 168, value: "operations_etablissement" },
    { label: "operationtype", indexvalue: 169, value: "operationtype" },
    { label: "order", indexvalue: 170, value: "order" },
    { label: "ordernature", indexvalue: 171, value: "ordernature" },
    { label: "participant", indexvalue: 172, value: "participant" },
    { label: "participantnature", indexvalue: 173, value: "participantnature" },
    { label: "pays", indexvalue: 174, value: "pays" },
    { label: "portefeuille", indexvalue: 175, value: "portefeuille" },
    { label: "poste_budgetaire", indexvalue: 176, value: "poste_budgetaire" },
    { label: "privelege", indexvalue: 177, value: "privelege" },
    { label: "privelege_utilisateur", indexvalue: 178, value: "privelege_utilisateur" },
    { label: "procedure_disciplinaire", indexvalue: 179, value: "procedure_disciplinaire" },
    { label: "profil", indexvalue: 180, value: "profil" },
    { label: "programme", indexvalue: 181, value: "programme" },
    { label: "propriete_location", indexvalue: 182, value: "propriete_location" },
    { label: "pvsemstriel", indexvalue: 183, value: "pvsemstriel" },
    { label: "qualification_dplm", indexvalue: 184, value: "qualification_dplm" },
    { label: "questionnaireperiode", indexvalue: 185, value: "questionnaireperiode" },
    { label: "recommandations", indexvalue: 186, value: "recommandations" },
    { label: "recommandationsgenerale", indexvalue: 187, value: "recommandationsgenerale" },
    { label: "region", indexvalue: 188, value: "region" },
    { label: "secteur", indexvalue: 189, value: "secteur" },
    { label: "secteurs", indexvalue: 190, value: "secteurs" },
    { label: "section", indexvalue: 191, value: "section" },
    { label: "section_pv", indexvalue: 192, value: "section_pv" },
    { label: "section_semestre", indexvalue: 193, value: "section_semestre" },
    { label: "section_semestre_module", indexvalue: 194, value: "section_semestre_module" },
    { label: "semestre", indexvalue: 195, value: "semestre" },
    { label: "semestre_formation", indexvalue: 196, value: "semestre_formation" },
    { label: "service", indexvalue: 197, value: "service" },
    { label: "session", indexvalue: 198, value: "session" },
    { label: "session_dfep", indexvalue: 199, value: "session_dfep" },
    { label: "session_mode_formation", indexvalue: 200, value: "session_mode_formation" },
    { label: "sessions", indexvalue: 201, value: "sessions" },
    { label: "siege_annex", indexvalue: 202, value: "siege_annex" },
    { label: "sitfamille", indexvalue: 203, value: "sitfamille" },
    { label: "situation", indexvalue: 204, value: "situation" },
    { label: "situationadministrat", indexvalue: 205, value: "situationadministrat" },
    { label: "sous_programme", indexvalue: 206, value: "sous_programme" },
    { label: "sousaction", indexvalue: 207, value: "sousaction" },
    { label: "souscategorie", indexvalue: 208, value: "souscategorie" },
    { label: "specialite", indexvalue: 209, value: "specialite" },
    { label: "specialite_module", indexvalue: 210, value: "specialite_module" },
    { label: "specialite_programme", indexvalue: 211, value: "specialite_programme" },
    { label: "specialites", indexvalue: 212, value: "specialites" },
    { label: "stageperfectionnemnt", indexvalue: 213, value: "stageperfectionnemnt" },
    { label: "statusets", indexvalue: 214, value: "statusets" },
    { label: "suivi", indexvalue: 215, value: "suivi" },
    { label: "syndicat", indexvalue: 216, value: "syndicat" },
    { label: "tache", indexvalue: 217, value: "tache" },
    { label: "tbudget", indexvalue: 218, value: "tbudget" },
    { label: "titre", indexvalue: 219, value: "titre" },
    { label: "trimestre", indexvalue: 220, value: "trimestre" },
    { label: "type_interv", indexvalue: 221, value: "type_interv" },
    { label: "typemodule", indexvalue: 222, value: "typemodule" },
    { label: "unitemodulaire", indexvalue: 223, value: "unitemodulaire" },
    { label: "utilisateur", indexvalue: 224, value: "utilisateur" },
    { label: "utilisateur_mode_formation", indexvalue: 225, value: "utilisateur_mode_formation" },
    { label: "validationdossier", indexvalue: 226, value: "validationdossier" },
    { label: "vehicule", indexvalue: 227, value: "vehicule" },
    { label: "vehiculeenergie", indexvalue: 228, value: "vehiculeenergie" },
    { label: "vehiculeetat", indexvalue: 229, value: "vehiculeetat" },
    { label: "vehiculegenre", indexvalue: 230, value: "vehiculegenre" },
    { label: "vehiculesmarque", indexvalue: 231, value: "vehiculesmarque" },
    { label: "vehiculestype", indexvalue: 232, value: "vehiculestype" },
    { label: "wilaya", indexvalue: 233, value: "wilaya" },
    { label: "wilayas", indexvalue: 234, value: "wilayas" },
    { label: "zone", indexvalue: 235, value: "zone" },
    { label: "All Tables", indexvalue: 900, value: "All Tables" },
    { label: "Small Tables Only", indexvalue: 901, value: "Small Tables Only" },
    { label: "Big Tables Only", indexvalue: 902, value: "Big Tables Only" }
  ];



  const startIndexValue = 903; // Define the starting index value

  for (let i = 0; i < 26; i++) {
    const label = String.fromCharCode(65 + i); // Convert ASCII code to letter
    const indexvalue = startIndexValue + i;
    const value = label;

    options_tables.push({ label, indexvalue, value });
  }


  // Sort the options_tables array alphabetically by label
  options_tables.sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (!loadingauth) {
      if (
        IDUtilisateur === null || Number(IDUtilisateur) != 999
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/');
        //return;
      }

    }
  }, [
    IDUtilisateur,
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress color="success" />
      </Box>
    );
  }



  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    {
      title: 'The Lord of the Rings: The Return of the King',
      year: 2003,
    },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    {
      title: 'The Lord of the Rings: The Fellowship of the Ring',
      year: 2001,
    },
    {
      title: 'Star Wars: Episode V - The Empire Strikes Back',
      year: 1980,
    },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    {
      title: 'The Lord of the Rings: The Two Towers',
      year: 2002,
    },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    {
      title: 'Star Wars: Episode IV - A New Hope',
      year: 1977,
    },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    {
      title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
      year: 1964,
    },
    { title: 'The Great Dictator', year: 1940 },
    { title: 'Cinema Paradiso', year: 1988 },
    { title: 'The Lives of Others', year: 2006 },
    { title: 'Grave of the Fireflies', year: 1988 },
    { title: 'Paths of Glory', year: 1957 },
    { title: 'Django Unchained', year: 2012 },
    { title: 'The Shining', year: 1980 },
    { title: 'WALL·E', year: 2008 },
    { title: 'American Beauty', year: 1999 },
    { title: 'The Dark Knight Rises', year: 2012 },
    { title: 'Princess Mononoke', year: 1997 },
    { title: 'Aliens', year: 1986 },
    { title: 'Oldboy', year: 2003 },
    { title: 'Once Upon a Time in America', year: 1984 },
    { title: 'Witness for the Prosecution', year: 1957 },
    { title: 'Das Boot', year: 1981 },
    { title: 'Citizen Kane', year: 1941 },
    { title: 'North by Northwest', year: 1959 },
    { title: 'Vertigo', year: 1958 },
    {
      title: 'Star Wars: Episode VI - Return of the Jedi',
      year: 1983,
    },
    { title: 'Reservoir Dogs', year: 1992 },
    { title: 'Braveheart', year: 1995 },
    { title: 'M', year: 1931 },
    { title: 'Requiem for a Dream', year: 2000 },
    { title: 'Amélie', year: 2001 },
    { title: 'A Clockwork Orange', year: 1971 },
    { title: 'Like Stars on Earth', year: 2007 },
    { title: 'Taxi Driver', year: 1976 },
    { title: 'Lawrence of Arabia', year: 1962 },
    { title: 'Double Indemnity', year: 1944 },
    {
      title: 'Eternal Sunshine of the Spotless Mind',
      year: 2004,
    },
    { title: 'Amadeus', year: 1984 },
    { title: 'To Kill a Mockingbird', year: 1962 },
    { title: 'Toy Story 3', year: 2010 },
    { title: 'Logan', year: 2017 },
    { title: 'Full Metal Jacket', year: 1987 },
    { title: 'Dangal', year: 2016 },
    { title: 'The Sting', year: 1973 },
    { title: '2001: A Space Odyssey', year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: 'Toy Story', year: 1995 },
    { title: 'Bicycle Thieves', year: 1948 },
    { title: 'The Kid', year: 1921 },
    { title: 'Inglourious Basterds', year: 2009 },
    { title: 'Snatch', year: 2000 },
    { title: '3 Idiots', year: 2009 },
    { title: 'Monty Python and the Holy Grail', year: 1975 },
  ];

  // Define a counter variable outside of the component
  let count = 0;

  return (
    <>
      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={
          download_cert_inscription_active_Dfep
        }
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={
          download_cert_inscription_active_Mfep
        }
      />

      <Box
        sx={{
          "& .super-app-theme--header": {
            fontFamily: "Amiri",
            fontSize: "20px",
            fontWeight: "bold", // Make the font bold
            backgroundColor: "#B9D3EE",
            borderRight: "1px solid #ccc", // Add this line to apply right border to the cell
          },
        }}
      >
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={themetextfieldnew}>
            {!!snackbar && (
              <Snackbar
                open
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                sx={{
                  marginTop: 12,
                }}
              >
                <Alert
                  {...snackbar}
                  onClose={handleCloseSnackbar}
                  sx={{
                    display: "flex",
                    fontFamily: "Amiri",
                    fontSize: "20px",
                    "& .MuiAlert-icon": { marginLeft: "10px" },
                    "& .MuiAlert-action": { marginRight: "8px" },
                  }} // Adjust padding here
                />
              </Snackbar>
            )}

            <Grid container>
              <Grid item sx={{ width: "100%" }}>
                <Divider sx={{ mt: "20px" }}>
                  <Grid item>
                    <Typography
                      style={{
                        color: "#004d40",
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 25,
                        fontWeight: 700,
                        marginBottom: "25px",
                      }}
                      variant="h3"
                      gutterBottom
                    >
                      <StorageIcon sx={{ mr: "2px", fontSize: 30 }} />
                      نقل قاعدة بيانات من (HFSQL)
                    </Typography>
                  </Grid>
                </Divider>

                <Grid container justifyContent="center"></Grid>
              </Grid>
              {/* 

               
              <Grid
                container
                alignItems="center"
                align="center"
                justifyContent="center"
                sx={{ width: "100%", marginBottom: "10px" }}
              >
                <Grid item xs={12}>
                  <Grid item xs={5}>
 
                    <CacheProvider value={cacheRtl}>
                      <Autocomplete
                        multiple
                        classes={classes_listdfep}
                        limitTags={5}
                        id="multiple-limit-tags"
                        options={options_tables}
                        onChange={onchange_table}
                        getOptionLabel={(option) => option.label}
 
                        renderOption={(props, option) => {
                          const { label } = option;
                          count++;  
                          return (
                            <span
                              {...props}
                              style={{
                                backgroundColor: count % 2 === 0 ? "#add8e6" : "#FFFFFF",
                                color: "#004d40",
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 18,
                                fontWeight: 700,
                                textAlign: "left",
                                direction: "ltr",
                              }}
                            >
                              {count === -1 ? "- " + label : label}
                            </span>
                          );
                        }}


                        renderInput={(params) => (
                          <Box display="flex" justifyContent="center">
                            <TextField fullWidth {...params} label="الجداول" />
                          </Box>
                        )}

                        sx={{ mt: 1, mb: 1 }}
                      />
                    </CacheProvider>

                  </Grid>
                </Grid>



              </Grid>
              */}
            </Grid>
          </ThemeProvider>
        </CacheProvider>


        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", marginBottom: "10px" }}
        >

          <Grid item sx={{ width: '70%' }}>
            <Autocomplete
              freeSolo
              options={options_nametables.map((option) => option.label)} // Map options to display labels
              value={value}
              onChange={(event, newValue) => {
                if (newValue !== null && typeof newValue === 'string') {
                  // Handle manual input or selection from the dropdown
                  setValue(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = options.filter((option) =>
                  option.toLowerCase().includes(params.inputValue.toLowerCase())
                );

                // Allow user to enter new values not in options
                if (params.inputValue !== '' && !filtered.includes(params.inputValue)) {
                  filtered.push(params.inputValue);
                }

                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Please enter the exact table name in HFSQL."
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: '16px', direction: 'ltr' }}
                />
              )}
            />
          </Grid>


          <Grid item>
            <Button
              variant="contained"
              style={{
                fontFamily: '"Tajawal", sans-serif',
                fontSize: 20,
                fontWeight: 700,
                marginBottom: '25px',
              }}
              sx={{
                backgroundColor: '#00695c', // Green color
                '&:hover': {
                  backgroundColor: '#004d40', // Darker green on hover
                }
              }}
              onClick={btnclicked}
            >
              إجلب الجدول
            </Button>
          </Grid>
        </Grid>


        {selectedtable ? (
          <>
            <Box sx={{ width: "100%" }}>
              <LinearProgress color="success" />
            </Box>
          </>
        ) : null}

        {responsevalue_gettable ? (
          <Grid item xs={12} sx={{ direction: "ltr" }}>
            <Alert severity="info" sx={{ direction: "ltr" }}>
              <AlertTitle sx={{ direction: "ltr" }}>Info Table exist</AlertTitle>
              <Box sx={{ direction: "ltr" }}>
                <div dangerouslySetInnerHTML={{ __html: responsevalue_gettable }} />
              </Box>
            </Alert>
          </Grid>
        ) : null}


        {responsevalue ? (
          <Grid item xs={12} sx={{ direction: "ltr" }}>
            <Alert severity="info" sx={{ direction: "ltr" }}>
              <AlertTitle sx={{ direction: "ltr" }}>Info</AlertTitle>
              <Box sx={{ direction: "ltr" }}>
                <div dangerouslySetInnerHTML={{ __html: responsevalue }} />
              </Box>
            </Alert>
          </Grid>
        ) : null}
      </Box>

      <Footer />
    </>
  );
}

export default Migrate_hfsql;
