import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import localforage from 'localforage';
import { API_BASE_URL } from "./config"; // Adjust the path if necessary
import 'jspdf-autotable';

const useStyles_CircularProgress = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure it's above everything else
  },
}));

const NotesByModulesPdf = () => {
  const [pdfUrl, setPdfUrl] = useState(null); // State to store PDF URL
  const [IDsection_semestre_Module_par, setIDsection_semestre_Module_par] = useState(99446);
  const [IDSemestre_formation_par, setIDSemestre_formation_par] = useState(31);
  const [IDSection_Semestre_par, setIDSection_Semestre_par] = useState(193480);
  const [result, setResult] = useState(null); // State to store fetched data
  const [totalPages, setTotalPages] = useState(0); // State to store total pages
/*
  "IDsection_semestre_Module": 99446,
  "IDSemestre_formation": 31,
  "IDSection_Semestre": 193480
  */
  // Fetch data from local storage on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve multiple items from localforage
        const [IDsection_semestre_Module, IDSemestre_formation, IDSection_Semestre] = await Promise.all([
          localforage.getItem("IDsection_semestre_Module"),
          localforage.getItem("IDSemestre_formation"),
          localforage.getItem("IDSection_Semestre")
        ]);

        // Check if each item is available and set them in state
        if (IDsection_semestre_Module) {
          setIDsection_semestre_Module_par(parseInt(IDsection_semestre_Module, 10));
          /// await localforage.removeItem("IDsection_semestre_Module");
        }
        if (IDSemestre_formation) {
          setIDSemestre_formation_par(parseInt(IDSemestre_formation, 10));
          ///await localforage.removeItem("IDSemestre_formation");
        }
        if (IDSection_Semestre) {
          setIDSection_Semestre_par(parseInt(IDSection_Semestre, 10));
          /// await localforage.removeItem("IDSection_Semestre");
        }
      } catch (err) {
        console.error("Error fetching data from localforage:", err);
      }
    };

    fetchData();
  }, []);

  // API URL for fetching data
  const apiUrl = `${API_BASE_URL}/api/REQ_Apprenant_Section_semstre_module`;

  useEffect(() => {
        
      fetch(`${apiUrl}/2233975`, {
        method: "Get",
        credentials: "include", // Send cookies with the request
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`,
        }
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Success:', data);

          // Repeat the data five times
          const repeatedData = Array(5).fill(data.data).flat();

          // Set the repeated data to the result state
          setResult(repeatedData);
        })
        .catch((error) => console.error("Error fetching data:", error));
    
  }, []); // Add dependencies


  // Generate PDF once data is fetched and pdfUrl is not set
  useEffect(() => {
    if (result && !pdfUrl) {
      //console.log('Success result:', result);

      // Create a new jsPDF instance
      const pdf = new jsPDF();

      // Set document information
      pdf.setProperties({
        title: 'النقاط حسب المادة',
        subject: 'النقاط حسب المادة',
        author: 'MFEP',
      });

      const fontUrl = `${process.env.PUBLIC_URL}/Amiri-Regular.ttf`;
      const fontUrl_bold = `${process.env.PUBLIC_URL}/Amiri-Bold.ttf`;
      const fontUrl_arabic_ejaza = `${process.env.PUBLIC_URL}/arabic-ejaza.ttf`;
      const fontUrl_majalla = `${process.env.PUBLIC_URL}/majalla.ttf`;
      const fontUrl_SimplifiedArabic = `${process.env.PUBLIC_URL}/SimplifiedArabic.ttf`;
      const fontUrl_calibri_0 = `${process.env.PUBLIC_URL}/calibri_0.ttf`;
      const fontUrl_calibrib_0 = `${process.env.PUBLIC_URL}/calibrib_0.ttf`;

      pdf.addFont(fontUrl, 'Amiri', 'normal');
      // Draw Certificate title
      pdf.addFont(fontUrl_bold, 'Amiri_bold', 'normal');
      pdf.addFont(fontUrl_arabic_ejaza, 'arabic_ejaza', 'normal');
      pdf.addFont(fontUrl_majalla, 'Sakkal Majalla', 'normal');
      pdf.addFont(fontUrl_SimplifiedArabic, 'SimplifiedArabic', 'normal');
      pdf.addFont(fontUrl_calibri_0, 'calibri', 'normal');
      pdf.addFont(fontUrl_calibrib_0, 'calibri_bold', 'normal');


      // Define function to draw page numbers
      const addPageNumbers = () => {
        const totalPagesString = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPagesString; i++) {
          pdf.setPage(i);
          pdf.setFont('Amiri');
          pdf.setFontSize(10);
          ///pdf.text(`Page ${i} of ${totalPagesString}`, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 10);
           pdf.text(`صفحة ${i} من ${totalPagesString}`, pdf.internal.pageSize.width / 2, pdf.internal.pageSize.height - 10,{ align: 'center' });
 
        }
      };
      


      pdf.setFont('Amiri');
      ////pdf.setFont('arabic_ejaza'); // Set font

      // Set the title of the browser tab
      document.title = 'النقاط حسب المادة';

      // Set font size and text direction
      pdf.setFontSize(16);
      ///pdf.text('الجمهورية الجزائرية الديمقراطية الشعبية', pdf.internal.pageSize.getWidth() / 2, 12, { align: 'center' });
      pdf.setFontSize(14);
      // Draw Ministry information
      pdf.text('وزارة التكويـن والتعلـيـم المهنييـن', pdf.internal.pageSize.getWidth() / 2, 12, { align: 'center' });

      // Move to new line
      pdf.setLineHeightFactor(1.5); // Adjust line height if needed
      pdf.text(result[0].Nom_etablissement, pdf.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

      pdf.setFont('Sakkal Majalla'); // Set font

      pdf.setFont('Amiri_bold');
      pdf.setFontSize(20);
      pdf.setLineHeightFactor(1.5); // Adjust line height if needed
      //pdf.text('وثيقة نقل النقاط', pdf.internal.pageSize.getWidth() / 2, 30, { align: 'center' });

      // Assuming pdf is your jspdf object
      let text = 'وثيقة نقل النقاط';
      let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
      let x = (pdf.internal.pageSize.getWidth() - textWidth) / 2;
      let y = 30;

      // Draw the text
      pdf.text(text, x, y);

      // Calculate the position for the underline
      let underlineY = y + 2; // Adjust this value based on your font size

      // Draw the underline
      pdf.setLineWidth(0.5); // Set line width as needed
      pdf.line(x, underlineY, x + textWidth, underlineY);



      ///pdf.setFont('Sakkal Majalla'); // Set font

      // Assuming pdf is your jspdf object

      // Set font for 'الفرع:'
      pdf.setFont('Amiri_bold');
      pdf.setFontSize(16);
      pdf.text(' :الفرع', pdf.internal.pageSize.getWidth() - 10, 40, { align: 'right' });

      // Set font for result[0].Nom_section + ' ' + result[0].Groupe+ ' ' + result[0].Nom_mode_formation
      pdf.setFont('Amiri');
      pdf.text(' ( ' + result[0].Nom_section + ' ' + result[0].Groupe + ' ) ' + result[0].Nom_mode_formation, pdf.internal.pageSize.getWidth() - 23, 40, { align: 'right' });

      // Set font for 'المادة:'
      pdf.setFont('Amiri_bold');
      pdf.setFontSize(16);
      pdf.text(' :المادة', pdf.internal.pageSize.getWidth() - 10, 50, { align: 'right' });

      // Set font for result[0].Nom_section + ' ' + result[0].Groupe+ ' ' + result[0].Nom_mode_formation
      pdf.setFont('Amiri');
      pdf.text(result[0].NomMdl, pdf.internal.pageSize.getWidth() - 23, 50, { align: 'right' });

      // Set font for 'الأستاذ:'
      pdf.setFont('Amiri_bold');
      pdf.setFontSize(14);
      pdf.text('(الأستاذ)ة', pdf.internal.pageSize.getWidth() - 10, 60, { align: 'right' });

      // Set font for result[0].Nom_section + ' ' + result[0].Groupe+ ' ' + result[0].Nom_mode_formation
      pdf.setFont('Amiri');
      pdf.text(result[0].Nom_encadrement + ' ' + result[0].Prenom_encadrement + ' ' + '/' + result[0].Nom_grade + ' : ', pdf.internal.pageSize.getWidth() - 28, 60, { align: 'right' });


      // Set font for 'المعامل / النقطة الإقصائية:'
      pdf.setFont('Amiri_bold');
      pdf.setFontSize(12);

      pdf.text('المعامل / النقطة الإقصائية', 24, 60, { align: 'left' });

      // Draw underline
      let textWidth2 = pdf.getStringUnitWidth('المعامل / النقطة الإقصائية') * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
      pdf.setLineWidth(0.5); // Adjust line width as needed
      pdf.line(24, 62, 24 + textWidth2, 62); // Adjust y-coordinate (62) based on font size and position



      // Set font for result[0].Nom_section + ' ' + result[0].Groupe+ ' ' + result[0].Nom_mode_formation
      pdf.setFont('Amiri');
      pdf.setFontSize(14);
      pdf.text(result[0].coef + ' / ' + result[0].Ne + ' : ', 10, 60, { align: 'left' });



      // Sample data for the table
      const data_table = [
        { id: 1, name: 'John Doe', age: 35, city: 'New York' },
        { id: 2, name: 'Jane Doe', age: 28, city: 'Los Angeles' },
        { id: 3, name: 'Jim Smith', age: 30, city: 'Chicago' },
        { id: 4, name: 'Mary Johnson', age: 25, city: 'Houston' }
      ];

      // AutoTable configuration
      pdf.autoTable({
        head: [['الملاحظة', 'المعدل بعد', 'الإستدراك', 'المعدل قبل', '40/ش', 'م20/2', 'م20/1', 'تاريخ الميلاد', 'الإسم', 'اللقب', 'الرقم']],
        body: result.map((row, index) => [
          '',          // الملاحظة
          '99.99',     // المعدل بعد
          '99.99',      // الإستدراك
          '99.99',    // المعدل قبل
          '99.99',       // 40/ش
          '99.99',      // م20/2
          '99.99',      // م20/1
          row.DateNais,     // تاريخ الميلاد
          row.Prenom,     // الإسم
          row.Nom,      // اللقب
          index + 1       // الرقم
        ]),
        /// body: result.map(row => Object.values(row)),
        startY: 70, // Y position from where the table should start
        theme: 'grid', // 'striped', 'grid', 'plain', 'css' (default is 'striped')
        margin: { top: 10, left: 5 }, // Margins (in units declared at inception of PDF document

        headStyles: {
          font: 'calibri_bold',
          fontSize: 8,
          fontStyle: 'bold',
          halign: 'center',
          valign: 'middle',
          fillColor: [240, 240, 240], // Light gray background
          textColor: [0, 0, 0] // Black text color
        },

        bodyStyles: {
          font: 'calibri_bold',
          fontSize: 10,
          cellPadding: 2,
          halign: 'right', // Align body cells to the right
          textColor: [0, 0, 0] // Black text color
        },

        columnStyles: {
          0: { cellWidth: 25 }, // الملاحظة column width
          1: { cellWidth: 15, halign: 'center', valign: 'middle' }, // المعدل بعد column width
          2: { cellWidth: 17, halign: 'center', valign: 'middle' }, // الإستدراك column width
          3: { cellWidth: 15, halign: 'center', valign: 'middle' }, // المعدل قبل column width
          4: { cellWidth: 15, halign: 'center', valign: 'middle' }, // 40/ش column width
          5: { cellWidth: 15, halign: 'center', valign: 'middle' }, // م20/2 column width
          6: { cellWidth: 15, halign: 'center', valign: 'middle' }, // م20/1 column width
          7: { cellWidth: 23, halign: 'center', valign: 'middle' }, // تاريخ الميلاد column width
          8: { cellWidth: 25 }, // الإسم column width
          9: { cellWidth: 25 }, // اللقب column width
          10: { cellWidth: 10, halign: 'center', valign: 'middle' }  // الرقم column width
        },
        didDrawPage: addPageNumbers // Call function to add page numbers
,
        styles: {
          cellWidth: 'wrap', // This ensures that content does not overflow
          halign: 'right' // Right align text in all cells by default
        }
      });


      // Customize PDF content based on fetched data
      // Adjust this part based on how your data is structured
      ///pdf.text(`IDsection_semestre_Module: ${IDsection_semestre_Module_par}`, 20, 20);
      //pdf.text(`IDSemestre_formation: ${IDSemestre_formation_par}`, 20, 40);
      ///pdf.text(`IDSection_Semestre: ${IDSection_Semestre_par}`, 20, 60);

      // Generate a blob URL for the PDF content
      const pdfBlob = pdf.output('blob');
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);

      // Set pdfUrl to the blob URL
      setPdfUrl(pdfBlobUrl);

      // Get total number of pages
      const totalPages = pdf.internal.getNumberOfPages();
      setTotalPages(totalPages);

    }
  }, [result, pdfUrl]);

  const classes_CircularProgress = useStyles_CircularProgress();

  return (
    <div style={{ width: '100%', height: '1000px' }}>
      {!pdfUrl && (
        <div className={classes_CircularProgress.overlay}>
          <Dialog open={!pdfUrl} PaperProps={{ sx: { backgroundColor: 'white' } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
              <CircularProgress sx={{ ml: 1 }} color="primary" />
              <Typography variant="h6" sx={{ color: '#004d40', fontFamily: 'Tajawal, sans-serif', fontWeight: 900, ml: 2 }}>
                Loading...
              </Typography>
            </Box>
          </Dialog>
        </div>
      )}

      {pdfUrl && (
        <div>

          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="800px"
            aria-label="Generated PDF"
          />
        </div>
      )}
    </div>
  );
};

export default NotesByModulesPdf;
