import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CacheProvider } from "@emotion/react";
import TextField from '@mui/material/TextField';
import createCache from "@emotion/cache";
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';

import getCsrfToken from '../CsrfToken';
import FormControl from '@mui/material/FormControl';
import isEmail from 'validator/lib/isEmail';
import ReplayIcon from '@mui/icons-material/Replay';
import Input from '@mui/material/Input';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Divider from '@mui/material/Divider';
import Footer from '../containers/Footer';
import Header from '../containers/Header';
import { API_BASE_URL } from '../containers/config'; // Adjust the path if necessary


// Set the CSRF token globally
const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

var md5 = require('md5');
let codeemail = (Math.floor(1000 + Math.random() * 9000));

let sendemailbyemailoneortwo = 'https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';


const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
});



const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});







function Inscriptions_st() {





  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(null);

  const [OpenDialog, setOpenDialog] = useState(false);


  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };


  const handleClickCloseDialog = () => {
    setOpenDialog(false);
    //// navigate('/Doc');
  };


  const [emailverificated, setemailverificated] = React.useState(false);

  const [emailsended, setemailsended] = React.useState(false);
  const [errorsendingemail, seterrorsendingemail] = React.useState(false);
  const [codeemailsent, setcodeemailsent] = useState(false);
  const [codeemailforchecking, setcodeemailforchecking] = useState(md5(codeemail));


  const [valueNomAr, setvalueNomAr] = useState('');
  const refNomAr = useRef(null);

  const [valuePrenomAr, setvaluePrenomAr] = useState('');
  const refPrenomAr = useRef(null);

  const [valuenbcodeverifie, setvaluenbcodeverifie] = useState('');
  const refnbcodeverifie = useRef(null);

  const ArabicCharactersRegex = /^[\u0600-\u06FF\s]+$/;
  const NumbersOnlyRegex = /^[0-9]+$/;

  const handleInput_NomAr = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueNomAr(inputValue);
    }
  };

  const handleInput_PrenomAr = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomAr(inputValue);
    }
  };

  const handleInput_nbcodeverifie = (event) => {
    const inputValue = event.target.value;

    if (NumbersOnlyRegex.test(inputValue) || inputValue === '') {
      setvaluenbcodeverifie(inputValue);
    }
  };

  const [valueNomFr, setvalueNomFr] = useState('');
  const refNomFr = useRef(null);

  const [valuePrenomFr, setvaluePrenomFr] = useState('');
  const refPrenomFr = useRef(null);

  const LatinLettersRegex = /^[a-zA-Z\s]+$/;

  const handleInput_NomFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueNomFr(inputValue);
    }
  };

  const handleInput_PrenomFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomFr(inputValue);
    }
  };



  const refbtnsubmit = useRef(null);
  const refpassword1 = useRef(null);
  const refpassword2 = useRef(null);




  const [valuesPassword, setValuesPassword] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [valuesPasswordRe, setValuesPasswordRe] = React.useState({
    amount: '',
    PasswordRe: '',
    weight: '',
    weightRange: '',
    showPasswordRe: false,
  });


  const handleChangePasswordRe = (prop) => (event) => {
    setValuesPasswordRe({ ...valuesPasswordRe, [prop]: event.target.value });
  };

  const handleClickShowPasswordRe = () => {
    setValuesPasswordRe({
      ...valuesPasswordRe,
      showPasswordRe: !valuesPasswordRe.showPasswordRe,
    });
  };

  const handleMouseDownPasswordRe = (event) => {
    event.preventDefault();
  };


  const handleChangePassword = (prop) => (event) => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  // Store token in state
  const [csrfToken, setCsrfToken] = useState(null);

  // Fetch on app load
  useEffect(() => {
    const getCsrfToken = async () => {
      const response = await axios.get(`${API_BASE_URL}/api/csrf-token`);
      setCsrfToken(response.data.csrf_token);
    };

    getCsrfToken();
  }, []);



  const onsubmitclicked = async (e) => {
    e.preventDefault();

    // Fetch CSRF token
    try {
      // Refresh token
      const response = await axios.get(`${API_BASE_URL}/api/csrf-token`);
      setCsrfToken(response.data.csrf_token);

      ///axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

      //console.log(response.data.csrf_token);


      try {
        const data = {
          NomAr: valueNomAr,
          NomFr: valueNomFr,
          PrenomAr: valuePrenomAr,
          PrenomFr: valuePrenomFr,
          Email_in: valueemail,
          Password: valuesPassword.password,

        };

        const responseFromApi = await axios.post(`${API_BASE_URL}/api/insertnewst`, data);

        if (responseFromApi.data.savedSuccess) {
          handleClickOpenDialog();
        } else {
          alert('Failed to register information. Please try again.');
        }

        //console.log(responseFromApi);
      } catch (error) {
        console.error('Error making API request:', error);
        if (error.response) {
          //console.log(error.response);

          if (error.response && error.response.data && error.response.data.error) {
            const validationErrors = error.response.data.error;
            const errorMessage = Object.keys(validationErrors)
              .map((field, index) => `- ${validationErrors[field][0]}`)
              .join('\n');
            setAlertMessage(errorMessage);
            setAlertSeverity('error');
          }

        }
      }
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };


  const refemail = useRef(null);
  const refcodeemail = useRef(null);
  const [valueemail, setValueemail] = useState('');
  const [isValidemail, setIsValidemail] = useState(false);

  useEffect(() => {


    if (isEmail(valueemail)) {
      setIsValidemail(true);
      //console.log(valueemail+"   yes email");            
    } else {
      setIsValidemail(false);
      //console.log(valueemail+"   not email");                         
    }


  }, [valueemail]);



  const handleChangeemail = event => {
    const val = event.target.value;
    setValueemail(val);

    /////handleChangeemail(val, isValidemail);
  }

  const onemailblur = event => {
    //alert('onblur')
    event.preventDefault();
    const val = event.target.value;

    if (isValidemail) {

      ResendEmailClick(event);
    }

  }

  useEffect(() => {
    if (emailsended && !emailverificated) {

      if (refbtnsubmit.current) {
        refbtnsubmit.current.click();
      }

      if (refcodeemail.current) {
        refcodeemail.current.focus();
        return
      }
    }


  }, [emailsended]);

  useEffect(() => {
    //console.log('isValidemail '+isValidemail+ 'emailverificated ' +emailverificated + 'emailsended '+emailsended )
    setemailsended(false);

  }, [isValidemail]);



  const handleClickcodeemail = (event) => {
    event.preventDefault();
  };

  const handleChangecodeemail = (event) => {

    if (md5(Number(event.target.value)) == codeemailforchecking) {
      ///alert('verificated succussfully');
      setemailverificated(true);
    }

    setValuescodeemail({
      ...valuescodeemail,
      [event.target.name]: event.target.value,
    });



  };

  const [valuescodeemail, setValuescodeemail] = React.useState({
    textmaskcodeemail: '',
    numberformat: '1320',
  });







  const ResendEmailClick = async (e) => {
    e.preventDefault();

    /// alert(codeemail+' now')
    setemailverificated(false);
    setemailsended(false);
    seterrorsendingemail(false)
    //setDirty(false)

    if (sendemailbyemailoneortwo == 'https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/') {
      sendemailbyemailoneortwo = 'https://www.doctorsdz.machrou3.com/user/sendemailbyemailtwo/';
    }
    else {
      sendemailbyemailoneortwo = 'https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';
    }


    let axiosConfig = {
      headers: {
        //'Content-Type' : 'application/json; charset=UTF-8',
        ///application/x-www-form-urlencoded
        ///'Content-Type' : 'application/x-www-form-urlencoded',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Accept': 'Token',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",

      }
    };

    // 
    try {

      const params = new URLSearchParams();
      params.append('codeemail', codeemail);
      params.append('emailtosend', valueemail);

      await axios.post(sendemailbyemailoneortwo
        , params, axiosConfig)
        .then(response => {

          if (codeemail == response.data.codeemail) {

            setemailsended(true);
            //alert('resp '+response.data.emailtosend)


          }

          //console.log(response)
        })
      //navigate('/dashboard');

    } catch (error) {

      if (error.response) {
        seterrorsendingemail(true)
        //console.log(error.response)
        return
      }

    }




  }



  return (



    <>

      <Header />

      <form>
        <Container sx={{ textAlign: 'center', mx: 'auto', width: '80%' }}>
          <Grid  >

            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                <Box>
                  {alertMessage && (
                    <Alert severity={alertSeverity} style={{ direction: 'rtl', textAlign: 'right' }}>
                      <AlertTitle>
                        <Typography
                          style={{
                            color: "red",
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 14,
                          }}
                        >
                          الأخطاء
                        </Typography>
                      </AlertTitle>
                      <pre>
                        <Typography
                          style={{
                            color: "#CD5C5C",
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 12,
                          }}
                        >

                          {alertMessage}
                        </Typography>
                      </pre>
                    </Alert>
                  )}

                </Box>
              </ThemeProvider>
            </CacheProvider>

            <Divider sx={{ mt: '20px' }}>
              <Grid item sx={{ mt: 2 }} >
                <Typography
                  style={{
                    color: "#004d40",
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 25,
                    fontWeight: 700,
                    marginBottom: '25px'
                  }}
                  variant="h3" gutterBottom>
                  تسجيل جديد <AddCircleOutlineIcon sx={{ ml: '0px' }} />
                </Typography>
              </Grid>
            </Divider>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>


              {/* اللقب باللاتنية */}

              <Grid item xs={6} sx={{ mt: 1 }}>
                <CacheProvider value={cacheRtl}>
                  <ThemeProvider theme={themetextfieldnew}>
                    <Box>
                      <div dir="ltr">
                        <TextField

                          fullWidth
                          name="valueNomFr"
                          value={valueNomFr}
                          inputRef={refNomFr}
                          onInput={handleInput_NomFr}
                          helperText={
                            !LatinLettersRegex.test(valueNomFr)
                              ? 'اللقب (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                              : ''
                          }

                          InputProps={{
                            inputProps: { tabIndex: 2 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon />
                              </InputAdornment>
                            ),
                          }}

                          sx={{

                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#004d40',
                              fontWeight: 700
                            },

                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#004d40'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#004d40',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#004d40',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: "#00796b",
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: "#00796b",
                              },
                            }
                          }}
                          id="outlined-basic" label="اللقب (ف)" variant="outlined" />
                      </div>
                    </Box>
                  </ThemeProvider>
                </CacheProvider>
              </Grid>


              {/* اللقب بالعربية */}

              <Grid item xs={6} sx={{ mt: 1 }}>
                <CacheProvider value={cacheRtl}>
                  <ThemeProvider theme={themetextfieldnew}>
                    <Box>
                      <div dir="rtl">
                        <TextField

                          fullWidth
                          name="valueNomAr"
                          value={valueNomAr}
                          inputRef={refNomAr}
                          onInput={handleInput_NomAr}
                          helperText={
                            !ArabicCharactersRegex.test(valueNomAr)
                              ? 'اللقب (ع) يجب ان يحتوي على حروف عربية فقط'
                              : ''
                          }

                          InputProps={{
                            inputProps: { tabIndex: 1 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon />
                              </InputAdornment>
                            ),
                          }}


                          sx={{

                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#004d40',
                              fontWeight: 700
                            },

                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#004d40'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#004d40',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#004d40',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: "#00796b",
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: "#00796b",
                              },
                            }
                          }}
                          id="outlined-basic" label="اللقب (ع)" variant="outlined" />
                      </div>
                    </Box>
                  </ThemeProvider>
                </CacheProvider>



              </Grid>


              {/* الإسم باللاتنية */}

              <Grid item xs={6} sx={{ mt: 1 }}>
                <CacheProvider value={cacheRtl}>
                  <ThemeProvider theme={themetextfieldnew}>
                    <Box>
                      <div dir="ltr">
                        <TextField

                          fullWidth
                          name="valuePrenomFr"
                          value={valuePrenomFr}
                          inputRef={refPrenomFr}
                          onInput={handleInput_PrenomFr}
                          helperText={
                            !LatinLettersRegex.test(valuePrenomFr)
                              ? 'الإسم (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                              : ''
                          }
                          InputProps={{
                            inputProps: { tabIndex: 4 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon />
                              </InputAdornment>
                            ),
                          }}

                          sx={{

                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#004d40',
                              fontWeight: 700
                            },

                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#004d40'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#004d40',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#004d40',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: "#00796b",
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: "#00796b",
                              },
                            }
                          }}
                          id="outlined-basic" label="الإسم (ف)" variant="outlined" />
                      </div>
                    </Box>
                  </ThemeProvider>
                </CacheProvider>
              </Grid>


              {/* الاسم بالعربية */}

              <Grid item xs={6} sx={{ mt: 1 }}>
                <CacheProvider value={cacheRtl}>
                  <ThemeProvider theme={themetextfieldnew}>
                    <Box>
                      <div dir="rtl">
                        <TextField

                          fullWidth
                          name="valuePrenomAr"
                          value={valuePrenomAr}
                          inputRef={refPrenomAr}
                          onInput={handleInput_PrenomAr}
                          helperText={
                            !ArabicCharactersRegex.test(valuePrenomAr)
                              ? 'الإسم (ع) يجب ان يحتوي على حروف عربية فقط'
                              : ''
                          }
                          InputProps={{
                            inputProps: { tabIndex: 3 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon />
                              </InputAdornment>
                            ),
                          }}


                          sx={{

                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#004d40',
                              fontWeight: 700
                            },

                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '16px',
                              color: '#004d40'
                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#004d40',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#004d40',
                                borderWidth: "2px",
                              },
                              '&:hover fieldset': {
                                borderColor: "#00796b",
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: "#00796b",
                              },
                            }
                          }}
                          id="outlined-basic" label="الإسم (ع)" variant="outlined" />
                      </div>
                    </Box>
                  </ThemeProvider>
                </CacheProvider>



              </Grid>




            </Grid>


            {/* Email */}
            <Grid item xs={6} sx={{ mt: 1 }}>
              <CacheProvider value={cacheRtl}>
                <ThemeProvider theme={themetextfieldnew}>
                  <Box>
                    <div dir="rtl">


                      <FormControl fullWidth variant="outlined"


                      >

                        <TextField

                          sx={{

                            //direction:'ltr',

                            '& .MuiFormHelperText-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '14px',
                              color: '#004d40',
                              fontWeight: 700

                            },
                            '& .MuiFormLabel-root': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '18px',
                              color: '#004d40',
                              dir: 'rtl',

                            },
                            '& .MuiInputBase-input': {
                              fontFamily: '"Tajawal", sans-serif',
                              fontSize: '22px',
                              color: '#004d40',
                              fontWeight: 700,
                              //text-align: right,



                            },
                            '& label.Mui-focused': {
                              fontSize: '16px',
                              color: '#004d40',
                              fontWeight: 700
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#004d40',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '16px',
                                borderColor: '#004d40',
                                borderWidth: "2px",

                              },
                              '&:hover fieldset': {
                                borderColor: "#00796b",
                              },
                              '&.Mui-focused fieldset': {

                                borderColor: "#00796b",
                              },
                            }
                          }}




                          inputRef={refemail}
                          error={isValidemail === false}
                          label="البريد الإلكتروني"
                          helperText={isValidemail === false ? "أدخل عنوان بريد إلكتروني صالح" : <>




                            {(isValidemail === true && emailverificated === false && emailsended === true) ?
                              "تم إرسال رمز التحقق إلى بريدك الإلكتروني" :

                              <>

                                {emailverificated ? "تم التحقق من بريدك الإلكتروني بنجاح" : <> </>
                                }

                              </>}



                          </>}

                          onBlur={(e) => onemailblur(e)}
                          onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                              onemailblur(ev);
                              ev.target.blur();///to hide keyboard
                              //console.log("enter")

                              ev.preventDefault();
                            }
                          }}


                          variant="outlined"
                          size={'small'}
                          name="valueemail"
                          value={valueemail}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => handleChangeemail(e)}



                          InputProps={{
                            inputProps: { tabIndex: 5 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),

                            endAdornment: (
                              <InputAdornment>


                                {emailverificated ? <>
                                  <CheckCircleIcon sx={{ color: "green" }} />
                                </> : <>

                                  {((isValidemail === true) && codeemailsent) || ((isValidemail === true) && emailsended) ?
                                    <>

                                      <IconButton
                                        onClick={(e) => {
                                          if (isValidemail) {

                                            ResendEmailClick(e);
                                          }

                                        }}


                                      >
                                        <ReplayIcon sx={{ color: "#004d40" }} />
                                      </IconButton>

                                    </> : <></>}



                                </>}


                              </InputAdornment>
                            )
                          }}




                          disabled={emailverificated}


                        />

                      </FormControl>


                    </div>
                  </Box>
                </ThemeProvider>
              </CacheProvider>



            </Grid>









            {((isValidemail === true) && codeemailsent) || ((isValidemail === true) && emailsended) ?

              <>

                {(emailverificated) ? <></>

                  : <>
                    {/* رمز التحقق*/}

                    <Grid item xs={6} sx={{ mt: 1 }}>
                      <CacheProvider value={cacheRtl}>
                        <ThemeProvider theme={themetextfieldnew}>
                          <Box>
                            <div dir="rtl">
                              <TextField
                                placeholder='أدخل هنا رمز التحقق الذي وصلك في بريدك الإلكتروني '
                                fullWidth

                                name="valuenbcodeverifie"
                                value={valuenbcodeverifie}
                                inputRef={refnbcodeverifie}
                                onChange={handleChangecodeemail}
                                onInput={handleInput_nbcodeverifie}
                                helperText={
                                  !NumbersOnlyRegex.test(valuenbcodeverifie)
                                    ? 'رمز التحقق الذي وصلك في بريدك الإلكتروني يحتوى على أرقام فقط'
                                    : ''
                                }

                                InputProps={{
                                  inputProps: { tabIndex: 6 },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <NumbersIcon />
                                    </InputAdornment>
                                  ),
                                }}


                                sx={{

                                  '& .MuiFormHelperText-root': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '14px',
                                    color: '#004d40',
                                    fontWeight: 700
                                  },

                                  '& .MuiFormLabel-root': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '16px',
                                    color: '#004d40'
                                  },
                                  '& .MuiInputBase-input': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '18px',
                                    color: '#004d40',
                                    fontWeight: 700
                                  },
                                  '& label.Mui-focused': {
                                    fontSize: '16px',
                                    color: '#004d40',
                                    fontWeight: 700
                                  },
                                  '& .MuiInput-underline:after': {
                                    borderBottomColor: '#004d40',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      fontFamily: '"Tajawal", sans-serif',
                                      fontSize: '16px',
                                      borderColor: '#004d40',
                                      borderWidth: "2px",
                                    },
                                    '&:hover fieldset': {
                                      borderColor: "#00796b",
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: "#00796b",
                                    },
                                  }
                                }}
                                id="outlined-basic" label="رمز التحقق" variant="outlined" />
                            </div>
                          </Box>
                        </ThemeProvider>
                      </CacheProvider>



                    </Grid>

                  </>}


              </>

              :


              <>




              </>



            }






            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>




              {/* إعادة كلمة السر */}
              <Grid item xs={6} sx={{ mt: 1 }}>
                <CacheProvider value={cacheRtl}>
                  <ThemeProvider theme={themetextfieldnew}>
                    <Box>
                      <div dir="ltr">

                        <FormControl fullWidth variant="outlined"

                        >

                          <InputLabel
                            sx={{
                              color: "#004d40", fontFamily:
                                '"Tajawal", sans-serif',
                              fontSize: 16
                              , fontWeight: 700
                            }}
                            htmlFor="outlined-adornment-password">اعادة كلمة المرور</InputLabel>
                          <OutlinedInput

                            fullWidth
                            dir="rtl"
                            sx={{

                              //direction:'ltr',

                              '& .MuiFormHelperText-root': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '14px',
                                color: '#004d40',
                                fontWeight: 700

                              },
                              '& .MuiFormLabel-root': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '18px',
                                color: '#004d40',
                                dir: 'rtl',

                              },
                              '& .MuiInputBase-input': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '22px',
                                color: '#004d40',
                                fontWeight: 700,
                                //text-align: right,



                              },
                              '& label.Mui-focused': {
                                fontSize: '16px',
                                color: '#004d40',
                                fontWeight: 700
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#004d40',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '16px',
                                  borderColor: '#004d40',
                                  borderWidth: "2px",

                                },
                                '&:hover fieldset': {
                                  borderColor: "#00796b",
                                },
                                '&.Mui-focused fieldset': {

                                  borderColor: "#00796b",
                                },
                              }
                            }}

                            inputRef={refpassword2}
                            id="outlined-adornment-PasswordRe"
                            type={valuesPasswordRe.showPasswordRe ? 'text' : 'Password'}
                            value={valuesPasswordRe.PasswordRe}
                            name="valuesPasswordRe"
                            onChange={handleChangePasswordRe('PasswordRe')}

                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPasswordRe}
                                  onMouseDown={handleMouseDownPasswordRe}
                                  edge="end"
                                >
                                  {valuesPasswordRe.showPasswordRe ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="اعادة كلمة المرور"

                            inputProps={{
                              tabIndex: 8,
                            }}
                          />

                          {valuesPasswordRe.PasswordRe !== valuesPassword.password ? <>

                            <FormHelperText
                              sx={{
                                color: "#004d40", fontFamily:
                                  '"Tajawal", sans-serif',
                                fontSize: 14
                                , fontWeight: 700
                              }}
                              error id="accountId-error">
                              يجب أن تدخل نفس كلمة السر التي أدخلتها أعلاه
                            </FormHelperText>


                          </> : <></>}



                        </FormControl>
                      </div>
                    </Box>
                  </ThemeProvider>
                </CacheProvider>
              </Grid>











              {/*  كلمة السر  */}

              <Grid item xs={6} sx={{ mt: 1 }}>
                <CacheProvider value={cacheRtl}>
                  <ThemeProvider theme={themetextfieldnew}>
                    <Box>
                      <div dir="ltr">

                        <FormControl fullWidth variant="outlined"

                        >

                          <InputLabel

                            sx={{
                              color: "#004d40", fontFamily:
                                '"Tajawal", sans-serif',
                              fontSize: 16
                              , fontWeight: 700
                            }}
                            htmlFor="outlined-adornment-password">كلمة المرور</InputLabel>
                          <OutlinedInput
                            fullWidth
                            dir="rtl"
                            sx={{

                              //direction:'ltr',

                              '& .MuiFormHelperText-root': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '14px',
                                color: '#004d40',
                                fontWeight: 700

                              },
                              '& .MuiFormLabel-root': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '18px',
                                color: '#004d40',
                                dir: 'rtl',

                              },
                              '& .MuiInputBase-input': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '22px',
                                color: '#004d40',
                                fontWeight: 700,
                                //text-align: right,


                              },
                              '& label.Mui-focused': {
                                fontSize: '16px',
                                color: '#004d40',
                                fontWeight: 700
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#004d40',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '16px',
                                  borderColor: '#004d40',
                                  borderWidth: "2px",

                                },
                                '&:hover fieldset': {
                                  borderColor: "#00796b",
                                },
                                '&.Mui-focused fieldset': {

                                  borderColor: "#00796b",
                                },
                              }
                            }}

                            inputRef={refpassword1}
                            id="outlined-adornment-password"
                            type={valuesPassword.showPassword ? 'text' : 'password'}
                            value={valuesPassword.password}
                            name="valuesPassword"
                            onChange={handleChangePassword('password')}

                            inputProps={{
                              tabIndex: 7,
                            }}

                            endAdornment={

                              <InputAdornment position="end">


                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {valuesPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="كلمة المرور"


                          />


                          {!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/.test(valuesPassword.password) ? (
                            <FormHelperText
                              sx={{
                                color: "#004d40",
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: 14,
                                fontWeight: 700
                              }}
                              error
                              id="accountId-error"
                            >
                              كلمة المرور لا تحتوي على حرف كبير أو حرف صغير أو رقم أو طولها أقل من 8 أحرف
                            </FormHelperText>
                          ) : (
                            <></>
                          )}



                        </FormControl>

                      </div>
                    </Box>
                  </ThemeProvider>
                </CacheProvider>
              </Grid>


            </Grid>


            {/* Submit Button */}
            <Grid item xs={12}>
              <CacheProvider value={cacheRtl}>
                <ThemeProvider theme={themetextfieldnew}>
                  <Box sx={{ minWidth: 120, mt: '15px' }}>
                    <div>

                      <Button

                        onClick={(e) => {


                          e.preventDefault();

                          if (!ArabicCharactersRegex.test(valueNomAr) || valueNomAr === '') {
                            refNomAr.current.focus();
                            return
                          }

                          if (!LatinLettersRegex.test(valueNomFr) || valueNomFr === '') {
                            refNomFr.current.focus();
                            return
                          }

                          {/* 
    
    if(!ArabicCharactersRegex.test(valuePrenomAr) || valuePrenomAr===''){
      refPrenomAr.current.focus();
      return
    }

    if(!LatinLettersRegex.test(valuePrenomFr) || valuePrenomFr===''){
      refPrenomFr.current.focus();
      return
    }

    */}

                          onsubmitclicked(e);

                        }}

                        variant="contained"
                        endIcon={<AddCircleOutlineIcon sx={{
                          mr: '10px', ml: '-10px',
                          transform: 'rotate(180deg)', // Adjust the angle as needed
                        }} />}>
                        <Typography sx={{
                          color: "#FFFFFF", fontFamily:
                            '"Tajawal", sans-serif',
                          fontSize: 22
                          , fontWeight: 900
                        }}>
                          تسجيل
                        </Typography>

                      </Button>

                    </div>

                  </Box>
                </ThemeProvider>
              </CacheProvider>

            </Grid>

          </Grid>
        </Container>
        <Footer />
      </form>


      {/* Dialog success save */}

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: '#004d40',
            boxShadow: 'none',
          },
        }}
        //fullScreen={mediumScreen}
        open={OpenDialog}
        onClose={handleClickCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >

        <Card style={{}} sx={{ maxWidth: 345 }}>

          {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
          <CardHeader
            avatar={

              <CheckCircleIcon sx={{ color: '#4BB543', width: 50, height: 50, ml: 2 }}></CheckCircleIcon>

            }
            action={


              <IconButton onClick={handleClickCloseDialog} aria-label="settings" sx={{ ml: -1, mt: -1 }}>

                <CloseIcon />

              </IconButton>
            }
            title={
              <>
                <Box sx={{ ml: 2, mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>

                  <Typography sx={{ color: '#004d40', fontFamily: '"Tajawal", sans-serif', fontSize: 22, fontWeight: 900 }} >
                    تم تسجيل معلوماتك بنجاح</Typography>
                </Box>

              </>

            }
            subheader=""
          />





        </Card>

      </Dialog>

    </>
  );
}

export default Inscriptions_st;
