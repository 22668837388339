import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { API_BASE_URL } from '../containers/config'; // Adjust the path if necessary
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { APP_PREFIX } from './config'; // Adjust the path if necessary
import useAuthentication_etab from './useAuthentication_etab';
import dayjs from 'dayjs';


const useStyles_CircularProgress = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure it's above everything else
  },
}));

const Pre_innovationpdf = () => {


  const { cryptedId } = useParams();
  const [result, setresult] = useState(null);
  const [result_dfep, setresult_dfep] = useState(null);

  const [result_mfep, setresult_mfep] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null); // State to store PDF URL
  const [Annee_formation_Nom, setAnnee_formation_Nom] = useState(null);

  const [download_cert_inscription_active_state, setdownload_cert_inscription_active_state] = useState(0);
  const [download_cert_inscription_active_dfep_state, setdownload_cert_inscription_active_dfep_state] = useState(0);
  const [download_cert_inscription_active_mfep_state, setdownload_cert_inscription_active_mfep_state] = useState(0);
  const [numsem_state, setnumsem_state] = useState(0);

  const navigate = useNavigate();
  const [loadcrypted, setloadcrypted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/getinnovationpdf/${cryptedId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setresult(data.data);

        setloadcrypted(true);
      } catch (error) {
        console.error(error);
        // Handle error, e.g., show an error message to the user
      }
    };
    if (cryptedId) {
      fetchData();
    }



  }, [cryptedId]);



  useEffect(() => {


    if (result && !pdfUrl) { // Only generate PDF if result is set and PDF URL is not already generated




      // Create a new jsPDF instance
      const pdf = new jsPDF();

      // Set document information
      pdf.setProperties({
        title: 'استمارة التسجيل الأولي لصالون الوطني للابتكار',
        subject: 'استمارة التسجيل الأولي لصالون الوطني للابتكار',
        author: 'MFEP',
      });
      const fontUrl = `${process.env.PUBLIC_URL}/Amiri-Regular.ttf`;
      const fontUrl_bold = `${process.env.PUBLIC_URL}/Amiri-Bold.ttf`;
      const fontUrl_arabic_ejaza = `${process.env.PUBLIC_URL}/arabic-ejaza.ttf`;
      const fontUrl_majalla = `${process.env.PUBLIC_URL}/majalla.ttf`;

      pdf.addFont(fontUrl, 'Amiri', 'normal');
      // Draw Certificate title
      pdf.addFont(fontUrl_bold, 'Amiri_bold', 'normal');
      pdf.addFont(fontUrl_arabic_ejaza, 'arabic_ejaza', 'normal');
      pdf.addFont(fontUrl_majalla, 'Sakkal Majalla', 'normal');

      pdf.setFont('arabic_ejaza'); // Set font

      // Set the title of the browser tab
      document.title = 'استمارة التسجيل الأولي لصالون الوطني للابتكار';

      // Set font size and text direction
      pdf.setFontSize(26);
      pdf.text('الجمهورية الجزائرية الديمقراطية الشعبية', pdf.internal.pageSize.getWidth() / 2, 12, { align: 'center' });

      // Move to new line
      pdf.setLineHeightFactor(1.5); // Adjust line height if needed

      // Draw Ministry information
      pdf.text('وزارة التكويـن والتعلـيـم المهنييـن', pdf.internal.pageSize.getWidth() / 2, 22, { align: 'center' });
      pdf.setFont('Sakkal Majalla'); // Set font
      ///pdf.setFont('Amiri'); // Set font
      pdf.setFontSize(16);
      // Draw Wilaya and Direction information


      // Draw Name, Date, and Number
      pdf.text(' الرقم:  ' + result.IDinnovation, pdf.internal.pageSize.getWidth() - 10, 35, { align: 'right' });


      ///pdf.setFont('Amiri_bold'); // Set font
      pdf.setFont('Sakkal Majalla'); // Set font
      pdf.setFontSize(35);
      pdf.text(' استمارة التسجيل الأولي  ', pdf.internal.pageSize.getWidth() / 2, 50, { align: 'center' });
      pdf.setFontSize(25);
      pdf.text('( الصالون الوطني للإبداع والإبتكار طبعة 2024 )', pdf.internal.pageSize.getWidth() / 2, 60, { align: 'center' });

      // Add empty line
      ///pdf.text('', 10, 80);

      // Draw trainee information
      pdf.setFontSize(16);
      pdf.text('الولاية : ' + result.wilayas_libelleArabe, pdf.internal.pageSize.getWidth() - 10, 80, { align: 'right' });
      pdf.text('عنوان المشروع : ' + result.valueprojectitle, pdf.internal.pageSize.getWidth() - 10, 90, { align: 'right' });
      pdf.text('شعبة المشروع: ' + result.Nombranche, pdf.internal.pageSize.getWidth() - 10, 100, { align: 'right' });
      pdf.text('نسبة إنجاز المشروع:  % ' + result.valueprojectitle_average, pdf.internal.pageSize.getWidth() - 10, 110, { align: 'right' });
      pdf.text(':أصحاب المشروع' , pdf.internal.pageSize.getWidth() - 10, 120, { align: 'right' });
      pdf.text(result.valueNom+' -', pdf.internal.pageSize.getWidth() - 10, 130, { align: 'right' });

      // Calculate the width of the area where the text will be displayed
      var textWidth = pdf.internal.pageSize.getWidth() - 10;
      
      // Split the text into an array of strings that fit within the specified width
      var lines = pdf.splitTextToSize(result.value_description, textWidth);
      
      // Set the initial y-coordinate for the first line of text
      var y = 140;
      
      if (result.valueNom2 !== null) {
         
          pdf.text( result.valueNom2+' -', pdf.internal.pageSize.getWidth() - 10, 140, { align: 'right' });
          pdf.text(result.valueNom3+' -', pdf.internal.pageSize.getWidth() - 10, 150, { align: 'right' });
          y = 160;
      } 
      

      // Add the prefix only to the first line of text
      pdf.text(':نبذة عن المشروع', textWidth, y, { align: 'right' });
      // Increment the y-coordinate for the next line of text
      y += 10; // You can adjust this value to add spacing between lines if needed

      // Loop through the lines and add them to the PDF document
      for (var i = 0; i < lines.length; i++) {
        // Add the current line of text to the PDF document
        pdf.text(lines[i], textWidth, y, { align: 'right' });

        // Increment the y-coordinate for the next line of text
        y += 10; // You can adjust this value to add spacing between lines if needed
      }


      // Draw issuing information
      pdf.setFontSize(16);
      // Get the current date
      var currentDate = dayjs(result.created_at);

      // Check if currentDate is a valid date object
      if (currentDate.isValid()) {
          var formattedCurrentDate = currentDate.format('DD-MM-YYYY HH:mm:ss');
          pdf.text(' في : ' + formattedCurrentDate, 60, 215, { align: 'left' });
      } else {
          // Handle case where result.created_at is null or invalid
          pdf.text('تاريخ الإنشاء غير متوفر', 60, 215, { align: 'left' });
      }
      
      ////pdf.text('مدير )ة( المؤسسة', 80, 200, { align: 'left' });


      // Create QR code as a data URL
      const qrDataURL = document.getElementById('qrCode').toDataURL('image/png');

      // Calculate the X-coordinate for right alignment
      const qrCodeX = pdf.internal.pageSize.getWidth() - 220; // 60 is the total width of the QR code image and its margin

      // Add QR code image to the PDF
      ///pdf.addImage(qrDataURL, 'PNG', qrCodeX, 200, 50, 50);
      pdf.addImage(qrDataURL, 'PNG', 60, 220, 50, 50);

      // Get the full current URL
      const currentUrl = window.location.href;

      // Add the current URL at the bottom of the PDF page
      ///pdf.text(currentUrl, 20, pdf.internal.pageSize.height - 40, { align: 'left' });
      // Draw issuing information
      pdf.setFont('Sakkal Majalla'); // Set font

      pdf.setFontSize(16);
      // Draw additional information
      ///pdf.text('سوف يتمّ النظر في مشروعكم من قبل اللجنة المكلفة بمعرض الابتكار الوطني. وفي حال قبول المشروع، سيتمّ التواصل معكم.', pdf.internal.pageSize.getWidth() - 10, 260, { align: 'right' });
      /// pdf.text('سوف يتمّ النظر في مشروعكم من قبل اللجنة المكلفة بمعرض الابتكار الوطني. وفي حال قبول المشروع، سيتمّ التواصل معكم.', 20, pdf.internal.pageSize.height - 30, { align: 'right' });
      ////pdf.text('سيتمّ مراجعة مشروعكم من قبل لجنة مختصة، وسيتمّ التواصل معكم في حال تمّت الموافقة على مشاركتكم.', pdf.internal.pageSize.getWidth() - 10, pdf.internal.pageSize.height - 15, { align: 'right' });
      pdf.text('سيتم التواصل معكم في حال تمت الموافقة على مشاركتكم', pdf.internal.pageSize.getWidth() - 10, pdf.internal.pageSize.height - 15, { align: 'right' });


      // Generate a blob URL for the PDF content
      const pdfBlob = pdf.output('blob');
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);

      // Set pdfUrl to the blob URL
      setPdfUrl(pdfBlobUrl);


    }
  }, [result, pdfUrl]); // pdfUrl added to dependency array

  const classes_CircularProgress = useStyles_CircularProgress();





  return (
    <>


      <div style={{ width: '100%', height: '1000px' }}>



        {!pdfUrl &&
          <div className={classes_CircularProgress.overlay}>

            <Dialog open={!pdfUrl} PaperProps={{ sx: { backgroundColor: 'white' } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                <CircularProgress sx={{ ml: 1 }} color="primary" />
                <Typography variant="h6" sx={{ color: '#004d40', fontFamily: 'Tajawal, sans-serif', fontWeight: 900, ml: 2 }}>
                  جاري التحميل...
                </Typography>
              </Box>
            </Dialog>

          </div>
        }
        {pdfUrl && (

          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="100%"
            aria-label="Generated PDF"
          >
          </object>


        )}
        {/* Render QR code component */}
        <div style={{ display: 'none' }}>
          <QRCode id="qrCode" value={`${window.location.origin}${window.location.pathname}`} />
        </div>
      </div>
    </>
  );
};

export default Pre_innovationpdf;
