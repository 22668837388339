import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CacheProvider } from "@emotion/react";
import TextField from '@mui/material/TextField';
import createCache from "@emotion/cache";
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LockIcon from '@mui/icons-material/Lock';
import useAuthentication_etab from './useAuthentication_etab';
import LinearProgress from '@mui/material/LinearProgress';

import getCsrfToken from '../CsrfToken';
import FormControl from '@mui/material/FormControl';
import isEmail from 'validator/lib/isEmail';
import ReplayIcon from '@mui/icons-material/Replay';
import Input from '@mui/material/Input';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useNavigate } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import Footer from './Footer';
import Header from './Header';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';
import CardContent from '@mui/material/CardContent';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import {
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Set the CSRF token globally
const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

var md5 = require('md5');
let codeemail = (Math.floor(1000 + Math.random() * 9000));

let sendemailbyemailoneortwo = 'https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';


const defaultColor = "#4caf50"; // Default green color
const hoverColor = "#004d40"; // Lighter green when hovered
const focusColor = "#81c784"; // Even lighter green when focused

const themetextfieldnew = createTheme({
  direction: 'rtl',
  components: {
      MuiOutlinedInput: {
          styleOverrides: {
              root: {
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: hoverColor,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: focusColor,
                  },
              },
              notchedOutline: {
                  borderColor: defaultColor,
              },
          },
      },
      MuiInputLabel: {
          styleOverrides: {
              root: {
                  "&.Mui-focused": {
                      color: focusColor,
                  },
                  "&:hover": {
                      color: hoverColor,
                  },
                  color: defaultColor, // Default label color
              },
          },
      },
  },
  fieldset: {
      fontFamily: '"Changa", sans-serif',
      fontSize: 45,
  },
});



const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

function ChangePwEtab() {


  const { IDetablissement, IDDFEP, IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  } = useAuthentication_etab();

  const [loading, setLoading] = useState(false);


  const [alertMessageEdit, setAlertMessageEdit] = useState(null);


  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(null);

  const [OpenDialog, setOpenDialog] = useState(false);


  const [errorget, setErrorget] = useState();
  const navigate = useNavigate();

  const refDateNais = useRef(null);


  const [Civ, setCiv] = React.useState(1);

  const handleChange_Civ = (event) => {
    setCiv(event.target.value);
  };



  let numrun = 0;





  const [Nom_Et_query, setNom_Et_query] = useState();
  const [Nom_Ca_query, setNom_Ca_query] = useState();
  const [Prenom_query, setPrenom_query] = useState();
  const [DateNais_query, setDateNais_query] = useState();
  const [NumIns_query, setNumIns_query] = useState();
  const [LieuNais_query, setLieuNais_query] = useState();
  const [Adres_query, setAdres_query] = useState();
  const [Nom_query, setNom_query] = useState();
  const [Mode_formation_Nom_query, setMode_formation_Nom_query] = useState();
  const [DateDF_query, setDateDF_query] = useState();
  const [DateFF_query, setDateFF_query] = useState();
  const [NumSem_query, setNumSem_query] = useState();
  const [DateD_sem_query, setDateD_sem_query] = useState();
  const [DateF_sem_query, setDateF_sem_query] = useState();
  const [libelleArabe_Wly_Dfep_query, setlibelleArabe_Wly_Dfep_query] = useState();


  function setValuesFromData_getinfo(data) {
    // Check if data object is defined
    if (!data) {
      console.error('Data is undefined');
      return;
    }
    setNom_Et_query(data.Nom_Et || '');
    setNom_Ca_query(data.Nom_Ca || '');
    setPrenom_query(data.Prenom || '');
    setDateNais_query(data.DateNais || '');
    setLieuNais_query(data.LieuNais || '');
    setAdres_query(data.Adres || '');
    setNom_query(data.Nom || '');
    setMode_formation_Nom_query(data.Mode_formation_Nom || '');
    setDateDF_query(data.DateDF || '');
    setDateFF_query(data.DateFF || '');
    setNumSem_query(data.NumSem || '');
    setDateD_sem_query(data.DateD_sem || '');
    setDateF_sem_query(data.DateF_sem || '');
    setNumIns_query(data.NumIns || '');
    setlibelleArabe_Wly_Dfep_query(data.libelleArabe_Wly_Dfep || '');


  }





  function setValuesFromData(data) {
    // Check if data object is defined
    if (!data) {
      console.error('Data is undefined');
      return;
    }

    // Set values based on the data received
    setvalueNom(data.Nom || '');
    setvalueNomFr(data.NomFr || '');
    setvaluePrenom(data.Prenom || '');
    setvaluePrenomFr(data.PrenomFr || '');
    setvalueLieuNaisFr(data.LieuNaisFr || '');
    setvalueLieuNais(data.LieuNais || '');
    setvalueDateNais(dayjs(data.DateNais) || '');
    setvalueAdres(data.Adres || '');
    setvalueAdresFr(data.AdresFr || '');
    setvaluePrenomPere(data.PrenomPere || '');
    setvaluePrenomPereFr(data.PrenomPereFr || '');
    setCiv(data.Civ || '');
    setvaluePrenomMere(data.PrenomMere || '');
    setvaluePrenomMereFr(data.PrenomMereFr || '');
    setvalueNomMere(data.NomMere || '');
    setvalueNomMereFr(data.NomMereFr || '');



  }


  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };


  const handleClickCloseDialog = () => {
    setOpenDialog(false);
    //// navigate('/Doc');
  };


  const [emailverificated, setemailverificated] = useState(false);

  const [emailsended, setemailsended] = useState(false);
  const [errorsendingemail, seterrorsendingemail] = useState(false);
  const [codeemailsent, setcodeemailsent] = useState(false);
  const [codeemailforchecking, setcodeemailforchecking] = useState(md5(codeemail));

  const [valueNomMere, setvalueNomMere] = useState('');
  const [valueNomMereFr, setvalueNomMereFr] = useState('');
  const [valuePrenomMereFr, setvaluePrenomMereFr] = useState('');
  const [valuePrenomMere, setvaluePrenomMere] = useState('');


  const [valueNom, setvalueNom] = useState('');
  const [valueLieuNaisFr, setvalueLieuNaisFr] = useState('');
  const [valueLieuNais, setvalueLieuNais] = useState('');

  const [valueAdres, setvalueAdres] = useState('');

  const [valuePrenomPere, setvaluePrenomPere] = useState('');
  const [valuePrenomPereFr, setvaluePrenomPereFr] = useState('');


  const [valueAdresFr, setvalueAdresFr] = useState('');


  const refLieuNais = useRef(null);
  const refLieuNaisFr = useRef(null);

  const refNomMere = useRef(null);
  const refNomMereFr = useRef(null);
  const refPrenomMere = useRef(null);
  const refPrenomMereFr = useRef(null);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));



  const handleInput_LieuNais = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex_withdash.test(inputValue) || inputValue === '') {
      setvalueLieuNais(inputValue);
    }
  };

  const handleInput_LieuNaisFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex_withdash.test(inputValue) || inputValue === '') {
      setvalueLieuNaisFr(inputValue);
    }
  };
  const refNom = useRef(null);
  const refvalueDateNais = useRef(null);
  const [valuePrenom, setvaluePrenom] = useState('');
  const refPrenom = useRef(null);
  const refPrenomPere = useRef(null);
  const refPrenomPereFr = useRef(null);


  const [valuenbcodeverifie, setvaluenbcodeverifie] = useState('');
  const refnbcodeverifie = useRef(null);

  const ArabicCharactersRegex = /^[\u0600-\u06FF\s]+$/;
  const ArabicCharactersRegex_withdash = /^[\u0600-\u06FF\s-_]+$/;
  const ArabicCharactersRegex_address = /^[\u0600-\u06FF\s-_\/0-9]+$/;

  const NumbersOnlyRegex = /^[0-9]+$/;


  const handleInput_Adres = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex_address.test(inputValue) || inputValue === '') {
      setvalueAdres(inputValue);
    }
  };

  const handleInput_PrenomMere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomMere(inputValue);
    }
  };

  const handleInput_NomMere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueNomMere(inputValue);
    }
  };

  const handleInput_Nom = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvalueNom(inputValue);
    }
  };



  const [valueDateNais, setvalueDateNais] = useState('');

  // Regular expression to match the format "YYYY/MM/DD"
  const dateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

  // Regular expression to match the format "YYYY/MM/DD" with digits for years, months, and days
  const fullDateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

  const handleInput_DateNais = (event) => {
    const inputValue = event.target.value;
    setvalueDateNais(inputValue);
  };



  const handleInput_PrenomPere = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomPere(inputValue);
    }
  };

  const handleInput_Prenom = (event) => {
    const inputValue = event.target.value;

    if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenom(inputValue);
    }
  };

  const handleInput_nbcodeverifie = (event) => {
    const inputValue = event.target.value;

    if (NumbersOnlyRegex.test(inputValue) || inputValue === '') {
      setvaluenbcodeverifie(inputValue);
    }
  };

  const [valueNomFr, setvalueNomFr] = useState('');
  const refNomFr = useRef(null);
  const [error, setError] = useState('');

  const [valuePrenomFr, setvaluePrenomFr] = useState('');
  const refPrenomFr = useRef(null);

  const LatinLettersRegex = /^[a-zA-Z\s]+$/;
  const LatinLettersRegex_withdash = /^[a-zA-Z\s-_]+$/;
  const LatinLettersRegex_address = /^[a-zA-Z\s\-_\/0-9]+$/;

  const handleInput_AdresFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex_address.test(inputValue) || inputValue === '') {
      setvalueAdresFr(inputValue);
    }
  };



  const handleInput_PrenomMereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomMereFr(inputValue);
    }
  };

  const handleInput_NomMereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueNomMereFr(inputValue);
    }
  };

  const handleInput_NomFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvalueNomFr(inputValue);
    }
  };

  const handleInput_PrenomPereFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomPereFr(inputValue);
    }
  };

  const handleInput_PrenomFr = (event) => {
    const inputValue = event.target.value;

    if (LatinLettersRegex.test(inputValue) || inputValue === '') {
      setvaluePrenomFr(inputValue);
    }
  };



  const refbtnsubmit = useRef(null);
  const refpassword1 = useRef(null);
  const refpassword2 = useRef(null);
  const refpasswordnow = useRef(null);
  const refAdres = useRef(null);
  const refAdresFr = useRef(null);



  const [valuesPassword, setValuesPassword] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [valuesPasswordRe, setValuesPasswordRe] = React.useState({
    amount: '',
    PasswordRe: '',
    weight: '',
    weightRange: '',
    showPasswordRe: false,
  });

  const [valuespasswordnow, setValuespasswordnow] = React.useState({
    amount: '',
    passwordnow: '',
    weight: '',
    weightRange: '',
    showpasswordnow: false,
  });


  const handleChangePasswordRe = (prop) => (event) => {
    setValuesPasswordRe({ ...valuesPasswordRe, [prop]: event.target.value });
  };

  const handleChangepasswordnow = (prop) => (event) => {
    setValuespasswordnow({ ...valuespasswordnow, [prop]: event.target.value });
  };

  const handleClickShowPasswordRe = () => {
    setValuesPasswordRe({
      ...valuesPasswordRe,
      showPasswordRe: !valuesPasswordRe.showPasswordRe,
    });
  };

  const handleClickShowpasswordnow = () => {
    setValuespasswordnow({
      ...valuespasswordnow,
      showpasswordnow: !valuespasswordnow.showpasswordnow,
    });
  };

  const handleMouseDownPasswordRe = (event) => {
    event.preventDefault();
  };

  const handleMouseDownpasswordnow = (event) => {
    event.preventDefault();
  };


  const handleChangePassword = (prop) => (event) => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  // Store token in state
  const [csrfToken, setCsrfToken] = useState(null);

  // Fetch on app load
  useEffect(() => {
    const getCsrfToken = async () => {
      const response = await axios.get(`${API_BASE_URL}/api/csrf-token`);
      setCsrfToken(response.data.csrf_token);
    };

    getCsrfToken();
  }, []);



  const onsubmitclicked = async (e) => {


    e.preventDefault();

    // Fetch CSRF token
    try {
      // Refresh token
      const response = await axios.get(`${API_BASE_URL}/api/csrf-token`);
      setCsrfToken(response.data.csrf_token);


      try {
        const data = {
          Password: valuesPassword.password,
          Password_old: valuespasswordnow.passwordnow,
          IDetablissement: IDetablissement

        };

        ///alert(JSON.stringify(data));
        const responseFromApi = await axios.post(`${API_BASE_URL}/api/update_etablissement_pw`, data,
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
            }
          }
        );


        setAlertMessage("");
        handleClickOpenDialog();

        //console.log(responseFromApi);
      } catch (error) {
        console.error('Error making API request:', error);
        if (error.response) {
          //console.log(error.response);

          // Check if there is a specific error message for password_old
          if (error.response && error.response.data && error.response.data.error) {
            const validationErrors = error.response.data.error;
            const errorMessage = Object.keys(validationErrors)
              .map((field, index) => `- ${validationErrors[field][0]}`)
              .join('\n');
            setAlertMessage(validationErrors);
            setAlertSeverity('error');
          }

        }
      }
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };




  useEffect(() => {
    if (!loadingauth) {
      if (
        IDetablissement === null ||
        IDDFEP === null ||
        IDNature_etsF === null
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/');
        return;
      }


    }
  }, [IDetablissement, IDDFEP, IDNature_etsF, IDCandidat, NumSem, IDapis_userslist,
    loadingauth, navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }



  return (
    <>


      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={download_cert_inscription_active_Dfep}
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={download_cert_inscription_active_Mfep}
      />


      <form>
        <Container sx={{ textAlign: 'center', mx: 'auto', width: '80%' }}>
          <Grid  >

            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>
                <Box>

                  {alertMessageEdit && (
                    <Alert severity="warning" style={{ direction: 'rtl', textAlign: 'right' }}>
                      <pre>
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 14,
                          }}
                        >

                          {alertMessageEdit}
                        </Typography>
                      </pre>
                    </Alert>

                  )}


                  {alertMessage && (
                    <Alert severity={alertSeverity} style={{ direction: 'rtl', textAlign: 'right' }}>
                      <AlertTitle>
                        <Typography
                          style={{
                            color: "red",
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 14,
                          }}
                        >
                          الأخطاء
                        </Typography>
                      </AlertTitle>
                      <pre>
                        <Typography
                          style={{
                            color: "#CD5C5C",
                            fontFamily: '"Tajawal", sans-serif',
                            fontSize: 12,
                          }}
                        >

                          {alertMessage}
                        </Typography>
                      </pre>
                    </Alert>
                  )}



                </Box>
              </ThemeProvider>
            </CacheProvider>





            {/* {!isMatch ? (   <></>):(<></>)};*/}

            <Divider >
              <Grid item sx={{ mt: '30px' }} >
                <Typography
                  style={{
                    color: "#004d40",
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: 25,
                    fontSize: isMatch ? 18 : 25,
                    fontWeight: 700,
                    marginBottom: '25px'
                  }}
                  variant="h3" gutterBottom>
                  تغيير كلمة سر حسابك  <LockIcon sx={{ ml: '0px' }} />
                </Typography>
              </Grid>

            </Divider>


            <Box sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {loading && <CircularProgress />}
            </Box>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>



              {/*   كلمة السر الحالية  */}

              <Grid item xs={12} sx={{ mt: 1 }}>
                <CacheProvider value={cacheRtl}>
                  <ThemeProvider theme={themetextfieldnew}>
                    <Box>
                      <div dir="ltr">

                        <FormControl fullWidth variant="outlined"

                        >

                          <InputLabel
                            sx={{
                              color: "#004d40", fontFamily:
                                '"Tajawal", sans-serif',
                              fontSize: 16
                              , fontWeight: 700
                            }}
                            htmlFor="outlined-adornment-password">كلمة المرور الحالية </InputLabel>
                          <OutlinedInput
                            fullWidth
                            dir="rtl"
                            sx={{

                              //direction:'ltr',

                              '& .MuiFormHelperText-root': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '14px',
                                color: '#004d40',
                                fontWeight: 700

                              },
                              '& .MuiFormLabel-root': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '18px',
                                color: '#004d40',
                                dir: 'rtl',

                              },
                              '& .MuiInputBase-input': {
                                fontFamily: '"Tajawal", sans-serif',
                                fontSize: '22px',
                                color: '#004d40',
                                fontWeight: 700,
                                //text-align: right,


                              },
                              '& label.Mui-focused': {
                                fontSize: '16px',
                                color: '#004d40',
                                fontWeight: 700
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#004d40',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '16px',
                                  borderColor: '#004d40',
                                  borderWidth: "2px",

                                },
                                '&:hover fieldset': {
                                  borderColor: "#00796b",
                                },
                                '&.Mui-focused fieldset': {

                                  borderColor: "#00796b",
                                },
                              }
                            }}

                            inputRef={refpasswordnow}
                            id="outlined-adornment-passwordnow"
                            type={valuespasswordnow.showpasswordnow ? 'text' : 'password'}
                            value={valuespasswordnow.passwordnow}
                            name="valuespasswordnow"
                            onChange={handleChangepasswordnow('passwordnow')}
                            inputProps={{
                              tabIndex: 7,
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowpasswordnow}
                                  onMouseDown={handleMouseDownpasswordnow}
                                  edge="end"
                                >
                                  {valuespasswordnow.showpasswordnow ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="كلمة المرور الحالية"
                          />



                        </FormControl>

                      </div>
                    </Box>
                  </ThemeProvider>
                </CacheProvider>
              </Grid>


              <Grid sx={{ mt: 2 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>


                {/*  كلمة السر  */}

                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                  <CacheProvider value={cacheRtl}>
                    <ThemeProvider theme={themetextfieldnew}>
                      <Box>
                        <div dir="ltr">

                          <FormControl fullWidth variant="outlined"

                          >

                            <InputLabel
                              sx={{
                                color: "#004d40", fontFamily:
                                  '"Tajawal", sans-serif',
                                fontSize: 16
                                , fontWeight: 700
                              }}
                              htmlFor="outlined-adornment-password">كلمة المرور الجديدة  </InputLabel>
                            <OutlinedInput
                              fullWidth
                              dir="rtl"
                              sx={{

                                //direction:'ltr',

                                '& .MuiFormHelperText-root': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '14px',
                                  color: '#004d40',
                                  fontWeight: 700

                                },
                                '& .MuiFormLabel-root': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '18px',
                                  color: '#004d40',
                                  dir: 'rtl',

                                },
                                '& .MuiInputBase-input': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '22px',
                                  color: '#004d40',
                                  fontWeight: 700,
                                  //text-align: right,


                                },
                                '& label.Mui-focused': {
                                  fontSize: '16px',
                                  color: '#004d40',
                                  fontWeight: 700
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#004d40',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '16px',
                                    borderColor: '#004d40',
                                    borderWidth: "2px",

                                  },
                                  '&:hover fieldset': {
                                    borderColor: "#00796b",
                                  },
                                  '&.Mui-focused fieldset': {

                                    borderColor: "#00796b",
                                  },
                                }
                              }}

                              inputRef={refpassword1}
                              id="outlined-adornment-password"
                              type={valuesPassword.showPassword ? 'text' : 'password'}
                              value={valuesPassword.password}
                              name="valuesPassword"
                              onChange={handleChangePassword('password')}
                              inputProps={{
                                tabIndex: 9,
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {valuesPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label=" كلمة المرور الجديدة  "
                            />


                            {!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/.test(valuesPassword.password) ? (
                              <FormHelperText
                                sx={{
                                  color: "#004d40",
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 14,
                                  fontWeight: 700
                                }}
                                error
                                id="accountId-error"
                              >
                                كلمة المرور يجب أن تحتوي على: - حرف كبير على الأقل - حرف صغير على الأقل - رقم واحد على الأقل - طولها 8 أحرف على الأقل
                              </FormHelperText>
                            ) : (
                              <></>
                            )}



                          </FormControl>

                        </div>
                      </Box>
                    </ThemeProvider>
                  </CacheProvider>
                </Grid>




                {/* إعادة كلمة السر */}
                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                  <CacheProvider value={cacheRtl}>
                    <ThemeProvider theme={themetextfieldnew}>
                      <Box>
                        <div dir="ltr">

                          <FormControl fullWidth variant="outlined"

                          >

                            <InputLabel
                              sx={{
                                color: "#004d40", fontFamily:
                                  '"Tajawal", sans-serif',
                                fontSize: 16
                                , fontWeight: 700
                              }}
                              htmlFor="outlined-adornment-password"> إعادة كلمة المرور الجديدة</InputLabel>
                            <OutlinedInput

                              fullWidth
                              dir="rtl"
                              sx={{

                                //direction:'ltr',

                                '& .MuiFormHelperText-root': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '14px',
                                  color: '#004d40',
                                  fontWeight: 700

                                },
                                '& .MuiFormLabel-root': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '18px',
                                  color: '#004d40',
                                  dir: 'rtl',

                                },
                                '& .MuiInputBase-input': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '22px',
                                  color: '#004d40',
                                  fontWeight: 700,
                                  //text-align: right,



                                },
                                '& label.Mui-focused': {
                                  fontSize: '16px',
                                  color: '#004d40',
                                  fontWeight: 700
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#004d40',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '16px',
                                    borderColor: '#004d40',
                                    borderWidth: "2px",

                                  },
                                  '&:hover fieldset': {
                                    borderColor: "#00796b",
                                  },
                                  '&.Mui-focused fieldset': {

                                    borderColor: "#00796b",
                                  },
                                }
                              }}

                              inputRef={refpassword2}
                              id="outlined-adornment-PasswordRe"
                              type={valuesPasswordRe.showPasswordRe ? 'text' : 'Password'}
                              value={valuesPasswordRe.PasswordRe}
                              name="valuesPasswordRe"
                              onChange={handleChangePasswordRe('PasswordRe')}
                              inputProps={{
                                tabIndex: 10,
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordRe}
                                    onMouseDown={handleMouseDownPasswordRe}
                                    edge="end"
                                  >
                                    {valuesPasswordRe.showPasswordRe ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label=" إعادة كلمة المرور الجديدة "
                            />

                            {valuesPasswordRe.PasswordRe !== valuesPassword.password ? <>

                              <FormHelperText
                                sx={{
                                  color: "#004d40", fontFamily:
                                    '"Tajawal", sans-serif',
                                  fontSize: 14
                                  , fontWeight: 700
                                }}
                                error id="accountId-error">
                                يجب أن تدخل نفس كلمة السر
                              </FormHelperText>


                            </> : <></>}


                          </FormControl>
                        </div>
                      </Box>
                    </ThemeProvider>
                  </CacheProvider>
                </Grid>






              </Grid>



              {/* Submit Button */}
              <Grid item xs={12}>
                <CacheProvider value={cacheRtl}>
                  <ThemeProvider theme={themetextfieldnew}>
                    <Box sx={{ minWidth: 120, mt: '15px' }}>
                      <div>

                        <Button

sx={{
  backgroundColor: '#00695c', // Green color
  '&:hover': {
    backgroundColor: '#004d40', // Darker green on hover
  },
  padding: '8px 16px',  // Add padding inside the button (top-bottom, left-right)
  gap: '8px',  // Space between Typography and the icon
  mb:5
}}
                          onClick={(e) => {

                            e.preventDefault();



                            if (valuespasswordnow.passwordnow === '') {
                              refpasswordnow.current.focus();
                              return
                            }

                            if (valuesPassword.password === '') {
                              refpassword1.current.focus();
                              return
                            }

                            if (valuesPasswordRe.PasswordRe === '') {
                              refpassword2.current.focus();
                              return
                            }

                            if (valuesPassword.password !== valuesPasswordRe.PasswordRe) {
                              refpassword2.current.focus();
                              return
                            }

                            if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/.test(valuesPassword.password)) {
                              refpassword1.current.focus();
                              return
                            }



                            onsubmitclicked(e);

                          }}

                          variant="contained"
                          startIcon={<LockIcon sx={{
                            mr: '1px',
                            transform: 'rotate(0deg)', // Adjust the angle as needed
                          }} />}>
                          <Typography sx={{
                            color: "#FFFFFF", fontFamily:
                              '"Tajawal", sans-serif',
                            fontSize: 22
                            , fontWeight: 900
                          }}>
                            تعديل

                          </Typography>

                        </Button>

                      </div>

                    </Box>
                  </ThemeProvider>
                </CacheProvider>

              </Grid>







            </Grid>
          </Grid>
        </Container>
        <Footer />

      </form>


      {/* Dialog success save */}

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: '#004d40',
            boxShadow: 'none',
          },
        }}
        //fullScreen={mediumScreen}
        open={OpenDialog}
        onClose={handleClickCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >

        <Card style={{}} sx={{ maxWidth: 345 }}>

          {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
          <CardHeader
            avatar={

              <CheckCircleIcon sx={{ color: '#4BB543', width: 50, height: 50, ml: 2 }}></CheckCircleIcon>

            }
            action={


              <IconButton onClick={handleClickCloseDialog} aria-label="settings" sx={{ ml: -1, mt: -1 }}>

                <CloseIcon />

              </IconButton>
            }
            title={
              <>
                <Box sx={{ ml: 2, mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>

                  <Typography sx={{ color: '#004d40', fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }} >
                    تم تغيير كلمة السر بنجاح</Typography>
                </Box>

              </>

            }
            subheader=""
          />





        </Card>

      </Dialog>

    </>
  );
}

export default ChangePwEtab;
