import React, { useState, useEffect, useRef } from "react";
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from "react-router-dom";
import CardContent from '@mui/material/CardContent';
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import localforage from 'localforage'; // Import localforage
import './selecthandicape.css';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CameraModal from './CameraModal'; // Import your CameraModal component
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
} from "react-router-dom";
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography, Box } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReCAPTCHA from 'react-google-recaptcha';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from "@material-ui/core/styles";
import {
    useMediaQuery,
    useTheme,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import isEmail from 'validator/lib/isEmail';


const defaultColor = "#004d40"; // Default green color
const hoverColor = "#004d40"; // Lighter green when hovered
const focusColor = "#004d40"; // Even lighter green when focused

const themetextfieldnew = createTheme({
    direction: 'rtl',
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    // Default border color
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: defaultColor,
                        borderWidth: '2px', // Border width
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: hoverColor,
                        border: '2px solid', // Border size and color on hover

                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: focusColor,
                        border: '2px solid', // Border size and color on hover

                    },
                },
                notchedOutline: {
                    borderColor: defaultColor,
                    border: '2px solid', // Border size and color on hover

                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: focusColor,
                        border: '2px solid transparent', // Border size and color on hover
                    },
                    "&:hover": {
                        color: hoverColor,
                        border: '2px solid transparent', // Border size and color on hover

                    },
                    color: defaultColor, // Default label color
                    border: '2px solid transparent', // Border size and color on hover

                },
            },
        },
    },
    fieldset: {
        fontFamily: '"Changa", sans-serif',
        fontSize: 45,
    },
});


const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const themeChanga = createTheme({
    typography: {
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 16,
    },
});

const BootstrapTooltip = styled((props) => (
    <Tooltip {...props} arrow classes={{ popper: props.className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        ///color: theme.palette.common.black,
        color: 'orange',

    },

    [`& .${tooltipClasses.tooltip}`]: {
        ///backgroundColor: theme.palette.common.black,
        backgroundColor: 'orange',
        fontSize: '18px',
        fontWeight: 'bold',
        top: '-20px'
    },
}));

export default function Inscription() {


    // Create the URL to the PDF file
    const pdfUrl = `${process.env.PUBLIC_URL}/DocMfep/CONTRAT_APPRENTISSAGE.PDF`;

    const modifyPdf = async (valueNom, valuePrenom, valueNomFr, valuePrenomFr, valueDateNais, valueLieuNais, valueAdres, valueTEL1, valueemail, Civ, niveaunompdf, isCheckedPresume, valuePresumeDateNais) => {
        try {
            const url = `${process.env.PUBLIC_URL}/DocMfep/CONTRAT_APPRENTISSAGE.PDF`;

            const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

            // Load the existing PDF
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            // Register fontkit to embed custom fonts
            pdfDoc.registerFontkit(fontkit);

            // Load and embed the custom font
            const fontUrl_SimplifiedArabic = `${process.env.PUBLIC_URL}/SimplifiedArabic.ttf`;
            const fontBytes = await fetch(fontUrl_SimplifiedArabic).then(res => res.arrayBuffer());
            const Fontseee = await pdfDoc.embedFont(fontBytes);

            // Get the first page
            const page = pdfDoc.getPages()[0];

            // Draw text on the PDF using the custom font
            page.drawText(valueNomFr, {
                x: 50,
                y: 590,
                size: 14,
                font: Fontseee,
                color: rgb(0, 0, 0),
            });


            // Define the text you want to draw
            const textnom = valueNom;

            // Define the size of the text
            const textSize = 14;

            // Calculate the width of the text in the desired font and size
            const textWidth = Fontseee.widthOfTextAtSize(textnom, textSize);

            // Calculate the x position so the text starts at 500 and flows to the left
            const xPosition = 547 - textWidth;

            page.drawText(textnom, {
                x: xPosition,
                y: 591,
                size: textSize,
                font: Fontseee,
                color: rgb(0, 0, 0),
            });



            // Draw text on the PDF using the custom font
            page.drawText(valuePrenomFr, {
                x: 215,
                y: 590,
                size: 14,
                font: Fontseee,
                color: rgb(0, 0, 0),
            });



            // Define the text you want to draw
            const textprenom = valuePrenom;

            // Define the size of the text
            const textSizeprenom = 14;

            // Calculate the width of the text in the desired font and size
            const textWidthprenom = Fontseee.widthOfTextAtSize(textprenom, textSizeprenom);

            // Calculate the x position so the text starts at 500 and flows to the left
            const xPositionprenom = 395 - textWidthprenom;

            page.drawText(textprenom, {
                x: xPositionprenom,
                y: 591,
                size: textSizeprenom,
                font: Fontseee,
                color: rgb(0, 0, 0),
            });

            const formatDateWithSpaces = (date) => {
                // Extract the day, month, and year from the date object
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear().toString();

                // Combine them into a single string
                //const dateString = `${day}${month}${year}`;
                const dateString = `${year}${month}${day}`;

                // Add spaces between each digit
                return dateString.split('').join(' ');
            };






            // Define the birthDatepdf
            const birthDatepdf = new Date(valueDateNais);

            // Format the date with spaces
            const formattedDate = formatDateWithSpaces(birthDatepdf);

            // Draw the formatted text on the PDF
            page.drawText(formattedDate, {
                x: 401,
                y: 573,
                size: 14,
                font: Fontseee,
                color: rgb(0, 0, 0),
            });





            // Define the text you want to draw
            const textlieuar = valueLieuNais;

            // Define the size of the text
            const textSizelieuar = 14;

            // Calculate the width of the text in the desired font and size
            const textWidthlieuar = Fontseee.widthOfTextAtSize(textlieuar, textSizelieuar);

            // Calculate the x position so the text starts at 500 and flows to the left
            const xPositionlieuar = 382 - textWidthlieuar;

            page.drawText(textlieuar, {
                x: xPositionlieuar,
                y: 573,
                size: textSizelieuar,
                font: Fontseee,
                color: rgb(0, 0, 0),
            });


            // Check the value of Civ and draw the text accordingly
            if (Civ === 1) {
                page.drawText("X", {
                    x: 512,
                    y: 557,
                    size: 14,
                    font: Fontseee,
                    color: rgb(0, 0, 0),
                });
            } else if (Civ === 2) {
                page.drawText("X", {
                    x: 470,
                    y: 557,
                    size: 14,
                    font: Fontseee,
                    color: rgb(0, 0, 0),
                });
            }



            // Define the text you want to draw
            const textaddrar = valueAdres;

            // Define the size of the text
            const textSizeaddrar = 14;

            // Calculate the width of the text in the desired font and size
            const textWidthaddrar = Fontseee.widthOfTextAtSize(textaddrar, textSizeaddrar);

            // Calculate the x position so the text starts at 500 and flows to the left
            const xPositionaddrar = 536 - textWidthaddrar;

            page.drawText(textaddrar, {
                x: xPositionaddrar,
                y: 542,
                size: textSizeaddrar,
                font: Fontseee,
                color: rgb(0, 0, 0),
            });


            // Function to draw text with specific spacing between characters
            const drawTextWithSpacing = (page, text, startX, startY, font, size, color, spacing) => {
                let xPosition = startX;

                for (let i = 0; i < text.length; i++) {
                    const char = text[i];
                    const charWidth = font.widthOfTextAtSize(char, size);

                    page.drawText(char, {
                        x: xPosition,
                        y: startY,
                        size: size,
                        font: font,
                        color: color,
                    });

                    xPosition += charWidth + spacing; // Move x position by character width plus spacing
                }
            };

            // Define the text and parameters
            const texttel = valueTEL1;
            const startXtel = 450;
            const startYtel = 510;
            const textSizetel = 14;
            const spacingPxtel = 2.3; // Define the space between characters in pixels

            // Draw the text with spacing
            drawTextWithSpacing(page, texttel, startXtel, startYtel, Fontseee, textSizetel, rgb(0, 0, 0), spacingPxtel);



            // Draw text on the PDF using the custom font
            page.drawText(valueemail, {
                x: 226,
                y: 508,
                size: 14,
                font: Fontseee,
                color: rgb(0, 0, 0),
            });




            // Define the text you want to draw
            const textniveau = niveaunompdf;

            // Define the size of the text
            const textSizeniveau = 14;

            // Calculate the width of the text in the desired font and size
            const textWidthniveau = Fontseee.widthOfTextAtSize(textniveau, textSizeniveau);

            // Calculate the x position so the text starts at 500 and flows to the left
            const xPositionniveau = 492 - textWidthniveau;

            page.drawText(textniveau, {
                x: xPositionniveau,
                y: 492,
                size: textSizeniveau,
                font: Fontseee,
                color: rgb(0, 0, 0),
            });


            // Set the PDF document metadata
            pdfDoc.setTitle(' عقد تمهين ' + valueNom + ' ' + valuePrenom); // Change the title here            // Save the modified PDF
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const urlObject = URL.createObjectURL(blob);

            // Open the modified PDF in a new tab
            window.open(urlObject, '_blank');

            // Clean up the URL object to free up memory
            URL.revokeObjectURL(urlObject);

        } catch (error) {
            console.error('Error modifying PDF:', error);
        }
    };

    const [isChecked, setIsChecked] = useState(false);
    const [open_info, setopen_info] = useState(false);
    const [selectedniveau_scolaires, setselectedniveau_scolaires] = useState(null);
    const [IDniveau_scolaires, setIDniveau_scolaires] = useState(null);

    //const [IDCycle_scol_selected, setIDCycle_scol_selected] = useState(null);
    //const [NumOrd_selected, setNumOrd_selected] = useState(null);

    const [options_niveau_scolaires, setoptions_niveau_scolaires] = useState([]);

    let optio_niveau_scolaires = [];
    let count_nivscol = 0;




    const [niveaunompdf, setniveaunompdf] = useState("");
    const handleniveau_scolairesChange = (event, values) => {


        if (values) {
            setselectedniveau_scolaires(values);
            setIDniveau_scolaires(values.value);
            //alert("IDCycle_scol_selected: "+IDCycle_scol_selected+" NumOrd: "+NumOrd_selected);
            //alert("IDCycle_scol: "+IDCycle_scol+" NumOrd: "+NumOrd);
            setIDCycle_scol(values.IDCycle_scol);
            setNumOrd(values.NumOrd);
            setniveaunompdf(values.Nom);

        } else {
            return;
        }


    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };


    const [loadingdata_mfep, setloadingdata_mfep] = useState(false);
    const [Nom_NomFr_session, setNom_NomFr_session] = useState('');
    const [Nom_session, setNom_session] = useState('');

    const [AgeMin, setAgeMin] = useState(15);
    const [AgeMAx, setAgeMAx] = useState(0);

    const [IDCycle_scol, setIDCycle_scol] = useState(0);
    const [NumOrd, setNumOrd] = useState(0);

    const [IDCycle_scol_db, setIDCycle_scol_db] = useState(0);
    const [NumOrd_db, setNumOrd_db] = useState(0);

    const [Clouture, setClouture] = useState(0);


    const [Nom_specialite, setNom_specialite] = useState('');

    const [Nom_etablissement, setNom_etablissement] = useState('');
    const [Nom_mode_formation, setNom_mode_formation] = useState('');
    const [IDMode_formation, setIDMode_formation] = useState(0);

    const [Nom_NomFr_qualification_dplm, setNom_NomFr_qualification_dplm] = useState('');
    const [Abr_AbrFr_qualification_dplm, setAbr_AbrFr_qualification_dplm] = useState('');
    const [Nom_NomFr_niveau_scol, setNom_NomFr_niveau_scol] = useState('');



    const [valueNom, setvalueNom] = useState('');
    const [valueNin, setvalueNin] = useState('');

    const [isCameraOpen, setCameraOpen] = useState(false);

    const handleCameraOpen = () => {
        setCameraOpen(true);
    };

    const handleCameraClose = () => {
        setCameraOpen(false);
    };

    const handleCapture = (capturedValue) => {
        setvalueNin(capturedValue);
    };

    const refNin = useRef(null);

    const [valueNomFr, setvalueNomFr] = useState('');
    const refNomFr = useRef(null);
    const refNom = useRef(null);
    const Refselectedniveau_scolaires = useRef(null);

    const refvalueDateNais = useRef(null);
    const refvaluePresumeDateNais = useRef(null);


    const [valuePrenom, setvaluePrenom] = useState('');
    const refPrenom = useRef(null);
    const refPrenomPere = useRef(null);
    const refPrenomPereFr = useRef(null);

    const [valueNomMere, setvalueNomMere] = useState('');
    const [valueNomMereFr, setvalueNomMereFr] = useState('');
    const [valuePrenomMereFr, setvaluePrenomMereFr] = useState('');
    const [valuePrenomMere, setvaluePrenomMere] = useState('');


    const [valueLieuNaisFr, setvalueLieuNaisFr] = useState('');
    const [valueLieuNais, setvalueLieuNais] = useState('');

    const [valueAdres, setvalueAdres] = useState('');

    const [valuePrenomPere, setvaluePrenomPere] = useState('');
    const [valuePrenomPereFr, setvaluePrenomPereFr] = useState('');


    const [valueAdresFr, setvalueAdresFr] = useState('');
    const [valueDateNais, setvalueDateNais] = useState('');
    const [valuePresumeDateNais, setvaluePresumeDateNais] = useState('');

    // Handle input change for presumed date
    const handleInput_valuePresumeDateNais = (event) => {
        setvaluePresumeDateNais(event.target.value);
    };

    const [msgerror, setmsgerror] = useState('');
    const [fieldnameerror, setfieldnameerror] = useState('');

    const refLieuNais = useRef(null);
    const refLieuNaisFr = useRef(null);

    const refNomMere = useRef(null);
    const refNomMereFr = useRef(null);
    const refPrenomMere = useRef(null);
    const refPrenomMereFr = useRef(null);

    const [valuePrenomFr, setvaluePrenomFr] = useState('');
    const refPrenomFr = useRef(null);

    const refDateNais = useRef(null);
    const [loading, setLoading] = useState(true);


    const [alertMessageEdit, setAlertMessageEdit] = useState(null);

    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState(null);

    const [open_recaptcha, setOpen_recaptcha] = React.useState(false);
    const [open_check, setopen_check] = React.useState(false);

    const [open_valueDateNais, setopen_valueDateNais] = React.useState(false);

    const handleClose_check = () => {
        if (!open_info) {
            setopen_check(false);
        }
        //

    };

    const handleClose_recaptcha = () => {

        setOpen_recaptcha(false);

    };

    const handleOpen_recaptcha = () => {
        setOpen_recaptcha(true);
    };


    const [OpenDialog, setOpenDialog] = useState(false);


    const [errorget, setErrorget] = useState();

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };


    const handleClickCloseDialog = () => {
        setOpenDialog(false);

        navigate(`/`);
        //navigate(`/Preinscriptionpdf/${crypted_id}`);
    };


    const [Civ, setCiv] = React.useState(1);
    const [endicape, setendicape] = React.useState(0);
    const [premier5, setpremier5] = React.useState(0);
    const [experienceYears, setExperienceYears] = useState(null);

    const [crypted_id, setcrypted_id] = React.useState(null);
    const [IDCandidat, setIDCandidat] = React.useState(null);



    const handleChange_Civ = (event) => {
        setCiv(event.target.value);
    };

    const handleChange_endicape = (event) => {
        setendicape(event.target.value);
    };

    const handleChange_premier5 = (event) => {
        setpremier5(event.target.value);
    };

    const handleOpenPdf = () => {
        if (pdfUrl) {
            window.open(pdfUrl, '_blank');
        }
    };

    const handlewlyChange = (event, values) => {

        if (values) {
            setSelectedwlys(values);
            setIDwly_selected(values.value);
        } else {
            return;
        }


    }



    const handlebldChange = (event, values) => {
        ///alert(values.value);
        setSelectedcommuns(values);
        setIDcommun_selected(values.value);

    };





    const [recaptchaVerified, setRecaptchaVerified] = useState(false); // Initially assuming reCAPTCHA is verified
    const refReCAPTCHA = useRef(null);
    const refcheckbox = useRef(null);



    const handleRecaptchaChange = (value) => {
        // handle reCAPTCHA response
        if (value) {
            // reCAPTCHA verification succeeded
            setRecaptchaVerified(true);
            // Proceed with your logic here
        } else {
            // reCAPTCHA verification failed
            setRecaptchaVerified(false);
            // Handle the failure scenario
        }
    };
    /*
    const handleRecaptchaChange = (e) => {
      setToken(e.target.value); // e.target.value should hold the token
    };*/



    const ArabicCharactersRegex = /^[\u0600-\u06FF\s]+$/;
    const ArabicCharactersRegex_withdash = /^[\u0600-\u06FF\s-_]+$/;
    const ArabicCharactersRegex_address = /^[\u0600-\u06FF\s-_\/0-9]+$/;
    const LatinLettersRegex = /^[a-zA-Z\s]+$/;
    const LatinLettersRegex_withdash = /^[a-zA-Z\s-_]+$/;
    const LatinLettersRegex_address = /^[a-zA-Z\s\-_\/0-9]+$/;


    const NumbersOnlyRegex = /^[0-9]+$/;
    const InternationalNumbersOnlyRegextel = /^\+\d*$/;
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const refemail = useRef(null);
    const refcodeemail = useRef(null);
    const [valueemail, setValueemail] = useState('');
    const [isValidemail, setIsValidemail] = useState(false);
    const refTEL1 = useRef(null);
    const [valueTEL1, setvalueTEL1] = useState('');
    const [valueContractNumber, setvalueContractNumber] = useState('');
    const refContractNumber = useRef(null);

    // Function to check if all characters in the string are the same
    const isAllSameDigits = (number) => {
        return /^(\d)\1*$/.test(number);
    };


    const refTEL2 = useRef(null);
    const [valueTEL2, setvalueTEL2] = useState('');

    useEffect(() => {


        if (isEmail(valueemail) || valueemail === '' || !valueemail) {
            setIsValidemail(true);
            //console.log(valueemail+"   yes email");            
        } else {
            setIsValidemail(false);
            //console.log(valueemail+"   not email");                         
        }


    }, [valueemail]);

    const [isCheckedPresume, setIsCheckedPresume] = useState(false);

    // Handle checkbox change
    const handleCheckboxChangePresume = (event) => {
        setIsCheckedPresume(event.target.checked);
    };

    const [age, setAge] = useState(0);
    let ageMinNum = isNaN(Number(AgeMin)) ? 0 : Number(AgeMin);
    let ageMaxNum = isNaN(Number(AgeMAx)) ? 0 : Number(AgeMAx);

    const [valueDateNais_year, setvalueDateNais_year] = useState("0");
    const [DateD, setDateD] = useState(new Date());
    useEffect(() => {

        // Check if DateD is a non-empty string
        const isValidDateD = typeof DateD === 'string' && DateD.trim() !== '';

        // Convert DateD to a Date object if valid, otherwise use the current date
        const currentDate = isValidDateD ? new Date(DateD) : new Date();

        // Ensure currentDate is a valid Date
        const isValidDate = !isNaN(currentDate.getTime());
        const finalDate = isValidDate ? currentDate : new Date();






        //alert(finalDate);

        if (valueDateNais) {
            const birthDate = new Date(valueDateNais);
            const valueDateNais_year = birthDate.getFullYear().toString();
            setvalueDateNais_year(valueDateNais_year);

            let calculatedAge = finalDate.getFullYear() - birthDate.getFullYear();
            const monthDiff = finalDate.getMonth() - birthDate.getMonth();
            const dateDiff = finalDate.getDate() - birthDate.getDate();

            // Adjust age if the birth month hasn't occurred yet this year or it's the current month but the birth day hasn't occurred yet
            if (monthDiff < 0 || (monthDiff === 0 && dateDiff < 0)) {
                calculatedAge--;
            }

            setAge(calculatedAge);
        } else {
            setAge(null);
        }
    }, [valueDateNais, DateD]);

    const handleChangeemail = event => {
        const val = event.target.value;
        setValueemail(val);

        /////handleChangeemail(val, isValidemail);
    }



    const handleInput_ContractNumber = (event) => {
        let inputValue = event.target.value;

        // Remove any non-digit characters
        inputValue = inputValue.replace(/\D/g, '');

        // Ensure the input is at most 5 digits long
        if (inputValue.length <= 5) {
            setvalueContractNumber(inputValue);
        }
    };

    // State for Checkbox
    const [checkedtel, setCheckedtel] = useState(false);

    // Handle Checkbox Change
    const handleCheckboxChangetel = (event) => {

        setCheckedtel(event.target.checked);
        setvalueTEL1('');
    };


    const handleInput_TEL1 = (event) => {
        let inputValue = event.target.value;
        if (!checkedtel) {
            inputValue = inputValue === '' ? '0' : (inputValue.startsWith('0') ? inputValue : '0' + inputValue);

            if ((NumbersOnlyRegex.test(inputValue) || inputValue === '') && inputValue.length <= 10) {
                setvalueTEL1(inputValue);
            }
        }
        else {

            // International number logic
            inputValue = inputValue === '' ? '+' : (inputValue.startsWith('+') ? inputValue : '+' + inputValue);

            // Update the regex to allow only numbers and ensure it starts with '+'
            const InternationalNumbersOnlyRegex = /^\+\d*$/;

            // Ensure the input does not exceed 16 characters (1 for "+" and up to 15 digits)
            if (InternationalNumbersOnlyRegex.test(inputValue) && inputValue.length <= 16) {
                setvalueTEL1(inputValue);
            }


        }

    };


    const handleInput_TEL2 = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue === '' ? '0' : (inputValue.startsWith('0') ? inputValue : '0' + inputValue);

        if ((NumbersOnlyRegex.test(inputValue) || inputValue === '') && inputValue.length <= 10) {
            setvalueTEL2(inputValue);
        }
    };



    const refbtnsubmit = useRef(null);
    const refpassword1 = useRef(null);
    const refpassword2 = useRef(null);
    const refpasswordnow = useRef(null);
    const refAdres = useRef(null);
    const refAdresFr = useRef(null);



    const [valuesPassword, setValuesPassword] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const [valuesPasswordRe, setValuesPasswordRe] = React.useState({
        amount: '',
        PasswordRe: '',
        weight: '',
        weightRange: '',
        showPasswordRe: false,
    });

    const [valuespasswordnow, setValuespasswordnow] = React.useState({
        amount: '',
        passwordnow: '',
        weight: '',
        weightRange: '',
        showpasswordnow: false,
    });


    const handleChangePasswordRe = (prop) => (event) => {
        setValuesPasswordRe({ ...valuesPasswordRe, [prop]: event.target.value });
    };

    const handleChangepasswordnow = (prop) => (event) => {
        setValuespasswordnow({ ...valuespasswordnow, [prop]: event.target.value });
    };

    const handleClickShowPasswordRe = () => {
        setValuesPasswordRe({
            ...valuesPasswordRe,
            showPasswordRe: !valuesPasswordRe.showPasswordRe,
        });
    };

    const handleClickShowpasswordnow = () => {
        setValuespasswordnow({
            ...valuespasswordnow,
            showpasswordnow: !valuespasswordnow.showpasswordnow,
        });
    };

    const handleMouseDownPasswordRe = (event) => {
        event.preventDefault();
    };

    const handleMouseDownpasswordnow = (event) => {
        event.preventDefault();
    };


    const handleChangePassword = (prop) => (event) => {
        setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValuesPassword({
            ...valuesPassword,
            showPassword: !valuesPassword.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const ContractNumberRegex = /^\d{5}$/;

    const navigate = useNavigate();
    const { IDOffre } = useParams();
    const { IDsession_selected } = useParams();
    const { IDDFEP_selected } = useParams();
    const { IDCycle_scol_selected } = useParams();
    const { NumOrd_selected } = useParams();


    const apiUrl_session = `${API_BASE_URL}/api/getsessionEncour`;



    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch data from the API
                const response = await fetch(apiUrl_session);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                if (!data || !data.data || !Array.isArray(data.data)) {
                    throw new Error('Unexpected data format received from API');
                }

                // Process fetched data
                const getsessionEncour = data.data.filter(Wlylist => Wlylist.Nom !== null);

                // Set selected session if available
                if (getsessionEncour.length > 0) {
                    setClouture(getsessionEncour[0].Clouture);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {

            }
        };

        const loadDataFromLocalForage = async () => {
            try {
                // Check if data exists in localforage
                // const localData = await localforage.getItem('sessionEncour');

                // If data doesn't exist in localforage, fetch it
                await fetchData();
            } catch (error) {
                console.error('Error loading data from localforage:', error);

            }
        };

        // Load data from localforage or fetch from API
        loadDataFromLocalForage();

    }, []);

    useEffect(() => {
        let localDatasetsuccess = false;

        // Check if IDOffre exists
        if (isNaN(IDOffre) || isNaN(IDsession_selected) || isNaN(IDDFEP_selected) || isNaN(IDCycle_scol_selected) || isNaN(NumOrd_selected)) {
            // navigate('/Offersinscription');
            //alert(IDCycle_scol_selected);
            return;
        }

        const fetchData = async () => {



            try {
                const localData = await localforage.getItem(`dataOffersinscription_${IDsession_selected}_${IDDFEP_selected}_${IDCycle_scol_selected}_${NumOrd_selected}`);
                if (localData && localData.data && localData.data.length > 0) {
                    const row = localData.data.find(row => row.IDOffre === parseInt(IDOffre, 10));
                    if (row) {

                        setNom_NomFr_session(row.Nom_NomFr_session);
                        setNom_session(row.Nom_NomFr_session);
                        setNom_specialite(row.Nom_NomFr_CodeSpec_NbrSem_Specialite);
                        setNom_etablissement(row.code_Nom_etablissement);
                        setNom_mode_formation(row.Nom_NomFr_mode_formation);
                        setIDMode_formation(row.IDMode_formation);
                        setNom_NomFr_qualification_dplm(row.Nom_NomFr_qualification_dplm);
                        setAbr_AbrFr_qualification_dplm(row.Abr_AbrFr_qualification_dplm);
                        setNom_NomFr_niveau_scol(row.Nom_NomFr_niveau_scol);

                        ageMinNum = isNaN(Number(row.AgeMin)) ? 0 : Number(row.AgeMin);
                        setAgeMin(ageMinNum);


                        setAgeMAx(isNaN(Number(row.AgeMAx)) ? 0 : Number(row.AgeMAx));
                        setIDCycle_scol_db(row.IDCycle_scol);
                        setNumOrd_db(row.NumOrd);
                        /*
                        if(Number(IDCycle_scol_selected)!==Number(row.IDCycle_scol)
                            || Number(NumOrd_selected)!==Number(row.NumOrd) )
                        {
                            alert("IDCycle_scol_selected: "+IDCycle_scol_selected+" row.IDCycle_scol: "+row.IDCycle_scol);
                            alert("NumOrd_selected: "+NumOrd_selected+" row.NumOrd: "+row.NumOrd);
                           //  navigate("/Offersinscription")
                        } 
*/

                        setDateD(row.DateDsession);


                        setloadingdata_mfep(true);
                        localDatasetsuccess = true;
                    }

                }
            } catch (error) {
                console.error('Error fetching data from localForage:', error);
            }


            if (localDatasetsuccess) { return; }


            const url_checkifidoffreexist = `${API_BASE_URL}/api/isIDOffre_exist/${IDOffre}`;

            axios.get(url_checkifidoffreexist)
                .then((response) => {
                    //console.log("Data isIDOffre_exist:", JSON.stringify(response.data));

                    if (response.data && response.data.data && response.data.data.length > 0) {
                        const responseData = response.data.data[0];
                        setNom_NomFr_session(responseData.Nom_NomFr_session);
                        setNom_session(responseData.Nom_session);
                        setNom_specialite(responseData.Nom_NomFr_CodeSpec_NbrSem_Specialite);
                        setNom_etablissement(responseData.code_Nom_etablissement);
                        setNom_mode_formation(responseData.Nom_NomFr_mode_formation);
                        setIDMode_formation(responseData.IDMode_formation);
                        setNom_NomFr_qualification_dplm(responseData.Nom_NomFr_qualification_dplm);
                        setAbr_AbrFr_qualification_dplm(responseData.Abr_AbrFr_qualification_dplm);
                        setNom_NomFr_niveau_scol(responseData.Nom_NomFr_niveau_scol);
                        ageMinNum = isNaN(Number(responseData.AgeMin)) ? 0 : Number(responseData.AgeMin);
                        setAgeMin(ageMinNum);
                        setAgeMAx(isNaN(Number(responseData.AgeMAx)) ? 0 : Number(responseData.AgeMAx));
                        /*
                       if(Number(IDCycle_scol_selected)!==Number(responseData.IDCycle_scol)
                           || Number(NumOrd)!==Number(responseData.NumOrd) )
                       {
                           //alert("IDCycle_scol_selected: "+IDCycle_scol_selected+" NumOrd: "+responseData.IDCycle_scol);
                           //alert("NumOrd_selected: "+NumOrd_selected+" NumOrd: "+responseData.NumOrd);
                            navigate("/Offersinscription")
                       } 
*/
                        setIDCycle_scol_db(responseData.IDCycle_scol);
                        setNumOrd_db(responseData.NumOrd);

                        setClouture(responseData.Clouture);

                        setDateD(responseData.DateDsession);

                        // Set other state variables as needed
                        setloadingdata_mfep(true);
                    } else {
                        // Handle case where data is empty
                        // navigate('/Offersinscription');
                        // return;
                        console.error("No data returned from the server.");
                    }
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                    navigate('/Offersinscription');
                    return;
                });


        };

        fetchData();






    }, [IDOffre, IDsession_selected, IDDFEP_selected, IDCycle_scol_selected, NumOrd_selected]);


    // Regular expression to match the format "YYYY/MM/DD"
    const dateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

    // Regular expression to match the format "YYYY/MM/DD" with digits for years, months, and days
    const fullDateFormatRegex = /^\d{4}\/\d{2}\/\d{2}$/;

    const handleInput_DateNais = (event) => {
        ///alert('inputValue');
        const inputValue = event.target.value;
        setvalueDateNais(inputValue);
    };


    const handleInput_LieuNais = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex_withdash.test(inputValue) || inputValue === '') {
            setvalueLieuNais(inputValue);
        }
    };

    const handleInput_LieuNaisFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex_withdash.test(inputValue) || inputValue === '') {
            setvalueLieuNaisFr(inputValue);
        }
    };

    const handleInput_Nin = (event) => {
        const inputValue = event.target.value;

        if ((NumbersOnlyRegex.test(inputValue) && inputValue.length <= 18) || inputValue === '') {
            setvalueNin(inputValue);
        }
    };

    const handleInput_Nom = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvalueNom(inputValue);
        }
    };


    const handleInput_NomFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvalueNomFr(inputValue);
        }
    };


    const handleInput_Adres = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex_address.test(inputValue) || inputValue === '') {
            setvalueAdres(inputValue);
        }
    };

    const handleInput_PrenomMere = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomMere(inputValue);
        }
    };

    const handleInput_NomMere = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvalueNomMere(inputValue);
        }
    };

    const handleInput_Prenom = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenom(inputValue);
        }
    };

    const handleInput_PrenomPere = (event) => {
        const inputValue = event.target.value;

        if (ArabicCharactersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomPere(inputValue);
        }
    };


    const handleInput_PrenomFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomFr(inputValue);
        }
    };



    const handleInput_AdresFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex_address.test(inputValue) || inputValue === '') {
            setvalueAdresFr(inputValue);
        }
    };



    const handleInput_PrenomMereFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomMereFr(inputValue);
        }
    };

    const handleInput_NomMereFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvalueNomMereFr(inputValue);
        }
    };


    const handleInput_PrenomPereFr = (event) => {
        const inputValue = event.target.value;

        if (LatinLettersRegex.test(inputValue) || inputValue === '') {
            setvaluePrenomPereFr(inputValue);
        }
    };





    const onsubmitclicked = async (e) => {
        e.preventDefault();

        try {
            const data = {
                Nom: valueNom,
                NomFr: valueNomFr,
                Prenom: valuePrenom,
                PrenomFr: valuePrenomFr,
                Password: valuesPassword.password,
                LieuNaisFr: valueLieuNaisFr,
                LieuNais: valueLieuNais,
                PrenomPereFr: valuePrenomPereFr,
                NomMereFr: valueNomMereFr,
                PrenomMereFr: valuePrenomMereFr,
                AdresFr: valueAdresFr,
                PrenomPere: valuePrenomPere,
                NomMere: valueNomMere,
                PrenomMere: valuePrenomMere,
                Adres: valueAdres,
                Civ: Civ,
                //DateNais: dayjs(valueDateNais).format('YYYY-MM-DD'),
                DateNais: valueDateNais ? dayjs(valueDateNais).format('YYYY-MM-DD') : null,
                IDwly_selected: IDwly_selected,
                IDcommun_selected: IDcommun_selected,
                IDOffre: IDOffre,
                valueNin: valueNin,
                valueemail: valueemail,
                valueTEL1: valueTEL1,
                valueTEL2: valueTEL2,
                IDniveau_scolaires: IDniveau_scolaires,
                endicape: endicape,
                NumActeNais: valueContractNumber,
                isCheckedPresume: isCheckedPresume ? "1" : "0",
                valuePresumeDateNais: valuePresumeDateNais,
                premier5: premier5,
                experienceYears: experienceYears

            };

            const responseFromApi = await axios.post(`${API_BASE_URL}/api/insertnewcandidat`, data);

            // Log the entire response data object to the console
            console.log('Response data:', responseFromApi.data);

            // Check if response has data
            if (responseFromApi.data && responseFromApi.data.crypted_id) {
                ///alert(responseFromApi.data.crypted_id);
                setcrypted_id(responseFromApi.data.crypted_id);
                setIDCandidat(responseFromApi.data.IDPreinscrit);
                // Handle successful response
                setOpenDialog(true);
            } else {
                // Handle empty or missing crypted_id in the response
                console.error('Missing crypted_id in response data');
            }


        } catch (error) {
            // Check if error has response
            if (error.response) {
                // Check if error response has data
                if (error.response.data) {
                    // Extract error messages from the response
                    const errorData = error.response.data;
                    let errorMessage = '';

                    if (errorData.errors) {
                        // Extract validation errors
                        const errors = errorData.errors;
                        for (const field in errors) {
                            errorMessage += `${errors[field][0]}\n`;
                        }
                    } else if (errorData.error) {
                        // Handle duplicate data error
                        errorMessage = errorData.error;
                    } else {
                        // Handle other error messages
                        errorMessage = 'حدث خطأ، يرجى المحاولة مرة أخرى ';
                    }

                    // Display error message
                    alert(errorMessage);
                } else {
                    // Handle error without error message
                    alert('حدث خطأ، يرجى المحاولة مرة أخرى.');

                }
            } else {
                // Handle network error or other types of errors
                alert('حدث خطأ. يرجى المحاولة مرة أخرى.');

            }
        }
    };

    const [selectedwlys, setSelectedwlys] = useState(null);
    const [IDwly_selected, setIDwly_selected] = useState(1);
    const [IDcommun_selected, setIDcommun_selected] = useState(0);


    const [options_wly, setoptions_wly] = useState([]);
    let options_bran = [];

    const apiUrl_wly = `${API_BASE_URL}/api/all_wilayas`;
    const apiUrl_niveau_scolaires = `${API_BASE_URL}/api/getniveau_scol`;

    // Fetch data from the API when the component mounts
    useEffect(() => {

        setloadingdata_mfep(true);

        fetch(apiUrl_wly)
            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of objects
                const Wlylists = data.data; // Extracting the relevant data
                ////console.log('Wlylists: '+Wlylists);
                /////////////Wilayets///////////////////

                const WlyOnly = Wlylists.filter(Wlylist => Wlylist.libelleArabe !== null);

                /*
                IDwilayas
                id
                code
                clibelleArabe
                clibelleLatin
                libelleArabe
                libelleLatin

                */

                // Sorting in descending order (from Z to A)
                options_bran = WlyOnly.sort((a, b) => Number(a.IDwilayas) - Number(b.IDwilayas))
                    .map((Wlylist, i) => ({
                        value: Wlylist.IDwilayas,
                        label: Wlylist.code + ' | ' + Wlylist.libelleArabe + ' | ' + Wlylist.libelleLatin,
                        indexvalue: i
                    }));

                setoptions_wly(options_bran);


            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });




        fetch(apiUrl_niveau_scolaires)

            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of objects
                const Wlylists = data.data; // Extracting the relevant data

                /////////////apiUrl_niveau_scolaires///////////////////

                const WlyOnly = Wlylists.filter(Wlylist => Wlylist.libelleArabe !== null);

                /*
         IDNiveau_Scol
IDCycle_scol
NumOrd
Nom
NomFr
Abr
AbrFr
iDMihnati
activee

    
                */

                // Sorting in descending order (from Z to A)
                optio_niveau_scolaires = WlyOnly.sort((a, b) => Number(b.libelleArabe) - Number(a.libelleArabe))
                    .map((Wlylist, i) => ({
                        value: Wlylist.IDNiveau_Scol,
                        label: Wlylist.Nom + ' | ' + Wlylist.NomFr,
                        indexvalue: i,
                        IDCycle_scol: Wlylist.IDCycle_scol,
                        NumOrd: Wlylist.NumOrd,
                        Nom: Wlylist.Nom,
                        NomFr: Wlylist.NomFr
                    }));

                ///// options_niveau_scolaires.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

                setoptions_niveau_scolaires(optio_niveau_scolaires);

                ///console.log('optio_niveau_scolaires[0].value:' + optio_niveau_scolaires[0].value);
                setloadingdata_mfep(false);

            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });


    }, []);



    const handleniveau_scolairesClick = (event, values) => {

        if (options_niveau_scolaires.length > 0) {
            return;
        }

        setloadingdata_mfep(true);


        fetch(apiUrl_niveau_scolaires)

            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of objects
                const Wlylists = data.data; // Extracting the relevant data

                /////////////apiUrl_niveau_scolaires///////////////////

                const WlyOnly = Wlylists.filter(Wlylist => Wlylist.libelleArabe !== null);

                /*
         IDNiveau_Scol
    IDCycle_scol
    NumOrd
    Nom
    NomFr
    Abr
    AbrFr
    iDMihnati
    activee
    
    
                */

                // Sorting in descending order (from Z to A)
                optio_niveau_scolaires = WlyOnly.sort((a, b) => Number(b.libelleArabe) - Number(a.libelleArabe))
                    .map((Wlylist, i) => ({
                        value: Wlylist.IDNiveau_Scol,
                        label: Wlylist.Nom + ' | ' + Wlylist.NomFr,
                        indexvalue: i,
                        IDCycle_scol: Wlylist.IDCycle_scol,
                        NumOrd: Wlylist.NumOrd
                    }));

                ///// options_niveau_scolaires.unshift({ value: 0, label: 'كل الدورات', indexvalue: -1 });

                setoptions_niveau_scolaires(optio_niveau_scolaires);

                ///console.log('optio_niveau_scolaires[0].value:' + optio_niveau_scolaires[0].value);
                setloadingdata_mfep(false);

            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });



    }








    const handleonclick_wilylist = (event, values) => {

        if (options_wly.length > 0) {
            return;
        }

        setloadingdata_mfep(true);

        fetch(apiUrl_wly)
            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of objects
                const Wlylists = data.data; // Extracting the relevant data
                ////console.log('Wlylists: '+Wlylists);
                /////////////Wilayets///////////////////

                const WlyOnly = Wlylists.filter(Wlylist => Wlylist.libelleArabe !== null);

                /*
                IDwilayas
                id
                code
                clibelleArabe
                clibelleLatin
                libelleArabe
                libelleLatin
    
                */

                // Sorting in descending order (from Z to A)
                options_bran = WlyOnly.sort((a, b) => Number(a.IDwilayas) - Number(b.IDwilayas))
                    .map((Wlylist, i) => ({
                        value: Wlylist.IDwilayas,
                        label: Wlylist.code + ' | ' + Wlylist.libelleArabe + ' | ' + Wlylist.libelleLatin,
                        indexvalue: i
                    }));

                setoptions_wly(options_bran);


            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });



    }






    useEffect(() => {
        if (options_wly && options_wly.length > 0) {
            setSelectedwlys(options_wly[0]);
            setIDwly_selected(options_wly[0].value);
        }

    }, [options_wly]);



    const [selectedcommuns, setSelectedcommuns] = useState(null);
    const [options_commun, setoptions_commun] = useState([]);
    let options_commu = [];


    useEffect(() => {
        if (options_commun && options_commun.length > 0) {
            setSelectedcommuns(options_commun[0]);
            setIDcommun_selected(options_commun[0].value);
            /// alert(options_commun[0].value);
        }

    }, [options_commun]);




    // Fetch data from the API when the component mounts
    useEffect(() => {
        if (!IDwly_selected || isNaN(IDwly_selected) || IDwly_selected <= 0) {
            return;
        }

        const apiUrl_commun = `${API_BASE_URL}/api/all_Communn/${IDwly_selected}`;

        setloadingdata_mfep(true);

        fetch(apiUrl_commun)
            .then(response => response.json())
            .then(data => {
                // Assuming 'data' is an array of objects
                const communlists = data.data; // Extracting the relevant data
                ///console.log('communlists: ' + communlists);

                const communOnly = communlists.filter(communlist => communlist.Nom !== null);

                /*
               IDCommunn
                IDWilayaa
                Nom
                NomFr
                Code
                CodeNouv
                NomEtat
                CODE_GEO


                */

                // Sorting in descending order (from Z to A)
                options_commu = communOnly.sort((a, b) => Number(a.IDCommunn) - Number(b.IDCommunn))
                    .map((communlist, i) => {
                        let label;
                        if (communlist.NomFr && communlist.NomFr.trim() !== '') {
                            label = `${communlist.Nom} | ${communlist.NomFr}`;
                        } else {
                            label = communlist.Nom;
                        }
                        return {
                            value: communlist.IDCommunn,
                            label: label,
                            indexvalue: i
                        };
                    });



                setoptions_commun(options_commu);

            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });




    }, [IDwly_selected]);



    const useStyles_listdfep = makeStyles((theme) => ({
        root: {
            "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                // Default transform is "translate(14px, 20px) scale(1)""
                // This lines up the label with the initial cursor position in the input
                // after changing its padding-left.
                transform: "translate(34px, 20px) scale(1);",
                fontFamily:
                    '"Tajawal", sans-serif',
                fontSize: 18,
                direction: "rtl"
            }
        },
        inputRoot: {

            color: "#004d40",


            // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                fontFamily: '"Tajawal", sans-serif',
                fontWeight: 700,
                color: "#004d40",
                // Default left padding is 6px
                paddingLeft: 26
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "2px",
                borderColor: "#004d40"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderWidth: "2px",
                borderColor: "#00796b"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "2px",
                borderColor: "#004d00"
            }
        }
    }));

    const classes_listdfep = useStyles_listdfep();




    const useStyles = makeStyles({
        cancelBtn: {
            backgroundColor: "#004d40", // أخضر
            color: "#fff",
            "&:hover": {
                backgroundColor: "#388e3c",
            },
        },
        yesBtn: {
            backgroundColor: "#f44336", // أحمر
            color: "#fff",
            "&:hover": {
                backgroundColor: "#d32f2f",
            },
        },
    });

    const classes = useStyles();


    return (
        <>
            {loadingdata_mfep ? <>

                <Box sx={{ width: '100%' }}>
                    <LinearProgress color="success" />
                </Box>

            </> : null}








            <Header />
            <div >

                <form>
                    <Container
                    >
                        <Grid  >


                            <Box
                                sx={{
                                    marginTop: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',

                                }}
                            >

                                <Typography
                                    sx={{
                                        color: "#eff0f4", // Text color
                                        fontFamily: '"Tajawal", sans-serif', // Font family
                                        fontWeight: 900, // Font weight
                                        fontSize: 20, // Font size
                                        backgroundColor: "#004d40", // Green background color
                                        border: "4px solid #e0e4e875", // Border
                                        padding: "10px", // Padding
                                        paddingLeft: "20px",
                                    }}
                                >

                                    <DescriptionIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> إستمارة التسجيل ( {Nom_session} )

                                </Typography>

                                {Clouture === 1 ? (
                                    <>

                                        <Grid item>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',


                                                    borderRadius: '5px',
                                                    marginBottom: '5px'
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        color: "red",
                                                        backgroundColor: '#ffcccc',
                                                        padding: '10px',
                                                        fontFamily: '"Tajawal", sans-serif',
                                                        fontSize: 18,
                                                        fontWeight: 700
                                                    }}
                                                    variant="h3"
                                                    gutterBottom
                                                >
                                                    التسجيلات متوقفة حالياً
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </>
                                ) : null}

                                {!Nom_etablissement ? <>

                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgress color="success" />
                                    </Box>

                                </> :

                                    <Typography
                                        sx={{
                                            mt: 2,
                                            color: '#004d40', // Text color
                                            fontFamily: '"Tajawal", sans-serif', // Font family
                                            fontWeight: 900, // Font weight
                                            fontSize: 16, // Font size
                                            padding: "4px",
                                            direction: 'rtl',
                                            textAlign: 'right'
                                        }}
                                    >
                                        <CheckCircleIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> {Nom_etablissement}
                                        <br />
                                        <CheckCircleIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> {Nom_mode_formation}
                                        <br />
                                        <CheckCircleIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> {Nom_specialite}
                                        {Nom_NomFr_qualification_dplm && (
                                            <>
                                                <br />
                                                <CheckCircleIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> {Nom_NomFr_qualification_dplm}

                                            </>

                                        )}

                                        {Nom_NomFr_niveau_scol && (<>
                                            <br />
                                            <CheckCircleIcon style={{ marginRight: '5px', marginBottom: '-6px' }} /> {Nom_NomFr_niveau_scol}

                                        </>

                                        )}



                                        <br />
                                        <br />
                                    </Typography>

                                }
                            </Box>

                            {Number(IDMode_formation) === 10 ? (
                                <Grid container justifyContent="center" sx={{ mt: 1 }}>
                                    <Grid item xs={isMatch ? 12 : 6} sx={{ textAlign: 'center' }}>
                                        <Button
                                            onClick={handleOpenPdf}
                                            variant="contained"
                                            endIcon={
                                                <PictureAsPdfIcon
                                                    sx={{
                                                        mr: "2px", // Space between icon and text
                                                        ml: "2px",
                                                        transform: "rotate(0deg)"
                                                    }}
                                                />
                                            }
                                            sx={{
                                                backgroundColor: '#00695c', // Green color
                                                '&:hover': {
                                                    backgroundColor: '#004d40', // Darker green on hover
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#FFFFFF",
                                                    fontFamily: '"Tajawal", sans-serif',
                                                    fontSize: 12,
                                                    fontWeight: 900,
                                                    ml: 1, // Add margin-right to create space between text and icon
                                                }}
                                            >
                                                حمل عقد التمهين (فارغ)
                                            </Typography>
                                        </Button>

                                    </Grid>
                                </Grid>
                            ) : (
                                <></>
                            )}



                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1, mb: 1 }} justifyContent="center" >




                                <Grid item xs={isMatch ? 12 : 6} >

                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth>
                                                        <Select
                                                            id="endicape-simple-select"
                                                            value={endicape}
                                                            onChange={handleChange_endicape}
                                                            displayEmpty
                                                            renderValue={(selected) => {
                                                                if (selected === "") {
                                                                    return (
                                                                        <span
                                                                            style={{
                                                                                fontFamily: '"Tajawal", sans-serif',
                                                                                fontSize: 18,
                                                                                fontWeight: 700,
                                                                                color: '#9e9e9e', // Placeholder color
                                                                                textAlign: 'right',
                                                                                direction: 'rtl',
                                                                            }}
                                                                        >
                                                                            اختر حالتك الصحية
                                                                        </span>
                                                                    );
                                                                }
                                                                return selected === 0
                                                                    ? "لست من ذوي الإحتياجات الخاصة"
                                                                    : "أنا من ذوي الاحتياجات الخاصة";
                                                            }}
                                                            className="select-endicape"
                                                        >
                                                            <MenuItem
                                                                style={{
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: 18,
                                                                    fontWeight: 700,
                                                                    color: '#004d40',
                                                                    textAlign: 'right',
                                                                    direction: 'rtl',
                                                                    backgroundColor: '#f2f9f2',
                                                                }}
                                                                dir="rtl"
                                                                value={0}
                                                            >
                                                                لست من ذوي الإحتياجات الخاصة
                                                            </MenuItem>
                                                            <MenuItem
                                                                style={{
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: 18,
                                                                    fontWeight: 700,
                                                                    color: '#004d40',
                                                                    textAlign: 'right',
                                                                    direction: 'rtl',
                                                                    backgroundColor: '#FFFFFF',
                                                                }}
                                                                dir="rtl"
                                                                value={1}
                                                            >
                                                                أنا من ذوي الاحتياجات الخاصة
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>


                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>

                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1, mb: 2 }} justifyContent="center" >


                                <Grid item xs={isMatch ? 12 : 6}  >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <Autocomplete
                                                        noOptionsText={'المستوي غير موجود'}
                                                        classes={classes_listdfep}
                                                        id="combo-box-niveau_scolaires"
                                                        value={selectedniveau_scolaires}
                                                        options={options_niveau_scolaires}
                                                        onChange={handleniveau_scolairesChange}
                                                        renderOption={(props, option, index) => {
                                                            const { label } = option;
                                                            count_nivscol++;
                                                            return (
                                                                <span
                                                                    key={count_nivscol}  // Use count_nivscol as the key
                                                                    {...props}
                                                                    style={{
                                                                        backgroundColor: count_nivscol % 2 === 0 ? '#f2f9f2' : '#FFFFFF',
                                                                        color: count_nivscol % 2 === 0 ? '#004d40' : '#004d40',
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: 18,
                                                                        fontWeight: 700,
                                                                        textAlign: "right",
                                                                        direction: 'rtl'
                                                                    }}
                                                                    dir="ltr"
                                                                >
                                                                    {count_nivscol === -1 ? '- ' + label : label}
                                                                </span>
                                                            );
                                                        }}

                                                        renderInput={(params) => (

                                                            <TextField
                                                                {...params}

                                                                placeholder="المستوى الدراسي"
                                                                onClick={handleniveau_scolairesClick}

                                                                error={
                                                                    endicape === 1
                                                                        ? !selectedniveau_scolaires
                                                                        : (!selectedniveau_scolaires ||
                                                                            (Number(IDCycle_scol) < Number(IDCycle_scol_db)) ||
                                                                            (Number(IDCycle_scol) === Number(IDCycle_scol_db) && Number(NumOrd) < Number(NumOrd_db)))
                                                                }
                                                                helperText={
                                                                    endicape === 1
                                                                        ? !selectedniveau_scolaires
                                                                            ? 'إختر المستوى الدراسي'
                                                                            : ''
                                                                        : !selectedniveau_scolaires
                                                                            ? 'إختر المستوى الدراسي'
                                                                            : (Number(IDCycle_scol) < Number(IDCycle_scol_db) ||
                                                                                (Number(IDCycle_scol) === Number(IDCycle_scol_db) && Number(NumOrd) < Number(NumOrd_db)))
                                                                                ? 'غير مسموح لك التسجيل في هذا العرض'
                                                                                : ''
                                                                }


                                                                tabIndex={1}
                                                                dir="rtl" // Add dir="rtl" here
                                                                inputRef={Refselectedniveau_scolaires}
                                                                fullWidth
                                                                sx={{

                                                                    '& .MuiFormHelperText-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '14px',
                                                                        color: 'red',
                                                                        fontWeight: 700
                                                                    },

                                                                    '& .MuiFormLabel-root': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        color: '#004d40'
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '18px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& label.Mui-focused': {
                                                                        fontSize: '16px',
                                                                        color: '#004d40',
                                                                        fontWeight: 700
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#004d40',
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: '16px',
                                                                            borderColor: '#004d40',
                                                                            borderWidth: "2px",
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: '#004d40',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: '#004d40',
                                                                        },
                                                                    }
                                                                }}
                                                            />


                                                        )}



                                                    />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>





                            </Grid>



                            {Number(IDMode_formation) === 7 ? (
                                <>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>


                                        <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }} >

                                            <CacheProvider value={cacheRtl}>
                                                <ThemeProvider theme={themetextfieldnew}>
                                                    <Box>
                                                        <div dir="rtl">
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    id="premier5-simple-select"
                                                                    value={premier5}
                                                                    onChange={handleChange_premier5}
                                                                    displayEmpty
                                                                    renderValue={(selected) => {
                                                                        if (selected === "") {
                                                                            return (
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                                        fontSize: 18,
                                                                                        fontWeight: 700,
                                                                                        color: '#9e9e9e', // Placeholder color
                                                                                        textAlign: 'right',
                                                                                        direction: 'rtl',
                                                                                    }}
                                                                                >
                                                                                    هل كنت من بين الخمسة (05) الأوائل في الدفعة السابقة؟
                                                                                </span>
                                                                            );
                                                                        }
                                                                        return selected === 0
                                                                            ? "لست من بين الخمسة (05) الأوائل في الدفعة."
                                                                            : "نعم، أنا من بين الخمسة (05) الأوائل.";
                                                                    }}
                                                                    className="select-endicape"
                                                                >
                                                                    <MenuItem
                                                                        style={{
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: 18,
                                                                            fontWeight: 700,
                                                                            color: '#004d40',
                                                                            textAlign: 'right',
                                                                            direction: 'rtl',
                                                                            backgroundColor: '#f2f9f2',
                                                                        }}
                                                                        dir="rtl"
                                                                        value={0}
                                                                    >
                                                                        لست من بين الخمسة (05) الأوائل في الدفعة.
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        style={{
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: 18,
                                                                            fontWeight: 700,
                                                                            color: '#004d40',
                                                                            textAlign: 'right',
                                                                            direction: 'rtl',
                                                                            backgroundColor: '#FFFFFF',
                                                                        }}
                                                                        dir="rtl"
                                                                        value={1}
                                                                    >
                                                                        نعم، أنا من بين الخمسة (05) الأوائل في الدفعة.
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </Box>
                                                </ThemeProvider>
                                            </CacheProvider>

                                        </Grid>

                                        <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }} >
                                            <CacheProvider value={cacheRtl}>
                                                <ThemeProvider theme={themetextfieldnew}>
                                                    <Box>
                                                        <div dir="rtl">
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="experience-years-input"

                                                                    variant="outlined"
                                                                    type="number"
                                                                    value={experienceYears}
                                                                    onChange={(e) => {
                                                                        const value = parseInt(e.target.value, 10);
                                                                        if (value >= 0 && value <= 99) {
                                                                            setExperienceYears(value);
                                                                        } else if (e.target.value === "") {
                                                                            setExperienceYears("");
                                                                        }
                                                                    }}
                                                                    sx={{
                                                                        width: '100%', // Ensure the text field takes up full width
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: '#004d40', // Border color
                                                                                borderWidth: '2px', // Border width
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: '#004d40', // Border color on hover
                                                                                borderWidth: '2px', // Border width
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: '#004d40', // Border color when focused
                                                                                borderWidth: '2px', // Border width
                                                                            },
                                                                        },
                                                                    }}
                                                                    inputProps={{
                                                                        min: 0,
                                                                        max: 99,
                                                                        style: {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: 18,
                                                                            fontWeight: 700,
                                                                            textAlign: 'right',
                                                                            direction: 'rtl',
                                                                            color: '#004d40',
                                                                        },
                                                                    }}
                                                                    InputLabelProps={{
                                                                        style: {
                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                            fontSize: 14,
                                                                            fontWeight: 700,
                                                                            textAlign: 'right',
                                                                            direction: 'rtl',
                                                                            color: '#004d40',
                                                                            maxWidth: '100%', // Ensures label does not exceed the width of the container
                                                                            whiteSpace: 'normal', // Allows the label to wrap onto multiple lines if needed
                                                                            overflow: 'hidden', // Hides any overflowed text
                                                                        },
                                                                    }}
                                                                    placeholder="عدد سنوات الخبرة بالشهادة"
                                                                />


                                                            </FormControl>
                                                        </div>
                                                    </Box>
                                                </ThemeProvider>
                                            </CacheProvider>
                                        </Grid>


                                    </Grid>

                                </>
                            ) : (
                                <></>
                            )}



                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: '4px' }}>

                                {/* اللقب بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueNom"
                                                        value={valueNom}
                                                        inputRef={refNom}
                                                        onInput={handleInput_Nom}
                                                        helperText={
                                                            !ArabicCharactersRegex.test(valueNom)
                                                                ? 'اللقب (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 1 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNom`}

                                                        label="اللقب (ع)"
                                                        placeholder="اللقب (ع)"
                                                        variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>



                                {/* اللقب باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valueNomFr"
                                                        value={valueNomFr}
                                                        inputRef={refNomFr}
                                                        onInput={handleInput_NomFr}
                                                        helperText={
                                                            !LatinLettersRegex.test(valueNomFr)
                                                                ? 'اللقب (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 2 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueNomFr" label="اللقب (ف)" placeholder="اللقب (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>

                            </Grid>




                            <Grid container spacing={2} sx={{ mt: '4px' }}>

                                {/* الاسم بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenom"
                                                        value={valuePrenom}
                                                        inputRef={refPrenom}
                                                        onInput={handleInput_Prenom}
                                                        helperText={
                                                            !ArabicCharactersRegex.test(valuePrenom)
                                                                ? 'الإسم (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 3 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenom" label="الإسم (ع)" placeholder="الإسم (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>





                                {/* الإسم باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenomFr"
                                                        value={valuePrenomFr}
                                                        inputRef={refPrenomFr}
                                                        onInput={handleInput_PrenomFr}
                                                        helperText={
                                                            !LatinLettersRegex.test(valuePrenomFr)
                                                                ? 'الإسم (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 4 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenomFr" label="الإسم (ف)" placeholder="الإسم (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>





                            </Grid>

                            <Grid container spacing={2} sx={{ mt: '4px' }}>

                                {/* النوع */}
                                <Grid item xs={isMatch ? 12 : 2} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">

                                                    <FormControl fullWidth>
                                                        <InputLabel
                                                            sx={{
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                transition: 'color 0.3s ease',
                                                                '&:hover, &.Mui-focused': {
                                                                    color: '#004d40',
                                                                }
                                                            }}
                                                            id="Civ-simple-select-label"
                                                        >
                                                            النوع
                                                        </InputLabel>

                                                        <Select
                                                            labelId="Civ-simple-select-label"
                                                            id="Civ-simple-select"
                                                            value={Civ}
                                                            label="Civ"
                                                            onChange={handleChange_Civ}
                                                            sx={{
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: 'red',
                                                                    fontWeight: 700,
                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    '&:hover': {
                                                                        color: '#004d40',
                                                                    },
                                                                    '&.Mui-focused': {
                                                                        color: '#81c784',
                                                                    },
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700,
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#004d40', // Border color
                                                                        borderWidth: '2px', // Border width
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#004d40', // Border color on hover
                                                                        borderWidth: '2px', // Border width on hover
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#004d40', // Border color when focused
                                                                        borderWidth: '2px', // Border width when focused
                                                                    },
                                                                },
                                                                '& .MuiSelect-select': {
                                                                    borderColor: '#004d40', // Optional, usually not needed
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem
                                                                style={{
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    fontWeight: 700,
                                                                    color: '#004d40',
                                                                    textAlign: 'right',
                                                                    direction: 'rtl',
                                                                    backgroundColor: '#f2f9f2',
                                                                }}
                                                                dir="rtl"
                                                                value={1}
                                                            >
                                                                ذكر
                                                            </MenuItem>
                                                            <MenuItem
                                                                style={{
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    fontWeight: 700,
                                                                    color: '#004d40',
                                                                    textAlign: 'right',
                                                                    direction: 'rtl',
                                                                    backgroundColor: '#FFFFFF',
                                                                }}
                                                                dir="rtl"
                                                                value={2}
                                                            >
                                                                أنثى
                                                            </MenuItem>
                                                        </Select>




                                                    </FormControl>


                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>

                                </Grid>

                                <Grid item xs={isMatch ? 12 : 4}   >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">




                                                    {/* Conditional Rendering based on Checkbox */}
                                                    {!isCheckedPresume ? (
                                                        <>

                                                            {/*  تاريخ الميلاد */}



                                                            <Box display="flex" alignItems="center" sx={{ mt: '-8px' }}>
                                                                <Box flex="1">
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DemoContainer components={['DatePicker']}>
                                                                            <DatePicker
                                                                                ref={refvalueDateNais}
                                                                                name="valueDateNais"
                                                                                value={valueDateNais}
                                                                                slotProps={{
                                                                                    /*
                                                                                     // Calculate age
                                                                                const currentDate = new Date();
                                                                                const birthDate = new Date(valueDateNais);
                                                                                const age = currentDate.getFullYear() - birthDate.getFullYear();
                                                    
                                                                                    */
                                                                                    textField: {
                                                                                        error: (!valueDateNais && !isCheckedPresume) || (endicape !== 1 && AgeMin !== 0 && (Number(age) < AgeMin || (AgeMAx > AgeMin && Number(age) > AgeMAx))),
                                                                                        helperText: (() => {
                                                                                            const ageNum = Number(age);

                                                                                            if ((!valueDateNais && !isCheckedPresume)) {
                                                                                                return 'أدخل تاريخ الميلاد';
                                                                                            } else if (endicape === 1) {
                                                                                                return '';
                                                                                            } else if (AgeMin === 0) {
                                                                                                return '';
                                                                                            } else if (ageNum < AgeMin) {
                                                                                                return `يجب أن يكون على الأقل ${AgeMin} عامًا`;
                                                                                            } else if (ageNum > AgeMAx && AgeMAx > AgeMin) {
                                                                                                return `يجب ان يكون أكبر من ${AgeMin} عاما و أقل من ${AgeMAx} عاما`;
                                                                                            } else {
                                                                                                return '';
                                                                                            }
                                                                                        })(),
                                                                                    }


                                                                                }}


                                                                                inputRef={refDateNais}

                                                                                onChange={(newValue) => setvalueDateNais(newValue)}
                                                                                format="YYYY/MM/DD" // Set the desired format here
                                                                                placeholder="YYYY/MM/DD"

                                                                                label=" تاريخ ميلاد "



                                                                                sx={{

                                                                                    '& .MuiFormHelperText-root': {
                                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                                        fontSize: '14px',
                                                                                        color: 'red',
                                                                                        fontWeight: 700
                                                                                    },

                                                                                    '& .MuiFormLabel-root': {
                                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                                        fontSize: '16px',
                                                                                        color: '#004d40'
                                                                                    },
                                                                                    '& .MuiInputBase-input': {
                                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                                        fontSize: '18px',
                                                                                        color: '#004d40',
                                                                                        fontWeight: 700
                                                                                    },
                                                                                    '& label.Mui-focused': {
                                                                                        fontSize: '16px',
                                                                                        color: '#004d40',
                                                                                        fontWeight: 700
                                                                                    },
                                                                                    '& .MuiInput-underline:after': {
                                                                                        borderBottomColor: '#004d40',
                                                                                    },
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            fontFamily: '"Tajawal", sans-serif',
                                                                                            fontSize: '16px',
                                                                                            borderColor: '#004d40',
                                                                                            borderWidth: "2px",
                                                                                        },
                                                                                        '&:hover fieldset': {
                                                                                            borderColor: '#004d40',
                                                                                        },
                                                                                        '&.Mui-focused fieldset': {
                                                                                            borderColor: '#004d40',
                                                                                        },
                                                                                    }
                                                                                }}

                                                                            />


                                                                        </DemoContainer>
                                                                    </LocalizationProvider>
                                                                </Box>
                                                                <Box display="flex" alignItems="center" ml={1}>
                                                                    {/* Checkbox */}
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={isCheckedPresume} onChange={handleCheckboxChangePresume} />}
                                                                        label="مفترض"
                                                                        sx={{
                                                                            '& .MuiFormControlLabel-label': {
                                                                                fontFamily: '"Tajawal", sans-serif',
                                                                                fontSize: '16px',
                                                                                color: '#004d40',
                                                                                fontWeight: 700,
                                                                            },
                                                                            '& .MuiCheckbox-root': {
                                                                                color: '#004d40',
                                                                                '&.Mui-checked': {
                                                                                    color: '#004d40',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Box>

                                                        </>
                                                    ) : (
                                                        <>

                                                            <Box display="flex" alignItems="center">
                                                                <Box flex="1">

                                                                    <TextField

                                                                        fullWidth
                                                                        name="valuePresumeDateNais"
                                                                        value={valuePresumeDateNais}
                                                                        inputRef={refvaluePresumeDateNais}
                                                                        onInput={handleInput_valuePresumeDateNais}
                                                                        helperText={

                                                                            'تاريخ الميلاد، مفترض.'

                                                                        }

                                                                        InputProps={{
                                                                            inputProps: { tabIndex: 17 },
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <CalendarMonthIcon />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}


                                                                        sx={{

                                                                            '& .MuiFormHelperText-root': {
                                                                                fontFamily: '"Tajawal", sans-serif',
                                                                                fontSize: '14px',
                                                                                color: 'red',
                                                                                fontWeight: 700
                                                                            },

                                                                            '& .MuiFormLabel-root': {
                                                                                fontFamily: '"Tajawal", sans-serif',
                                                                                fontSize: '16px',
                                                                                color: '#004d40'
                                                                            },
                                                                            '& .MuiInputBase-input': {
                                                                                fontFamily: '"Tajawal", sans-serif',
                                                                                fontSize: '18px',
                                                                                color: '#004d40',
                                                                                fontWeight: 700
                                                                            },
                                                                            '& label.Mui-focused': {
                                                                                fontSize: '16px',
                                                                                color: '#004d40',
                                                                                fontWeight: 700
                                                                            },
                                                                            '& .MuiInput-underline:after': {
                                                                                borderBottomColor: '#004d40',
                                                                            },
                                                                            '& .MuiOutlinedInput-root': {
                                                                                '& fieldset': {
                                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                                    fontSize: '16px',
                                                                                    borderColor: '#004d40',
                                                                                    borderWidth: "2px",
                                                                                },
                                                                                '&:hover fieldset': {
                                                                                    borderColor: "#00796b",
                                                                                },
                                                                                '&.Mui-focused fieldset': {
                                                                                    borderColor: "#00796b",
                                                                                },
                                                                            }
                                                                        }}
                                                                        id="outlined-basic-valueNomMere" label="تاريخ الميلاد، مفترض." placeholder="تاريخ الميلاد، مفترض." variant="outlined" />

                                                                </Box>
                                                                <Box display="flex" alignItems="center" ml={2}>
                                                                    {/* Checkbox */}
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={isCheckedPresume} onChange={handleCheckboxChangePresume} />}
                                                                        label="مفترض"
                                                                        sx={{
                                                                            '& .MuiFormControlLabel-label': {
                                                                                fontFamily: '"Tajawal", sans-serif',
                                                                                fontSize: '16px',
                                                                                color: '#004d40',
                                                                                fontWeight: 700,
                                                                            },
                                                                            '& .MuiCheckbox-root': {
                                                                                color: '#004d40',
                                                                                '&.Mui-checked': {
                                                                                    color: '#004d40',
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Box>


                                                        </>
                                                    )}


                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>

                                <Grid item xs={isMatch ? 12 : 3} >

                                    <Autocomplete
                                        noOptionsText={'حدث الصفحة مرة أخرى'}
                                        classes={classes_listdfep}
                                        id="combo-box-demowly"
                                        value={selectedwlys}
                                        options={options_wly}
                                        defaultValue={options_wly[0]}
                                        onChange={handlewlyChange}

                                        renderOption={(props, option) => {
                                            const { label, indexvalue } = option;
                                            return (
                                                <span
                                                    {...props}
                                                    style={{
                                                        backgroundColor: indexvalue % 2 === 0 ? '#f2f9f2' : '#FFFFFF',
                                                        color: indexvalue % 2 === 0 ? '#004d40' : '#004d40',
                                                        fontFamily: '"Tajawal", sans-serif',
                                                        fontSize: 18,
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {indexvalue === -1 ? '- ' + label : label}
                                                </span>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <Box display="flex" justifyContent="center">
                                                <TextField
                                                    onClick={handleonclick_wilylist}

                                                    fullWidth {...params} label="ولاية الميلاد" placeholder="ولاية الميلاد" />
                                            </Box>
                                        )}
                                    />
                                </Grid>




                                <Grid item xs={isMatch ? 12 : 3} >

                                    <Autocomplete
                                        noOptionsText={'حدث الصفحة مرة أخرى'}
                                        classes={classes_listdfep}
                                        id="combo-box-demobld"
                                        value={selectedcommuns}
                                        options={options_commun}
                                        onChange={handlebldChange}


                                        renderOption={(props, option) => {
                                            const { label, indexvalue } = option;
                                            return (
                                                <span
                                                    {...props}
                                                    style={{
                                                        backgroundColor: indexvalue % 2 === 0 ? '#f2f9f2' : '#FFFFFF',
                                                        color: indexvalue % 2 === 0 ? '#004d40' : '#004d40',
                                                        fontFamily: '"Tajawal", sans-serif',
                                                        fontSize: 18,
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {indexvalue === -1 ? '- ' + label : label}
                                                </span>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <Box display="flex" justifyContent="center">
                                                <TextField fullWidth {...params} label="بلدية الميلاد" placeholder="بلدية الميلاد" />
                                            </Box>
                                        )}
                                    />
                                </Grid>





                                {/* مكان الميلاد بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueLieuNais"
                                                        value={valueLieuNais}
                                                        inputRef={refLieuNais}
                                                        onInput={handleInput_LieuNais}
                                                        helperText={
                                                            !ArabicCharactersRegex_withdash.test(valueLieuNais)
                                                                ? 'مكان الميلاد (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 7 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueLieuNais" label="مكان الميلاد (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>





                                {/* مكان الميلاد باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} >
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valueLieuNaisFr"
                                                        value={valueLieuNaisFr}
                                                        inputRef={refLieuNaisFr}
                                                        onInput={handleInput_LieuNaisFr}
                                                        helperText={
                                                            !LatinLettersRegex_withdash.test(valueLieuNaisFr)
                                                                ? 'مكان الميلاد (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 8 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueLieuNaisFr" label="مكان الميلاد (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>










                            </Grid>





                            <Grid container spacing={2} sx={{ mt: '4px' }}>

                                {/* العنوان بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueAdres"
                                                        value={valueAdres}
                                                        inputRef={refAdres}
                                                        onInput={handleInput_Adres}
                                                        helperText={
                                                            !ArabicCharactersRegex_address.test(valueAdres)
                                                                ? 'العنوان (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 9 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueAdres" label="العنوان (ع)" placeholder="العنوان (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>





                                {/* العنوان باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valueAdresFr"
                                                        value={valueAdresFr}
                                                        inputRef={refAdresFr}
                                                        onInput={handleInput_AdresFr}
                                                        helperText={
                                                            !LatinLettersRegex_address.test(valueAdresFr)
                                                                ? 'العنوان (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 10 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueAdresFr" label="العنوان (ف)" placeholder="العنوان (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>










                            </Grid>




                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField
                                                        fullWidth
                                                        name="valueContractNumber"
                                                        value={valueContractNumber}
                                                        inputRef={refContractNumber}
                                                        onInput={handleInput_ContractNumber}
                                                        helperText={
                                                            ContractNumberRegex.test(valueContractNumber) || !valueContractNumber
                                                                ? ''
                                                                : 'رقم عقد الميلاد يجب أن يحتوي على 5 أرقام فقط'
                                                        }
                                                        inputProps={{ maxLength: 5 }}
                                                        InputProps={{
                                                            inputProps: { tabIndex: 11 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <ArticleIcon />

                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        sx={{
                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueContractNumber`}
                                                        label="رقم عقد الميلاد"
                                                        placeholder="رقم عقد الميلاد"
                                                        variant="outlined"
                                                    />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>

                                {/* رقم التعريف الوطني NIN */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>


                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">

                                                    <TextField
                                                        fullWidth
                                                        name="valueNin"
                                                        value={valueNin}
                                                        inputRef={refNin}
                                                        onInput={handleInput_Nin}
                                                        error={
                                                            (!valueDateNais && !isCheckedPresume) ||
                                                            !NumbersOnlyRegex.test(valueNin) ||
                                                            !valueNin ||
                                                            valueNin.length !== 18 ||
                                                            (!(valueNin.substring(0, 1) === "1" && !isCheckedPresume) && !(valueNin.substring(0, 1) === "2" && isCheckedPresume)) ||
                                                            (!(valueNin.substring(1, 2) === "0" && Civ === 1) && !(valueNin.substring(1, 2) === "1" && Civ === 2)) ||
                                                            (valueNin.substring(2, 5) !== valueDateNais_year.substring(1, 4) && !isCheckedPresume)
                                                            || valueNin.substring(9, 14) !== valueContractNumber.substring(0, 5)
                                                            || (valueNin.substring(2, 5) !== valuePresumeDateNais.match(/\d{4}/)?.[0]?.substring(1) && isCheckedPresume)


                                                        }
                                                        helperText={

                                                            (valueNin.substring(2, 5) !== valuePresumeDateNais.match(/\d{4}/)?.[0]?.substring(1) && isCheckedPresume)
                                                                ? `قم بملئ خانة تاريخ الميلاد المفترض.`
                                                                : (!valueDateNais && !isCheckedPresume)
                                                                    ? 'قم بملئ خانة تاريخ الميلاد'
                                                                    : !valueContractNumber
                                                                        ? 'قم بملئ خانة رقم عقد الميلاد'
                                                                        : (!NumbersOnlyRegex.test(valueNin) || !valueNin || valueNin.length !== 18
                                                                            || (valueNin.substring(2, 5) !== valueDateNais_year.substring(1, 4) && !isCheckedPresume)
                                                                            || valueNin.substring(9, 14) !== valueContractNumber.substring(0, 5)
                                                                            || (!(valueNin.substring(1, 2) === "0" && Civ === 1) && !(valueNin.substring(1, 2) === "1" && Civ === 2))
                                                                            ||
                                                                            (!(valueNin.substring(0, 1) === "1" && !isCheckedPresume) && !(valueNin.substring(0, 1) === "2" && isCheckedPresume))
                                                                        )
                                                                            ? 'رقم التعريف الوطني غير صحيح'
                                                                            : ''
                                                        }


                                                        InputProps={{
                                                            inputProps: { tabIndex: 12 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <NumbersIcon />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (  // Changed to endAdornment
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={handleCameraOpen}>
                                                                        <CameraAltIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: '#004d40',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#004d40',
                                                                },
                                                            }
                                                        }}
                                                        id={`outlined-basic-valueNomnin`}

                                                        label="رقم التعريف الوطني NIN "
                                                        placeholder="رقم التعريف الوطني NIN "

                                                        variant="outlined" />                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>




                                    <CameraModal open={isCameraOpen} onClose={handleCameraClose} onCapture={handleCapture} />

                                </Grid>






                            </Grid>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>



                                {/* رقم الهاتف 01 */}


                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box display="flex" alignItems="center" sx={{ mt: '0px' }}>

                                                <Box display="flex" alignItems="center">


                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={checkedtel}
                                                                onChange={handleCheckboxChangetel}
                                                                color="primary"

                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        color: '#004d40', // Green color for the checkbox
                                                                    },
                                                                    '&.Mui-checked': {
                                                                        '& .MuiSvgIcon-root': {
                                                                            color: '#004d40', // Green color when checked
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <Typography variant="body2" dir="rtl" sx={{ // Use Typography component
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: 14,
                                                                color: '#004d40', // Change label color to blue
                                                                fontWeight: 'bold', // Make the label text bolder
                                                            }}>
                                                                أجنبي
                                                            </Typography>
                                                        }
                                                        sx={{ // Remove styling for the FormControlLabel itself (optional)
                                                            direction: 'rtl',
                                                            textAlign: 'right'
                                                        }}
                                                    />
                                                </Box>
                                                <Box flex="1">
                                                    <div dir="ltr">
                                                        <TextField
                                                            fullWidth
                                                            name="valueTEL1"
                                                            value={valueTEL1}
                                                            inputRef={refTEL1}
                                                            onInput={handleInput_TEL1}
                                                            error={!NumbersOnlyRegex.test(valueTEL1)}
                                                            helperText={
                                                                (((!NumbersOnlyRegex.test(valueTEL1) || valueTEL1.length !== 10 || isAllSameDigits(valueTEL1)) && !checkedtel)
                                                                ||
                                                                ((!InternationalNumbersOnlyRegextel.test(valueTEL1) || !(valueTEL1.length >= 12 && valueTEL1.length <= 16)) && checkedtel))
                                                                ? 'أدخل رقم هاتف صالح'
                                                                : ''
                                                               
                                                                  

                                                            }
                                                            InputProps={{
                                                                inputProps: { tabIndex: 13 },
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <PhonelinkRingIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            sx={{
                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: 'red',
                                                                    fontWeight: 700,
                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700,
                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700,
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: '2px',
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#004d40',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#004d40',
                                                                    },
                                                                },
                                                            }}
                                                            id={`outlined-basic-valueNomtel1`}
                                                            label="رقم الهاتف"
                                                            placeholder="رقم الهاتف"
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                </Box>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>



                                {/* البريد الإلكتروني */}
                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">


                                                    <FormControl fullWidth variant="outlined"


                                                    >

                                                        <TextField

                                                            sx={{

                                                                //direction:'ltr',

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: 'red',
                                                                    fontWeight: 700

                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    dir: 'rtl',

                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '22px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700,
                                                                    //text-align: right,



                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",

                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: "#00796b",
                                                                    },
                                                                    '&.Mui-focused fieldset': {

                                                                        borderColor: "#00796b",
                                                                    },
                                                                }
                                                            }}

                                                            inputRef={refemail}
                                                            error={isValidemail === false}
                                                            label="البريد الإلكتروني (إختياري)"
                                                            placeholder="البريد الإلكتروني (إختياري)"
                                                            helperText={isValidemail === false ? "أدخل عنوان بريد إلكتروني صالح" : <>


                                                            </>}

                                                            variant="outlined"
                                                            size={'small'}
                                                            name="valueemail"
                                                            value={valueemail}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => handleChangeemail(e)}



                                                            InputProps={{
                                                                inputProps: { tabIndex: 14 },
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <EmailIcon />
                                                                    </InputAdornment>
                                                                )


                                                            }}


                                                        />

                                                    </FormControl>


                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>


                            </Grid>



                            <Grid container spacing={2} sx={{ mt: '4px' }}>

                                {/* إسم الأب بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenomPere"
                                                        value={valuePrenomPere}
                                                        inputRef={refPrenomPere}
                                                        onInput={handleInput_PrenomPere}
                                                        helperText={
                                                            !ArabicCharactersRegex.test(valuePrenomPere)
                                                                ? 'إسم الأب (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 15 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenomPere" label="إسم الأب (ع)" placeholder="إسم الأب (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>





                                {/* إسم الأب باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenomPereFr"
                                                        value={valuePrenomPereFr}
                                                        inputRef={refPrenomPereFr}
                                                        onInput={handleInput_PrenomPereFr}
                                                        helperText={
                                                            !LatinLettersRegex.test(valuePrenomPereFr)
                                                                ? 'إسم الأب (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 16 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenomPereFr" label="إسم الأب (ف)" placeholder="إسم الأب (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>










                            </Grid>






                            <Grid container rowSpacing={1} sx={{ mt: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                {/* لقب الأم بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valueNomMere"
                                                        value={valueNomMere}
                                                        inputRef={refNomMere}
                                                        onInput={handleInput_NomMere}
                                                        helperText={
                                                            !ArabicCharactersRegex.test(valueNomMere)
                                                                ? 'لقب الأم (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 17 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueNomMere" label="لقب الأم (ع)" placeholder="لقب الأم (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>



                                {/* لقب الأم باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valueNomMereFr"
                                                        value={valueNomMereFr}
                                                        inputRef={refNomMereFr}
                                                        onInput={handleInput_NomMereFr}
                                                        helperText={
                                                            !LatinLettersRegex.test(valueNomMereFr)
                                                                ? 'لقب الأم (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 18 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valueNomMereFr" label="لقب الأم (ف)" placeholder="لقب الأم (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>

                            </Grid>


                            <Grid container spacing={2} sx={{ mt: '4px' }}>

                                {/* إسم الأم بالعربية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="rtl">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenomMere"
                                                        value={valuePrenomMere}
                                                        inputRef={refPrenomMere}
                                                        onInput={handleInput_PrenomMere}
                                                        helperText={
                                                            !ArabicCharactersRegex.test(valuePrenomMere)
                                                                ? 'إسم الأم (ع) يجب ان يحتوي على حروف عربية فقط'
                                                                : ''
                                                        }

                                                        InputProps={{
                                                            inputProps: { tabIndex: 19 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}


                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenomMere" label="إسم الأم (ع)" placeholder="إسم الأم (ع)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>



                                </Grid>





                                {/* إسم الأم باللاتنية */}

                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">
                                                    <TextField

                                                        fullWidth
                                                        name="valuePrenomMereFr"
                                                        value={valuePrenomMereFr}
                                                        inputRef={refPrenomMereFr}
                                                        onInput={handleInput_PrenomMereFr}
                                                        helperText={
                                                            !LatinLettersRegex.test(valuePrenomMereFr)
                                                                ? 'إسم الأم (ف) يجب ان يحتوي على حروف لاتنية فقط                           '
                                                                : ''
                                                        }
                                                        InputProps={{
                                                            inputProps: { tabIndex: 20 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}

                                                        sx={{

                                                            '& .MuiFormHelperText-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '14px',
                                                                color: 'red',
                                                                fontWeight: 700
                                                            },

                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '16px',
                                                                color: '#004d40'
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: '18px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& label.Mui-focused': {
                                                                fontSize: '16px',
                                                                color: '#004d40',
                                                                fontWeight: 700
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottomColor: '#004d40',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '16px',
                                                                    borderColor: '#004d40',
                                                                    borderWidth: "2px",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#00796b",
                                                                },
                                                            }
                                                        }}
                                                        id="outlined-basic-valuePrenomMereFr" label="إسم الأم (ف)" placeholder="إسم الأم (ف)" variant="outlined" />
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>





                            </Grid>






                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>








                                {/*  كلمة السر  */}
                                {/*
                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">

                                                    <FormControl fullWidth variant="outlined"

                                                    >

                                                        <InputLabel
                                                            sx={{
                                                                color: "#004d40", fontFamily:
                                                                    '"Tajawal", sans-serif',
                                                                fontSize: 16
                                                                , fontWeight: 700
                                                            }}
                                                            htmlFor="outlined-adornment-password">كلمة المرور </InputLabel>
                                                        <OutlinedInput
                                                            fullWidth
                                                            dir="rtl"
                                                            sx={{

                                                                //direction:'ltr',

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700

                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    dir: 'rtl',

                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '22px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700,
                                                                    //text-align: right,


                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",

                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: "#00796b",
                                                                    },
                                                                    '&.Mui-focused fieldset': {

                                                                        borderColor: "#00796b",
                                                                    },
                                                                }
                                                            }}

                                                            inputRef={refpassword1}
                                                            id="outlined-adornment-password"
                                                            type={valuesPassword.showPassword ? 'text' : 'password'}
                                                            value={valuesPassword.password}
                                                            name="valuesPassword"
                                                            onChange={handleChangePassword('password')}
                                                            inputProps={{
                                                                tabIndex: 18,
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {valuesPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label=" كلمة المرور  "
                                                            placeholder=" كلمة المرور  "
                                                        />


                                                        {!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/.test(valuesPassword.password) ? (
                                                            <FormHelperText
                                                                sx={{
                                                                    color: "#004d40",
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: 14,
                                                                    fontWeight: 700
                                                                }}
                                                                error
                                                                id="accountId-error"
                                                            >
                                                                أنشئ كلمة مرور قوية تتضمن حرفًا كبيرًا واحدًا على الأقل، وحرفًا صغيرًا واحدًا على الأقل، ورقمًا واحدًا على الأقل، ويجب أن لا تقل عن 8 أحرف.

                                                            </FormHelperText>
                                                        ) : (
                                                            <></>
                                                        )}



                                                    </FormControl>

                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>

*/}


                                {/* إعادة كلمة السر */}
                                {/*
                                <Grid item xs={isMatch ? 12 : 6} sx={{ mt: 1 }}>
                                    <CacheProvider value={cacheRtl}>
                                        <ThemeProvider theme={themetextfieldnew}>
                                            <Box>
                                                <div dir="ltr">

                                                    <FormControl fullWidth variant="outlined"

                                                    >

                                                        <InputLabel
                                                            sx={{
                                                                color: "#004d40", fontFamily:
                                                                    '"Tajawal", sans-serif',
                                                                fontSize: 16
                                                                , fontWeight: 700
                                                            }}
                                                            htmlFor="outlined-adornment-password"> إعادة كلمة المرور الجديدة</InputLabel>
                                                        <OutlinedInput

                                                            fullWidth
                                                            dir="rtl"
                                                            sx={{

                                                                //direction:'ltr',

                                                                '& .MuiFormHelperText-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '14px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700

                                                                },
                                                                '& .MuiFormLabel-root': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '18px',
                                                                    color: '#004d40',
                                                                    dir: 'rtl',

                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    fontFamily: '"Tajawal", sans-serif',
                                                                    fontSize: '22px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700,
                                                                    //text-align: right,



                                                                },
                                                                '& label.Mui-focused': {
                                                                    fontSize: '16px',
                                                                    color: '#004d40',
                                                                    fontWeight: 700
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#004d40',
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        fontFamily: '"Tajawal", sans-serif',
                                                                        fontSize: '16px',
                                                                        borderColor: '#004d40',
                                                                        borderWidth: "2px",

                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: "#00796b",
                                                                    },
                                                                    '&.Mui-focused fieldset': {

                                                                        borderColor: "#00796b",
                                                                    },
                                                                }
                                                            }}

                                                            inputRef={refpassword2}
                                                            id="outlined-adornment-PasswordRe"
                                                            type={valuesPasswordRe.showPasswordRe ? 'text' : 'Password'}
                                                            value={valuesPasswordRe.PasswordRe}
                                                            name="valuesPasswordRe"
                                                            onChange={handleChangePasswordRe('PasswordRe')}
                                                            inputProps={{
                                                                tabIndex: 19,
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPasswordRe}
                                                                        onMouseDown={handleMouseDownPasswordRe}
                                                                        edge="end"
                                                                    >
                                                                        {valuesPasswordRe.showPasswordRe ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label=" إعادة كلمة المرور الجديدة "
                                                            placeholder=" إعادة كلمة المرور الجديدة "
                                                        />

                                                        {valuesPasswordRe.PasswordRe !== valuesPassword.password ? <>

                                                            <FormHelperText
                                                                sx={{
                                                                    color: "#004d40", fontFamily:
                                                                        '"Tajawal", sans-serif',
                                                                    fontSize: 14
                                                                    , fontWeight: 700
                                                                }}
                                                                error id="accountId-error">
                                                                يجب أن تدخل نفس كلمة السر
                                                            </FormHelperText>


                                                        </> : <></>}


                                                    </FormControl>
                                                </div>
                                            </Box>
                                        </ThemeProvider>
                                    </CacheProvider>
                                </Grid>
                                */}






                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: '10px' }}>
                                <Paper style={{ maxHeight: 400, overflow: 'auto', padding: 20, mt: 2, mb: 2 }}>
                                    <div className="terms-section" style={{ textAlign: "right" }} direction="rtl">
                                        <Typography variant="body1" dir="rtl"
                                            sx={{
                                                fontFamily: '"Tajawal", sans-serif', // Font family
                                                fontSize: 14, // Font size
                                                direction: 'rtl',
                                                textAlign: 'right'
                                            }}
                                        >

                                            <h2>شروط تخزين ومشاركة البيانات</h2>
                                            <p>سيتم تخزين بياناتك الشخصية بأمان:</p>
                                            <ul>
                                                <li>نحتفظ ببياناتك طوال مسارك المهني وبعد تخرجك لفترة زمنية محددة.</li>
                                                <li>نستخدم قاعدة بيانات آمنة لحماية بياناتك من الوصول غير المصرح به أو الاستخدام أو الكشف أو التغيير أو التلف.</li>
                                            </ul>
                                            <h2>مشاركة البيانات مع جهات خارجية محددة</h2>
                                            <p>قد نضطر إلى مشاركة بياناتك مع جهات خارجية موثوقة في بعض الحالات، مثل:</p>
                                            <ul>
                                                <li>مؤسسات التكوين المهني التي ستلتحق بها لتسهيل عملية التسجيل و المتابعة.</li>
                                                <li>الجهات الحكومية المختصة عند الطلب لأغراض قانونية أو إدارية.</li>
                                                <li>مقدمي الخدمات الذين نتعاقد معهم لتقديم خدمات الدعم والمتابعة، مع ضمان حماية بياناتك.</li>
                                            </ul>
                                            <h2>موافقتك ضرورية</h2>
                                            <p>باستخدامك لهذه المنصة، فإنك توافق على شروط الاتفاق هذه.</p>
                                            <p>إذا كنت لا توافق على هذه الشروط، فلا تستخدم هذه المنصة.</p>

                                        </Typography>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                    inputRef={refcheckbox}
                                                    color="primary"
                                                    inputProps={{
                                                        tabIndex: 21,
                                                    }}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            color: '#004d40', // Green color for the checkbox
                                                        },
                                                        '&.Mui-checked': {
                                                            '& .MuiSvgIcon-root': {
                                                                color: '#004d40', // Green color when checked
                                                            },
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography variant="body2" dir="rtl" sx={{ // Use Typography component
                                                    fontFamily: '"Tajawal", sans-serif',
                                                    fontSize: 14,
                                                    color: '#004d40', // Change label color to blue
                                                    fontWeight: 'bold', // Make the label text bolder
                                                }}>
                                                    أوافق على شروط وأحكام الاستخدام
                                                </Typography>
                                            }
                                            sx={{ // Remove styling for the FormControlLabel itself (optional)
                                                direction: 'rtl',
                                                textAlign: 'right'
                                            }}
                                        />


                                    </div>
                                </Paper>


                            </Grid>
                            <ClickAwayListener onClickAway={handleClose_recaptcha}>
                                <div>
                                    <BootstrapTooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        open={open_recaptcha} onClose={handleClose_recaptcha} onOpen={handleOpen_recaptcha}


                                        title="اثبت انك لست روبوت"

                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                    >

                                        {Number(IDMode_formation) === 10 && valueNom && valuePrenom && valueNomFr && valuePrenomFr
                                            && valueLieuNais && valueAdres && valueTEL1 && !isAllSameDigits(valueTEL1) && Civ && niveaunompdf && (valueDateNais || valuePresumeDateNais) ? (
                                            <Grid container justifyContent="center" sx={{ mt: 1 }}>
                                                <Grid item xs={isMatch ? 12 : 6} sx={{ textAlign: 'center' }}>
                                                    <Button
                                                        onClick={() => modifyPdf(valueNom, valuePrenom, valueNomFr, valuePrenomFr, valueDateNais, valueLieuNais, valueAdres, valueTEL1, valueemail, Civ, niveaunompdf, isCheckedPresume, valuePresumeDateNais)}
                                                        variant="contained"
                                                        endIcon={
                                                            <PictureAsPdfIcon
                                                                sx={{
                                                                    mr: "2px", // Space between icon and text
                                                                    ml: "2px",
                                                                    transform: "rotate(0deg)"
                                                                }}
                                                            />
                                                        }
                                                        sx={{
                                                            backgroundColor: '#00695c', // Green color
                                                            '&:hover': {
                                                                backgroundColor: '#004d40', // Darker green on hover
                                                            },
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: "#FFFFFF",
                                                                fontFamily: '"Tajawal", sans-serif',
                                                                fontSize: 12,
                                                                fontWeight: 900,
                                                                pt: 1,
                                                                pb: 1,
                                                                ml: 1, // Add margin-right to create space between text and icon
                                                            }}
                                                        >
                                                            حمل عقد التمهين (مملوء بمعلوماتك)
                                                        </Typography>
                                                    </Button>

                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}


                                        <Grid item xs={12} container justifyContent="center">


                                            <ReCAPTCHA style={{ marginTop: '10px' }}
                                                ref={refReCAPTCHA}
                                                sitekey="6LcCt3YpAAAAAK33Jnte_3V4gKcLl-kQgeV3qWMP"
                                                onChange={handleRecaptchaChange}
                                                hl="ar"
                                            // Additional optional props (e.g., action, verifyApiArgs)
                                            />

                                        </Grid>

                                    </BootstrapTooltip>


                                    {/* Submit Button */}
                                    <Grid item xs={12} container justifyContent="center" >
                                        <CacheProvider value={cacheRtl}>
                                            <ThemeProvider theme={themetextfieldnew}>
                                                <Box sx={{ minWidth: 120, mt: '35px', mb: 15 }}>
                                                    <div>

                                                        <Button

                                                            sx={{
                                                                backgroundColor: '#00695c', // Green color
                                                                '&:hover': {
                                                                    backgroundColor: '#004d40', // Darker green on hover
                                                                }
                                                            }}
                                                            onClick={(e) => {



                                                                e.preventDefault();



                                                                if (Clouture === 1) {
                                                                    alert('التسجيلات متوقفة حالياً');
                                                                    return;
                                                                }

                                                                /*
                                                                setvalueNom('صبرو');
                                                                setvalueNomFr('sebrou');
                                                                setvaluePrenom('عبد العزيز');
                                                                setvaluePrenomFr('abdelaziz');

                                                                setvalueAdres('حي بن سمارة');
                                                                setvalueAdresFr('ben smara rue');
                                                                setvaluePrenomPere('بلقاسم');
                                                                setvaluePrenomPereFr('belkacem');
                                                                setvalueNomMere('الشحمة');
                                                                setvalueNomMereFr('chehma');
                                                                setvaluePrenomMere('فطيمة');
                                                                setvaluePrenomMereFr('fatima');
                                                                setIsChecked(true);
                                                                setRecaptchaVerified(true);

                                                                // Now you can set the Password property using setValuesPassword
                                                                setValuesPassword(prevState => ({
                                                                    ...prevState,
                                                                    password: 'azizSEBROU1991' // Changed from 'Password' to 'password'
                                                                }));


                                                                setValuesPasswordRe(prevState => ({
                                                                    ...prevState,
                                                                    PasswordRe: 'azizSEBROU1991'
                                                                }));

                                                                */


                                                                if (!selectedniveau_scolaires || (endicape !== 1 && (Number(IDCycle_scol) < Number(IDCycle_scol_db) || ((Number(IDCycle_scol) === Number(IDCycle_scol_db) && Number(NumOrd) < Number(NumOrd_db)))))) {
                                                                    setmsgerror("أقل من المستوى المطلوب في العرض");
                                                                    setfieldnameerror("المستوى الدراسي: ")
                                                                    setopen_info(true);

                                                                    Refselectedniveau_scolaires.current.focus();
                                                                    return;
                                                                }

                                                                if (!ArabicCharactersRegex.test(valueNom) || valueNom === '') {
                                                                    refNom.current.focus();
                                                                    return
                                                                }

                                                                if (!ArabicCharactersRegex.test(valueLieuNais) || valueLieuNais === '') {
                                                                    refLieuNais.current.focus();
                                                                    return
                                                                }


                                                                if (!LatinLettersRegex.test(valueLieuNaisFr) || valueLieuNaisFr === '') {
                                                                    refLieuNaisFr.current.focus();
                                                                    return
                                                                }

                                                                if (!LatinLettersRegex.test(valueNomFr) || valueNomFr === '') {
                                                                    refNomFr.current.focus();
                                                                    return
                                                                }

                                                                if (!ArabicCharactersRegex.test(valuePrenom) || valuePrenom === '') {
                                                                    refPrenom.current.focus();
                                                                    return
                                                                }

                                                                if (!LatinLettersRegex.test(valuePrenomFr) || valuePrenomFr === '') {
                                                                    refPrenomFr.current.focus();
                                                                    return
                                                                }

                                                                if (!isCheckedPresume) {                                                                                 // Check if DateD is a non-empty string
                                                                    const isValidDateD = typeof DateD === 'string' && DateD.trim() !== '';

                                                                    // Convert DateD to a Date object if valid, otherwise use the current date
                                                                    const currentDatea = isValidDateD ? new Date(DateD) : new Date();

                                                                    // Ensure currentDatea is a valid Date
                                                                    const isValidDate = !isNaN(currentDatea.getTime());
                                                                    const currentDate = isValidDate ? currentDatea : new Date();



                                                                    //const currentDate = new Date();
                                                                    const birthDate = new Date(valueDateNais);
                                                                    const age_curr = currentDate.getFullYear() - birthDate.getFullYear();

                                                                    if ((!valueDateNais && !isCheckedPresume) || (endicape !== 1 && (AgeMin !== 0 && (Number(age_curr) < AgeMin || (AgeMAx > AgeMin && Number(age_curr) > AgeMAx))))) {
                                                                        setmsgerror("غير صالح");
                                                                        setfieldnameerror("تاريخ الميلاد: ")
                                                                        setopen_info(true);

                                                                        refDateNais.current.focus();
                                                                        return false;
                                                                    }
                                                                } else {
                                                                    if (valuePresumeDateNais === '') {
                                                                        setmsgerror("");
                                                                        setfieldnameerror("التاريخ المفترض فارغ")
                                                                        setopen_info(true);
                                                                        refvaluePresumeDateNais.current.focus();
                                                                        return
                                                                    }

                                                                    const match = valuePresumeDateNais.match(/\d{4}/);
                                                                    if (match) {
                                                                        //alert(`Found 4 digits: ${match[0]}`);
                                                                    } else {
                                                                        setmsgerror("");
                                                                        setfieldnameerror("أدخل التاريخ المفترض")
                                                                        setopen_info(true);
                                                                        refvaluePresumeDateNais.current.focus();
                                                                        return;
                                                                    }
                                                                }

                                                                if (!ContractNumberRegex.test(valueContractNumber)) {
                                                                    refContractNumber.current.focus();
                                                                    return;
                                                                }


                                                                if (!ArabicCharactersRegex_address.test(valueAdres) || valueAdres === '') {
                                                                    refAdres.current.focus();
                                                                    return
                                                                }

                                                                if (!LatinLettersRegex_address.test(valueAdresFr) || valueAdresFr === '') {
                                                                    refAdresFr.current.focus();
                                                                    return
                                                                }

                                                                if (!ArabicCharactersRegex.test(valuePrenomPere) || valuePrenomPere === '') {
                                                                    refPrenomPere.current.focus();
                                                                    return
                                                                }

                                                                if (!LatinLettersRegex.test(valuePrenomPereFr) || valuePrenomPereFr === '') {
                                                                    refPrenomPereFr.current.focus();
                                                                    return
                                                                }

                                                                if (!ArabicCharactersRegex.test(valueNomMere) || valueNomMere === '') {
                                                                    refNomMere.current.focus();
                                                                    return
                                                                }
                                                                if (!LatinLettersRegex.test(valueNomMereFr) || valueNomMereFr === '') {
                                                                    refNomMereFr.current.focus();
                                                                    return
                                                                }

                                                                if (!ArabicCharactersRegex.test(valuePrenomMere) || valuePrenomMere === '') {
                                                                    refPrenomMere.current.focus();
                                                                    return
                                                                }

                                                                if (!LatinLettersRegex.test(valuePrenomMereFr) || valuePrenomMereFr === '') {
                                                                    refPrenomMereFr.current.focus();
                                                                    return
                                                                }




                                                                if (!isValidemail) {
                                                                    refemail.current.focus();
                                                                    return;
                                                                }




                                                                if ((!valueDateNais && !isCheckedPresume) ||
                                                                    !NumbersOnlyRegex.test(valueNin) ||
                                                                    !valueNin ||
                                                                    valueNin.length !== 18 ||
                                                                    (!(valueNin.substring(0, 1) === "1" && !isCheckedPresume) && !(valueNin.substring(0, 1) === "2" && isCheckedPresume)) ||
                                                                    (!(valueNin.substring(1, 2) === "0" && Civ === 1) && !(valueNin.substring(1, 2) === "1" && Civ === 2)) ||
                                                                    (valueNin.substring(2, 5) !== valueDateNais_year.substring(1, 4) && !isCheckedPresume)
                                                                    || valueNin.substring(9, 14) !== valueContractNumber.substring(0, 5)
                                                                    || (valueNin.substring(2, 5) !== valuePresumeDateNais.match(/\d{4}/)?.[0]?.substring(1) && isCheckedPresume)

                                                                ) {

                                                                    refNin.current.focus();
                                                                    return
                                                                }

                                                                if ((!NumbersOnlyRegex.test(valueTEL1) || valueTEL1.length !== 10
                                                                    || isAllSameDigits(valueTEL1)) && !checkedtel) {
                                                                    refTEL1.current.focus();

                                                                    return
                                                                }
                                                                if (checkedtel) {

                                                                    // Update the regex to allow only numbers and ensure it starts with '+'
                                                                    const InternationalNumbersOnlyRegex = /^\+\d*$/;

                                                                    // Ensure the input does not exceed 16 characters (1 for "+" and up to 15 digits)
                                                                    if (!InternationalNumbersOnlyRegex.test(valueTEL1) ||  !(valueTEL1.length >= 12 && valueTEL1.length <= 16)) {
                                                                        refTEL1.current.focus();

                                                                              return
                                                                    }


                                                                }




                                                                /*
                                                                                                                                if (valuesPassword.password === '') {
                                                                                                                                    refpassword1.current.focus();
                                                                                                                                    return
                                                                                                                                }
                                                                
                                                                                                                                if (valuesPasswordRe.PasswordRe === '') {
                                                                                                                                    refpassword2.current.focus();
                                                                                                                                    return
                                                                                                                                }
                                                                
                                                                                                                                if (valuesPassword.password !== valuesPasswordRe.PasswordRe) {
                                                                                                                                    refpassword2.current.focus();
                                                                                                                                    return
                                                                                                                                }
                                                                
                                                                                                                                if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/.test(valuesPassword.password)) {
                                                                                                                                    refpassword1.current.focus();
                                                                                                                                    return
                                                                                                                                }*/




                                                                if (!isChecked) {
                                                                    setmsgerror("لم تقم بالموافقة على شروط و أحكام الإستخدام");
                                                                    setopen_info(true);
                                                                    setopen_check(true);
                                                                    refcheckbox.current.focus();
                                                                    return
                                                                }


                                                                if (!recaptchaVerified) {
                                                                    setmsgerror("قم بالنقر على 'أنا لست روبوت' في ريكابتشا");
                                                                    setfieldnameerror("ريكابتشا: ")
                                                                    setopen_info(true);

                                                                    setOpen_recaptcha(true);
                                                                    return
                                                                }

                                                                onsubmitclicked(e);


                                                            }}



                                                            variant="contained"
                                                            endIcon={<SaveIcon sx={{
                                                                mr: '5px', ml: '5px',
                                                                transform: 'rotate(0deg)', // Adjust the angle as needed
                                                            }} />}>
                                                            <Typography sx={{
                                                                color: "#FFFFFF", fontFamily:
                                                                    '"Tajawal", sans-serif',
                                                                fontSize: 22
                                                                , fontWeight: 900
                                                            }}>
                                                                سجل الآن

                                                            </Typography>

                                                        </Button>



                                                    </div>

                                                </Box>
                                            </ThemeProvider>
                                        </CacheProvider>

                                    </Grid>

                                </div>
                            </ClickAwayListener>





                        </Grid>
                    </Container>


                </form>
            </div >

            <Footer />






            {/* Dialog success save */}

            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: '#004d40',
                        boxShadow: 'none',
                    },
                }}
                //fullScreen={mediumScreen}
                open={OpenDialog}
                onClose={handleClickCloseDialog}
                aria-labelledby="responsive-dialog-title"
            >

                <Card style={{}} sx={{ maxWidth: 345 }}>

                    {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
                    <CardHeader
                        avatar={

                            <CheckCircleIcon sx={{ color: '#4BB543', width: 50, height: 50, ml: 2 }}></CheckCircleIcon>

                        }
                        action={


                            <IconButton onClick={handleClickCloseDialog} aria-label="settings" sx={{ ml: -1, mt: -1 }}>

                                <CloseIcon />

                            </IconButton>
                        }
                        title={
                            <>
                                <Box sx={{ ml: 2, mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>

                                    <Typography sx={{ color: '#004d40', fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }} >
                                        تم التسجيل بنجاح</Typography>

                                </Box>

                            </>

                        }
                        subheader={
                            <>
                                <Box sx={{ mt: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>

                                    <Typography sx={{ color: '#004d40', fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }} >

                                        رقم التسجيل: {IDCandidat}

                                    </Typography>

                                </Box>

                            </>

                        }
                    />
                    <CardContent>
                        <Divider sx={{ mt: '0px' }}></Divider>
                        <Grid item  >
                            <Button
                                component={Link}
                                to={`/Preinscriptionpdf/${crypted_id}`}
                                target="_blank"
                                variant="contained" // Add this line to make it a contained button
                                sx={{
                                    display: 'block', // Display the button as a block element
                                    textAlign: 'right',
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: 16,
                                    fontWeight: 700,

                                }}
                            >

                                <Typography
                                    theme={themeChanga}
                                    sx={{ textAlign: "right", mr: 0 }}
                                    type="body2"
                                >

                                    تحميل استمارة التسجيل <PictureAsPdfIcon />
                                </Typography>
                            </Button>
                        </Grid>
                    </CardContent>


                </Card>

            </Dialog>










            <CacheProvider value={cacheRtl}>
                <ThemeProvider theme={themetextfieldnew}>


                    <Dialog
                        open={open_info} onClose={() => setopen_info(false)}>
                        <DialogTitle>
                            <Divider >
                                <Grid item sx={{ mt: 2 }} >
                                    <Typography
                                        style={{
                                            color: "#023020",
                                            fontFamily: '"Tajawal", sans-serif',
                                            fontSize: 20,
                                            fontWeight: 700,
                                        }}
                                    >
                                        أكمل المعلومات <InfoIcon sx={{ ml: '0px' }} />
                                    </Typography>
                                </Grid>
                            </Divider>


                        </DialogTitle>



                        <DialogContent>
                            <DialogContentText
                                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                                <span style={{ color: 'black' }}>{fieldnameerror}</span> {msgerror}

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                onClick={() => setopen_info(false)}
                                className={classes.cancelBtn}
                                color="primary"
                                sx={{
                                    fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                                    backgroundColor: '#00695c', // Green color
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#004d40', // Darker green on hover
                                    }
                                }}
                            >
                                موافق
                            </Button>

                        </DialogActions>
                    </Dialog>

                </ThemeProvider>
            </CacheProvider>



        </>
    )
}