import React, { useState, useEffect, useRef } from 'react';

import { Typography, Box } from '@mui/material';

import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
 
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { arSD } from '@mui/x-data-grid/locales';

import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { CacheProvider } from "@emotion/react";
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import XLSX from 'sheetjs-style';
import Footer from './Footer';
import Header from './Header';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import createCache from "@emotion/cache";
import { makeStyles } from "@material-ui/core/styles";

import '../containers/amirifont.css'; // Import the font CSS file
import HowToRegIcon from '@mui/icons-material/HowToReg';

const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
}, arSD);

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});



async function loginUser(email, password) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, password: password }),
    });

    if (!response.ok) {
      throw new Error('Invalid credentials');
    }

    const data = await response.json();
    const token = data.token;
    return token;
  } catch (error) {
    console.error('Error logging in:', error.message);
    return null;
  }
}


const StyledBox = styled('div')(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {

  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only alphabets (a to z).');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only Arabic alphabets.');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}


const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}


const apiUrl = `${API_BASE_URL}/api/getcandbyidetab_valid_Candidat`;
const apiUrl_update = `${API_BASE_URL}/api/getcandbyidetab_valid_Candidat_update`;

function CustomToolbar({ setRows, setRowModesModel, handleExportExcel, columns }) {
  return (
    <GridToolbarContainer style={{ fontFamily: 'Amiri', fontSize: '20px' }} >
      <GridToolbarColumnsButton style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <GridToolbarFilterButton style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <GridToolbarDensitySelector style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <Button style={{ fontFamily: 'Amiri', fontSize: '20px' }} onClick={handleExportExcel} color="primary">
        تنزيل كملف إكسال
      </Button>
      <GridToolbarExport style={{ fontFamily: 'Amiri', fontSize: '20px' }} />

      <GridToolbarQuickFilter
        style={{ fontFamily: 'Amiri', fontSize: '20px', marginRight: '25px' }}
        inputProps={{ style: { fontFamily: 'Amiri', fontSize: '20px' } }}
      />
    </GridToolbarContainer>
  );
}




function ValidationCandInfo() {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);

  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState('');

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState('');



  const editedValueRef = useRef(editedValue);

  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);

  const navigate = useNavigate();

  useEffect(() => {
    // Log the edited values whenever rows are updated
    //console.log('Edited Values:', editedValue);
  }, [editedValue]);


  const fetchDataAndUpdateRows = async () => {


    const IDetablissement = sessionStorage.getItem("IDetablissement");

    // Fetching data from apiUrl
    const fetchData = async () => {
      /* this for cancel this page */
      navigate('/');
      return;


      try {
        const response = await fetch(apiUrl + '/' + IDetablissement,
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
            }
          });
        const data = await response.json();
        return data.data.map((item, index) => ({
          id: `candid_${item.IDCandidat}`,//item.IDCandidat,
          sourcedt: `candid`,
          MatSt: item.NumIns,
          NIN: item.Nin,
          ID: item.IDCandidat,
          NomAr: item.Nom_Ca,
          NomFr: item.NomFr,
          PrenomAr: item.Prenom,
          PrenomFr: item.PrenomFr,
          DateNais: item.DateNais,
          LieuNaisFr: item.LieuNaisFr,
          LieuNais: item.LieuNais,
          PrenomPereFr: item.PrenomPereFr,
          PrenomPere: item.PrenomPere,
          NomMereFr: item.NomMereFr,
          PrenomMereFr: item.PrenomMereFr,
          NomMere: item.NomMere,
          Civ: Number(item.Civ) === 1 ? "ذكر" : "أنثى",
          PrenomMere: item.PrenomMere,
          Adres: item.Adres,
          AdresFr: item.AdresFr,
          NomSection: item.Nom,  ///Section.Nom
          actions: '',
        }));
      } catch (error) {
        console.error('Error fetching data from apiUrl:', error);
        return [];
      }
    };

    // Fetching data from apiUrl_update
    const fetchUpdateData = async () => {
      try {
        const response = await fetch(apiUrl_update + '/' + IDetablissement
          ,
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
            }
          });

        const data = await response.json();
        return data.data.map((item, index) => ({
          id: `candid_update_${item.IDCandidat}`,//item.IDCandidat,
          sourcedt: `candid_update`,
          MatSt: item.NumIns,
          NIN: item.Nin,
          ID: item.IDCandidat,
          NomAr: item.Nom_Ca,
          NomFr: item.NomFr,
          PrenomAr: item.Prenom,
          PrenomFr: item.PrenomFr,
          DateNais: item.DateNais,
          LieuNaisFr: item.LieuNaisFr,
          LieuNais: item.LieuNais,
          PrenomPereFr: item.PrenomPereFr,
          PrenomPere: item.PrenomPere,
          NomMereFr: item.NomMereFr,
          PrenomMereFr: item.PrenomMereFr,
          NomMere: item.NomMere,
          Civ: Number(item.Civ) === 1 ? "ذكر" : "أنثى",
          PrenomMere: item.PrenomMere,
          Adres: item.Adres,
          AdresFr: item.AdresFr,
          NomSection: item.Nom,  ///Section.Nom
          actions: '',
        }));
      } catch (error) {
        console.error('Error fetching data from apiUrl_update:', error);
        return [];
      }
    };

    // Using Promise.all to fetch data from both APIs simultaneously
    Promise.all([fetchData(), fetchUpdateData()])
      .then(([apiRows, updateRows]) => {
        // Combining results from both APIs
        const combinedRows = [...apiRows, ...updateRows];
        setRows(combinedRows);
      })
      .catch((error) => console.error('Error fetching data:', error));


  };

  React.useEffect(() => {
    fetchDataAndUpdateRows();

  }, []);




  // Fetch data from the API when the component mounts
  /*
React.useEffect(() => {
  const IDetablissement = sessionStorage.getItem("IDetablissement");
  
  fetch(apiUrl+'/'+IDetablissement)
    .then((response) => response.json())
    .then((data) => {
      const apiRows = data.data.map((item, index) => ({
        id: item.IDCandidat,
        MatSt: item.NumIns,
        NIN: item.Nin,
        ID: item.IDCandidat,
        NomAr: item.Nom_Ca,
        NomFr: item.NomFr,
        PrenomAr: item.Prenom,
        PrenomFr: item.PrenomFr,
        NomSection:item.Nom,  ///Section.Nom
        Password: '****',
        actions: '',
      }));
      setRows(apiRows);
      rowsdata=apiRows;
    })
    .catch((error) => console.error('Error fetching data:', error));
}, []);
*/


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.commit) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);


    }
  };




  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };



  const [idsaved, setidsaved] = useState(0);


  const [confirmationTrigger, setConfirmationTrigger] = useState(false);


  const handleSaveClick = (id) => () => {

    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { ID } = rowToDelete;
      const { NomAr } = rowToDelete;
      const { PrenomAr } = rowToDelete;
      // Open the confirmation dialog
      setSaveConfirmationOpen(true);
      setDeletedRowId(ID);
      setDeleted_NomAr(NomAr);
      setDeleted_PrenomAr(PrenomAr);


    }


    ////setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    //setidforsave(id);  
    ///setConfirmationTrigger(true); // Set the trigger for confirmation

  };

  /*
  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);
  */


  const handleDeleteClick = (id) => () => {
    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { ID } = rowToDelete;
      const { NomAr } = rowToDelete;
      const { PrenomAr } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      setDeletedRowId(ID);
      setDeleted_NomAr(NomAr);
      setDeleted_PrenomAr(PrenomAr);


    }

  };




  const handleConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setConfirmationOpen(false);
    if (confirmed) {
      const idToDelete = deletedRowId;
      setDeletedRowId(null); // Reset deletedRowId to avoid multiple deletions

      try {
        // Send the API request to delete the row from the Laravel backend
        const response = await fetch(`${API_BASE_URL}/api/deleteRow_Can_updatebyid/${idToDelete}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });

        if (!response.ok) {
          // Log and display specific error messages
          const errorData = await response.json();
          const errorMessage = `API error: ${response.status} ${errorData.error || 'Unknown error'}`;
          console.error(errorMessage);
          setSnackbar({ children: errorMessage, severity: 'error' });
          return;
        }

        if (response.ok) {
          // If the API request is successful, update the local state to remove the row
          setRows((prevRows) => prevRows.filter((row) => row.id !== idToDelete));
          //console.log('Row deleted successfully from Laravel backend.');
          setSnackbar({ children: '، تم الحذف بنجاح!', severity: 'success' });
          fetchDataAndUpdateRows();

        } else {
          console.error('Failed to delete row from Laravel backend.');
          // Handle the error or display a notification to the user
        }
      } catch (error) {
        console.error('Error during the API request:', error);
        // Handle the error or display a notification to the user
      }
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const handleSaveConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setSaveConfirmationOpen(false);

    if (confirmed) {
      const idforsavea = deletedRowId;
      handleSaveOperation(idforsavea);
      /// setidforsave(null);
    } else {
      // User clicked "Cancel," do nothing
    }
  };



  const processRowUpdate = async (newRow) => {
    // Check if the row is new
    const isNewRow = rows.find((row) => row.id === newRow.id)?.isNew;

    // Preserve the original isNew value if it exists
    const updatedRow = { ...newRow, isNew: isNewRow };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Display an alert with the updated row data and id
    ///alert(`Row Data After Update for ID ${newRow.id}: ${JSON.stringify(updatedRow)}`);

    try {
      // Call handleSaveOperation with the id
      await handleSaveOperation(newRow.id, updatedRow);
    } catch (error) {
      console.error('Error during handleSaveOperation:', error);
    }

    return updatedRow;
  };





  const handleSaveOperation = async (id) => {

    // Input validation (enhanced from both responses)
    if (!id) {
      console.error('Missing required parameters: id or updatedRow');
      return;
    }

    // API endpoint URL construction
    const updateUrl = `${API_BASE_URL}/api/updateRowCand_from_canUpdate/${id}`;


    try {
      // API call with clear error handling
      const response = await fetch(updateUrl, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        },
        body: JSON.stringify(id),
      });

      if (!response.ok) {
        // Log and display specific error messages
        const errorData = await response.json();
        const errorMessage = `API error: ${response.status} ${errorData.error || 'Unknown error'}`;
        console.error(errorMessage);
        setSnackbar({ children: errorMessage, severity: 'error' });
        return;
      }
      // Success message and UI updates
      //console.log('Record updated successfully in the database.');
      setSnackbar({ children: 'تمت المصادقة بنجاح!', severity: 'success' });
      fetchDataAndUpdateRows();
    } catch (error) {
      // Generic error handling and fallback UI actions
      console.error('Error during API request:', error);
      setSnackbar({ children: 'حدث خطأ. يرجى المحاولة مرة أخرى', severity: 'error' });
    }
  };



  const useStyles = makeStyles({
    cancelBtn: {
      backgroundColor: "#004d40", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const SaveConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();
    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>


          <Dialog
            open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider >
                <Grid item sx={{ mt: 2 }} >
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد المصادقة <CheckIcon sx={{ ml: '0px' }} />
                  </Typography>
                </Grid>
              </Divider>


            </DialogTitle>



            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                هل أنت متأكد من أنك تريد المصادقة على معلومات <span style={{ color: 'black' }}>{deleted_PrenomAr} {deleted_NomAr}؟</span>

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#004d40", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                مصادقة
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      </CacheProvider>
    );
  };


  const ConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>


          <Dialog
            open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider >
                <Grid item sx={{ mt: 2 }} >
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحذف <DeleteIcon sx={{ ml: '0px' }} />
                  </Typography>
                </Grid>
              </Divider>


            </DialogTitle>



            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                هل أنت متأكد من أنك تريد حذف <span style={{ color: 'black' }}>{deleted_PrenomAr} {deleted_NomAr}؟</span>

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#004d40", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      </CacheProvider>
    );
  };




  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };





  const [addRecordClicked, setAddRecordClicked] = useState(false);

  useEffect(() => {
    if (addRecordClicked) {
      handleAddRecord();
      setAddRecordClicked(false);
    }
  }, [addRecordClicked]);

  const handleAddClick = () => {
    setIsAdding(true);
    setAddRecordClicked(true);
  };



  const handleAddRecord = () => {
    const id = uuidv4();
    setRows((oldRows) => {
      const newRows = [
        ...oldRows,
        { id, MatSt: '', NIN: '', NomAr: '', NomFr: '', PrenomAr: '', PrenomFr: '', Email: '', Password: '', isNew: true },
      ];



      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'NomAr' },
      }));
      return newRows;
    });
  };


  const handleRowModesModelChange = (newRowModesModel) => {

    setRowModesModel(newRowModesModel);
  };



  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map(col => ({ name: col.field, headerName: col.headerName }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
      const newRow = {};
      relevantColumns.forEach(col => {
        newRow[col.headerName] = row[col.name];
      });
      return newRow;
    }));

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };


  /*
const handleExportExcel = () => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(rows, {
    header: columns.map((col) => col.headerName),
  });

  // Add cell styling as needed
  // Example:
  ws["A1"].s = {
    font: {
      name: 'Calibri',
      sz: 11,
      bold: true,
      color: {
        rgb: 'FFFFAA00',
      },
    },
  };

  XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
  const timestamp = Math.floor(Date.now() / 1000);

  XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
};*/

  const columns = [

    {
      field: 'MatSt',
      minWidth: 170, headerName: 'رقم التسجيل', headerClassName: 'super-app-theme--header',

      headerAlign: 'center',
    },

    {
      field: 'NomAr', headerName: 'اللقب', editable: false,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: preProcessEditCellPropsAr,
      renderEditCell: renderEditName, minWidth: 170,
    },

    {
      field: 'NomFr',
      headerName: 'Nom',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      preProcessEditCellProps: preProcessEditCellProps,
      renderEditCell: renderEditName,
      minWidth: 170,
    },

    {
      field: 'PrenomAr', headerName: 'الإسم', editable: false,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: preProcessEditCellPropsAr,
      renderEditCell: renderEditName, minWidth: 170,
    },
    {
      field: 'PrenomFr', headerName: 'Prénom', editable: false,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      preProcessEditCellProps: preProcessEditCellProps,
      renderEditCell: renderEditName, minWidth: 170,
    },
    ,
    {
      field: 'Civ',
      headerName: 'الجنس',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },

    {
      field: 'DateNais',
      headerName: 'تاريخ الميلاد',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'LieuNais',
      headerName: 'مكان الميلاد',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'LieuNaisFr',
      headerName: 'Lieu de naissance',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'PrenomPere',
      headerName: 'إسم الأب',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'PrenomPereFr',
      headerName: 'Prénom du père',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'NomMere',
      headerName: 'لقب الأم',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'PrenomMere',
      headerName: 'إسم الأم',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'NomMereFr',
      headerName: 'Nom de la mère',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'PrenomMereFr',
      headerName: 'Prénom de la mère',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'Adres',
      headerName: 'العنوان',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'AdresFr',
      headerName: 'Adresse',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },



    {
      field: 'NomSection',
      headerClassName: 'super-app-theme--header',
      headerName: 'التخصص', editable: false, minWidth: 170,
      align: 'center',
      headerAlign: 'center',
    },



    ////{ field: 'Email', headerName: 'Email', width: 200, editable: false },

    ///{ field: 'crypted_id', headerName: 'crypted_id', width: 200, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'إجراءات',
      minWidth: 220,
      flex: 1,
      cellClassName: 'actions',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        const iscandid = row.sourcedt === 'candid';
        if (iscandid) {
          return [
            <Typography sx={{ display: 'flex', alignItems: 'center', fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
              المصادق عليه
              <CheckCircleIcon sx={{ mr: '5px', ml: '5px', transform: 'rotate(0deg)' }} />
            </Typography>

          ];
        }

        return [
          <Button
            onClick={handleSaveClick(id)}
            variant="contained" endIcon={<CheckIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />} style={{ backgroundColor: 'green', width: 'fit-content' }}>
            <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
              مصادقة
            </Typography>
          </Button>

          ,
          <Button
            onClick={handleDeleteClick(id)}
            variant="contained" endIcon={<DeleteIcon sx={{ mr: '0px', ml: '0px', transform: 'rotate(0deg)' }} />} style={{ backgroundColor: 'red', width: 'fit-content' }}>
            <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
              حذف
            </Typography>
          </Button>

          ,
        ];
      },
    },
  ];

  function generateRandomString(length = 6) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }




  const CopyToClipboardButton = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };




  return (
    <>
      <Header />

      <Box
        sx={{
          height: 500,
          mt: 5,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >

        <div style={{ height: 400, width: '100%' }}>






          <Box


            sx={{
              height: 500,
              width: '100%',
              '& .actions': {
                color: 'text.secondary',
              },
              '& .textPrimary': {
                color: 'text.primary',
              },
              '& .super-app-theme--header': {
                fontFamily: 'Amiri',
                fontSize: '20px',
                fontWeight: 'bold',
                backgroundColor: '#B9D3EE',
                borderRight: '1px solid #ccc',
              }


            }}

          >

            <CacheProvider value={cacheRtl}>
              <ThemeProvider theme={themetextfieldnew}>



                {!!snackbar && (
                  <Snackbar
                    open
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    sx={{
                      marginTop: 12
                    }}
                  >
                    <Alert
                      {...snackbar}
                      onClose={handleCloseSnackbar}
                      sx={{
                        display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
                        , '& .MuiAlert-action': { marginRight: '8px' }
                      }} // Adjust padding here
                    />
                  </Snackbar>

                )}

                <Grid container direction="column" spacing={2}>

                  <Divider sx={{ mt: '20px' }}>
                    <Grid item  >
                      <Typography
                        style={{
                          color: "#004d40",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 25,
                          fontWeight: 700,
                          marginBottom: '25px'
                        }}
                        variant="h3" gutterBottom>
                        <HowToRegIcon sx={{ ml: '5px' }} />
                        مصادقة معلومات المتكونين
                      </Typography>
                    </Grid>

                  </Divider>


                  <Grid item sx={{ width: "100%" }}>

                    <DataGrid
                      locale="ar-SD"
                      rows={rows}
                      columns={columns}
                      editMode="row"
                      rowModesModel={rowModesModel}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}

                      getRowId={(row) => row.id}
                      getRowClassName={(params) =>
                        parseInt(params.id, 10) % 2 === 0 ? 'even-row' : 'odd-row'
                      }

                      sortModel={[
                        {
                          field: 'MatSt',
                          sort: 'asc',
                        },
                      ]}

                      slots={{
                        toolbar: CustomToolbar,
                      }}

                      slotProps={{
                        toolbar: { setRows, setRowModesModel, columns, handleExportExcel },
                      }}

                      sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light',

                        // Key improvements for horizontal scrolling and full field width:



                        '& .MuiDataGrid-cell': {
                          fontFamily: 'Amiri',
                          fontSize: '20px',
                          textAlign: 'center',
                          backgroundColor: 'rgba(255,255,255,0.65)',
                          borderRight: '1px solid #ccc', // Add this line to apply right border to the cell


                        },

                        '& .MuiDataGrid-row:hover': {
                          backgroundColor: '#2b84dd', // Background color when row is hovered
                        },


                        '& .even-row': {
                          backgroundColor: '#D3D3D3', // Background color for even rows
                        },
                        '& .odd-row': {
                          backgroundColor: '#ffffff', // Background color for odd rows
                        },


                      }}


                    />



                  </Grid>
                </Grid>


              </ThemeProvider>
            </CacheProvider>

          </Box>



        </div>
        <ConfirmationDialog open={isConfirmationOpen} onClose={handleConfirmationResponse} />
        <SaveConfirmationDialog open={isSaveConfirmationOpen} onClose={handleSaveConfirmationResponse} />
      </Box>
      <Footer />
    </>
  );
}

export default ValidationCandInfo;
