import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import TextSnippetIcon from '@mui/icons-material/TextSnippet'; // Ensure this import

const MyDialog = ({ open, handleClose, prenom, nom, urls, text }) => {
  const dialogTitle = `${prenom} ${nom}`;

  // Ensure urls is always an array
  const urlArray = Array.isArray(urls) ? urls : [];

  // Determine the content to display based on the availability of `urls` or `text`
  const content = urlArray.length > 0 ? (
    urlArray.map((url, index) => (
      <Typography
        key={index}
        component="div"
        sx={{
          fontFamily: 'Tajawal, sans-serif',
          fontSize: '20px',
          padding: '8px',
          whiteSpace: 'pre-line',
          wordBreak: 'break-word',
        }}
      >
        <a href={url} target="_blank" rel="noopener noreferrer" style={{ color: '#1e88e5' }}>
          {url}
        </a>
      </Typography>
    ))
  ) : text ? (
    <Typography
      component="div"
      sx={{
        fontFamily: 'Tajawal, sans-serif',
        fontSize: '20px',
        padding: '8px',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      }}
    >
      {text}
    </Typography>
  ) : (
    <Typography
      component="div"
      sx={{
        fontFamily: 'Tajawal, sans-serif',
        fontSize: '20px',
        padding: '8px',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
        color: '#757575', // Optional: gray color for empty state
      }}
    >
      No content available
    </Typography>
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{
        fontFamily: '"Tajawal", sans-serif',
        fontSize: 18,
        fontWeight: 600,
      }}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: '#c62828', // Red color
            color: '#FFFFFF', // White text
            '&:hover': {
              backgroundColor: '#b71c1c', // Darker red on hover
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: '"Tajawal", sans-serif',
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            إغلاق
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MyDialog;
