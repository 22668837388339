import React, { useState, useEffect, useRef } from 'react';

import { Typography, Box } from '@mui/material';
import axios from 'axios';

import { InputLabel, Select, MenuItem } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ApiIcon from '@mui/icons-material/Api';
import FunctionsIcon from '@mui/icons-material/Functions';
import useAuthentication_etab from './useAuthentication_etab';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';


import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { arSD } from '@mui/x-data-grid/locales';

import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { CacheProvider } from "@emotion/react";

import Checkbox from '@mui/material/Checkbox';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import XLSX from 'sheetjs-style';
import Footer from './Footer';
import Header from './Header';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import createCache from "@emotion/cache";

import '../containers/amirifont.css'; // Import the font CSS file
import Divider from '@mui/material/Divider';
import LockIcon from '@mui/icons-material/Lock';
import { makeStyles } from "@material-ui/core/styles";

const defaultColor = "#ff0000";
const hoverColor = "#0000ff";
const focusColor = "#00ff00";

const themetextfieldnew = createTheme({
  direction: 'rtl',
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: hoverColor
        },
        "&$focused $notchedOutline": {
          borderColor: focusColor
        }
      },
      notchedOutline: {
        borderColor: defaultColor
      }
    }
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  }
}, arSD);

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});



async function loginUser(email, MotDePass) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, MotDePass: MotDePass }),
    });

    if (!response.ok) {
      throw new Error('Invalid credentials');
    }

    const data = await response.json();
    const token = data.token;
    return token;
  } catch (error) {
    console.error('Error logging in:', error.message);
    return null;
  }
}


const StyledBox = styled('div')(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {

  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only alphabets (a to z).');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only Arabic alphabets.');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}


const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}


const apiUrl = `${API_BASE_URL}/api/getapis_userlist_functions`;

function CustomToolbar({ setRows, setRowModesModel, handleExportExcel, handleAddRecord }) {
  return (


    <GridToolbarContainer style={{ fontFamily: 'Amiri', fontSize: '20px' }} >
      <Button
        onClick={handleAddRecord}
        variant="contained"
        endIcon={<AddCircleOutlineIcon sx={{ fontSize: 40 }} />}
        style={{ backgroundColor: 'green', width: 'fit-content' }}
      >
        <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 24, fontWeight: 900 }}>
          إضافة
        </Typography>
      </Button>

      <GridToolbarColumnsButton style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <GridToolbarFilterButton style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <GridToolbarDensitySelector style={{ fontFamily: 'Amiri', fontSize: '20px' }} />
      <Button style={{ fontFamily: 'Amiri', fontSize: '20px' }} onClick={handleExportExcel} color="primary">
        تنزيل كملف إكسال
      </Button>


      <GridToolbarExport style={{ fontFamily: 'Amiri', fontSize: '20px' }} />

      <GridToolbarQuickFilter
        style={{ fontFamily: 'Amiri', fontSize: '20px', marginRight: '25px' }}
        inputProps={{ style: { fontFamily: 'Amiri', fontSize: '20px' } }}
      />
    </GridToolbarContainer>
  );
}




function Apis_userlist_functions() {

  const { IDetablissement, IDDFEP, IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  } = useAuthentication_etab();

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isConfirmationOpen_token, setConfirmationOpen_token] = useState(false);
  const [confirmed_token, setconfirmed_token] = useState(false);

  const [apis_userslistdb, setapis_userslistdb] = useState([]);
  const [apis_functionsdb, setapis_functionsdb] = useState([]);




  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState('');

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [valuegenpw, setvaluegenpw] = useState('');
  const [valuegenpw_nomUser, setvaluegenpw_nomUser] = useState('');


  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);

  const [privilege_users_data, setprivilege_users_data] = useState([]);
  const [selectedID_privilege_users, setselectedID_privilege_users] = useState('');


  const editedValueRef = useRef(editedValue);

  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);

  const navigate = useNavigate();



  const [options_username, setOptions_username] = useState([]);
  const [options_functionname, setOptions_functionname] = useState([]);

  

  useEffect(() => {
    // Log the edited values whenever rows are updated
    //////////console.log('Edited Values:', editedValue);
  }, [editedValue]);


  // Fetch data from the API when the component mounts
  React.useEffect(() => {

    fetch(apiUrl,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })



      .then((response) => response.json())
      .then((data) => {
        const apiRows = data.data.map((item, index) => ({
          id: item.idapis_userlist_functions, // Maintain original column name for clarity
          id_rows: index + 1,
          idapis_userlist_functions: item.idapis_userlist_functions, // Maintain original column name for clarity
          IDapis_userslist: item.IDapis_userslist, // Use the appropriate column name
          idapis_functions: item.idapis_functions,
          actions: '',
        }));
        setRows(apiRows);
        rowsdata = apiRows;
      })
      .catch((error) => console.error('Error fetching data:', error));


    // Fetch data from the backend API
    axios.get(`${API_BASE_URL}/api/getapis_userslist`
      , {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => {
        setapis_userslistdb(response.data);
        const dataapi = response.data.data;
 
    // Log the response data to inspect its structure
    ///console.log('Response data:', dataapi);
    if (Array.isArray(dataapi)) {
      // Fetch your data or use it directly if already available
      const fetchedOptions = dataapi.map((item, i) => ({
        value: item.IDapis_userslist,
        label: item.Nom,
        indexvalue: i
      }));

      // Set the options in the state
      setOptions_username(fetchedOptions);

    } else {
      //console.error('Response data is not an array:', dataapi);
    }

        // Alert the fetched data
        /// alert(JSON.stringify(fetchedOptions));
      })
      .catch(error => {
        console.error('Error fetching getapis_userslist:', error);
      });

    ////////////////////


    // Fetch data from the backend API
    axios.get(`${API_BASE_URL}/api/getapis_functions`
      , {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => {
        setapis_functionsdb(response.data);

        const dataapifun = response.data.data;
 
        // Log the response data to inspect its structure
        ///console.log('Response data:', dataapifun);
        if (Array.isArray(dataapifun)) {
          // Fetch your data or use it directly if already available
          const fetchedOptions = dataapifun.map((item, i) => ({
            value: item.idapis_functions,
            label: item.Nom,
            indexvalue: i
          }));
    
          // Set the options in the state
          setOptions_functionname(fetchedOptions);
    
        } else {
          //console.error('Response data is not an array:', dataapifun);
        }


        // Alert the fetched data
        ///alert(JSON.stringify(response.data));
      })
      .catch(error => {
        console.error('Error fetching getapis_functions:', error);
      });





  }, []);


  // Render Cell for apis_functions
  const renderCell_apis_functions = (params) => {
    // Check if apis_functions is defined and has the data property
    if (!apis_functionsdb || !apis_functionsdb.data) {
      return null; // or any other fallback value
    }

    // Use find method only if data property of apis_functionsdb is an array
    const rowData = apis_functionsdb.data.find(item => Number(item.idapis_functions) === Number(params.value));

    return (
      <div>
        {rowData ? rowData.Nom : '/'}
      </div>
    );
  };

  // Render EditCell for apis_userslist
  const renderEditCell_apis_functions = (params) => {
    return (

      ///options_functionname

      <Grid item xs={12}>
  <Autocomplete
  options={options_functionname}
  getOptionLabel={(option) => option.label}
  
    sx={{
      fontFamily: 'Amiri',
      fontSize: '18px',
      fontWeight: 'bold',
    }}
    value={
      selectedIDs_apis_functions[params.value] !== undefined
        ? selectedIDs_apis_functions[params.value]
        : options_functionname.find(option => Number(option.value) === Number(params.value))
    }
 
    onChange={(event, value) => {
      handleChange_privilege_users_apis_functions(params.row.id, value);
    }}

    renderOption={(props, option) => {
      const { label, indexvalue } = option;
      return (
        <span
          {...props}
          style={{
            backgroundColor: indexvalue % 2 === 0 ? '#004d40' : '#FFFFFF',
            color: indexvalue % 2 === 0 ? '#FFFFFF' : '#004d40',
            fontFamily: '"Tajawal", sans-serif',
            fontSize: 18,
            fontWeight: 700,
          }}
        >
          {indexvalue === -1 ? '- ' + label : label}
        </span>
      );
    }}

    renderInput={(params) => (
      <Box display="flex" justifyContent="center">
        <TextField fullWidth {...params} label="تسمية الدالة" />
      </Box>
    )}

  />
</Grid>



    );
  };






  // Render Cell for apis_userslist
  const renderCell_apis_userslist = (params) => {
    // Check if apis_userslistdb is defined and has the data property
    if (!apis_userslistdb || !apis_userslistdb.data) {
      return null; // or any other fallback value
    }

    // Use find method only if data property of apis_userslistdb is an array
    const rowData = apis_userslistdb.data.find(item => Number(item.IDapis_userslist) === Number(params.value));

    return (
      <div>
        {rowData ? rowData.Nom : '/'}
      </div>
    );
  };

  // Render EditCell for apis_userslist
  const renderEditCell_apis_userslist = (params) => {
    return (
      
       <Grid item xs={12}>
  <Autocomplete
  options={options_username}
  getOptionLabel={(option) => option.label}
  
    sx={{
      fontFamily: 'Amiri',
      fontSize: '18px',
      fontWeight: 'bold',
    }}
    value={
      selectedIDs[params.value] !== undefined
        ? selectedIDs[params.value]
        : options_username.find(option => Number(option.value) === Number(params.value))
    }
     onChange={(event, value) => {
      handleChange_privilege_users(params.row.id, value);
    }}
    renderOption={(props, option) => {
      const { label, indexvalue } = option;
      return (
        <span
          {...props}
          style={{
            backgroundColor: indexvalue % 2 === 0 ? '#004d40' : '#FFFFFF',
            color: indexvalue % 2 === 0 ? '#FFFFFF' : '#004d40',
            fontFamily: '"Tajawal", sans-serif',
            fontSize: 18,
            fontWeight: 700,
          }}
        >
          {indexvalue === -1 ? '- ' + label : label}
        </span>
      );
    }}

    renderInput={(params) => (
      <Box display="flex" justifyContent="center">
        <TextField fullWidth {...params} label="ّإسم المستخدم" />
      </Box>
    )}

  />
</Grid>
   
    );
  };



  const renderEditCellWithFontSize = (params, fontSize) => {
    return (
      <TextField
        value={params.value}
        onChange={(e) => params.api.setValue(params.id, e.target.value)}
        style={{ fontSize: fontSize }} // Apply the specified font size here
      />
    );
  };


  // Define state variable to store selected IDs for each Select component
  const [selectedIDs, setSelectedIDs] = useState({});
  const [selectedIDs_apis_functions, setSelectedIDs_apis_functions] = useState({});


  // Handler to update selected ID for a specific Select component
  const handleChange_privilege_users = (idRows, value) => {
   ///alert('idRows: '+idRows+' value: '+value);

    setSelectedIDs(prevState => ({
      ...prevState,
      [idRows]: value !== null && value !== undefined ? value : null,
    }));
 

  };


  // Handler to update selected ID for a specific Select component
  const handleChange_privilege_users_apis_functions = (idRows, value) => {
     ///const newID = event.target.value;
     ///alert('idRows: '+idRows+' value: '+value);
 


    // Update selectedIDs state
    setSelectedIDs_apis_functions(prevState => ({
      ...prevState,
      [idRows]: value !== null && value !== undefined ? value : null,
    }));



  };






  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.commit) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);

      if (editedRow) {
        /// alert(`Editing stopped for ID: ${editedRow.id}`);
        // Trigger the save operation for the edited row
        ////handleSaveOperation(editedRow.id);


      }
    }
  };




  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };



  const [idsaved, setidsaved] = useState(0);


  const [confirmationTrigger, setConfirmationTrigger] = useState(false);



  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);



  const handleDeleteClick = (id) => () => {

    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { id } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      setDeletedRowId(id);
      //// setDeleted_NomAr(Nom);


    }


  };




  const handleConfirmationResponse = async (confirmed) => {

    // Close the confirmation dialog
    setConfirmationOpen(false);
    if (confirmed) {
      const idToDelete = deletedRowId;
      setDeletedRowId(null); // Reset deletedRowId to avoid multiple deletions

      try {
        // Send the API request to delete the row from the Laravel backend
        const response = await fetch(`${API_BASE_URL}/api/deleteRow_apis_userlist_functions/${idToDelete}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });

        if (response.ok) {
          // If the API request is successful, update the local state to remove the row
          setRows((prevRows) => prevRows.filter((row) => row.id !== idToDelete));
          ////////console.log('Row deleted successfully from Laravel backend.');
          setSnackbar({ children: '  تم الحذف بنجاح  ', severity: 'success' });

        } else {
          console.error('Failed to delete row from Laravel backend.');
          // Handle the error or display a notification to the user
        }
      } catch (error) {
        console.error('Error during the API request:', error);
        // Handle the error or display a notification to the user
      }
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {

    setRowModesModel(newRowModesModel);


  };

  const handleSaveConfirmationResponse = async (confirmed) => {


    if (confirmed) {

    }
    setconfirmed_token(false);


  };


  const processRowUpdate = async (newRow) => {


    // Check if the row is new
    const isNewRow = rows.find((row) => row.id === newRow.id)?.isNew;

    // Preserve the original isNew value if it exists
    const updatedRow = { ...newRow, isNew: isNewRow };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    try {
      // Call handleSaveOperation with the id

      await handleSaveOperation(newRow.id, updatedRow);
    } catch (error) {
      console.error('Error during handleSaveOperation:', error);
    }



    return updatedRow;
  };





  const handleSaveOperation = async (id, updatedRow) => {
    // Check if updatedRow is defined and has the IDapis_userslist property

    let newID;

    if (selectedIDs[id] !== undefined) {
      newID = selectedIDs[id].value;
    } else if (updatedRow && updatedRow.IDapis_userslist) {
      newID = updatedRow.IDapis_userslist;
    }

    let newID2;

    if (selectedIDs_apis_functions[id] !== undefined) {
      newID2 = selectedIDs_apis_functions[id].value;
    } else if (updatedRow && updatedRow.idapis_functions) {
      newID2 = updatedRow.idapis_functions;
    }

  

    const editedRow = { ...updatedRow, IDapis_userslist: newID, idapis_functions: newID2 };

    if (!editedRow) {
      console.error('Row not found for ID:', id);
      return;
    }

      //alert(`Row Data After Update: ${JSON.stringify(selectedIDs[id])}`);
      ////alert('IDapis_userslist: ', newID+ ' idapis_functions: ', newID2)

    try {
      let response;


      // If the row is not new, it means it's already in the database, so update it
      response = await fetch(`${API_BASE_URL}/api/updateorinsert_apis_userlist_functions`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', // Specify the content type
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        },
        body: JSON.stringify(editedRow),
      });


      if (response.ok) {

        const responseData = await response.json(); // Extract JSON from the response
        /// alert(`Row Data After Update: ${JSON.stringify(responseData.data)}`);

        ///alert(responseData.data); // Alert the token from the response

        // Update the datagrid with the edited row
        setRows(prevRows => {
          return prevRows.map(row => {
            if (row.id === id) {
              // If the row being updated has the same id as the edited row
              // Update all fields except for the MotDePass
              return { ...editedRow, isNew: false, MotDePass: '****' };
            }
            return row;
          });
        });




        // Set the snackbar message
        setSnackbar({ children: '  تم الحفظ  ', severity: 'success' });

        // Set the row mode to view after saving
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });


        fetch(apiUrl,
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
            }
          })


          .then((response) => response.json())
          .then((data) => {
            const apiRows = data.data.map((item, index) => ({
              id: item.idapis_userlist_functions, // Maintain original column name for clarity
              id_rows: index + 1,
              idapis_userlist_functions: item.idapis_userlist_functions, // Maintain original column name for clarity
              IDapis_userslist: item.IDapis_userslist, // Use the appropriate column name
              idapis_functions: item.idapis_functions,
              actions: '',
            }));
            setRows(apiRows);
            rowsdata = apiRows;
          })
          .catch((error) => console.error('Error fetching data:', error));


      } else {
        console.error('Failed to save record in the database.');

        // Extract validation error messages from the API response
        const errorData = await response.json();
        const validationErrors = errorData?.error;
        console.error('validationErrors:', validationErrors);
        console.error('validationmessage:', errorData.message);

        // Concatenate validation error messages
        const errorMessage =
          'الأخطاء:' +
          Object.values(validationErrors || {}).join(', ');

        console.error('errorMessage:', errorMessage);

        setSnackbar({ children: validationErrors, severity: 'error' });


      }
    } catch (error) {
      console.error('Error during the API request:', error);

      // Set the snackbar message for the error
      setSnackbar({ children: 'حدث خطأ', severity: 'error' });
    }



  };




  const SaveConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCancelClick = (id) => () => {

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };


  const CopyToClipboardButton_handlecopytoken_new = (value, Nom) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    ////setSnackbar({ children:  `  تم نسخ  ${value}  `, severity: 'success' });
    setSnackbar({ children: <span dangerouslySetInnerHTML={{ __html: ` تم توليد مفتاح (Token) جديد و نسخه بنجاح  <div dir="ltr">${Nom}</div>` }} />, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };


  const CopyToClipboardButton_handlecopytoken = (value, Nom) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    ////setSnackbar({ children:  `  تم نسخ  ${value}  `, severity: 'success' });
    setSnackbar({ children: <span dangerouslySetInnerHTML={{ __html: ` تم نسخ مفتاح (Token) <div dir="ltr">${Nom}</div>` }} />, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };

  const handlecopytoken = (id) => {


    const rowtokens = rows.find((row) => row.id === id);
    if (rowtokens) {
      // Extract NomAr value from the row
      const { tokenofapi } = rowtokens;
      const { Nom } = rowtokens;
      CopyToClipboardButton_handlecopytoken(tokenofapi, Nom);

    }

  };


  const handlenewtoken = async (id, updatedRow) => {
    const rowtokens = rows.find((row) => row.id === id);
    if (!rowtokens) {
      console.error('Row not found for ID:', id);
      return;
    }

    const { tokenofapi, Nom, IDapis_userslist } = rowtokens;

    //alert(`Row Data After Update: ${JSON.stringify(rowtokens)}`);

    try {
      const response = await fetch(`${API_BASE_URL}/api/updatetoken_apis_userslist`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        },
        body: JSON.stringify(rowtokens),
      });

      if (response.ok) {
        const responseData = await response.json(); // Extract JSON from the response
        /// alert(responseData.token); // Alert the token from the response


        // Update the datagrid with the edited row
        setRows(prevRows => {
          return prevRows.map(row => {
            if (row.id === id) {
              // If the row being updated has the same id as the edited row
              // Update all fields except for the MotDePass
              return { ...rowtokens, tokenofapi: responseData.token, isNew: false, MotDePass: '****' };
            }
            return row;
          });
        });

        CopyToClipboardButton_handlecopytoken_new(responseData.token, Nom);


        ///setSnackbar({ children: ' تم توليد مفتاح جديد و حفظه بنجاح ', severity: 'success' });


        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      } else {
        console.error('Failed to save record in the database.');
        const errorData = await response.json();
        const validationErrors = errorData?.error;
        console.error('validationErrors:', validationErrors);
        console.error('validationmessage:', errorData.message);

        const errorMessage = 'الأخطاء:' + Object.values(validationErrors || {}).join(', ');
        console.error('errorMessage:', errorMessage);
        setSnackbar({ children: errorMessage, severity: 'error' });
      }
    } catch (error) {
      console.error('Error during the API request:', error);
      setSnackbar({ children: 'حدث خطأ', severity: 'error' });
    }
  };







  const [addRecordClicked, setAddRecordClicked] = useState(false);

  useEffect(() => {
    if (addRecordClicked) {
      handleAddRecord();
      setAddRecordClicked(false);
    }
  }, [addRecordClicked]);

  const handleAddClick = () => {
    setIsAdding(true);
    setAddRecordClicked(true);
  };




  const handleAddRecord = () => {
    const id = uuidv4();

    // Default values
    let defaultIDapis_userslist = '';

    // If rows have been fetched and there is at least one row
    if (rows.length > 0) {
      // Use values from the last row
      const lastRow = rows[rows.length - 1];
      if (lastRow) {
        defaultIDapis_userslist = lastRow.IDapis_userslist || '';
      }
    }


    // Add an empty row at the end of the existing rows
    const emptyRow = {
      id: id,
      idapis_userlist_functions: 'addnew',
      IDapis_userslist: 0,
      idapis_functions: 0,
      actions: '',
      isNew: true,
    };

    setRows((oldRows) => [...oldRows, emptyRow]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'IDapis_userslist' },
    }));
  };








  /*

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  
    // Check if a row is in edit mode
    const editedRowId = Object.keys(newRowModesModel).find(
      (id) => newRowModesModel[id]?.mode === GridRowModes.Edit
    );
  
    // If a row is in edit mode, trigger the save operation
    if (editedRowId) {
      handleSaveOperation(editedRowId);
    }
  };

*/
  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };


  const useStyles = makeStyles({
    cancelBtn: {
      backgroundColor: "#004d40", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const ConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>


          <Dialog
            open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider >
                <Grid item sx={{ mt: 2 }} >
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحذف <DeleteIcon sx={{ ml: '0px' }} />
                  </Typography>
                </Grid>
              </Divider>


            </DialogTitle>



            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                هل أنت متأكد من أنك تريد حذف هذا السطر؟
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#004d40", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      </CacheProvider>
    );
  };










  const useStyles_token = makeStyles({
    cancelBtn: {
      backgroundColor: "#004d40", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const ConfirmationDialog_token = ({ open, onClose }) => {
    const classes = useStyles_token();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>


          <Dialog
            open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider >
                <Grid item sx={{ mt: 2 }} >
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحفظ <DeleteIcon sx={{ ml: '0px' }} />
                  </Typography>
                </Grid>
              </Divider>


            </DialogTitle>



            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                هل تريد توليد مفتاح Token جديد؟

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#004d40", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      </CacheProvider>
    );
  };



  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map(col => ({ name: col.field, headerName: col.headerName }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
      const newRow = {};
      relevantColumns.forEach(col => {
        newRow[col.headerName] = row[col.name];
      });
      return newRow;
    }));

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };



  const handleSaveClick = (id, params) => () => {

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

    /*const rowToDelete = rows.find((row) => row.id === id);
       if (rowToDelete) {
        // Extract NomAr value from the row
        const { id } = rowToDelete;
         // Open the confirmation dialog
         setConfirmationOpen_token(true);
       setDeletedRowId(id);
  
      }*/


  };



  const columns = [
    {
      field: 'id_rows',
      headerName: 'ترقيم',
      minWidth: 70,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'IDapis_userslist',
      headerClassName: 'super-app-theme--header',
      headerName: 'إسم المستخدم',
      cellClassName: 'cell-editable', // Add a custom header class for this column
      editable: true, // Adjust based on your requirements
      minWidth: 400, // Set a minimum width
      align: 'center',
      headerAlign: 'center',
      renderCell: renderCell_apis_userslist,
      renderEditCell: renderEditCell_apis_userslist


    }
    ,
    {
      field: 'idapis_functions',
      headerClassName: 'super-app-theme--header',
      headerName: 'تسمية الدالة',
      cellClassName: 'cell-editable', // Add a custom header class for this column
      editable: true, // Adjust based on your requirements
      flex: 1, // Allow column to grow/shrink
      minWidth: 600, // Set a minimum width
      align: 'center',
      headerAlign: 'center',
      renderCell: renderCell_apis_functions,
      renderEditCell: renderEditCell_apis_functions



    }

    ,


    ///{ field: 'crypted_id', headerName: 'crypted_id', width: 200, editable: false },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'إجراءات',
      align: 'left',
      minWidth: 220,
      cellClassName: 'actions',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      getActions: ({ id, params }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Button
              onClick={handleSaveClick(id, params)}
              variant="contained" endIcon={<SaveIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />} style={{ backgroundColor: 'green', width: 'fit-content' }}>
              <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
                حفظ
              </Typography>
            </Button>

            ,
            <Button
              onClick={handleCancelClick(id)}
              variant="contained" endIcon={<CancelIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />} style={{ backgroundColor: 'grey', width: 'fit-content' }}>
              <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
                إلغاء
              </Typography>
            </Button>
            ,
          ];
        }

        return [

          <Button
            onClick={handleEditClick(id)}
            variant="contained" endIcon={<EditIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />} style={{ backgroundColor: '#4CAF50', width: 'fit-content' }}>
            <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
              تعديل
            </Typography>
          </Button>

          /* <GridActionsCellItem
            icon={<EditIcon style={{ color: 'green' }} />}
            label="Edit"
            className="textPrimary"
            
            color="inherit"
          />*/


          ,
          <Button
            onClick={handleDeleteClick(id)}
            variant="contained" endIcon={<DeleteIcon sx={{ mr: '0px', ml: '0px', transform: 'rotate(0deg)' }} />} style={{ backgroundColor: 'red', width: 'fit-content' }}>
            <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
              حذف
            </Typography>
          </Button>
          /*
            <GridActionsCellItem
              icon={<DeleteIcon style={{ color: 'red' }} />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
   
             /> */



          ,
        ];
      },
    },
  ];


  const styles_cell_editable = {
    cell_editable: {
      border: '2px solid green', // Define the border style
    },
  };

  const CellRenderer = ({ params }) => {
    const { id, value } = params;

    const [isActive, setIsActive] = useState(Number(value) === 1);


    const handleChange = () => {
      setIsActive(!isActive);
    };

    return (

      <div>
        <Checkbox
          checked={isActive}
          onChange={handleChange}
          disabled={!params.row.editable}
          color="success" // Set the color to "success"
        />
        {isActive ? 'قَيْد التشغيل' : 'مُعَطَّل'}
      </div>
    );
  };


  const EditCellRenderer = ({ params }) => {
    const { id, value, api, field } = params;
    const [isActive, setIsActive] = useState(Number(value) === 1);

    const handleChange = () => {
      setIsActive(!isActive);
      api.setEditCellValue({ id, field, value: isActive ? "0" : "1" });
    };

    return (


      <Checkbox
        checked={isActive}
        onChange={handleChange}
        color="success" // Set the color to "success"
      />

    );
  };



  function generateRandomString(length = 8) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function generateRandomString_nomUser(length = 8) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return IDetablissement + '_' + result;
  }




  const CopyToClipboardButton = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };

  const CopyToClipboardButton_nomUser = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    ////setSnackbar({ children:  `  تم نسخ  ${value}  `, severity: 'success' });
    setSnackbar({ children: <span dangerouslySetInnerHTML={{ __html: `تم نسخ <div dir="ltr">${value}</div>` }} />, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };



  useEffect(() => {
    if (!loadingauth) {
      if (
        IDetablissement === null ||
        IDDFEP === null ||
        IDNature_etsF === null ||
        Number(IDNature_etsF) !== 1
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/');
        return;
      }


    }
  }, [IDetablissement, IDDFEP, IDNature_etsF, IDCandidat, NumSem, IDapis_userslist,
    loadingauth, navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep
  ]); // Add navigate to dependencies

  if (loadingauth) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }


  return (
    <>

      <Header
        IDetablissement={IDetablissement}
        IDDFEP={IDDFEP}
        IDNature_etsF={IDNature_etsF}
        IDCandidat={IDCandidat}
        NumSem={NumSem}
        IDapis_userslist={IDapis_userslist}
        loadingauth={loadingauth}
        Singup_active={Singup_active}
        download_cert_scol_active={download_cert_scol_active}
        EditingInfo_active={EditingInfo_active}
        Eval_notes_active={Eval_notes_active}
        download_cert_inscription_active={download_cert_inscription_active}
        Singup_active_Dfep={Singup_active_Dfep}
        download_cert_scol_active_Dfep={download_cert_scol_active_Dfep}
        EditingInfo_active_Dfep={EditingInfo_active_Dfep}
        Eval_notes_active_Dfep={Eval_notes_active_Dfep}
        download_cert_inscription_active_Dfep={download_cert_inscription_active_Dfep}
        Singup_active_Mfep={Singup_active_Mfep}
        download_cert_scol_active_Mfep={download_cert_scol_active_Mfep}
        EditingInfo_active_Mfep={EditingInfo_active_Mfep}
        Eval_notes_active_Mfep={Eval_notes_active_Mfep}
        download_cert_inscription_active_Mfep={download_cert_inscription_active_Mfep}
      />







      <Box
        sx={{
          '& .super-app-theme--header': {
            fontFamily: 'Amiri',
            fontSize: '20px',
            fontWeight: 'bold', // Make the font bold
            backgroundColor: '#B9D3EE',
            borderRight: '1px solid #ccc', // Add this line to apply right border to the cell
          }

        }}

      >

        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={themetextfieldnew}>



            {!!snackbar && (
              <Snackbar
                open
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                sx={{
                  marginTop: 12
                }}
              >
                <Alert
                  {...snackbar}
                  onClose={handleCloseSnackbar}
                  sx={{
                    display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
                    , '& .MuiAlert-action': { marginRight: '8px' }
                  }} // Adjust padding here
                />
              </Snackbar>

            )}



            <Grid container   >


              <Grid item sx={{ width: "100%" }}>

                <Divider sx={{ mt: '20px' }}>
                  <Grid item  >
                    <Typography
                      style={{
                        color: "#004d40",
                        fontFamily: '"Tajawal", sans-serif',
                        fontSize: 25,
                        fontWeight: 700,
                        marginBottom: '25px'
                      }}
                      variant="h3" gutterBottom>
                      <FunctionsIcon sx={{ mr: '2px', fontSize: 30, }} />
                      <ApiIcon sx={{ mr: '2px', fontSize: 30, }} />
                      الدالة و الحساب (API)

                    </Typography>
                  </Grid>

                </Divider>



                <Grid container justifyContent="center">



                </Grid>





              </Grid>


              <DataGrid
                locale="ar-SD"
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                getRowId={(row) => row.id}
                getRowClassName={(params) =>
                  parseInt(params.row.id_rows, 10) % 2 === 0 ? 'even-row' : 'odd-row'
                }

                slots={{
                  toolbar: CustomToolbar,
                }}

                slotProps={{
                  toolbar: { setRows, setRowModesModel, columns, handleExportExcel, handleAddRecord },
                }}

                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',


                  '& .MuiDataGrid-editInputCell': {
                    fontFamily: 'Amiri',
                    fontSize: '20px',
                    textAlign: 'center',
                    backgroundColor: 'rgba(255,255,255,0.65)',
                    borderRight: '1px solid #ccc', // Add this line to apply right border to the cell


                  },

                  '& .MuiDataGrid-cell': {
                    fontFamily: 'Amiri',
                    fontSize: '20px',
                    textAlign: 'center',
                    backgroundColor: 'rgba(255,255,255,0.65)',
                    borderRight: '1px solid #ccc', // Add this line to apply right border to the cell


                  },
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: '#2b84dd', // Background color when row is hovered
                  },

                  '& .even-row': {
                    backgroundColor: '#2b84dd61', // Background color for even rows
                  },
                  '& .odd-row': {
                    backgroundColor: '#ffffff', // Background color for odd rows
                  },

                  '& .cell-editable': styles_cell_editable.cell_editable, // Apply styles to the header with class 'cell-editable'


                }}

                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 25, page: 0 },
                  },
                }}
              />



            </Grid>



          </ThemeProvider>
        </CacheProvider>

      </Box>


      <Footer />

      <ConfirmationDialog open={isConfirmationOpen} onClose={handleConfirmationResponse} />
      <ConfirmationDialog_token open={isConfirmationOpen_token} onClose={handleSaveConfirmationResponse} />


      {/* 
      
      <SaveConfirmationDialog open={isSaveConfirmationOpen} onClose={handleSaveConfirmationResponse} />

*/}
    </>
  );
}

export default Apis_userlist_functions;
